// React
import React, { useCallback, useEffect, useState } from "react";
// Third party
import { useNavigate, useParams } from "react-router-dom";
// Components
import Breadcrumbs from "../Components/Breadcrumbs/Breadcrumbs";
import Loader from "../Components/Loader";
// OpenAPI
import { apiConfig } from "../Components/ConfigurationApi/Configuration";
import { expiredTokenValidation } from "../api/expiredTokenValidation";
import { FileRestControllerApi, FundamentalRestControllerApi, SectorRestControllerApi } from "../openapi";
// Images
import fundamentalIcon from "../Assets/svg/blue-fundamental.svg";
import Table from "../Components/Tables/Recent-event";
import { Columns } from "../Components/Columns/Columns";
import { ResponseFile, Sort } from "../interfaces/pages/variedInterfaces";
import { getFileSize } from "../utils";
import moment from "moment";
import { getApiData } from "../Controller/getApiDataConfiguration";

const FundamentalDetails = () => {
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    fetchFundamentalData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchFundamentalNoteList = useCallback(async (pageIndex: number, pageSize: number, sortBy: Sort[]) => {
    const getFetchFundamentalNoteList = new getApiData();
    return getFetchFundamentalNoteList.getNoteRestControllerApi2(Number(id), pageIndex, pageSize, sortBy, "Fundamental");
  }, [id]);

  const fetchFundamentalData = async () => {
    setLoading(true);
    try {
      const api = new FundamentalRestControllerApi(apiConfig());
      const response = await api.getFundamentalById(Number(id));
      let sectorId;
      if (response.data.sectorId) {
        sectorId = await fetchSectorNameById(response.data.sectorId);
      }

      setData({ ...response?.data, sectorId } || {});
    } catch (error) {
      expiredTokenValidation(error);
    }
    setLoading(false);
  };

  const columnsInstance = new Columns();


  const fetchSectorNameById = async (sectorId: number) => {
    try {
      const api = new SectorRestControllerApi(apiConfig());
      const response = await api.getSectorById(sectorId);
      return response.data?.name || "";
    } catch (error) {
      expiredTokenValidation(error);
    }
  };

  const handleFileDownload = async (id: number, type: string) => {
    try {
      // Set Authorization header
      const headers: {} = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      const api = new FileRestControllerApi(apiConfig());
      const response = await api.downloadFile(id);
      const imageUrl = (response as ResponseFile).config.url;

      // Getting the image url
      const imageResponse = await fetch(imageUrl, { headers });

      // If the request image response it's ok create Blob with the imageResponse
      if (imageResponse.ok) {
        const imageBlob = await imageResponse.blob();

        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(imageBlob);

        // Config file name
        const parts = type.split(".");
        const fileName = parts[0];
        downloadLink.download = `${fileName}.${parts[1] || "png"}`;

        // Add url at document body and simulate click to start the donwload
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        return;
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <React.Fragment>
      {loading && <Loader loading={loading} />}
      <div className="p-[30px]">
        <Breadcrumbs
          breadcrumbs={["Fundamental", `${data?.name || ""}`]}
          icon={fundamentalIcon}
          addItem={() => navigate("/add-fundamental")}
          updateItem={() => navigate(`/update-fundamental/${id}`)}

        />
        <div className="px-[30px] pt-5 pb-[30px] border border-[#D4D2E6] rounded-md mt-[30px]">
          <div className="flex justify-center items-center gap-[35px]">
          </div>
          <div className="flex flex-col md:flex-row gap-[30px] mt-[29px]">
            <div className="md:w-1/2 flex flex-col">
              <div className=" overflow-x-auto">
                <div className=" min-w-full inline-block align-middle">
                  <div className="border border-[#DCDBEE] rounded-lg overflow-hidden bg-[#FAFAFE]">
                    <table className="min-w-full dark:bg-primary-dark">
                      <caption className="text-left text-[18px] font-semibold bg-primary-default dark:bg-black text-[#FFF] py-[19px] px-[29px]">
                        Company Information
                      </caption>
                      <tbody className="divide-y divide-[#DCDBEE]">
                        <tr>
                          <td className="px-[30px] py-[20px]  text-[#696868]  dark:text-white   text-base font-normal">
                            Ticker:
                          </td>
                          <td className="px-[30px] py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.ticker}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[30px] py-[20px]  text-[#696868] dark:text-white   text-base font-normal">
                            Sector:
                          </td>
                          <td className="px-[30px] py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.sectorId}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[30px] py-[20px]  text-[#696868] dark:text-white   text-base font-normal">
                            Incorporation:
                          </td>
                          <td className="px-[30px] py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.incorp}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[30px] py-[20px]  text-[#696868] dark:text-white   text-base font-normal">
                            Headquarters:
                          </td>
                          <td className="px-[30px] py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.hq}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[30px] py-[20px]  text-[#696868] dark:text-white   text-base font-normal">
                            Geo Exposure:
                          </td>
                          <td className="px-[30px] py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.geoExposure}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[30px] py-[20px]  text-[#696868] dark:text-white   text-base font-normal">
                            Date IPO:
                          </td>
                          <td className="px-[30px] py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.dateIpo}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[30px] py-[20px]  text-[#696868] dark:text-white   text-base font-normal">
                            Description:
                          </td>
                          <td className="px-[30px] py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.description}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[30px] py-[20px]  text-[#696868] dark:text-white   text-base font-normal">
                            Address:
                          </td>
                          <td className="px-[30px] py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.address}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:w-1/2 bg-[#FAFAFE] dark:bg-primary-dark">
              <div className="border border-[#E4E3ED] rounded-lg h-full">
                <div className=" px-[30px] py-[19px] border-b border-[#E5E4FB] bg-primary-default dark:bg-black text-[#fff] dark:text-white text-lg font-semibold flex items-center justify-between rounded-tl-md rounded-tr-md">
                  What Matters
                </div>
                <div className="bg-[#FAFAFE]]  px-[30px] py-[19px] ">
                  <div
                    className="ql-container ql-snow"
                    style={{ position: "relative" }}
                  >
                    <div
                      className="ql-editor"
                      dangerouslySetInnerHTML={{
                        __html: data?.whatMatters,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col md:flex-row gap-[30px] mt-[29px]">
            <div className="md:w-1/2 bg-[#FAFAFE] dark:bg-primary-dark">
              <div className="border border-[#E4E3ED] rounded-lg h-full">
                <div className=" px-[30px] py-[19px] border-b border-[#E5E4FB] bg-primary-default dark:bg-black text-[#fff] text-lg font-semibold flex items-center justify-between rounded-tl-md rounded-tr-md">
                  Business Description
                </div>
                <div className="bg-[#FAFAFE]] px-[30px] py-[19px] ">
                   <div
                    className="ql-container ql-snow"
                    style={{ position: "relative" }}
                  >
                    <div
                      className="ql-editor"
                      dangerouslySetInnerHTML={{
                        __html: data?.businessDesc,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="md:w-1/2 bg-[#FAFAFE] dark:bg-primary-dark">
              <div className="border border-[#E4E3ED] rounded-lg h-full">
                <div className=" px-[30px] py-[19px] border-b border-[#E5E4FB] bg-primary-default dark:bg-black text-[#fff] text-lg font-semibold flex items-center justify-between rounded-tl-md rounded-tr-md">
                  Company History
                </div>
                <div className="bg-[#FAFAFE]] px-[30px] py-[19px] ">
                   <div
                    className="ql-container ql-snow"
                    style={{ position: "relative" }}
                  >
                    <div
                      className="ql-editor"
                      dangerouslySetInnerHTML={{
                        __html: data?.companyHistory,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-[30px] mt-[29px]">
            <div className="md:w-1/2 bg-[#FAFAFE] dark:bg-primary-dark">
              <div className="border border-[#E4E3ED] rounded-lg h-full">
                <div className=" px-[30px] py-[19px] border-b border-[#E5E4FB] dark:bg-black bg-primary-default text-[#fff] text-lg font-semibold flex items-center justify-between rounded-tl-md rounded-tr-md">
                  Company Basics
                </div>
                <div className="bg-[#FAFAFE]] px-[30px] py-[19px] ">
                   <div
                    className="ql-container ql-snow"
                    style={{ position: "relative" }}
                  >
                    <div
                      className="ql-editor"
                      dangerouslySetInnerHTML={{
                        __html: data?.companyBasics,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="md:w-1/2 bg-[#FAFAFE] dark:bg-primary-dark">
              <div className="border border-[#E4E3ED] rounded-lg h-full">
                <div className=" px-[30px] py-[19px] border-b border-[#E5E4FB] bg-primary-default dark:bg-black text-[#fff] text-lg font-semibold flex items-center justify-between rounded-tl-md rounded-tr-md">
                  Drivers / Valuation
                </div>
                <div className="bg-[#FAFAFE]] px-[30px] py-[19px] ">
                   <div
                    className="ql-container ql-snow"
                    style={{ position: "relative" }}
                  >
                    <div
                      className="ql-editor"
                      dangerouslySetInnerHTML={{
                        __html: data?.driversValuation,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full mt-[30px]">
            <div className="border border-[#E4E3ED] rounded-lg ">
              <div className=" px-[30px] py-[19px] border-b dark:bg-black border-[#E5E4FB] bg-primary-default text-[#fff] text-lg font-semibold flex items-center justify-between rounded-tl-md rounded-tr-md">
                Valuation History
              </div>
              <div className="px-[33px] py-[25px]">
                 <div
                    className="ql-container ql-snow"
                    style={{ position: "relative" }}
                  >
                    <div
                      className="ql-editor"
                      dangerouslySetInnerHTML={{
                        __html: data?.valuationHistory,
                      }}
                    />
                  </div>
              </div>
            </div>
          </div>

          <div className="overflow-x-auto mt-[30px]">
            <div className="min-w-full inline-block align-middle">
              <div className="border border-[#DCDBEE] rounded-lg overflow-hidden">
                <table className="min-w-full dark:bg-table-dark bg-table-light rounded-bl-lg rounded-br-lg">
                  <thead className="bg-primary-default dark:bg-black">
                    <tr>
                      <th
                        scope="col"
                        className="px-[30px] py-[20px] text-[#fff] text-base font-semibold text-left
                    "
                      >
                        Filename
                      </th>
                      <th
                        scope="col"
                        className="px-[30px] py-[20 px] text-[#fff] text-base font-semibold text-left"
                      >
                        Size
                      </th>
                      <th
                        scope="col"
                        className="px-[30px] py-[20px] text-[#fff] text-base font-semibold text-left"
                      >
                        Date modified
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-[#DCDBEE]">
                    {data?.files?.length > 0 ? (
                      data?.files?.map((item: any, index: number) => (
                        <tr key={`files-${index}`}>
                          <td className="px-[30px] py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            <button
                              onClick={() =>
                                handleFileDownload(item.fileId, item.filename)
                              }
                            >
                              {item.filename}
                            </button>
                          </td>
                          <td className="px-[30px] py-[20px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {getFileSize(item.size)}
                          </td>
                          <td className="px-[30px] py-[20px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {moment(item?.dateModified).format("MM/DD/YYYY")}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="text-center">
                        <td
                          colSpan={3}
                          className="text-primary font-bold text-base p-4"
                        >
                          None
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="mt-[30px]">
          <Table
            fetchData={fetchFundamentalNoteList}
            columns={columnsInstance.NotesListDetail()}
            additionalDataKey="notes"

            // openModal={openModal}
          />
        </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FundamentalDetails;
