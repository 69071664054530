import activeCompany from "../Assets/svg/active-company.svg";
import activeDashboard from "../Assets/svg/active-dashboard.svg";
import activeFile from "../Assets/svg/active-file.svg";
import activeFundamental from "../Assets/svg/active-fundamental.svg";
import activeLibrary from "../Assets/svg/active-library.svg";
import activeMna from "../Assets/svg/active-mna.svg";
import activeNote from "../Assets/svg/active-note.svg";
import Company from "../Assets/svg/company.svg";
import Dashboard from "../Assets/svg/dashboard.svg";
import DashboardL from "../Assets/svg/light-dashboard.svg";
import File from "../Assets/svg/file.svg";
import FileL from "../Assets/svg/light-file.svg";
import Fundamental from "../Assets/svg/fundamental.svg";
import FundamentalL from "../Assets/svg/light-fundamental.svg";
import Library from "../Assets/svg/library.svg";
import LibraryL from "../Assets/svg/light-library.svg";
import Mna from "../Assets/svg/mna.svg";
import MnaL from "../Assets/svg/light-mna.svg";
import Note from "../Assets/svg/note.svg";
import NoteL from "../Assets/svg/light-note.svg";
import Admin from "../Assets/svg/admin.svg";
import Regulatory from "../Assets/svg/Regulatory.svg";
import RegulatoryL from "../Assets/svg/light-regulatory.svg";

type NavbarData = {
  pathname: string;
  title: string;
  icon: string;
  lightIcon: string;
};

export const navbarData: NavbarData[] = [
  {
    pathname: "dashboard",
    title: "Dashboard",
    icon: Dashboard,
    lightIcon: DashboardL,
  },
  {
    pathname: "notes",
    title: "Notes",
    icon: Note,
    lightIcon: NoteL,
  },
  {
    pathname: "mnas",
    title: "MNAs",
    icon: Mna,
    lightIcon: MnaL,
  },
  {
    pathname: "fundamental",
    title: "Fundamental",
    icon: Fundamental,
    lightIcon: FundamentalL,
  },
  {
    pathname: "regulatory-library",
    title: "Regulatory Library",
    icon: Library,
    lightIcon: LibraryL,
  },
  {
    pathname: "files",
    title: "Files",
    icon: File,
    lightIcon: FileL,
  },
  {
    pathname: "error",
    title: "Admin",
    icon: Admin,
    lightIcon: Admin,
  },
  {
    pathname: "regulatory",
    title: "Regulatory",
    icon: Regulatory,
    lightIcon: RegulatoryL,
  },
];

export const menuItem = [
  {
    path: "/dashboard",
    icon: Dashboard,
    active: activeDashboard,
    name: "Dashboard",
  },
  {
    path: "/notes",
    icon: Note,
    active: activeNote,
    name: "Notes",
  },
  { path: "/mnas", icon: Mna, active: activeMna, name: "MNAs" },
  {
    path: "/regulatory-library",
    icon: Library,
    active: activeLibrary,
    name: "Regulatory Library",
  },
  {
    path: "/files",
    icon: File,
    active: activeFile,
    name: "Files",
  },
  {
    path: "/company",
    icon: Company,
    active: activeCompany,
    name: "Company",
  },
  {
    path: "/fundamental",
    icon: Fundamental,
    active: activeFundamental,
    name: "Fundamental",
  },
];
