/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * Request details for marking an alert as read. This is used to track which user has read specific data in our system. Domains data include MNA, Note, Fundamentals, etc. 
 * @export
 * @interface AlertReadRequest
 */
export interface AlertReadRequest {
    /**
     * ID of the alert to be marked as read
     * @type {number}
     * @memberof AlertReadRequest
     */
    'alertId': number;
}
/**
 * 
 * @export
 * @interface AssociationActionsLongConditionRequest
 */
export interface AssociationActionsLongConditionRequest {
    /**
     * 
     * @type {Array<ConditionRequest>}
     * @memberof AssociationActionsLongConditionRequest
     */
    'add'?: Array<ConditionRequest>;
    /**
     * 
     * @type {{ [key: string]: ConditionRequest; }}
     * @memberof AssociationActionsLongConditionRequest
     */
    'edit'?: { [key: string]: ConditionRequest; };
    /**
     * 
     * @type {Array<number>}
     * @memberof AssociationActionsLongConditionRequest
     */
    'delete'?: Array<number>;
}
/**
 * 
 * @export
 * @interface AssociationActionsLongDateEventRequest
 */
export interface AssociationActionsLongDateEventRequest {
    /**
     * 
     * @type {Array<DateEventRequest>}
     * @memberof AssociationActionsLongDateEventRequest
     */
    'add'?: Array<DateEventRequest>;
    /**
     * 
     * @type {{ [key: string]: DateEventRequest; }}
     * @memberof AssociationActionsLongDateEventRequest
     */
    'edit'?: { [key: string]: DateEventRequest; };
    /**
     * 
     * @type {Array<number>}
     * @memberof AssociationActionsLongDateEventRequest
     */
    'delete'?: Array<number>;
}
/**
 * 
 * @export
 * @interface AssociationActionsLongDomainAssociationRequest
 */
export interface AssociationActionsLongDomainAssociationRequest {
    /**
     * 
     * @type {Array<DomainAssociationRequest>}
     * @memberof AssociationActionsLongDomainAssociationRequest
     */
    'add'?: Array<DomainAssociationRequest>;
    /**
     * 
     * @type {{ [key: string]: DomainAssociationRequest; }}
     * @memberof AssociationActionsLongDomainAssociationRequest
     */
    'edit'?: { [key: string]: DomainAssociationRequest; };
    /**
     * 
     * @type {Array<number>}
     * @memberof AssociationActionsLongDomainAssociationRequest
     */
    'delete'?: Array<number>;
}
/**
 * 
 * @export
 * @interface AssociationActionsLongFeatureAssociationRequest
 */
export interface AssociationActionsLongFeatureAssociationRequest {
    /**
     * 
     * @type {Array<FeatureAssociationRequest>}
     * @memberof AssociationActionsLongFeatureAssociationRequest
     */
    'add'?: Array<FeatureAssociationRequest>;
    /**
     * 
     * @type {{ [key: string]: FeatureAssociationRequest; }}
     * @memberof AssociationActionsLongFeatureAssociationRequest
     */
    'edit'?: { [key: string]: FeatureAssociationRequest; };
    /**
     * 
     * @type {Array<number>}
     * @memberof AssociationActionsLongFeatureAssociationRequest
     */
    'delete'?: Array<number>;
}
/**
 * 
 * @export
 * @interface AssociationActionsLongLinkRequest
 */
export interface AssociationActionsLongLinkRequest {
    /**
     * 
     * @type {Array<LinkRequest>}
     * @memberof AssociationActionsLongLinkRequest
     */
    'add'?: Array<LinkRequest>;
    /**
     * 
     * @type {{ [key: string]: LinkRequest; }}
     * @memberof AssociationActionsLongLinkRequest
     */
    'edit'?: { [key: string]: LinkRequest; };
    /**
     * 
     * @type {Array<number>}
     * @memberof AssociationActionsLongLinkRequest
     */
    'delete'?: Array<number>;
}
/**
 * 
 * @export
 * @interface AssociationActionsLongLong
 */
export interface AssociationActionsLongLong {
    /**
     * 
     * @type {Array<number>}
     * @memberof AssociationActionsLongLong
     */
    'add'?: Array<number>;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof AssociationActionsLongLong
     */
    'edit'?: { [key: string]: number; };
    /**
     * 
     * @type {Array<number>}
     * @memberof AssociationActionsLongLong
     */
    'delete'?: Array<number>;
}
/**
 * 
 * @export
 * @interface AssociationActionsLongTagRequest
 */
export interface AssociationActionsLongTagRequest {
    /**
     * 
     * @type {Array<TagRequest>}
     * @memberof AssociationActionsLongTagRequest
     */
    'add'?: Array<TagRequest>;
    /**
     * 
     * @type {{ [key: string]: TagRequest; }}
     * @memberof AssociationActionsLongTagRequest
     */
    'edit'?: { [key: string]: TagRequest; };
    /**
     * 
     * @type {Array<number>}
     * @memberof AssociationActionsLongTagRequest
     */
    'delete'?: Array<number>;
}
/**
 * 
 * @export
 * @interface AssociationActionsLongTxDocSummaryRequest
 */
export interface AssociationActionsLongTxDocSummaryRequest {
    /**
     * 
     * @type {Array<TxDocSummaryRequest>}
     * @memberof AssociationActionsLongTxDocSummaryRequest
     */
    'add'?: Array<TxDocSummaryRequest>;
    /**
     * 
     * @type {{ [key: string]: TxDocSummaryRequest; }}
     * @memberof AssociationActionsLongTxDocSummaryRequest
     */
    'edit'?: { [key: string]: TxDocSummaryRequest; };
    /**
     * 
     * @type {Array<number>}
     * @memberof AssociationActionsLongTxDocSummaryRequest
     */
    'delete'?: Array<number>;
}
/**
 * 
 * @export
 * @interface AssociationActionsLongWhatMattersRequest
 */
export interface AssociationActionsLongWhatMattersRequest {
    /**
     * 
     * @type {Array<WhatMattersRequest>}
     * @memberof AssociationActionsLongWhatMattersRequest
     */
    'add'?: Array<WhatMattersRequest>;
    /**
     * 
     * @type {{ [key: string]: WhatMattersRequest; }}
     * @memberof AssociationActionsLongWhatMattersRequest
     */
    'edit'?: { [key: string]: WhatMattersRequest; };
    /**
     * 
     * @type {Array<number>}
     * @memberof AssociationActionsLongWhatMattersRequest
     */
    'delete'?: Array<number>;
}
/**
 * The response object that contains the information for the corresponding domain entity. It might be a MnaResponse, a NoteResponse, a FundamentalResponse, etc. 
 * @export
 * @interface Audited
 */
export interface Audited {
    /**
     * 
     * @type {number}
     * @memberof Audited
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof Audited
     */
    'dateModified'?: string;
    /**
     * 
     * @type {string}
     * @memberof Audited
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof Audited
     */
    'createdBy'?: UserAccountInfo;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof Audited
     */
    'lastModifiedBy'?: UserAccountInfo;
}
/**
 * 
 * @export
 * @interface AuthenticationRequest
 */
export interface AuthenticationRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthenticationRequest
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface AuthenticationResponse
 */
export interface AuthenticationResponse {
    /**
     * 
     * @type {number}
     * @memberof AuthenticationResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationResponse
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationResponse
     */
    'accessToken'?: string;
}
/**
 * 
 * @export
 * @interface CompactMnaResponse
 */
export interface CompactMnaResponse {
    /**
     * 
     * @type {number}
     * @memberof CompactMnaResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CompactMnaResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompactMnaResponse
     */
    'categoryName'?: string;
    /**
     * 
     * @type {Array<IdAndValue>}
     * @memberof CompactMnaResponse
     */
    'tags'?: Array<IdAndValue>;
}
/**
 * 
 * @export
 * @interface CompactNoteResponse
 */
export interface CompactNoteResponse {
    /**
     * 
     * @type {number}
     * @memberof CompactNoteResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CompactNoteResponse
     */
    'content'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompactNoteResponse
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompactNoteResponse
     */
    'sourceTypeId'?: string;
    /**
     * 
     * @type {Array<IdAndValue>}
     * @memberof CompactNoteResponse
     */
    'tags'?: Array<IdAndValue>;
}
/**
 * 
 * @export
 * @interface CompanyData
 */
export interface CompanyData {
    /**
     * 
     * @type {number}
     * @memberof CompanyData
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyData
     */
    'domainTypeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyData
     */
    'domainName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyData
     */
    'ticker'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyData
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ConditionCategoryEntity
 */
export interface ConditionCategoryEntity {
    /**
     * 
     * @type {number}
     * @memberof ConditionCategoryEntity
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof ConditionCategoryEntity
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConditionCategoryEntity
     */
    'dateModified'?: string;
    /**
     * 
     * @type {UserAccount}
     * @memberof ConditionCategoryEntity
     */
    'createdBy'?: UserAccount;
    /**
     * 
     * @type {UserAccount}
     * @memberof ConditionCategoryEntity
     */
    'lastModifiedBy'?: UserAccount;
    /**
     * 
     * @type {number}
     * @memberof ConditionCategoryEntity
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ConditionCategoryEntity
     */
    'name': string;
}
/**
 * Request object used to create or update a category for conditions.
 * @export
 * @interface ConditionCategoryRequest
 */
export interface ConditionCategoryRequest {
    /**
     * Name of the condition category
     * @type {string}
     * @memberof ConditionCategoryRequest
     */
    'name': string;
}
/**
 * Response object containing details of a condition category.
 * @export
 * @interface ConditionCategoryResponse
 */
export interface ConditionCategoryResponse {
    /**
     * Name of the condition category
     * @type {string}
     * @memberof ConditionCategoryResponse
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ConditionCategoryResponse
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ConditionCategoryResponse
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof ConditionCategoryResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConditionCategoryResponse
     */
    'dateModified'?: string;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof ConditionCategoryResponse
     */
    'createdBy'?: UserAccountInfo;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof ConditionCategoryResponse
     */
    'lastModifiedBy'?: UserAccountInfo;
}
/**
 * 
 * @export
 * @interface ConditionEntity
 */
export interface ConditionEntity {
    /**
     * 
     * @type {number}
     * @memberof ConditionEntity
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof ConditionEntity
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConditionEntity
     */
    'dateModified'?: string;
    /**
     * 
     * @type {UserAccount}
     * @memberof ConditionEntity
     */
    'createdBy'?: UserAccount;
    /**
     * 
     * @type {UserAccount}
     * @memberof ConditionEntity
     */
    'lastModifiedBy'?: UserAccount;
    /**
     * 
     * @type {number}
     * @memberof ConditionEntity
     */
    'id'?: number;
    /**
     * 
     * @type {MnaEntity}
     * @memberof ConditionEntity
     */
    'mna': MnaEntity;
    /**
     * 
     * @type {ConditionCategoryEntity}
     * @memberof ConditionEntity
     */
    'category'?: ConditionCategoryEntity;
    /**
     * 
     * @type {ConditionStatusEntity}
     * @memberof ConditionEntity
     */
    'status'?: ConditionStatusEntity;
    /**
     * 
     * @type {string}
     * @memberof ConditionEntity
     */
    'categoryOtherComment'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConditionEntity
     */
    'filed'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConditionEntity
     */
    'expectedConfirmed'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConditionEntity
     */
    'commentary'?: string;
}
/**
 * Request object used to create or update a condition associated with merger and acquisition (MNA) activities.
 * @export
 * @interface ConditionRequest
 */
export interface ConditionRequest {
    /**
     * 
     * @type {number}
     * @memberof ConditionRequest
     */
    'version': number;
    /**
     * 
     * @type {number}
     * @memberof ConditionRequest
     */
    'mnaId': number;
    /**
     * 
     * @type {number}
     * @memberof ConditionRequest
     */
    'conditionCategoryId': number;
    /**
     * 
     * @type {string}
     * @memberof ConditionRequest
     */
    'categoryOtherComment'?: string;
    /**
     * 
     * @type {number}
     * @memberof ConditionRequest
     */
    'statusId': number;
    /**
     * Date when the Condition was filed
     * @type {string}
     * @memberof ConditionRequest
     */
    'filed'?: string;
    /**
     * Expected confirmation date for the Condition
     * @type {string}
     * @memberof ConditionRequest
     */
    'expectedConfirmed'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConditionRequest
     */
    'commentary'?: string;
}
/**
 * Response object providing details about a condition related to merger and acquisition (MNA) activities.
 * @export
 * @interface ConditionResponse
 */
export interface ConditionResponse {
    /**
     * 
     * @type {number}
     * @memberof ConditionResponse
     */
    'version'?: number;
    /**
     * 
     * @type {number}
     * @memberof ConditionResponse
     */
    'mnaId': number;
    /**
     * 
     * @type {number}
     * @memberof ConditionResponse
     */
    'conditionCategoryId': number;
    /**
     * 
     * @type {string}
     * @memberof ConditionResponse
     */
    'categoryOtherComment'?: string;
    /**
     * 
     * @type {number}
     * @memberof ConditionResponse
     */
    'statusId': number;
    /**
     * Date when the Condition was filed
     * @type {string}
     * @memberof ConditionResponse
     */
    'filed'?: string;
    /**
     * Expected confirmation date for the Condition
     * @type {string}
     * @memberof ConditionResponse
     */
    'expectedConfirmed'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConditionResponse
     */
    'commentary'?: string;
    /**
     * 
     * @type {number}
     * @memberof ConditionResponse
     */
    'id'?: number;
    /**
     * 
     * @type {ConditionCategoryResponse}
     * @memberof ConditionResponse
     */
    'category'?: ConditionCategoryResponse;
    /**
     * 
     * @type {string}
     * @memberof ConditionResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConditionResponse
     */
    'dateModified'?: string;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof ConditionResponse
     */
    'createdBy'?: UserAccountInfo;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof ConditionResponse
     */
    'lastModifiedBy'?: UserAccountInfo;
}
/**
 * 
 * @export
 * @interface ConditionStatusEntity
 */
export interface ConditionStatusEntity {
    /**
     * 
     * @type {number}
     * @memberof ConditionStatusEntity
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof ConditionStatusEntity
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConditionStatusEntity
     */
    'dateModified'?: string;
    /**
     * 
     * @type {UserAccount}
     * @memberof ConditionStatusEntity
     */
    'createdBy'?: UserAccount;
    /**
     * 
     * @type {UserAccount}
     * @memberof ConditionStatusEntity
     */
    'lastModifiedBy'?: UserAccount;
    /**
     * 
     * @type {number}
     * @memberof ConditionStatusEntity
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ConditionStatusEntity
     */
    'status'?: string;
}
/**
 * Request object for creating a condition status. The status can be either \'pending\' or \'complete\'.
 * @export
 * @interface ConditionStatusRequest
 */
export interface ConditionStatusRequest {
    /**
     * Status of the condition
     * @type {string}
     * @memberof ConditionStatusRequest
     */
    'status': string;
}
/**
 * Response object for condition status. Provides details about the status of a condition.
 * @export
 * @interface ConditionStatusResponse
 */
export interface ConditionStatusResponse {
    /**
     * Status of the condition
     * @type {string}
     * @memberof ConditionStatusResponse
     */
    'status': string;
    /**
     * 
     * @type {number}
     * @memberof ConditionStatusResponse
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ConditionStatusResponse
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof ConditionStatusResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof ConditionStatusResponse
     */
    'createdBy'?: UserAccountInfo;
}
/**
 * 
 * @export
 * @interface DashboardResp
 */
export interface DashboardResp {
    /**
     * 
     * @type {Array<MnaPortlet>}
     * @memberof DashboardResp
     */
    'mnas'?: Array<MnaPortlet>;
    /**
     * 
     * @type {Array<NotePortlet>}
     * @memberof DashboardResp
     */
    'notes'?: Array<NotePortlet>;
}
/**
 * Request object for creating or updating a Date Event. Date Events represent significant occurrences related to mergers and acquisitions (M&A) activities. 
 * @export
 * @interface DateEventRequest
 */
export interface DateEventRequest {
    /**
     * 
     * @type {number}
     * @memberof DateEventRequest
     */
    'version': number;
    /**
     * 
     * @type {number}
     * @memberof DateEventRequest
     */
    'mnaId': number;
    /**
     * The identifier of the type of date event. This field indicates the specific type of event, such as \'Out Date\', \'Broker Closing Estimate\', or \'Financing\'. 
     * @type {number}
     * @memberof DateEventRequest
     */
    'dateEventTypeId'?: number;
    /**
     * The date of the event. This field represents the date when the event is scheduled to occur.
     * @type {string}
     * @memberof DateEventRequest
     */
    'dateEvent': string;
    /**
     * Additional comments or notes regarding the date event. Users can provide additional information or context about the event using this field.
     * @type {string}
     * @memberof DateEventRequest
     */
    'comment'?: string;
}
/**
 * Response object containing details about a Date Event. Date Events capture significant milestones and events in mergers and acquisitions (M&A) processes.  This response provides information about the Date Event, including the associated M&A entity, event type, date, and any additional comments.   It also includes audit information such as creation and modification dates, as well as the user responsible for the last modification. 
 * @export
 * @interface DateEventResponse
 */
export interface DateEventResponse {
    /**
     * 
     * @type {number}
     * @memberof DateEventResponse
     */
    'version'?: number;
    /**
     * 
     * @type {number}
     * @memberof DateEventResponse
     */
    'mnaId': number;
    /**
     * 
     * @type {number}
     * @memberof DateEventResponse
     */
    'dateEventTypeId'?: number;
    /**
     * The date of the event. This field represents the date when the event is scheduled to occur.
     * @type {string}
     * @memberof DateEventResponse
     */
    'dateEvent': string;
    /**
     * Additional comments or notes regarding the date event. Users can provide additional information or context about the event using this field.
     * @type {string}
     * @memberof DateEventResponse
     */
    'comment'?: string;
    /**
     * 
     * @type {number}
     * @memberof DateEventResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DateEventResponse
     */
    'dateEventTypeName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DateEventResponse
     */
    'mnaName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DateEventResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof DateEventResponse
     */
    'dateModified'?: string;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof DateEventResponse
     */
    'createdBy'?: UserAccountInfo;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof DateEventResponse
     */
    'lastModifiedBy'?: UserAccountInfo;
}
/**
 * 
 * @export
 * @interface DomainAssociationRequest
 */
export interface DomainAssociationRequest {
    /**
     * 
     * @type {number}
     * @memberof DomainAssociationRequest
     */
    'typeId': number;
    /**
     * 
     * @type {number}
     * @memberof DomainAssociationRequest
     */
    'entityId': number;
}
/**
 * 
 * @export
 * @interface DomainChangeByProperty
 */
export interface DomainChangeByProperty {
    /**
     * 
     * @type {number}
     * @memberof DomainChangeByProperty
     */
    'id'?: number;
    /**
     * 
     * @type {{ [key: string]: Array<DomainPropertyChange>; }}
     * @memberof DomainChangeByProperty
     */
    'propertyMap'?: { [key: string]: Array<DomainPropertyChange>; };
}
/**
 * 
 * @export
 * @interface DomainPropertyChange
 */
export interface DomainPropertyChange {
    /**
     * 
     * @type {string}
     * @memberof DomainPropertyChange
     */
    'propertyName'?: string;
    /**
     * 
     * @type {object}
     * @memberof DomainPropertyChange
     */
    'left'?: object;
    /**
     * 
     * @type {object}
     * @memberof DomainPropertyChange
     */
    'right'?: object;
    /**
     * 
     * @type {number}
     * @memberof DomainPropertyChange
     */
    'version'?: number;
    /**
     * 
     * @type {number}
     * @memberof DomainPropertyChange
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DomainPropertyChange
     */
    'dateModified'?: string;
}
/**
 * Response object representing a domain type (e.g., MNA, Fundamental, Note, etc.).
 * @export
 * @interface DomainTypeResponse
 */
export interface DomainTypeResponse {
    /**
     * Unique identifier of the domain type.
     * @type {number}
     * @memberof DomainTypeResponse
     */
    'id'?: number;
    /**
     * Name of the domain type.
     * @type {string}
     * @memberof DomainTypeResponse
     */
    'name'?: string;
}
/**
 * Contains structured error information for efficient troubleshooting and resolution.  Includes user details, request specifics, stack trace, and timestamps. Essential for error handling and debugging.\" 
 * @export
 * @interface ErrorLogResponse
 */
export interface ErrorLogResponse {
    /**
     * 
     * @type {number}
     * @memberof ErrorLogResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ErrorLogResponse
     */
    'username'?: string;
    /**
     * 
     * @type {number}
     * @memberof ErrorLogResponse
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ErrorLogResponse
     */
    'requestBody'?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorLogResponse
     */
    'requestHeaders'?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorLogResponse
     */
    'requestUri'?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorLogResponse
     */
    'requestMethod'?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorLogResponse
     */
    'stackTrace'?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorLogResponse
     */
    'remoteIpAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorLogResponse
     */
    'dateCreated'?: string;
}
/**
 * 
 * @export
 * @interface ExchangeEntity
 */
export interface ExchangeEntity {
    /**
     * 
     * @type {number}
     * @memberof ExchangeEntity
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof ExchangeEntity
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExchangeEntity
     */
    'dateModified'?: string;
    /**
     * 
     * @type {UserAccount}
     * @memberof ExchangeEntity
     */
    'createdBy'?: UserAccount;
    /**
     * 
     * @type {UserAccount}
     * @memberof ExchangeEntity
     */
    'lastModifiedBy'?: UserAccount;
    /**
     * 
     * @type {number}
     * @memberof ExchangeEntity
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ExchangeEntity
     */
    'name'?: string;
}
/**
 * Request object for creating or updating an exchange. An exchange represents a marketplace where financial instruments, such as stocks,  bonds, commodities, and derivatives, are traded. 
 * @export
 * @interface ExchangeRequest
 */
export interface ExchangeRequest {
    /**
     * 
     * @type {number}
     * @memberof ExchangeRequest
     */
    'version': number;
    /**
     * The name of the exchange. Example: New York Stock Exchange (NYSE), NASDAQ, London Stock Exchange (LSE), etc.
     * @type {string}
     * @memberof ExchangeRequest
     */
    'name': string;
}
/**
 * Response object containing exchange information.
 * @export
 * @interface ExchangeResponse
 */
export interface ExchangeResponse {
    /**
     * 
     * @type {number}
     * @memberof ExchangeResponse
     */
    'version': number;
    /**
     * The name of the exchange. Example: New York Stock Exchange (NYSE), NASDAQ, London Stock Exchange (LSE), etc.
     * @type {string}
     * @memberof ExchangeResponse
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ExchangeResponse
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface FeatureAssociationRequest
 */
export interface FeatureAssociationRequest {
    /**
     * 
     * @type {number}
     * @memberof FeatureAssociationRequest
     */
    'version': number;
    /**
     * 
     * @type {number}
     * @memberof FeatureAssociationRequest
     */
    'featureId': number;
    /**
     * 
     * @type {number}
     * @memberof FeatureAssociationRequest
     */
    'mnaId': number;
    /**
     * 
     * @type {string}
     * @memberof FeatureAssociationRequest
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface FeatureAssociationResponse
 */
export interface FeatureAssociationResponse {
    /**
     * 
     * @type {number}
     * @memberof FeatureAssociationResponse
     */
    'version': number;
    /**
     * 
     * @type {number}
     * @memberof FeatureAssociationResponse
     */
    'featureId': number;
    /**
     * 
     * @type {number}
     * @memberof FeatureAssociationResponse
     */
    'mnaId': number;
    /**
     * 
     * @type {string}
     * @memberof FeatureAssociationResponse
     */
    'value': string;
    /**
     * 
     * @type {number}
     * @memberof FeatureAssociationResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FeatureAssociationResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureAssociationResponse
     */
    'dateModified'?: string;
}
/**
 * Request object for creating or updating a feature. Features allow users to customize Mna entities by adding additional details such as \'budget\', \'location\', or \'status\', providing more context and enhancing the overall understanding of an Mna entity.
 * @export
 * @interface FeatureRequest
 */
export interface FeatureRequest {
    /**
     * 
     * @type {number}
     * @memberof FeatureRequest
     */
    'version': number;
    /**
     * The name of the feature.
     * @type {string}
     * @memberof FeatureRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface FeatureResponse
 */
export interface FeatureResponse {
    /**
     * 
     * @type {number}
     * @memberof FeatureResponse
     */
    'version': number;
    /**
     * The name of the feature.
     * @type {string}
     * @memberof FeatureResponse
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof FeatureResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FeatureResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureResponse
     */
    'dateModified'?: string;
}
/**
 * Request object for associating files with other domains.
 * @export
 * @interface FileAssociationRequest
 */
export interface FileAssociationRequest {
    /**
     * 
     * @type {number}
     * @memberof FileAssociationRequest
     */
    'typeId': number;
    /**
     * 
     * @type {number}
     * @memberof FileAssociationRequest
     */
    'entityId': number;
    /**
     * 
     * @type {number}
     * @memberof FileAssociationRequest
     */
    'fileId': number;
}
/**
 * Response object for file association with other domains.
 * @export
 * @interface FileAssociationResponse
 */
export interface FileAssociationResponse {
    /**
     * 
     * @type {number}
     * @memberof FileAssociationResponse
     */
    'typeId': number;
    /**
     * 
     * @type {number}
     * @memberof FileAssociationResponse
     */
    'entityId': number;
    /**
     * 
     * @type {number}
     * @memberof FileAssociationResponse
     */
    'fileId': number;
    /**
     * 
     * @type {number}
     * @memberof FileAssociationResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileAssociationResponse
     */
    'filename'?: string;
    /**
     * 
     * @type {number}
     * @memberof FileAssociationResponse
     */
    'size'?: number;
    /**
     * 
     * @type {number}
     * @memberof FileAssociationResponse
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileAssociationResponse
     */
    'domainType'?: string;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof FileAssociationResponse
     */
    'createdBy'?: UserAccountInfo;
    /**
     * 
     * @type {string}
     * @memberof FileAssociationResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof FileAssociationResponse
     */
    'lastModifiedBy'?: UserAccountInfo;
    /**
     * 
     * @type {string}
     * @memberof FileAssociationResponse
     */
    'dateModified'?: string;
}
/**
 * Response object for the details of a file.
 * @export
 * @interface FileResponse
 */
export interface FileResponse {
    /**
     * The original filename of the file.
     * @type {string}
     * @memberof FileResponse
     */
    'filename': string;
    /**
     * The filename as stored in the system.
     * @type {string}
     * @memberof FileResponse
     */
    'filenameStored'?: string;
    /**
     * The size of the file in bytes.
     * @type {number}
     * @memberof FileResponse
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileResponse
     */
    'mimeType'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileResponse
     */
    'extension'?: string;
    /**
     * 
     * @type {number}
     * @memberof FileResponse
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof FileResponse
     */
    'version'?: number;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof FileResponse
     */
    'createdBy'?: UserAccountInfo;
    /**
     * 
     * @type {string}
     * @memberof FileResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof FileResponse
     */
    'lastModifiedBy'?: UserAccountInfo;
    /**
     * 
     * @type {string}
     * @memberof FileResponse
     */
    'dateModified'?: string;
}
/**
 * 
 * @export
 * @interface FundamentalEntity
 */
export interface FundamentalEntity {
    /**
     * 
     * @type {number}
     * @memberof FundamentalEntity
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof FundamentalEntity
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalEntity
     */
    'dateModified'?: string;
    /**
     * 
     * @type {UserAccount}
     * @memberof FundamentalEntity
     */
    'createdBy'?: UserAccount;
    /**
     * 
     * @type {UserAccount}
     * @memberof FundamentalEntity
     */
    'lastModifiedBy'?: UserAccount;
    /**
     * 
     * @type {number}
     * @memberof FundamentalEntity
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FundamentalEntity
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalEntity
     */
    'ticker'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalEntity
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalEntity
     */
    'address'?: string;
    /**
     * 
     * @type {SectorEntity}
     * @memberof FundamentalEntity
     */
    'sector'?: SectorEntity;
    /**
     * 
     * @type {ExchangeEntity}
     * @memberof FundamentalEntity
     */
    'exchange'?: ExchangeEntity;
    /**
     * 
     * @type {string}
     * @memberof FundamentalEntity
     */
    'dateIpo'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalEntity
     */
    'incorp'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalEntity
     */
    'hq'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalEntity
     */
    'geoExposure'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalEntity
     */
    'businessDesc'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalEntity
     */
    'companyHistory'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalEntity
     */
    'companyBasics'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalEntity
     */
    'driversValuation'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalEntity
     */
    'valuationHistory'?: string;
}
/**
 * Represents a single company or entity that is the focus of Merger Daily research, similar to a merger and acquisition (MNA) between two companies.
 * @export
 * @interface FundamentalRequest
 */
export interface FundamentalRequest {
    /**
     * 
     * @type {number}
     * @memberof FundamentalRequest
     */
    'version': number;
    /**
     * 
     * @type {string}
     * @memberof FundamentalRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalRequest
     */
    'ticker'?: string;
    /**
     * 
     * @type {number}
     * @memberof FundamentalRequest
     */
    'sectorId'?: number;
    /**
     * 
     * @type {string}
     * @memberof FundamentalRequest
     */
    'exchange'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalRequest
     */
    'dateIpo'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalRequest
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalRequest
     */
    'incorp'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalRequest
     */
    'hq'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalRequest
     */
    'geoExposure'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalRequest
     */
    'whatMatters'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalRequest
     */
    'businessDesc'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalRequest
     */
    'companyHistory'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalRequest
     */
    'companyBasics'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalRequest
     */
    'driversValuation'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalRequest
     */
    'valuationHistory'?: string;
    /**
     * 
     * @type {AssociationActionsLongTagRequest}
     * @memberof FundamentalRequest
     */
    'tags'?: AssociationActionsLongTagRequest;
    /**
     * 
     * @type {AssociationActionsLongLong}
     * @memberof FundamentalRequest
     */
    'files'?: AssociationActionsLongLong;
}
/**
 * Represents a single company or entity that is the focus of Merger Daily research, similar to a merger and acquisition (MNA) between two companies.
 * @export
 * @interface FundamentalResponse
 */
export interface FundamentalResponse {
    /**
     * 
     * @type {number}
     * @memberof FundamentalResponse
     */
    'version': number;
    /**
     * 
     * @type {string}
     * @memberof FundamentalResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalResponse
     */
    'ticker'?: string;
    /**
     * 
     * @type {number}
     * @memberof FundamentalResponse
     */
    'sectorId'?: number;
    /**
     * 
     * @type {string}
     * @memberof FundamentalResponse
     */
    'exchange'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalResponse
     */
    'dateIpo'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalResponse
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalResponse
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalResponse
     */
    'incorp'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalResponse
     */
    'hq'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalResponse
     */
    'geoExposure'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalResponse
     */
    'whatMatters'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalResponse
     */
    'businessDesc'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalResponse
     */
    'companyHistory'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalResponse
     */
    'companyBasics'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalResponse
     */
    'driversValuation'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalResponse
     */
    'valuationHistory'?: string;
    /**
     * 
     * @type {number}
     * @memberof FundamentalResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FundamentalResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof FundamentalResponse
     */
    'dateModified'?: string;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof FundamentalResponse
     */
    'createdBy'?: UserAccountInfo;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof FundamentalResponse
     */
    'lastModifiedBy'?: UserAccountInfo;
    /**
     * 
     * @type {Array<TagAssociationResponse>}
     * @memberof FundamentalResponse
     */
    'tags'?: Array<TagAssociationResponse>;
    /**
     * 
     * @type {Array<FileAssociationResponse>}
     * @memberof FundamentalResponse
     */
    'files'?: Array<FileAssociationResponse>;
}
/**
 * 
 * @export
 * @interface IdAndValue
 */
export interface IdAndValue {
    /**
     * 
     * @type {number}
     * @memberof IdAndValue
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof IdAndValue
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface ImpliedProbabilityRequest
 */
export interface ImpliedProbabilityRequest {
    /**
     * 
     * @type {number}
     * @memberof ImpliedProbabilityRequest
     */
    'version': number;
    /**
     * 
     * @type {number}
     * @memberof ImpliedProbabilityRequest
     */
    'mnaId': number;
    /**
     * 
     * @type {number}
     * @memberof ImpliedProbabilityRequest
     */
    'downside'?: number;
    /**
     * 
     * @type {number}
     * @memberof ImpliedProbabilityRequest
     */
    'currentPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof ImpliedProbabilityRequest
     */
    'upside'?: number;
    /**
     * 
     * @type {number}
     * @memberof ImpliedProbabilityRequest
     */
    'impliedProb'?: number;
    /**
     * 
     * @type {string}
     * @memberof ImpliedProbabilityRequest
     */
    'comments'?: string;
    /**
     * 
     * @type {string}
     * @memberof ImpliedProbabilityRequest
     */
    'author'?: string;
}
/**
 * 
 * @export
 * @interface ImpliedProbabilityResponse
 */
export interface ImpliedProbabilityResponse {
    /**
     * 
     * @type {number}
     * @memberof ImpliedProbabilityResponse
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ImpliedProbabilityResponse
     */
    'version'?: number;
    /**
     * 
     * @type {number}
     * @memberof ImpliedProbabilityResponse
     */
    'mnaId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ImpliedProbabilityResponse
     */
    'downside'?: number;
    /**
     * 
     * @type {number}
     * @memberof ImpliedProbabilityResponse
     */
    'currentPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof ImpliedProbabilityResponse
     */
    'upside'?: number;
    /**
     * 
     * @type {number}
     * @memberof ImpliedProbabilityResponse
     */
    'impliedProb'?: number;
    /**
     * 
     * @type {string}
     * @memberof ImpliedProbabilityResponse
     */
    'comments'?: string;
    /**
     * 
     * @type {string}
     * @memberof ImpliedProbabilityResponse
     */
    'author'?: string;
}
/**
 * Request object for associating Link with other domains.
 * @export
 * @interface LinkAssociationRequest
 */
export interface LinkAssociationRequest {
    /**
     * 
     * @type {number}
     * @memberof LinkAssociationRequest
     */
    'typeId': number;
    /**
     * 
     * @type {number}
     * @memberof LinkAssociationRequest
     */
    'entityId': number;
    /**
     * 
     * @type {number}
     * @memberof LinkAssociationRequest
     */
    'linkId': number;
}
/**
 * Response object for Link association with other domains.
 * @export
 * @interface LinkAssociationResponse
 */
export interface LinkAssociationResponse {
    /**
     * 
     * @type {number}
     * @memberof LinkAssociationResponse
     */
    'typeId': number;
    /**
     * 
     * @type {number}
     * @memberof LinkAssociationResponse
     */
    'entityId': number;
    /**
     * 
     * @type {number}
     * @memberof LinkAssociationResponse
     */
    'linkId': number;
    /**
     * 
     * @type {number}
     * @memberof LinkAssociationResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof LinkAssociationResponse
     */
    'link'?: string;
    /**
     * 
     * @type {number}
     * @memberof LinkAssociationResponse
     */
    'linkCategoryId'?: number;
    /**
     * 
     * @type {number}
     * @memberof LinkAssociationResponse
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof LinkAssociationResponse
     */
    'domainType'?: string;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof LinkAssociationResponse
     */
    'createdBy'?: UserAccountInfo;
    /**
     * 
     * @type {string}
     * @memberof LinkAssociationResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof LinkAssociationResponse
     */
    'lastModifiedBy'?: UserAccountInfo;
    /**
     * 
     * @type {string}
     * @memberof LinkAssociationResponse
     */
    'dateModified'?: string;
}
/**
 * Request object for creating or updating a link category. A link category is used to classify links into different groups for easier management and retrieval. 
 * @export
 * @interface LinkCategoryRequest
 */
export interface LinkCategoryRequest {
    /**
     * 
     * @type {number}
     * @memberof LinkCategoryRequest
     */
    'version': number;
    /**
     * The name of the link category
     * @type {string}
     * @memberof LinkCategoryRequest
     */
    'name': string;
}
/**
 * Response object for Link Category, containing the category\'s details. A link category represents a specific classification for links within the system, such as News, Research, or Def Proxy. 
 * @export
 * @interface LinkCategoryResponse
 */
export interface LinkCategoryResponse {
    /**
     * 
     * @type {number}
     * @memberof LinkCategoryResponse
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof LinkCategoryResponse
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof LinkCategoryResponse
     */
    'name'?: string;
}
/**
 * Request object for creating or updating a link. A link is an external URL associated with a specific Mergers and Acquisitions (MNA) record,  often used to provide additional context or reference material. 
 * @export
 * @interface LinkRequest
 */
export interface LinkRequest {
    /**
     * 
     * @type {number}
     * @memberof LinkRequest
     */
    'version': number;
    /**
     * 
     * @type {number}
     * @memberof LinkRequest
     */
    'linkCategoryId': number;
    /**
     * 
     * @type {string}
     * @memberof LinkRequest
     */
    'link': string;
    /**
     * 
     * @type {AssociationActionsLongDomainAssociationRequest}
     * @memberof LinkRequest
     */
    'domains'?: AssociationActionsLongDomainAssociationRequest;
}
/**
 * Response object for a link, which represents an external website link associated with a specific merger or acquisition (M&A).
 * @export
 * @interface LinkResponse
 */
export interface LinkResponse {
    /**
     * 
     * @type {number}
     * @memberof LinkResponse
     */
    'version': number;
    /**
     * 
     * @type {number}
     * @memberof LinkResponse
     */
    'linkCategoryId': number;
    /**
     * 
     * @type {string}
     * @memberof LinkResponse
     */
    'link': string;
    /**
     * 
     * @type {number}
     * @memberof LinkResponse
     */
    'id'?: number;
    /**
     * The category of the link, indicating the type or context of the link (e.g., news, research, analysis).
     * @type {string}
     * @memberof LinkResponse
     */
    'category'?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkResponse
     */
    'dateModified'?: string;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof LinkResponse
     */
    'createdBy'?: UserAccountInfo;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof LinkResponse
     */
    'lastModifiedBy'?: UserAccountInfo;
    /**
     * 
     * @type {Array<LinkAssociationResponse>}
     * @memberof LinkResponse
     */
    'domains'?: Array<LinkAssociationResponse>;
}
/**
 * 
 * @export
 * @interface MnaCategoryEntity
 */
export interface MnaCategoryEntity {
    /**
     * 
     * @type {number}
     * @memberof MnaCategoryEntity
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof MnaCategoryEntity
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaCategoryEntity
     */
    'dateModified'?: string;
    /**
     * 
     * @type {UserAccount}
     * @memberof MnaCategoryEntity
     */
    'createdBy'?: UserAccount;
    /**
     * 
     * @type {UserAccount}
     * @memberof MnaCategoryEntity
     */
    'lastModifiedBy'?: UserAccount;
    /**
     * 
     * @type {number}
     * @memberof MnaCategoryEntity
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MnaCategoryEntity
     */
    'name'?: string;
}
/**
 * Request object representing an MNA category. All MNA records must be associated with a category.
 * @export
 * @interface MnaCategoryRequest
 */
export interface MnaCategoryRequest {
    /**
     * The name of the MNA category.
     * @type {string}
     * @memberof MnaCategoryRequest
     */
    'name': string;
}
/**
 * Response object representing an MNA category. All MNA records must be associated with a category.
 * @export
 * @interface MnaCategoryResponse
 */
export interface MnaCategoryResponse {
    /**
     * The name of the MNA category.
     * @type {string}
     * @memberof MnaCategoryResponse
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof MnaCategoryResponse
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof MnaCategoryResponse
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof MnaCategoryResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof MnaCategoryResponse
     */
    'createdBy'?: UserAccountInfo;
}
/**
 * 
 * @export
 * @interface MnaEntity
 */
export interface MnaEntity {
    /**
     * 
     * @type {number}
     * @memberof MnaEntity
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof MnaEntity
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaEntity
     */
    'dateModified'?: string;
    /**
     * 
     * @type {UserAccount}
     * @memberof MnaEntity
     */
    'createdBy'?: UserAccount;
    /**
     * 
     * @type {UserAccount}
     * @memberof MnaEntity
     */
    'lastModifiedBy'?: UserAccount;
    /**
     * 
     * @type {number}
     * @memberof MnaEntity
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MnaEntity
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof MnaEntity
     */
    'description'?: string;
    /**
     * 
     * @type {MnaCategoryEntity}
     * @memberof MnaEntity
     */
    'category'?: MnaCategoryEntity;
    /**
     * 
     * @type {MnaSubCategoryEntity}
     * @memberof MnaEntity
     */
    'subCategory'?: MnaSubCategoryEntity;
    /**
     * 
     * @type {FundamentalEntity}
     * @memberof MnaEntity
     */
    'fundamentalTarget'?: FundamentalEntity;
    /**
     * 
     * @type {FundamentalEntity}
     * @memberof MnaEntity
     */
    'fundamentalAcquirer'?: FundamentalEntity;
    /**
     * 
     * @type {string}
     * @memberof MnaEntity
     */
    'dateAnnounced'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaEntity
     */
    'dateClosing'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaEntity
     */
    'size'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaEntity
     */
    'terms'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaEntity
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaEntity
     */
    'target'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaEntity
     */
    'acquirer'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaEntity
     */
    'tickerTarget'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaEntity
     */
    'tickerAcquirer'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaEntity
     */
    'premium'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaEntity
     */
    'closingLanguage'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaEntity
     */
    'secondaryTicker'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaEntity
     */
    'sectorAcquirer'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaEntity
     */
    'sectorTarget'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaEntity
     */
    'incorpAcquirer'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaEntity
     */
    'incorpTarget'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaEntity
     */
    'hqAcquirer'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaEntity
     */
    'hqTarget'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaEntity
     */
    'geoExposureAcquirer'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaEntity
     */
    'geoExposureTarget'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaEntity
     */
    'terminationFeeAcquirer'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaEntity
     */
    'terminationFeeTarget'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaEntity
     */
    'debtTarget'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaEntity
     */
    'requiredToLitigateComment'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MnaEntity
     */
    'requiredToLitigate'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MnaEntity
     */
    'tax'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaEntity
     */
    'financialAdvisorForAcquirer'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaEntity
     */
    'financialAdvisorForTarget'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaEntity
     */
    'legalCounselForAcquirer'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaEntity
     */
    'legalCounselForTarget'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaEntity
     */
    'proxySolicitors'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaEntity
     */
    'informationAgent'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaEntity
     */
    'adrDepositary'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaEntity
     */
    'guaranteedDelivery'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaEntity
     */
    'appraisalRights'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaEntity
     */
    'specificPerformance'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaEntity
     */
    'competitors'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaEntity
     */
    'customers'?: string;
}
/**
 * 
 * @export
 * @interface MnaPortlet
 */
export interface MnaPortlet {
    /**
     * 
     * @type {number}
     * @memberof MnaPortlet
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MnaPortlet
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaPortlet
     */
    'categoryName'?: string;
    /**
     * 
     * @type {number}
     * @memberof MnaPortlet
     */
    'fundamentalTargetId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MnaPortlet
     */
    'fundamentalAcquirerId'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof MnaPortlet
     */
    'tickers'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MnaPortlet
     */
    'dateModified'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaPortlet
     */
    'dateAnnounced'?: string;
}
/**
 * Represents a merger and acquisition (MNA) between two companies. This request object is used to create or update MNA entities.
 * @export
 * @interface MnaRequest
 */
export interface MnaRequest {
    /**
     * 
     * @type {number}
     * @memberof MnaRequest
     */
    'version': number;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof MnaRequest
     */
    'categoryId': number;
    /**
     * 
     * @type {number}
     * @memberof MnaRequest
     */
    'subCategoryId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MnaRequest
     */
    'fundamentalTargetId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MnaRequest
     */
    'fundamentalAcquirerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'dateAnnounced': string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'dateClosing'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'acquirer'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'target'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'tickerAcquirer'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'tickerTarget'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'sectorAcquirer'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'sectorTarget'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'incorpAcquirer'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'incorpTarget'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'hqAcquirer'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'hqTarget'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'geoExposureAcquirer'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'geoExposureTarget'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'terminationFeeAcquirer'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'terminationFeeTarget'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'financialAdvisorForAcquirer'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'financialAdvisorForTarget'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'legalCounselForAcquirer'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'legalCounselForTarget'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'size'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'terms'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'premium'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'closingLanguage'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'appraisalRights'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'specificPerformance'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'requiredToLitigateComment'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MnaRequest
     */
    'requiredToLitigate'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'tax'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'proxySolicitors'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'informationAgent'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'adrDepositary'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'guaranteedDelivery'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'competitors'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaRequest
     */
    'customers'?: string;
    /**
     * 
     * @type {AssociationActionsLongConditionRequest}
     * @memberof MnaRequest
     */
    'conditions'?: AssociationActionsLongConditionRequest;
    /**
     * 
     * @type {AssociationActionsLongWhatMattersRequest}
     * @memberof MnaRequest
     */
    'whatMatters'?: AssociationActionsLongWhatMattersRequest;
    /**
     * 
     * @type {AssociationActionsLongLinkRequest}
     * @memberof MnaRequest
     */
    'links'?: AssociationActionsLongLinkRequest;
    /**
     * 
     * @type {AssociationActionsLongDateEventRequest}
     * @memberof MnaRequest
     */
    'outDates'?: AssociationActionsLongDateEventRequest;
    /**
     * 
     * @type {AssociationActionsLongDateEventRequest}
     * @memberof MnaRequest
     */
    'brokerClosingDates'?: AssociationActionsLongDateEventRequest;
    /**
     * 
     * @type {AssociationActionsLongDateEventRequest}
     * @memberof MnaRequest
     */
    'financingDates'?: AssociationActionsLongDateEventRequest;
    /**
     * 
     * @type {AssociationActionsLongFeatureAssociationRequest}
     * @memberof MnaRequest
     */
    'features'?: AssociationActionsLongFeatureAssociationRequest;
    /**
     * 
     * @type {AssociationActionsLongTagRequest}
     * @memberof MnaRequest
     */
    'tags'?: AssociationActionsLongTagRequest;
    /**
     * 
     * @type {AssociationActionsLongLong}
     * @memberof MnaRequest
     */
    'files'?: AssociationActionsLongLong;
    /**
     * 
     * @type {AssociationActionsLongTxDocSummaryRequest}
     * @memberof MnaRequest
     */
    'txDocSummaries'?: AssociationActionsLongTxDocSummaryRequest;
}
/**
 * Response object representing a merger and acquisition (MNA) between two companies.
 * @export
 * @interface MnaResponse
 */
export interface MnaResponse {
    /**
     * 
     * @type {number}
     * @memberof MnaResponse
     */
    'version': number;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof MnaResponse
     */
    'categoryId': number;
    /**
     * 
     * @type {number}
     * @memberof MnaResponse
     */
    'subCategoryId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MnaResponse
     */
    'fundamentalTargetId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MnaResponse
     */
    'fundamentalAcquirerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'dateAnnounced': string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'dateClosing'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'acquirer'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'target'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'tickerAcquirer'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'tickerTarget'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'sectorAcquirer'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'sectorTarget'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'incorpAcquirer'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'incorpTarget'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'hqAcquirer'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'hqTarget'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'geoExposureAcquirer'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'geoExposureTarget'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'terminationFeeAcquirer'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'terminationFeeTarget'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'financialAdvisorForAcquirer'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'financialAdvisorForTarget'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'legalCounselForAcquirer'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'legalCounselForTarget'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'size'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'terms'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'premium'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'closingLanguage'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'appraisalRights'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'specificPerformance'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'requiredToLitigateComment'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MnaResponse
     */
    'requiredToLitigate'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'tax'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'proxySolicitors'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'informationAgent'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'adrDepositary'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'guaranteedDelivery'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'competitors'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'customers'?: string;
    /**
     * 
     * @type {number}
     * @memberof MnaResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'dateModified'?: string;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof MnaResponse
     */
    'createdBy'?: UserAccountInfo;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof MnaResponse
     */
    'lastModifiedBy'?: UserAccountInfo;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'categoryName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaResponse
     */
    'subCategoryName'?: string;
    /**
     * 
     * @type {Array<WhatMattersResponse>}
     * @memberof MnaResponse
     */
    'whatMatters'?: Array<WhatMattersResponse>;
    /**
     * 
     * @type {Array<LinkResponse>}
     * @memberof MnaResponse
     */
    'links'?: Array<LinkResponse>;
    /**
     * 
     * @type {Array<ConditionResponse>}
     * @memberof MnaResponse
     */
    'conditions'?: Array<ConditionResponse>;
    /**
     * 
     * @type {Array<TxDocSummaryResponse>}
     * @memberof MnaResponse
     */
    'txDocSummaries'?: Array<TxDocSummaryResponse>;
    /**
     * 
     * @type {Array<DateEventResponse>}
     * @memberof MnaResponse
     */
    'outDates'?: Array<DateEventResponse>;
    /**
     * 
     * @type {Array<DateEventResponse>}
     * @memberof MnaResponse
     */
    'brokerClosingDates'?: Array<DateEventResponse>;
    /**
     * 
     * @type {Array<DateEventResponse>}
     * @memberof MnaResponse
     */
    'financingDates'?: Array<DateEventResponse>;
    /**
     * 
     * @type {Array<FeatureAssociationResponse>}
     * @memberof MnaResponse
     */
    'features'?: Array<FeatureAssociationResponse>;
    /**
     * 
     * @type {Array<TagAssociationResponse>}
     * @memberof MnaResponse
     */
    'tags'?: Array<TagAssociationResponse>;
    /**
     * 
     * @type {Array<FileAssociationResponse>}
     * @memberof MnaResponse
     */
    'files'?: Array<FileAssociationResponse>;
    /**
     * 
     * @type {Array<NoteSimple>}
     * @memberof MnaResponse
     */
    'notes'?: Array<NoteSimple>;
}
/**
 * 
 * @export
 * @interface MnaSubCategoryEntity
 */
export interface MnaSubCategoryEntity {
    /**
     * 
     * @type {number}
     * @memberof MnaSubCategoryEntity
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof MnaSubCategoryEntity
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof MnaSubCategoryEntity
     */
    'dateModified'?: string;
    /**
     * 
     * @type {UserAccount}
     * @memberof MnaSubCategoryEntity
     */
    'createdBy'?: UserAccount;
    /**
     * 
     * @type {UserAccount}
     * @memberof MnaSubCategoryEntity
     */
    'lastModifiedBy'?: UserAccount;
    /**
     * 
     * @type {number}
     * @memberof MnaSubCategoryEntity
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MnaSubCategoryEntity
     */
    'name'?: string;
}
/**
 * Request object representing a sub-category for MNA (Merger and Acquisition).
 * @export
 * @interface MnaSubCategoryRequest
 */
export interface MnaSubCategoryRequest {
    /**
     * 
     * @type {string}
     * @memberof MnaSubCategoryRequest
     */
    'name': string;
}
/**
 * Response object representing a sub-category for MNA (Merger and Acquisition).
 * @export
 * @interface MnaSubCategoryResponse
 */
export interface MnaSubCategoryResponse {
    /**
     * 
     * @type {string}
     * @memberof MnaSubCategoryResponse
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof MnaSubCategoryResponse
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof MnaSubCategoryResponse
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof MnaSubCategoryResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof MnaSubCategoryResponse
     */
    'createdBy'?: UserAccountInfo;
}
/**
 * 
 * @export
 * @interface MultiStepResponse
 */
export interface MultiStepResponse {
    /**
     * 
     * @type {number}
     * @memberof MultiStepResponse
     */
    'formId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MultiStepResponse
     */
    'page'?: number;
}
/**
 * Represents a request to associate a note with other domains. Note associations can link a note to various entities or domains within the system.  A note can belong to multiple Fundamentals and multiple MNA. 
 * @export
 * @interface NoteAssociationRequest
 */
export interface NoteAssociationRequest {
    /**
     * 
     * @type {number}
     * @memberof NoteAssociationRequest
     */
    'typeId': number;
    /**
     * 
     * @type {number}
     * @memberof NoteAssociationRequest
     */
    'entityId': number;
    /**
     * The ID of the note to be associated to a specific domain e.g Fundamental.
     * @type {number}
     * @memberof NoteAssociationRequest
     */
    'noteId': number;
}
/**
 * Represents a response for a note association. Note association responses provide information about the association between a note and other domains.  A note can belong to multiple Fundamentals and multiple MNA. 
 * @export
 * @interface NoteAssociationResponse
 */
export interface NoteAssociationResponse {
    /**
     * 
     * @type {number}
     * @memberof NoteAssociationResponse
     */
    'typeId': number;
    /**
     * 
     * @type {number}
     * @memberof NoteAssociationResponse
     */
    'entityId': number;
    /**
     * The ID of the note to be associated to a specific domain e.g Fundamental.
     * @type {number}
     * @memberof NoteAssociationResponse
     */
    'noteId': number;
    /**
     * 
     * @type {number}
     * @memberof NoteAssociationResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof NoteAssociationResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteAssociationResponse
     */
    'domainName'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteAssociationResponse
     */
    'ticker'?: string;
    /**
     * 
     * @type {number}
     * @memberof NoteAssociationResponse
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof NoteAssociationResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof NoteAssociationResponse
     */
    'createdBy'?: UserAccountInfo;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof NoteAssociationResponse
     */
    'lastModifiedBy'?: UserAccountInfo;
    /**
     * 
     * @type {string}
     * @memberof NoteAssociationResponse
     */
    'dateModified'?: string;
}
/**
 * 
 * @export
 * @interface NotePortlet
 */
export interface NotePortlet {
    /**
     * 
     * @type {number}
     * @memberof NotePortlet
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotePortlet
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotePortlet
     */
    'sourceDetail'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotePortlet
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotePortlet
     */
    'sourceType'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof NotePortlet
     */
    'tickers'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof NotePortlet
     */
    'dateModified'?: string;
}
/**
 * A detailed request object of a note, capturing important information and analysis relevant to business or financial contexts.  Notes are collected from various sources and serve as a comprehensive news feed providing financial data and insights. 
 * @export
 * @interface NoteRequest
 */
export interface NoteRequest {
    /**
     * 
     * @type {number}
     * @memberof NoteRequest
     */
    'version': number;
    /**
     * 
     * @type {number}
     * @memberof NoteRequest
     */
    'noteSourceTypeId': number;
    /**
     * 
     * @type {string}
     * @memberof NoteRequest
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof NoteRequest
     */
    'datePublished': string;
    /**
     * 
     * @type {string}
     * @memberof NoteRequest
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof NoteRequest
     */
    'commentary'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteRequest
     */
    'sourceDetail'?: string;
    /**
     * 
     * @type {number}
     * @memberof NoteRequest
     */
    'sentiment'?: number;
    /**
     * 
     * @type {string}
     * @memberof NoteRequest
     */
    'author1'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteRequest
     */
    'author2'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteRequest
     */
    'author3'?: string;
    /**
     * 
     * @type {AssociationActionsLongTagRequest}
     * @memberof NoteRequest
     */
    'tags'?: AssociationActionsLongTagRequest;
    /**
     * 
     * @type {AssociationActionsLongLong}
     * @memberof NoteRequest
     */
    'files'?: AssociationActionsLongLong;
    /**
     * 
     * @type {AssociationActionsLongDomainAssociationRequest}
     * @memberof NoteRequest
     */
    'domains'?: AssociationActionsLongDomainAssociationRequest;
}
/**
 * A detailed response object of a note, capturing important information and analysis relevant to business or financial contexts.  Notes are collected from various sources and serve as a comprehensive news feed providing financial data and insights. 
 * @export
 * @interface NoteResponse
 */
export interface NoteResponse {
    /**
     * 
     * @type {number}
     * @memberof NoteResponse
     */
    'version': number;
    /**
     * 
     * @type {number}
     * @memberof NoteResponse
     */
    'noteSourceTypeId': number;
    /**
     * 
     * @type {string}
     * @memberof NoteResponse
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof NoteResponse
     */
    'datePublished': string;
    /**
     * 
     * @type {string}
     * @memberof NoteResponse
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof NoteResponse
     */
    'commentary'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteResponse
     */
    'sourceDetail'?: string;
    /**
     * 
     * @type {number}
     * @memberof NoteResponse
     */
    'sentiment'?: number;
    /**
     * 
     * @type {string}
     * @memberof NoteResponse
     */
    'author1'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteResponse
     */
    'author2'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteResponse
     */
    'author3'?: string;
    /**
     * 
     * @type {number}
     * @memberof NoteResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof NoteResponse
     */
    'sourceType'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteResponse
     */
    'dateModified'?: string;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof NoteResponse
     */
    'createdBy'?: UserAccountInfo;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof NoteResponse
     */
    'lastModifiedBy'?: UserAccountInfo;
    /**
     * 
     * @type {Array<TagAssociationResponse>}
     * @memberof NoteResponse
     */
    'tags'?: Array<TagAssociationResponse>;
    /**
     * 
     * @type {Array<FileAssociationResponse>}
     * @memberof NoteResponse
     */
    'files'?: Array<FileAssociationResponse>;
    /**
     * 
     * @type {Array<NoteAssociationResponse>}
     * @memberof NoteResponse
     */
    'domains'?: Array<NoteAssociationResponse>;
}
/**
 * 
 * @export
 * @interface NoteSimple
 */
export interface NoteSimple {
    /**
     * 
     * @type {number}
     * @memberof NoteSimple
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof NoteSimple
     */
    'sourceType'?: string;
    /**
     * 
     * @type {number}
     * @memberof NoteSimple
     */
    'sourceTypeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof NoteSimple
     */
    'sourceDetail'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteSimple
     */
    'datePublished'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteSimple
     */
    'content'?: string;
    /**
     * 
     * @type {number}
     * @memberof NoteSimple
     */
    'sentiment'?: number;
    /**
     * 
     * @type {string}
     * @memberof NoteSimple
     */
    'author1'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteSimple
     */
    'author2'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteSimple
     */
    'author3'?: string;
}
/**
 * Request object for creating a note source type.
 * @export
 * @interface NoteSourceTypeCreateRequest
 */
export interface NoteSourceTypeCreateRequest {
    /**
     * The name of the note source type.
     * @type {string}
     * @memberof NoteSourceTypeCreateRequest
     */
    'name': string;
}
/**
 * Response object for creating a note source type.
 * @export
 * @interface NoteSourceTypeCreateResponse
 */
export interface NoteSourceTypeCreateResponse {
    /**
     * 
     * @type {number}
     * @memberof NoteSourceTypeCreateResponse
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof NoteSourceTypeCreateResponse
     */
    'version'?: number;
    /**
     * The name of the note source type.
     * @type {string}
     * @memberof NoteSourceTypeCreateResponse
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface PageCompanyData
 */
export interface PageCompanyData {
    /**
     * 
     * @type {number}
     * @memberof PageCompanyData
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageCompanyData
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageCompanyData
     */
    'size'?: number;
    /**
     * 
     * @type {Array<CompanyData>}
     * @memberof PageCompanyData
     */
    'content'?: Array<CompanyData>;
    /**
     * 
     * @type {number}
     * @memberof PageCompanyData
     */
    'number'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageCompanyData
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageCompanyData
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageCompanyData
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageCompanyData
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageCompanyData
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageCompanyData
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageConditionCategoryResponse
 */
export interface PageConditionCategoryResponse {
    /**
     * 
     * @type {number}
     * @memberof PageConditionCategoryResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageConditionCategoryResponse
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageConditionCategoryResponse
     */
    'size'?: number;
    /**
     * 
     * @type {Array<ConditionCategoryResponse>}
     * @memberof PageConditionCategoryResponse
     */
    'content'?: Array<ConditionCategoryResponse>;
    /**
     * 
     * @type {number}
     * @memberof PageConditionCategoryResponse
     */
    'number'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageConditionCategoryResponse
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageConditionCategoryResponse
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageConditionCategoryResponse
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageConditionCategoryResponse
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageConditionCategoryResponse
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageConditionCategoryResponse
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageConditionEntity
 */
export interface PageConditionEntity {
    /**
     * 
     * @type {number}
     * @memberof PageConditionEntity
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageConditionEntity
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageConditionEntity
     */
    'size'?: number;
    /**
     * 
     * @type {Array<ConditionEntity>}
     * @memberof PageConditionEntity
     */
    'content'?: Array<ConditionEntity>;
    /**
     * 
     * @type {number}
     * @memberof PageConditionEntity
     */
    'number'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageConditionEntity
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageConditionEntity
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageConditionEntity
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageConditionEntity
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageConditionEntity
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageConditionEntity
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageConditionResponse
 */
export interface PageConditionResponse {
    /**
     * 
     * @type {number}
     * @memberof PageConditionResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageConditionResponse
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageConditionResponse
     */
    'size'?: number;
    /**
     * 
     * @type {Array<ConditionResponse>}
     * @memberof PageConditionResponse
     */
    'content'?: Array<ConditionResponse>;
    /**
     * 
     * @type {number}
     * @memberof PageConditionResponse
     */
    'number'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageConditionResponse
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageConditionResponse
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageConditionResponse
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageConditionResponse
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageConditionResponse
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageConditionResponse
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageConditionStatusResponse
 */
export interface PageConditionStatusResponse {
    /**
     * 
     * @type {number}
     * @memberof PageConditionStatusResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageConditionStatusResponse
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageConditionStatusResponse
     */
    'size'?: number;
    /**
     * 
     * @type {Array<ConditionStatusResponse>}
     * @memberof PageConditionStatusResponse
     */
    'content'?: Array<ConditionStatusResponse>;
    /**
     * 
     * @type {number}
     * @memberof PageConditionStatusResponse
     */
    'number'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageConditionStatusResponse
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageConditionStatusResponse
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageConditionStatusResponse
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageConditionStatusResponse
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageConditionStatusResponse
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageConditionStatusResponse
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageDateEventResponse
 */
export interface PageDateEventResponse {
    /**
     * 
     * @type {number}
     * @memberof PageDateEventResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDateEventResponse
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDateEventResponse
     */
    'size'?: number;
    /**
     * 
     * @type {Array<DateEventResponse>}
     * @memberof PageDateEventResponse
     */
    'content'?: Array<DateEventResponse>;
    /**
     * 
     * @type {number}
     * @memberof PageDateEventResponse
     */
    'number'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageDateEventResponse
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageDateEventResponse
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageDateEventResponse
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageDateEventResponse
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageDateEventResponse
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageDateEventResponse
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageDomainTypeResponse
 */
export interface PageDomainTypeResponse {
    /**
     * 
     * @type {number}
     * @memberof PageDomainTypeResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDomainTypeResponse
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDomainTypeResponse
     */
    'size'?: number;
    /**
     * 
     * @type {Array<DomainTypeResponse>}
     * @memberof PageDomainTypeResponse
     */
    'content'?: Array<DomainTypeResponse>;
    /**
     * 
     * @type {number}
     * @memberof PageDomainTypeResponse
     */
    'number'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageDomainTypeResponse
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageDomainTypeResponse
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageDomainTypeResponse
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageDomainTypeResponse
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageDomainTypeResponse
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageDomainTypeResponse
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageExchangeResponse
 */
export interface PageExchangeResponse {
    /**
     * 
     * @type {number}
     * @memberof PageExchangeResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageExchangeResponse
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageExchangeResponse
     */
    'size'?: number;
    /**
     * 
     * @type {Array<ExchangeResponse>}
     * @memberof PageExchangeResponse
     */
    'content'?: Array<ExchangeResponse>;
    /**
     * 
     * @type {number}
     * @memberof PageExchangeResponse
     */
    'number'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageExchangeResponse
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageExchangeResponse
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageExchangeResponse
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageExchangeResponse
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageExchangeResponse
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageExchangeResponse
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageFeatureAssociationResponse
 */
export interface PageFeatureAssociationResponse {
    /**
     * 
     * @type {number}
     * @memberof PageFeatureAssociationResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageFeatureAssociationResponse
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageFeatureAssociationResponse
     */
    'size'?: number;
    /**
     * 
     * @type {Array<FeatureAssociationResponse>}
     * @memberof PageFeatureAssociationResponse
     */
    'content'?: Array<FeatureAssociationResponse>;
    /**
     * 
     * @type {number}
     * @memberof PageFeatureAssociationResponse
     */
    'number'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageFeatureAssociationResponse
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageFeatureAssociationResponse
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageFeatureAssociationResponse
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageFeatureAssociationResponse
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageFeatureAssociationResponse
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageFeatureAssociationResponse
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageFeatureResponse
 */
export interface PageFeatureResponse {
    /**
     * 
     * @type {number}
     * @memberof PageFeatureResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageFeatureResponse
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageFeatureResponse
     */
    'size'?: number;
    /**
     * 
     * @type {Array<FeatureResponse>}
     * @memberof PageFeatureResponse
     */
    'content'?: Array<FeatureResponse>;
    /**
     * 
     * @type {number}
     * @memberof PageFeatureResponse
     */
    'number'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageFeatureResponse
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageFeatureResponse
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageFeatureResponse
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageFeatureResponse
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageFeatureResponse
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageFeatureResponse
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageFileAssociationResponse
 */
export interface PageFileAssociationResponse {
    /**
     * 
     * @type {number}
     * @memberof PageFileAssociationResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageFileAssociationResponse
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageFileAssociationResponse
     */
    'size'?: number;
    /**
     * 
     * @type {Array<FileAssociationResponse>}
     * @memberof PageFileAssociationResponse
     */
    'content'?: Array<FileAssociationResponse>;
    /**
     * 
     * @type {number}
     * @memberof PageFileAssociationResponse
     */
    'number'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageFileAssociationResponse
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageFileAssociationResponse
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageFileAssociationResponse
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageFileAssociationResponse
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageFileAssociationResponse
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageFileAssociationResponse
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageFileResponse
 */
export interface PageFileResponse {
    /**
     * 
     * @type {number}
     * @memberof PageFileResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageFileResponse
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageFileResponse
     */
    'size'?: number;
    /**
     * 
     * @type {Array<FileResponse>}
     * @memberof PageFileResponse
     */
    'content'?: Array<FileResponse>;
    /**
     * 
     * @type {number}
     * @memberof PageFileResponse
     */
    'number'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageFileResponse
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageFileResponse
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageFileResponse
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageFileResponse
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageFileResponse
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageFileResponse
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageFundamentalResponse
 */
export interface PageFundamentalResponse {
    /**
     * 
     * @type {number}
     * @memberof PageFundamentalResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageFundamentalResponse
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageFundamentalResponse
     */
    'size'?: number;
    /**
     * 
     * @type {Array<FundamentalResponse>}
     * @memberof PageFundamentalResponse
     */
    'content'?: Array<FundamentalResponse>;
    /**
     * 
     * @type {number}
     * @memberof PageFundamentalResponse
     */
    'number'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageFundamentalResponse
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageFundamentalResponse
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageFundamentalResponse
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageFundamentalResponse
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageFundamentalResponse
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageFundamentalResponse
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageImpliedProbabilityResponse
 */
export interface PageImpliedProbabilityResponse {
    /**
     * 
     * @type {number}
     * @memberof PageImpliedProbabilityResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageImpliedProbabilityResponse
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageImpliedProbabilityResponse
     */
    'size'?: number;
    /**
     * 
     * @type {Array<ImpliedProbabilityResponse>}
     * @memberof PageImpliedProbabilityResponse
     */
    'content'?: Array<ImpliedProbabilityResponse>;
    /**
     * 
     * @type {number}
     * @memberof PageImpliedProbabilityResponse
     */
    'number'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageImpliedProbabilityResponse
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageImpliedProbabilityResponse
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageImpliedProbabilityResponse
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageImpliedProbabilityResponse
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageImpliedProbabilityResponse
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageImpliedProbabilityResponse
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageLinkCategoryResponse
 */
export interface PageLinkCategoryResponse {
    /**
     * 
     * @type {number}
     * @memberof PageLinkCategoryResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLinkCategoryResponse
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLinkCategoryResponse
     */
    'size'?: number;
    /**
     * 
     * @type {Array<LinkCategoryResponse>}
     * @memberof PageLinkCategoryResponse
     */
    'content'?: Array<LinkCategoryResponse>;
    /**
     * 
     * @type {number}
     * @memberof PageLinkCategoryResponse
     */
    'number'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageLinkCategoryResponse
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageLinkCategoryResponse
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageLinkCategoryResponse
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageLinkCategoryResponse
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLinkCategoryResponse
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLinkCategoryResponse
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageLinkResponse
 */
export interface PageLinkResponse {
    /**
     * 
     * @type {number}
     * @memberof PageLinkResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLinkResponse
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLinkResponse
     */
    'size'?: number;
    /**
     * 
     * @type {Array<LinkResponse>}
     * @memberof PageLinkResponse
     */
    'content'?: Array<LinkResponse>;
    /**
     * 
     * @type {number}
     * @memberof PageLinkResponse
     */
    'number'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageLinkResponse
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageLinkResponse
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageLinkResponse
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageLinkResponse
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLinkResponse
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLinkResponse
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageMnaCategoryResponse
 */
export interface PageMnaCategoryResponse {
    /**
     * 
     * @type {number}
     * @memberof PageMnaCategoryResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMnaCategoryResponse
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMnaCategoryResponse
     */
    'size'?: number;
    /**
     * 
     * @type {Array<MnaCategoryResponse>}
     * @memberof PageMnaCategoryResponse
     */
    'content'?: Array<MnaCategoryResponse>;
    /**
     * 
     * @type {number}
     * @memberof PageMnaCategoryResponse
     */
    'number'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageMnaCategoryResponse
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageMnaCategoryResponse
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageMnaCategoryResponse
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageMnaCategoryResponse
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageMnaCategoryResponse
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageMnaCategoryResponse
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageMnaResponse
 */
export interface PageMnaResponse {
    /**
     * 
     * @type {number}
     * @memberof PageMnaResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMnaResponse
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMnaResponse
     */
    'size'?: number;
    /**
     * 
     * @type {Array<MnaResponse>}
     * @memberof PageMnaResponse
     */
    'content'?: Array<MnaResponse>;
    /**
     * 
     * @type {number}
     * @memberof PageMnaResponse
     */
    'number'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageMnaResponse
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageMnaResponse
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageMnaResponse
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageMnaResponse
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageMnaResponse
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageMnaResponse
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageMnaSubCategoryResponse
 */
export interface PageMnaSubCategoryResponse {
    /**
     * 
     * @type {number}
     * @memberof PageMnaSubCategoryResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMnaSubCategoryResponse
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMnaSubCategoryResponse
     */
    'size'?: number;
    /**
     * 
     * @type {Array<MnaSubCategoryResponse>}
     * @memberof PageMnaSubCategoryResponse
     */
    'content'?: Array<MnaSubCategoryResponse>;
    /**
     * 
     * @type {number}
     * @memberof PageMnaSubCategoryResponse
     */
    'number'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageMnaSubCategoryResponse
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageMnaSubCategoryResponse
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageMnaSubCategoryResponse
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageMnaSubCategoryResponse
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageMnaSubCategoryResponse
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageMnaSubCategoryResponse
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageNoteResponse
 */
export interface PageNoteResponse {
    /**
     * 
     * @type {number}
     * @memberof PageNoteResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageNoteResponse
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageNoteResponse
     */
    'size'?: number;
    /**
     * 
     * @type {Array<NoteResponse>}
     * @memberof PageNoteResponse
     */
    'content'?: Array<NoteResponse>;
    /**
     * 
     * @type {number}
     * @memberof PageNoteResponse
     */
    'number'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageNoteResponse
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageNoteResponse
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageNoteResponse
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageNoteResponse
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageNoteResponse
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageNoteResponse
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageNoteSourceTypeCreateResponse
 */
export interface PageNoteSourceTypeCreateResponse {
    /**
     * 
     * @type {number}
     * @memberof PageNoteSourceTypeCreateResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageNoteSourceTypeCreateResponse
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageNoteSourceTypeCreateResponse
     */
    'size'?: number;
    /**
     * 
     * @type {Array<NoteSourceTypeCreateResponse>}
     * @memberof PageNoteSourceTypeCreateResponse
     */
    'content'?: Array<NoteSourceTypeCreateResponse>;
    /**
     * 
     * @type {number}
     * @memberof PageNoteSourceTypeCreateResponse
     */
    'number'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageNoteSourceTypeCreateResponse
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageNoteSourceTypeCreateResponse
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageNoteSourceTypeCreateResponse
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageNoteSourceTypeCreateResponse
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageNoteSourceTypeCreateResponse
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageNoteSourceTypeCreateResponse
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageRegulatoryResponse
 */
export interface PageRegulatoryResponse {
    /**
     * 
     * @type {number}
     * @memberof PageRegulatoryResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageRegulatoryResponse
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageRegulatoryResponse
     */
    'size'?: number;
    /**
     * 
     * @type {Array<RegulatoryResponse>}
     * @memberof PageRegulatoryResponse
     */
    'content'?: Array<RegulatoryResponse>;
    /**
     * 
     * @type {number}
     * @memberof PageRegulatoryResponse
     */
    'number'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageRegulatoryResponse
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageRegulatoryResponse
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageRegulatoryResponse
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageRegulatoryResponse
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageRegulatoryResponse
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageRegulatoryResponse
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageSectorResponse
 */
export interface PageSectorResponse {
    /**
     * 
     * @type {number}
     * @memberof PageSectorResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageSectorResponse
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageSectorResponse
     */
    'size'?: number;
    /**
     * 
     * @type {Array<SectorResponse>}
     * @memberof PageSectorResponse
     */
    'content'?: Array<SectorResponse>;
    /**
     * 
     * @type {number}
     * @memberof PageSectorResponse
     */
    'number'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageSectorResponse
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageSectorResponse
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageSectorResponse
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageSectorResponse
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageSectorResponse
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageSectorResponse
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageTagAssociationResponse
 */
export interface PageTagAssociationResponse {
    /**
     * 
     * @type {number}
     * @memberof PageTagAssociationResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageTagAssociationResponse
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageTagAssociationResponse
     */
    'size'?: number;
    /**
     * 
     * @type {Array<TagAssociationResponse>}
     * @memberof PageTagAssociationResponse
     */
    'content'?: Array<TagAssociationResponse>;
    /**
     * 
     * @type {number}
     * @memberof PageTagAssociationResponse
     */
    'number'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageTagAssociationResponse
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageTagAssociationResponse
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageTagAssociationResponse
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageTagAssociationResponse
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageTagAssociationResponse
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageTagAssociationResponse
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageTagResponse
 */
export interface PageTagResponse {
    /**
     * 
     * @type {number}
     * @memberof PageTagResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageTagResponse
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageTagResponse
     */
    'size'?: number;
    /**
     * 
     * @type {Array<TagResponse>}
     * @memberof PageTagResponse
     */
    'content'?: Array<TagResponse>;
    /**
     * 
     * @type {number}
     * @memberof PageTagResponse
     */
    'number'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageTagResponse
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageTagResponse
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageTagResponse
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageTagResponse
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageTagResponse
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageTagResponse
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageTxDocSummaryResponse
 */
export interface PageTxDocSummaryResponse {
    /**
     * 
     * @type {number}
     * @memberof PageTxDocSummaryResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageTxDocSummaryResponse
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageTxDocSummaryResponse
     */
    'size'?: number;
    /**
     * 
     * @type {Array<TxDocSummaryResponse>}
     * @memberof PageTxDocSummaryResponse
     */
    'content'?: Array<TxDocSummaryResponse>;
    /**
     * 
     * @type {number}
     * @memberof PageTxDocSummaryResponse
     */
    'number'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageTxDocSummaryResponse
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageTxDocSummaryResponse
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageTxDocSummaryResponse
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageTxDocSummaryResponse
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageTxDocSummaryResponse
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageTxDocSummaryResponse
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageUserPreferenceTypeOptionResponse
 */
export interface PageUserPreferenceTypeOptionResponse {
    /**
     * 
     * @type {number}
     * @memberof PageUserPreferenceTypeOptionResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageUserPreferenceTypeOptionResponse
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageUserPreferenceTypeOptionResponse
     */
    'size'?: number;
    /**
     * 
     * @type {Array<UserPreferenceTypeOptionResponse>}
     * @memberof PageUserPreferenceTypeOptionResponse
     */
    'content'?: Array<UserPreferenceTypeOptionResponse>;
    /**
     * 
     * @type {number}
     * @memberof PageUserPreferenceTypeOptionResponse
     */
    'number'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageUserPreferenceTypeOptionResponse
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageUserPreferenceTypeOptionResponse
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageUserPreferenceTypeOptionResponse
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageUserPreferenceTypeOptionResponse
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageUserPreferenceTypeOptionResponse
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageUserPreferenceTypeOptionResponse
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageUserPreferenceTypeResponse
 */
export interface PageUserPreferenceTypeResponse {
    /**
     * 
     * @type {number}
     * @memberof PageUserPreferenceTypeResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageUserPreferenceTypeResponse
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageUserPreferenceTypeResponse
     */
    'size'?: number;
    /**
     * 
     * @type {Array<UserPreferenceTypeResponse>}
     * @memberof PageUserPreferenceTypeResponse
     */
    'content'?: Array<UserPreferenceTypeResponse>;
    /**
     * 
     * @type {number}
     * @memberof PageUserPreferenceTypeResponse
     */
    'number'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageUserPreferenceTypeResponse
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageUserPreferenceTypeResponse
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageUserPreferenceTypeResponse
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageUserPreferenceTypeResponse
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageUserPreferenceTypeResponse
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageUserPreferenceTypeResponse
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageWhatMattersResponse
 */
export interface PageWhatMattersResponse {
    /**
     * 
     * @type {number}
     * @memberof PageWhatMattersResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageWhatMattersResponse
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageWhatMattersResponse
     */
    'size'?: number;
    /**
     * 
     * @type {Array<WhatMattersResponse>}
     * @memberof PageWhatMattersResponse
     */
    'content'?: Array<WhatMattersResponse>;
    /**
     * 
     * @type {number}
     * @memberof PageWhatMattersResponse
     */
    'number'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageWhatMattersResponse
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {number}
     * @memberof PageWhatMattersResponse
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageWhatMattersResponse
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageWhatMattersResponse
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageWhatMattersResponse
     */
    'last'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageWhatMattersResponse
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface Pageable
 */
export interface Pageable {
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'size'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Pageable
     */
    'sort'?: Array<string>;
}
/**
 * 
 * @export
 * @interface PageableObject
 */
export interface PageableObject {
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'offset'?: number;
    /**
     * 
     * @type {Array<SortObject>}
     * @memberof PageableObject
     */
    'sort'?: Array<SortObject>;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    'paged'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    'unpaged'?: boolean;
}
/**
 * Password reset details for the user
 * @export
 * @interface PasswordResetRequest
 */
export interface PasswordResetRequest {
    /**
     * 
     * @type {string}
     * @memberof PasswordResetRequest
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof PasswordResetRequest
     */
    'passwordConfirm': string;
}
/**
 * Response details for the password reset operation
 * @export
 * @interface PasswordResetResponse
 */
export interface PasswordResetResponse {
    /**
     * Status of the password reset operation
     * @type {string}
     * @memberof PasswordResetResponse
     */
    'status'?: string;
    /**
     * Detailed message of the password reset operation
     * @type {string}
     * @memberof PasswordResetResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface RegulatoryRequest
 */
export interface RegulatoryRequest {
    /**
     * 
     * @type {number}
     * @memberof RegulatoryRequest
     */
    'version': number;
    /**
     * 
     * @type {string}
     * @memberof RegulatoryRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof RegulatoryRequest
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof RegulatoryRequest
     */
    'purpose'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegulatoryRequest
     */
    'mandate': string;
    /**
     * 
     * @type {string}
     * @memberof RegulatoryRequest
     */
    'process'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegulatoryRequest
     */
    'timing'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegulatoryRequest
     */
    'commentary'?: string;
    /**
     * 
     * @type {AssociationActionsLongWhatMattersRequest}
     * @memberof RegulatoryRequest
     */
    'whatMatters'?: AssociationActionsLongWhatMattersRequest;
    /**
     * 
     * @type {AssociationActionsLongLinkRequest}
     * @memberof RegulatoryRequest
     */
    'links'?: AssociationActionsLongLinkRequest;
    /**
     * 
     * @type {AssociationActionsLongLong}
     * @memberof RegulatoryRequest
     */
    'files'?: AssociationActionsLongLong;
}
/**
 * 
 * @export
 * @interface RegulatoryResponse
 */
export interface RegulatoryResponse {
    /**
     * 
     * @type {number}
     * @memberof RegulatoryResponse
     */
    'version': number;
    /**
     * 
     * @type {string}
     * @memberof RegulatoryResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof RegulatoryResponse
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof RegulatoryResponse
     */
    'purpose'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegulatoryResponse
     */
    'mandate': string;
    /**
     * 
     * @type {string}
     * @memberof RegulatoryResponse
     */
    'process'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegulatoryResponse
     */
    'timing'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegulatoryResponse
     */
    'commentary'?: string;
    /**
     * 
     * @type {number}
     * @memberof RegulatoryResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof RegulatoryResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegulatoryResponse
     */
    'dateModified'?: string;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof RegulatoryResponse
     */
    'createdBy'?: UserAccountInfo;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof RegulatoryResponse
     */
    'lastModifiedBy'?: UserAccountInfo;
    /**
     * 
     * @type {Array<LinkResponse>}
     * @memberof RegulatoryResponse
     */
    'links'?: Array<LinkResponse>;
    /**
     * 
     * @type {Array<FileAssociationResponse>}
     * @memberof RegulatoryResponse
     */
    'files'?: Array<FileAssociationResponse>;
    /**
     * 
     * @type {Array<NoteSimple>}
     * @memberof RegulatoryResponse
     */
    'notes'?: Array<NoteSimple>;
    /**
     * 
     * @type {Array<WhatMattersResponse>}
     * @memberof RegulatoryResponse
     */
    'whatMatters'?: Array<WhatMattersResponse>;
}
/**
 * 
 * @export
 * @interface SearchResponse
 */
export interface SearchResponse {
    /**
     * 
     * @type {Array<CompactMnaResponse>}
     * @memberof SearchResponse
     */
    'mnas'?: Array<CompactMnaResponse>;
    /**
     * 
     * @type {Array<CompactNoteResponse>}
     * @memberof SearchResponse
     */
    'notes'?: Array<CompactNoteResponse>;
}
/**
 * 
 * @export
 * @interface SectorEntity
 */
export interface SectorEntity {
    /**
     * 
     * @type {number}
     * @memberof SectorEntity
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof SectorEntity
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof SectorEntity
     */
    'dateModified'?: string;
    /**
     * 
     * @type {UserAccount}
     * @memberof SectorEntity
     */
    'createdBy'?: UserAccount;
    /**
     * 
     * @type {UserAccount}
     * @memberof SectorEntity
     */
    'lastModifiedBy'?: UserAccount;
    /**
     * 
     * @type {number}
     * @memberof SectorEntity
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SectorEntity
     */
    'name'?: string;
}
/**
 * Request object for creating or updating a sector. Example: Real Estate
 * @export
 * @interface SectorRequest
 */
export interface SectorRequest {
    /**
     * Name of the sector
     * @type {string}
     * @memberof SectorRequest
     */
    'name': string;
}
/**
 * Response object for a sector. Example: Technology
 * @export
 * @interface SectorResponse
 */
export interface SectorResponse {
    /**
     * Name of the sector
     * @type {string}
     * @memberof SectorResponse
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof SectorResponse
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof SectorResponse
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof SectorResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof SectorResponse
     */
    'createdBy'?: UserAccountInfo;
}
/**
 * 
 * @export
 * @interface SimplePageErrorLogResponse
 */
export interface SimplePageErrorLogResponse {
    /**
     * 
     * @type {Array<ErrorLogResponse>}
     * @memberof SimplePageErrorLogResponse
     */
    'content'?: Array<ErrorLogResponse>;
    /**
     * 
     * @type {number}
     * @memberof SimplePageErrorLogResponse
     */
    'page'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof SimplePageErrorLogResponse
     */
    'sort'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof SimplePageErrorLogResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof SimplePageErrorLogResponse
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof SimplePageErrorLogResponse
     */
    'size'?: number;
}
/**
 * 
 * @export
 * @interface SimplePageLinkAssociationResponse
 */
export interface SimplePageLinkAssociationResponse {
    /**
     * 
     * @type {Array<LinkAssociationResponse>}
     * @memberof SimplePageLinkAssociationResponse
     */
    'content'?: Array<LinkAssociationResponse>;
    /**
     * 
     * @type {number}
     * @memberof SimplePageLinkAssociationResponse
     */
    'page'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof SimplePageLinkAssociationResponse
     */
    'sort'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof SimplePageLinkAssociationResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof SimplePageLinkAssociationResponse
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof SimplePageLinkAssociationResponse
     */
    'size'?: number;
}
/**
 * 
 * @export
 * @interface SimplePageNoteAssociationResponse
 */
export interface SimplePageNoteAssociationResponse {
    /**
     * 
     * @type {Array<NoteAssociationResponse>}
     * @memberof SimplePageNoteAssociationResponse
     */
    'content'?: Array<NoteAssociationResponse>;
    /**
     * 
     * @type {number}
     * @memberof SimplePageNoteAssociationResponse
     */
    'page'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof SimplePageNoteAssociationResponse
     */
    'sort'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof SimplePageNoteAssociationResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof SimplePageNoteAssociationResponse
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof SimplePageNoteAssociationResponse
     */
    'size'?: number;
}
/**
 * 
 * @export
 * @interface SimplePageTxDocSummaryTypeResponse
 */
export interface SimplePageTxDocSummaryTypeResponse {
    /**
     * 
     * @type {Array<TxDocSummaryTypeResponse>}
     * @memberof SimplePageTxDocSummaryTypeResponse
     */
    'content'?: Array<TxDocSummaryTypeResponse>;
    /**
     * 
     * @type {number}
     * @memberof SimplePageTxDocSummaryTypeResponse
     */
    'page'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof SimplePageTxDocSummaryTypeResponse
     */
    'sort'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof SimplePageTxDocSummaryTypeResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof SimplePageTxDocSummaryTypeResponse
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof SimplePageTxDocSummaryTypeResponse
     */
    'size'?: number;
}
/**
 * 
 * @export
 * @interface SimplePageUserAlertResponse
 */
export interface SimplePageUserAlertResponse {
    /**
     * 
     * @type {Array<UserAlertResponse>}
     * @memberof SimplePageUserAlertResponse
     */
    'content'?: Array<UserAlertResponse>;
    /**
     * 
     * @type {number}
     * @memberof SimplePageUserAlertResponse
     */
    'page'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof SimplePageUserAlertResponse
     */
    'sort'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof SimplePageUserAlertResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof SimplePageUserAlertResponse
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof SimplePageUserAlertResponse
     */
    'size'?: number;
}
/**
 * 
 * @export
 * @interface SimplePageWhatMattersAssociationResponse
 */
export interface SimplePageWhatMattersAssociationResponse {
    /**
     * 
     * @type {Array<WhatMattersAssociationResponse>}
     * @memberof SimplePageWhatMattersAssociationResponse
     */
    'content'?: Array<WhatMattersAssociationResponse>;
    /**
     * 
     * @type {number}
     * @memberof SimplePageWhatMattersAssociationResponse
     */
    'page'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof SimplePageWhatMattersAssociationResponse
     */
    'sort'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof SimplePageWhatMattersAssociationResponse
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof SimplePageWhatMattersAssociationResponse
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof SimplePageWhatMattersAssociationResponse
     */
    'size'?: number;
}
/**
 * 
 * @export
 * @interface SortObject
 */
export interface SortObject {
    /**
     * 
     * @type {string}
     * @memberof SortObject
     */
    'direction'?: string;
    /**
     * 
     * @type {string}
     * @memberof SortObject
     */
    'nullHandling'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    'ascending'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SortObject
     */
    'property'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    'ignoreCase'?: boolean;
}
/**
 * Represents a response for a tag association. Tag association responses provide information about the association between a tag and other domains.
 * @export
 * @interface TagAssociationRequest
 */
export interface TagAssociationRequest {
    /**
     * 
     * @type {number}
     * @memberof TagAssociationRequest
     */
    'typeId': number;
    /**
     * 
     * @type {number}
     * @memberof TagAssociationRequest
     */
    'entityId': number;
    /**
     * 
     * @type {string}
     * @memberof TagAssociationRequest
     */
    'tag': string;
}
/**
 * Represents a response for a tag association. Tag association responses provide information about the association between a tag and other domains.
 * @export
 * @interface TagAssociationResponse
 */
export interface TagAssociationResponse {
    /**
     * 
     * @type {number}
     * @memberof TagAssociationResponse
     */
    'typeId': number;
    /**
     * 
     * @type {number}
     * @memberof TagAssociationResponse
     */
    'entityId': number;
    /**
     * 
     * @type {string}
     * @memberof TagAssociationResponse
     */
    'tag': string;
    /**
     * 
     * @type {number}
     * @memberof TagAssociationResponse
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof TagAssociationResponse
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof TagAssociationResponse
     */
    'domainType'?: string;
    /**
     * 
     * @type {string}
     * @memberof TagAssociationResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof TagAssociationResponse
     */
    'createdBy'?: UserAccountInfo;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof TagAssociationResponse
     */
    'lastModifiedBy'?: UserAccountInfo;
    /**
     * 
     * @type {string}
     * @memberof TagAssociationResponse
     */
    'dateModified'?: string;
}
/**
 * Request object for creating or updating a tag.
 * @export
 * @interface TagRequest
 */
export interface TagRequest {
    /**
     * The text of the tag, used to categorize domain entities like MNA,Note
     * @type {string}
     * @memberof TagRequest
     */
    'tag': string;
}
/**
 * Response object for a tag.
 * @export
 * @interface TagResponse
 */
export interface TagResponse {
    /**
     * The text of the tag, used to categorize domain entities like MNA,Note
     * @type {string}
     * @memberof TagResponse
     */
    'tag': string;
    /**
     * 
     * @type {number}
     * @memberof TagResponse
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof TagResponse
     */
    'version'?: number;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof TagResponse
     */
    'createdBy'?: UserAccountInfo;
    /**
     * 
     * @type {string}
     * @memberof TagResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof TagResponse
     */
    'lastModifiedBy'?: UserAccountInfo;
    /**
     * 
     * @type {string}
     * @memberof TagResponse
     */
    'dateModified'?: string;
}
/**
 * 
 * @export
 * @interface TxDocSummaryRequest
 */
export interface TxDocSummaryRequest {
    /**
     * 
     * @type {string}
     * @memberof TxDocSummaryRequest
     */
    'summary': string;
    /**
     * 
     * @type {number}
     * @memberof TxDocSummaryRequest
     */
    'typeId': number;
    /**
     * 
     * @type {number}
     * @memberof TxDocSummaryRequest
     */
    'mnaId': number;
}
/**
 * 
 * @export
 * @interface TxDocSummaryResponse
 */
export interface TxDocSummaryResponse {
    /**
     * 
     * @type {string}
     * @memberof TxDocSummaryResponse
     */
    'summary': string;
    /**
     * 
     * @type {number}
     * @memberof TxDocSummaryResponse
     */
    'typeId': number;
    /**
     * 
     * @type {number}
     * @memberof TxDocSummaryResponse
     */
    'mnaId': number;
    /**
     * 
     * @type {number}
     * @memberof TxDocSummaryResponse
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof TxDocSummaryResponse
     */
    'version'?: number;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof TxDocSummaryResponse
     */
    'createdBy'?: UserAccountInfo;
    /**
     * 
     * @type {string}
     * @memberof TxDocSummaryResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof TxDocSummaryResponse
     */
    'lastModifiedBy'?: UserAccountInfo;
    /**
     * 
     * @type {string}
     * @memberof TxDocSummaryResponse
     */
    'dateModified'?: string;
}
/**
 * 
 * @export
 * @interface TxDocSummaryTypeResponse
 */
export interface TxDocSummaryTypeResponse {
    /**
     * 
     * @type {number}
     * @memberof TxDocSummaryTypeResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TxDocSummaryTypeResponse
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface UserAccount
 */
export interface UserAccount {
    /**
     * 
     * @type {number}
     * @memberof UserAccount
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserAccount
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAccount
     */
    'dateModified'?: string;
    /**
     * 
     * @type {UserAccount}
     * @memberof UserAccount
     */
    'createdBy'?: UserAccount;
    /**
     * 
     * @type {UserAccount}
     * @memberof UserAccount
     */
    'lastModifiedBy'?: UserAccount;
    /**
     * 
     * @type {number}
     * @memberof UserAccount
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserAccount
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAccount
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAccount
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface UserAccountInfo
 */
export interface UserAccountInfo {
    /**
     * 
     * @type {number}
     * @memberof UserAccountInfo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserAccountInfo
     */
    'username'?: string;
}
/**
 * Response object containing details about all unread alerts across all domains (e.g., MNA, Fundamental, Note, etc.).
 * @export
 * @interface UserAlertResponse
 */
export interface UserAlertResponse {
    /**
     * 
     * @type {number}
     * @memberof UserAlertResponse
     */
    'domainTypeId': number;
    /**
     * 
     * @type {number}
     * @memberof UserAlertResponse
     */
    'entityId': number;
    /**
     * 
     * @type {number}
     * @memberof UserAlertResponse
     */
    'entityVersion': number;
    /**
     * 
     * @type {number}
     * @memberof UserAlertResponse
     */
    'createdById'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserAlertResponse
     */
    'lastModifiedById'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserAlertResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAlertResponse
     */
    'dateModified'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserAlertResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserAlertResponse
     */
    'domainType'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserAlertResponse
     */
    'version'?: number;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof UserAlertResponse
     */
    'createdBy'?: UserAccountInfo;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof UserAlertResponse
     */
    'lastModifiedBy'?: UserAccountInfo;
    /**
     * Title identifier for the domain type to which the alert pertains.
     * @type {string}
     * @memberof UserAlertResponse
     */
    'title'?: string;
    /**
     * Name of the user who created the alert.
     * @type {string}
     * @memberof UserAlertResponse
     */
    'createdByName'?: string;
    /**
     * Name of the user who last modified the alert.
     * @type {string}
     * @memberof UserAlertResponse
     */
    'lastModifiedByName'?: string;
    /**
     * 
     * @type {Audited}
     * @memberof UserAlertResponse
     */
    'response'?: Audited;
}
/**
 * Request object for user preferences. User preferences represent the settings or configurations chosen by a user.
 * @export
 * @interface UserPreferenceRequest
 */
export interface UserPreferenceRequest {
    /**
     * 
     * @type {number}
     * @memberof UserPreferenceRequest
     */
    'typeId': number;
    /**
     * 
     * @type {string}
     * @memberof UserPreferenceRequest
     */
    'value': string;
}
/**
 * Response object for user preferences. User preferences represent the settings or configurations chosen by a user.
 * @export
 * @interface UserPreferenceResponse
 */
export interface UserPreferenceResponse {
    /**
     * 
     * @type {number}
     * @memberof UserPreferenceResponse
     */
    'typeId': number;
    /**
     * 
     * @type {string}
     * @memberof UserPreferenceResponse
     */
    'value': string;
    /**
     * 
     * @type {number}
     * @memberof UserPreferenceResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserPreferenceResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreferenceResponse
     */
    'dateModified'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserPreferenceResponse
     */
    'version'?: number;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof UserPreferenceResponse
     */
    'createdBy'?: UserAccountInfo;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof UserPreferenceResponse
     */
    'lastModifiedBy'?: UserAccountInfo;
}
/**
 * 
 * @export
 * @interface UserPreferenceTypeOptionResponse
 */
export interface UserPreferenceTypeOptionResponse {
    /**
     * 
     * @type {number}
     * @memberof UserPreferenceTypeOptionResponse
     */
    'typeId': number;
    /**
     * 
     * @type {string}
     * @memberof UserPreferenceTypeOptionResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UserPreferenceTypeOptionResponse
     */
    'value': string;
    /**
     * 
     * @type {number}
     * @memberof UserPreferenceTypeOptionResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserPreferenceTypeOptionResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreferenceTypeOptionResponse
     */
    'dateModified'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserPreferenceTypeOptionResponse
     */
    'version'?: number;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof UserPreferenceTypeOptionResponse
     */
    'createdBy'?: UserAccountInfo;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof UserPreferenceTypeOptionResponse
     */
    'lastModifiedBy'?: UserAccountInfo;
}
/**
 * 
 * @export
 * @interface UserPreferenceTypeRequest
 */
export interface UserPreferenceTypeRequest {
    /**
     * 
     * @type {string}
     * @memberof UserPreferenceTypeRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreferenceTypeRequest
     */
    'valueDefault'?: string;
}
/**
 * 
 * @export
 * @interface UserPreferenceTypeResponse
 */
export interface UserPreferenceTypeResponse {
    /**
     * 
     * @type {string}
     * @memberof UserPreferenceTypeResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreferenceTypeResponse
     */
    'valueDefault'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserPreferenceTypeResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserPreferenceTypeResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreferenceTypeResponse
     */
    'dateModified'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserPreferenceTypeResponse
     */
    'version'?: number;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof UserPreferenceTypeResponse
     */
    'createdBy'?: UserAccountInfo;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof UserPreferenceTypeResponse
     */
    'lastModifiedBy'?: UserAccountInfo;
}
/**
 * Request object for updating user profile information
 * @export
 * @interface UserProfileRequest
 */
export interface UserProfileRequest {
    /**
     * 
     * @type {string}
     * @memberof UserProfileRequest
     */
    'nameFirst': string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileRequest
     */
    'nameMiddle'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileRequest
     */
    'nameLast': string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileRequest
     */
    'email': string;
}
/**
 * Response object containing user profile information
 * @export
 * @interface UserProfileResponse
 */
export interface UserProfileResponse {
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponse
     */
    'nameFirst': string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponse
     */
    'nameMiddle'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponse
     */
    'nameLast': string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponse
     */
    'email': string;
    /**
     * 
     * @type {number}
     * @memberof UserProfileResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponse
     */
    'dateModified'?: string;
}
/**
 * User details for creating a new account
 * @export
 * @interface UserRequest
 */
export interface UserRequest {
    /**
     * 
     * @type {string}
     * @memberof UserRequest
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof UserRequest
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof UserRequest
     */
    'email': string;
}
/**
 * Request object for associating whatMatters with other domains.
 * @export
 * @interface WhatMattersAssociationRequest
 */
export interface WhatMattersAssociationRequest {
    /**
     * 
     * @type {number}
     * @memberof WhatMattersAssociationRequest
     */
    'typeId': number;
    /**
     * 
     * @type {number}
     * @memberof WhatMattersAssociationRequest
     */
    'entityId': number;
    /**
     * 
     * @type {number}
     * @memberof WhatMattersAssociationRequest
     */
    'whatMattersId': number;
}
/**
 * Response object for whatMatters association with other domains.
 * @export
 * @interface WhatMattersAssociationResponse
 */
export interface WhatMattersAssociationResponse {
    /**
     * 
     * @type {number}
     * @memberof WhatMattersAssociationResponse
     */
    'typeId': number;
    /**
     * 
     * @type {number}
     * @memberof WhatMattersAssociationResponse
     */
    'entityId': number;
    /**
     * 
     * @type {number}
     * @memberof WhatMattersAssociationResponse
     */
    'whatMattersId': number;
    /**
     * 
     * @type {number}
     * @memberof WhatMattersAssociationResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof WhatMattersAssociationResponse
     */
    'note'?: string;
    /**
     * 
     * @type {number}
     * @memberof WhatMattersAssociationResponse
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof WhatMattersAssociationResponse
     */
    'domainType'?: string;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof WhatMattersAssociationResponse
     */
    'createdBy'?: UserAccountInfo;
    /**
     * 
     * @type {string}
     * @memberof WhatMattersAssociationResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof WhatMattersAssociationResponse
     */
    'lastModifiedBy'?: UserAccountInfo;
    /**
     * 
     * @type {string}
     * @memberof WhatMattersAssociationResponse
     */
    'dateModified'?: string;
}
/**
 * Request object for creating or updating what matters entries. Represents important notes or details related to mergers and acquisitions.
 * @export
 * @interface WhatMattersRequest
 */
export interface WhatMattersRequest {
    /**
     * 
     * @type {number}
     * @memberof WhatMattersRequest
     */
    'version': number;
    /**
     * 
     * @type {string}
     * @memberof WhatMattersRequest
     */
    'note': string;
    /**
     * 
     * @type {AssociationActionsLongDomainAssociationRequest}
     * @memberof WhatMattersRequest
     */
    'domains'?: AssociationActionsLongDomainAssociationRequest;
}
/**
 * Response object for what matters entries. Represents important notes or details related to mergers and acquisitions.
 * @export
 * @interface WhatMattersResponse
 */
export interface WhatMattersResponse {
    /**
     * 
     * @type {number}
     * @memberof WhatMattersResponse
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof WhatMattersResponse
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof WhatMattersResponse
     */
    'note'?: string;
    /**
     * 
     * @type {string}
     * @memberof WhatMattersResponse
     */
    'dateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof WhatMattersResponse
     */
    'dateModified'?: string;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof WhatMattersResponse
     */
    'createdBy'?: UserAccountInfo;
    /**
     * 
     * @type {UserAccountInfo}
     * @memberof WhatMattersResponse
     */
    'lastModifiedBy'?: UserAccountInfo;
    /**
     * 
     * @type {Array<WhatMattersAssociationResponse>}
     * @memberof WhatMattersResponse
     */
    'domains'?: Array<WhatMattersAssociationResponse>;
}
/**
 * Represents a response object containing detailed information about the currently authenticated user.
 * @export
 * @interface WhoAmIResponse
 */
export interface WhoAmIResponse {
    /**
     * 
     * @type {string}
     * @memberof WhoAmIResponse
     */
    'username'?: string;
    /**
     * 
     * @type {UserProfileResponse}
     * @memberof WhoAmIResponse
     */
    'profile'?: UserProfileResponse;
    /**
     * List of roles associated with the user
     * @type {Array<string>}
     * @memberof WhoAmIResponse
     */
    'roles'?: Array<string>;
}

/**
 * AdminUserRestControllerApi - axios parameter creator
 * @export
 */
export const AdminUserRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This endpoint allows an admin to reset a user\'s password. The new password and confirmation must match.
         * @summary Reset user password
         * @param {PasswordResetRequest} passwordResetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserResetPassword: async (passwordResetRequest: PasswordResetRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'passwordResetRequest' is not null or undefined
            assertParamExists('adminUserResetPassword', 'passwordResetRequest', passwordResetRequest)
            const localVarPath = `/api/admin/users/passwords`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordResetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint allows an admin to create a new user account. The created user will be assigned the \'ROLE_USER\' role by default.
         * @summary Create a new user
         * @param {UserRequest} userRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (userRequest: UserRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userRequest' is not null or undefined
            assertParamExists('createUser', 'userRequest', userRequest)
            const localVarPath = `/api/admin/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminUserRestControllerApi - functional programming interface
 * @export
 */
export const AdminUserRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminUserRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * This endpoint allows an admin to reset a user\'s password. The new password and confirmation must match.
         * @summary Reset user password
         * @param {PasswordResetRequest} passwordResetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserResetPassword(passwordResetRequest: PasswordResetRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PasswordResetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserResetPassword(passwordResetRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminUserRestControllerApi.adminUserResetPassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This endpoint allows an admin to create a new user account. The created user will be assigned the \'ROLE_USER\' role by default.
         * @summary Create a new user
         * @param {UserRequest} userRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(userRequest: UserRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(userRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AdminUserRestControllerApi.createUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AdminUserRestControllerApi - factory interface
 * @export
 */
export const AdminUserRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminUserRestControllerApiFp(configuration)
    return {
        /**
         * This endpoint allows an admin to reset a user\'s password. The new password and confirmation must match.
         * @summary Reset user password
         * @param {PasswordResetRequest} passwordResetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserResetPassword(passwordResetRequest: PasswordResetRequest, options?: any): AxiosPromise<PasswordResetResponse> {
            return localVarFp.adminUserResetPassword(passwordResetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint allows an admin to create a new user account. The created user will be assigned the \'ROLE_USER\' role by default.
         * @summary Create a new user
         * @param {UserRequest} userRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(userRequest: UserRequest, options?: any): AxiosPromise<number> {
            return localVarFp.createUser(userRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminUserRestControllerApi - object-oriented interface
 * @export
 * @class AdminUserRestControllerApi
 * @extends {BaseAPI}
 */
export class AdminUserRestControllerApi extends BaseAPI {
    /**
     * This endpoint allows an admin to reset a user\'s password. The new password and confirmation must match.
     * @summary Reset user password
     * @param {PasswordResetRequest} passwordResetRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserRestControllerApi
     */
    public adminUserResetPassword(passwordResetRequest: PasswordResetRequest, options?: RawAxiosRequestConfig) {
        return AdminUserRestControllerApiFp(this.configuration).adminUserResetPassword(passwordResetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint allows an admin to create a new user account. The created user will be assigned the \'ROLE_USER\' role by default.
     * @summary Create a new user
     * @param {UserRequest} userRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserRestControllerApi
     */
    public createUser(userRequest: UserRequest, options?: RawAxiosRequestConfig) {
        return AdminUserRestControllerApiFp(this.configuration).createUser(userRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AlertReadRestControllerApi - axios parameter creator
 * @export
 */
export const AlertReadRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This endpoint allows a user to mark an alert as read.
         * @summary Mark alert as read
         * @param {AlertReadRequest} alertReadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAlertRead: async (alertReadRequest: AlertReadRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'alertReadRequest' is not null or undefined
            assertParamExists('createAlertRead', 'alertReadRequest', alertReadRequest)
            const localVarPath = `/api/alerts/read`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(alertReadRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint allows an authenticated user to delete an alert read entry by its ID.
         * @summary Delete alert read entry
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAlertRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAlertRead', 'id', id)
            const localVarPath = `/api/alerts/read/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint allows a user to update the read status of an alert by its ID.
         * @summary Update alert read status
         * @param {number} id 
         * @param {AlertReadRequest} alertReadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAlertRead: async (id: number, alertReadRequest: AlertReadRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAlertRead', 'id', id)
            // verify required parameter 'alertReadRequest' is not null or undefined
            assertParamExists('updateAlertRead', 'alertReadRequest', alertReadRequest)
            const localVarPath = `/api/alerts/read/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(alertReadRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AlertReadRestControllerApi - functional programming interface
 * @export
 */
export const AlertReadRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AlertReadRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * This endpoint allows a user to mark an alert as read.
         * @summary Mark alert as read
         * @param {AlertReadRequest} alertReadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAlertRead(alertReadRequest: AlertReadRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAlertRead(alertReadRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AlertReadRestControllerApi.createAlertRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This endpoint allows an authenticated user to delete an alert read entry by its ID.
         * @summary Delete alert read entry
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAlertRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAlertRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AlertReadRestControllerApi.deleteAlertRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This endpoint allows a user to update the read status of an alert by its ID.
         * @summary Update alert read status
         * @param {number} id 
         * @param {AlertReadRequest} alertReadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAlertRead(id: number, alertReadRequest: AlertReadRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAlertRead(id, alertReadRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AlertReadRestControllerApi.updateAlertRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AlertReadRestControllerApi - factory interface
 * @export
 */
export const AlertReadRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AlertReadRestControllerApiFp(configuration)
    return {
        /**
         * This endpoint allows a user to mark an alert as read.
         * @summary Mark alert as read
         * @param {AlertReadRequest} alertReadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAlertRead(alertReadRequest: AlertReadRequest, options?: any): AxiosPromise<number> {
            return localVarFp.createAlertRead(alertReadRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint allows an authenticated user to delete an alert read entry by its ID.
         * @summary Delete alert read entry
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAlertRead(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAlertRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint allows a user to update the read status of an alert by its ID.
         * @summary Update alert read status
         * @param {number} id 
         * @param {AlertReadRequest} alertReadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAlertRead(id: number, alertReadRequest: AlertReadRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateAlertRead(id, alertReadRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AlertReadRestControllerApi - object-oriented interface
 * @export
 * @class AlertReadRestControllerApi
 * @extends {BaseAPI}
 */
export class AlertReadRestControllerApi extends BaseAPI {
    /**
     * This endpoint allows a user to mark an alert as read.
     * @summary Mark alert as read
     * @param {AlertReadRequest} alertReadRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertReadRestControllerApi
     */
    public createAlertRead(alertReadRequest: AlertReadRequest, options?: RawAxiosRequestConfig) {
        return AlertReadRestControllerApiFp(this.configuration).createAlertRead(alertReadRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint allows an authenticated user to delete an alert read entry by its ID.
     * @summary Delete alert read entry
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertReadRestControllerApi
     */
    public deleteAlertRead(id: number, options?: RawAxiosRequestConfig) {
        return AlertReadRestControllerApiFp(this.configuration).deleteAlertRead(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint allows a user to update the read status of an alert by its ID.
     * @summary Update alert read status
     * @param {number} id 
     * @param {AlertReadRequest} alertReadRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertReadRestControllerApi
     */
    public updateAlertRead(id: number, alertReadRequest: AlertReadRequest, options?: RawAxiosRequestConfig) {
        return AlertReadRestControllerApiFp(this.configuration).updateAlertRead(id, alertReadRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AlertRestControllerApi - axios parameter creator
 * @export
 */
export const AlertRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Fetches all unread alerts for the authenticated user. These alerts include categories such as MNA, note, condition, what matters,  fundamental, and date events.\" 
         * @summary Get all unread alerts for a specific user
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlerts: async (page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/alerts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetches the total count of unread alerts for the authenticated user.
         * @summary Get total count of unread alerts for the authenticated user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalUnreadAlertsCount: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/alerts/total`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AlertRestControllerApi - functional programming interface
 * @export
 */
export const AlertRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AlertRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Fetches all unread alerts for the authenticated user. These alerts include categories such as MNA, note, condition, what matters,  fundamental, and date events.\" 
         * @summary Get all unread alerts for a specific user
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAlerts(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimplePageUserAlertResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAlerts(page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AlertRestControllerApi.getAlerts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Fetches the total count of unread alerts for the authenticated user.
         * @summary Get total count of unread alerts for the authenticated user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTotalUnreadAlertsCount(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalUnreadAlertsCount(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AlertRestControllerApi.getTotalUnreadAlertsCount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AlertRestControllerApi - factory interface
 * @export
 */
export const AlertRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AlertRestControllerApiFp(configuration)
    return {
        /**
         * Fetches all unread alerts for the authenticated user. These alerts include categories such as MNA, note, condition, what matters,  fundamental, and date events.\" 
         * @summary Get all unread alerts for a specific user
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlerts(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<SimplePageUserAlertResponse> {
            return localVarFp.getAlerts(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetches the total count of unread alerts for the authenticated user.
         * @summary Get total count of unread alerts for the authenticated user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalUnreadAlertsCount(options?: any): AxiosPromise<number> {
            return localVarFp.getTotalUnreadAlertsCount(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AlertRestControllerApi - object-oriented interface
 * @export
 * @class AlertRestControllerApi
 * @extends {BaseAPI}
 */
export class AlertRestControllerApi extends BaseAPI {
    /**
     * Fetches all unread alerts for the authenticated user. These alerts include categories such as MNA, note, condition, what matters,  fundamental, and date events.\" 
     * @summary Get all unread alerts for a specific user
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertRestControllerApi
     */
    public getAlerts(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return AlertRestControllerApiFp(this.configuration).getAlerts(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetches the total count of unread alerts for the authenticated user.
     * @summary Get total count of unread alerts for the authenticated user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertRestControllerApi
     */
    public getTotalUnreadAlertsCount(options?: RawAxiosRequestConfig) {
        return AlertRestControllerApiFp(this.configuration).getTotalUnreadAlertsCount(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AuthenticationRestControllerApi - axios parameter creator
 * @export
 */
export const AuthenticationRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AuthenticationRequest} authenticationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticate: async (authenticationRequest: AuthenticationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authenticationRequest' is not null or undefined
            assertParamExists('authenticate', 'authenticationRequest', authenticationRequest)
            const localVarPath = `/authenticate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authenticationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationRestControllerApi - functional programming interface
 * @export
 */
export const AuthenticationRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AuthenticationRequest} authenticationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticate(authenticationRequest: AuthenticationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticate(authenticationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationRestControllerApi.authenticate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuthenticationRestControllerApi - factory interface
 * @export
 */
export const AuthenticationRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationRestControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {AuthenticationRequest} authenticationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticate(authenticationRequest: AuthenticationRequest, options?: any): AxiosPromise<AuthenticationResponse> {
            return localVarFp.authenticate(authenticationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationRestControllerApi - object-oriented interface
 * @export
 * @class AuthenticationRestControllerApi
 * @extends {BaseAPI}
 */
export class AuthenticationRestControllerApi extends BaseAPI {
    /**
     * 
     * @param {AuthenticationRequest} authenticationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationRestControllerApi
     */
    public authenticate(authenticationRequest: AuthenticationRequest, options?: RawAxiosRequestConfig) {
        return AuthenticationRestControllerApiFp(this.configuration).authenticate(authenticationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ConditionCategoryRestControllerApi - axios parameter creator
 * @export
 */
export const ConditionCategoryRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This endpoint allows an admin or user to create a new condition category.
         * @summary Create a new Condition Category
         * @param {ConditionCategoryRequest} conditionCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConditionCategory: async (conditionCategoryRequest: ConditionCategoryRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conditionCategoryRequest' is not null or undefined
            assertParamExists('createConditionCategory', 'conditionCategoryRequest', conditionCategoryRequest)
            const localVarPath = `/api/conditions/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conditionCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint allows an admin or user to delete an existing condition category by its ID.
         * @summary Delete a Condition Category
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConditionCategory: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteConditionCategory', 'id', id)
            const localVarPath = `/api/conditions/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a paginated list of condition categories, optionally filtered by a search term.
         * @summary Get a page of Condition Categories
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConditionCategories: async (filter?: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/conditions/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a condition category by its unique identifier.
         * @summary Get Condition Category by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConditionCategoryById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getConditionCategoryById', 'id', id)
            const localVarPath = `/api/conditions/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint allows an admin or user to update an existing condition category.
         * @summary Update an existing Condition Category
         * @param {number} id 
         * @param {ConditionCategoryRequest} conditionCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConditionCategory: async (id: number, conditionCategoryRequest: ConditionCategoryRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateConditionCategory', 'id', id)
            // verify required parameter 'conditionCategoryRequest' is not null or undefined
            assertParamExists('updateConditionCategory', 'conditionCategoryRequest', conditionCategoryRequest)
            const localVarPath = `/api/conditions/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conditionCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConditionCategoryRestControllerApi - functional programming interface
 * @export
 */
export const ConditionCategoryRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConditionCategoryRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * This endpoint allows an admin or user to create a new condition category.
         * @summary Create a new Condition Category
         * @param {ConditionCategoryRequest} conditionCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createConditionCategory(conditionCategoryRequest: ConditionCategoryRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createConditionCategory(conditionCategoryRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConditionCategoryRestControllerApi.createConditionCategory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This endpoint allows an admin or user to delete an existing condition category by its ID.
         * @summary Delete a Condition Category
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteConditionCategory(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteConditionCategory(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConditionCategoryRestControllerApi.deleteConditionCategory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a paginated list of condition categories, optionally filtered by a search term.
         * @summary Get a page of Condition Categories
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConditionCategories(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageConditionCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConditionCategories(filter, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConditionCategoryRestControllerApi.getConditionCategories']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a condition category by its unique identifier.
         * @summary Get Condition Category by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConditionCategoryById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConditionCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConditionCategoryById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConditionCategoryRestControllerApi.getConditionCategoryById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This endpoint allows an admin or user to update an existing condition category.
         * @summary Update an existing Condition Category
         * @param {number} id 
         * @param {ConditionCategoryRequest} conditionCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConditionCategory(id: number, conditionCategoryRequest: ConditionCategoryRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConditionCategory(id, conditionCategoryRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConditionCategoryRestControllerApi.updateConditionCategory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ConditionCategoryRestControllerApi - factory interface
 * @export
 */
export const ConditionCategoryRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConditionCategoryRestControllerApiFp(configuration)
    return {
        /**
         * This endpoint allows an admin or user to create a new condition category.
         * @summary Create a new Condition Category
         * @param {ConditionCategoryRequest} conditionCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConditionCategory(conditionCategoryRequest: ConditionCategoryRequest, options?: any): AxiosPromise<number> {
            return localVarFp.createConditionCategory(conditionCategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint allows an admin or user to delete an existing condition category by its ID.
         * @summary Delete a Condition Category
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConditionCategory(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteConditionCategory(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a paginated list of condition categories, optionally filtered by a search term.
         * @summary Get a page of Condition Categories
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConditionCategories(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageConditionCategoryResponse> {
            return localVarFp.getConditionCategories(filter, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a condition category by its unique identifier.
         * @summary Get Condition Category by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConditionCategoryById(id: number, options?: any): AxiosPromise<ConditionCategoryResponse> {
            return localVarFp.getConditionCategoryById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint allows an admin or user to update an existing condition category.
         * @summary Update an existing Condition Category
         * @param {number} id 
         * @param {ConditionCategoryRequest} conditionCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConditionCategory(id: number, conditionCategoryRequest: ConditionCategoryRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateConditionCategory(id, conditionCategoryRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConditionCategoryRestControllerApi - object-oriented interface
 * @export
 * @class ConditionCategoryRestControllerApi
 * @extends {BaseAPI}
 */
export class ConditionCategoryRestControllerApi extends BaseAPI {
    /**
     * This endpoint allows an admin or user to create a new condition category.
     * @summary Create a new Condition Category
     * @param {ConditionCategoryRequest} conditionCategoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConditionCategoryRestControllerApi
     */
    public createConditionCategory(conditionCategoryRequest: ConditionCategoryRequest, options?: RawAxiosRequestConfig) {
        return ConditionCategoryRestControllerApiFp(this.configuration).createConditionCategory(conditionCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint allows an admin or user to delete an existing condition category by its ID.
     * @summary Delete a Condition Category
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConditionCategoryRestControllerApi
     */
    public deleteConditionCategory(id: number, options?: RawAxiosRequestConfig) {
        return ConditionCategoryRestControllerApiFp(this.configuration).deleteConditionCategory(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a paginated list of condition categories, optionally filtered by a search term.
     * @summary Get a page of Condition Categories
     * @param {string} [filter] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConditionCategoryRestControllerApi
     */
    public getConditionCategories(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return ConditionCategoryRestControllerApiFp(this.configuration).getConditionCategories(filter, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a condition category by its unique identifier.
     * @summary Get Condition Category by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConditionCategoryRestControllerApi
     */
    public getConditionCategoryById(id: number, options?: RawAxiosRequestConfig) {
        return ConditionCategoryRestControllerApiFp(this.configuration).getConditionCategoryById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint allows an admin or user to update an existing condition category.
     * @summary Update an existing Condition Category
     * @param {number} id 
     * @param {ConditionCategoryRequest} conditionCategoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConditionCategoryRestControllerApi
     */
    public updateConditionCategory(id: number, conditionCategoryRequest: ConditionCategoryRequest, options?: RawAxiosRequestConfig) {
        return ConditionCategoryRestControllerApiFp(this.configuration).updateConditionCategory(id, conditionCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ConditionRestControllerApi - axios parameter creator
 * @export
 */
export const ConditionRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This endpoint allows deleting a condition by providing its ID. Only users with \'ADMIN\' or \'USER\' role can perform this operation.
         * @summary Delete a condition
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('_delete', 'id', id)
            const localVarPath = `/api/conditions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint allows creating a new condition. The user must have \'ADMIN\' or \'USER\' role to access this endpoint.
         * @summary Create a new condition
         * @param {ConditionRequest} conditionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCondition: async (conditionRequest: ConditionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conditionRequest' is not null or undefined
            assertParamExists('createCondition', 'conditionRequest', conditionRequest)
            const localVarPath = `/api/conditions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conditionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllConditionsByDateModified: async (startDate: string, endDate: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getAllConditionsByDateModified', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getAllConditionsByDateModified', 'endDate', endDate)
            const localVarPath = `/api/conditions/betweenDates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint returns the details of a condition by its ID.
         * @summary Get condition by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConditionById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getConditionById', 'id', id)
            const localVarPath = `/api/conditions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint retrieves a paginated list of conditions, optionally filtered by a search term which is not used currently.
         * @summary Get a list of Conditions
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConditions: async (filter?: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/conditions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint returns the count of conditions that were modified between the provided start and end dates.
         * @summary Get the count of conditions modified between specified dates
         * @param {string} startDate Start date in ISO format
         * @param {string} endDate End date in ISO format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConditionsCountByDateModified: async (startDate: string, endDate: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getConditionsCountByDateModified', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getConditionsCountByDateModified', 'endDate', endDate)
            const localVarPath = `/api/conditions/countBetweenDates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint allows updating an existing condition. The user must have \'ADMIN\' or \'USER\' role to access this endpoint.
         * @summary Update an existing condition
         * @param {number} id 
         * @param {ConditionRequest} conditionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCondition: async (id: number, conditionRequest: ConditionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCondition', 'id', id)
            // verify required parameter 'conditionRequest' is not null or undefined
            assertParamExists('updateCondition', 'conditionRequest', conditionRequest)
            const localVarPath = `/api/conditions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conditionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConditionRestControllerApi - functional programming interface
 * @export
 */
export const ConditionRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConditionRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * This endpoint allows deleting a condition by providing its ID. Only users with \'ADMIN\' or \'USER\' role can perform this operation.
         * @summary Delete a condition
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConditionRestControllerApi._delete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This endpoint allows creating a new condition. The user must have \'ADMIN\' or \'USER\' role to access this endpoint.
         * @summary Create a new condition
         * @param {ConditionRequest} conditionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCondition(conditionRequest: ConditionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCondition(conditionRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConditionRestControllerApi.createCondition']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllConditionsByDateModified(startDate: string, endDate: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageConditionEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllConditionsByDateModified(startDate, endDate, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConditionRestControllerApi.getAllConditionsByDateModified']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This endpoint returns the details of a condition by its ID.
         * @summary Get condition by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConditionById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConditionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConditionById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConditionRestControllerApi.getConditionById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This endpoint retrieves a paginated list of conditions, optionally filtered by a search term which is not used currently.
         * @summary Get a list of Conditions
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConditions(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageConditionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConditions(filter, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConditionRestControllerApi.getConditions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This endpoint returns the count of conditions that were modified between the provided start and end dates.
         * @summary Get the count of conditions modified between specified dates
         * @param {string} startDate Start date in ISO format
         * @param {string} endDate End date in ISO format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConditionsCountByDateModified(startDate: string, endDate: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConditionsCountByDateModified(startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConditionRestControllerApi.getConditionsCountByDateModified']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This endpoint allows updating an existing condition. The user must have \'ADMIN\' or \'USER\' role to access this endpoint.
         * @summary Update an existing condition
         * @param {number} id 
         * @param {ConditionRequest} conditionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCondition(id: number, conditionRequest: ConditionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCondition(id, conditionRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConditionRestControllerApi.updateCondition']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ConditionRestControllerApi - factory interface
 * @export
 */
export const ConditionRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConditionRestControllerApiFp(configuration)
    return {
        /**
         * This endpoint allows deleting a condition by providing its ID. Only users with \'ADMIN\' or \'USER\' role can perform this operation.
         * @summary Delete a condition
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp._delete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint allows creating a new condition. The user must have \'ADMIN\' or \'USER\' role to access this endpoint.
         * @summary Create a new condition
         * @param {ConditionRequest} conditionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCondition(conditionRequest: ConditionRequest, options?: any): AxiosPromise<number> {
            return localVarFp.createCondition(conditionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllConditionsByDateModified(startDate: string, endDate: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageConditionEntity> {
            return localVarFp.getAllConditionsByDateModified(startDate, endDate, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint returns the details of a condition by its ID.
         * @summary Get condition by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConditionById(id: number, options?: any): AxiosPromise<ConditionResponse> {
            return localVarFp.getConditionById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint retrieves a paginated list of conditions, optionally filtered by a search term which is not used currently.
         * @summary Get a list of Conditions
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConditions(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageConditionResponse> {
            return localVarFp.getConditions(filter, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint returns the count of conditions that were modified between the provided start and end dates.
         * @summary Get the count of conditions modified between specified dates
         * @param {string} startDate Start date in ISO format
         * @param {string} endDate End date in ISO format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConditionsCountByDateModified(startDate: string, endDate: string, options?: any): AxiosPromise<number> {
            return localVarFp.getConditionsCountByDateModified(startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint allows updating an existing condition. The user must have \'ADMIN\' or \'USER\' role to access this endpoint.
         * @summary Update an existing condition
         * @param {number} id 
         * @param {ConditionRequest} conditionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCondition(id: number, conditionRequest: ConditionRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateCondition(id, conditionRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConditionRestControllerApi - object-oriented interface
 * @export
 * @class ConditionRestControllerApi
 * @extends {BaseAPI}
 */
export class ConditionRestControllerApi extends BaseAPI {
    /**
     * This endpoint allows deleting a condition by providing its ID. Only users with \'ADMIN\' or \'USER\' role can perform this operation.
     * @summary Delete a condition
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConditionRestControllerApi
     */
    public _delete(id: number, options?: RawAxiosRequestConfig) {
        return ConditionRestControllerApiFp(this.configuration)._delete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint allows creating a new condition. The user must have \'ADMIN\' or \'USER\' role to access this endpoint.
     * @summary Create a new condition
     * @param {ConditionRequest} conditionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConditionRestControllerApi
     */
    public createCondition(conditionRequest: ConditionRequest, options?: RawAxiosRequestConfig) {
        return ConditionRestControllerApiFp(this.configuration).createCondition(conditionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConditionRestControllerApi
     */
    public getAllConditionsByDateModified(startDate: string, endDate: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return ConditionRestControllerApiFp(this.configuration).getAllConditionsByDateModified(startDate, endDate, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint returns the details of a condition by its ID.
     * @summary Get condition by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConditionRestControllerApi
     */
    public getConditionById(id: number, options?: RawAxiosRequestConfig) {
        return ConditionRestControllerApiFp(this.configuration).getConditionById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint retrieves a paginated list of conditions, optionally filtered by a search term which is not used currently.
     * @summary Get a list of Conditions
     * @param {string} [filter] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConditionRestControllerApi
     */
    public getConditions(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return ConditionRestControllerApiFp(this.configuration).getConditions(filter, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint returns the count of conditions that were modified between the provided start and end dates.
     * @summary Get the count of conditions modified between specified dates
     * @param {string} startDate Start date in ISO format
     * @param {string} endDate End date in ISO format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConditionRestControllerApi
     */
    public getConditionsCountByDateModified(startDate: string, endDate: string, options?: RawAxiosRequestConfig) {
        return ConditionRestControllerApiFp(this.configuration).getConditionsCountByDateModified(startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint allows updating an existing condition. The user must have \'ADMIN\' or \'USER\' role to access this endpoint.
     * @summary Update an existing condition
     * @param {number} id 
     * @param {ConditionRequest} conditionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConditionRestControllerApi
     */
    public updateCondition(id: number, conditionRequest: ConditionRequest, options?: RawAxiosRequestConfig) {
        return ConditionRestControllerApiFp(this.configuration).updateCondition(id, conditionRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ConditionStatusRestControllerApi - axios parameter creator
 * @export
 */
export const ConditionStatusRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new condition status with the provided details. Requires ADMIN or USER role.
         * @summary Create Condition Status
         * @param {ConditionStatusRequest} conditionStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConditionStatus: async (conditionStatusRequest: ConditionStatusRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conditionStatusRequest' is not null or undefined
            assertParamExists('createConditionStatus', 'conditionStatusRequest', conditionStatusRequest)
            const localVarPath = `/api/conditions/statuses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conditionStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an existing condition status by its ID. Requires ADMIN or USER role.
         * @summary Delete Condition Status
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConditionStatus: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteConditionStatus', 'id', id)
            const localVarPath = `/api/conditions/statuses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve the details of a condition status based on its unique identifier.
         * @summary Get Condition Status by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConditionStatusById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getConditionStatusById', 'id', id)
            const localVarPath = `/api/conditions/statuses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint retrieves a list of condition statuses. It supports pagination and filtering by providing optional parameters.
         * @summary Get condition statuses
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConditionStatuses: async (filter?: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/conditions/statuses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing condition status with the provided details. Requires ADMIN or USER role.
         * @summary Update Condition Status
         * @param {number} id 
         * @param {ConditionStatusRequest} conditionStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConditionStatus: async (id: number, conditionStatusRequest: ConditionStatusRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateConditionStatus', 'id', id)
            // verify required parameter 'conditionStatusRequest' is not null or undefined
            assertParamExists('updateConditionStatus', 'conditionStatusRequest', conditionStatusRequest)
            const localVarPath = `/api/conditions/statuses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conditionStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConditionStatusRestControllerApi - functional programming interface
 * @export
 */
export const ConditionStatusRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConditionStatusRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new condition status with the provided details. Requires ADMIN or USER role.
         * @summary Create Condition Status
         * @param {ConditionStatusRequest} conditionStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createConditionStatus(conditionStatusRequest: ConditionStatusRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createConditionStatus(conditionStatusRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConditionStatusRestControllerApi.createConditionStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete an existing condition status by its ID. Requires ADMIN or USER role.
         * @summary Delete Condition Status
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteConditionStatus(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteConditionStatus(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConditionStatusRestControllerApi.deleteConditionStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve the details of a condition status based on its unique identifier.
         * @summary Get Condition Status by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConditionStatusById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConditionStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConditionStatusById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConditionStatusRestControllerApi.getConditionStatusById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This endpoint retrieves a list of condition statuses. It supports pagination and filtering by providing optional parameters.
         * @summary Get condition statuses
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConditionStatuses(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageConditionStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConditionStatuses(filter, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConditionStatusRestControllerApi.getConditionStatuses']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an existing condition status with the provided details. Requires ADMIN or USER role.
         * @summary Update Condition Status
         * @param {number} id 
         * @param {ConditionStatusRequest} conditionStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConditionStatus(id: number, conditionStatusRequest: ConditionStatusRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConditionStatus(id, conditionStatusRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConditionStatusRestControllerApi.updateConditionStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ConditionStatusRestControllerApi - factory interface
 * @export
 */
export const ConditionStatusRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConditionStatusRestControllerApiFp(configuration)
    return {
        /**
         * Create a new condition status with the provided details. Requires ADMIN or USER role.
         * @summary Create Condition Status
         * @param {ConditionStatusRequest} conditionStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConditionStatus(conditionStatusRequest: ConditionStatusRequest, options?: any): AxiosPromise<number> {
            return localVarFp.createConditionStatus(conditionStatusRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an existing condition status by its ID. Requires ADMIN or USER role.
         * @summary Delete Condition Status
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConditionStatus(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteConditionStatus(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve the details of a condition status based on its unique identifier.
         * @summary Get Condition Status by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConditionStatusById(id: number, options?: any): AxiosPromise<ConditionStatusResponse> {
            return localVarFp.getConditionStatusById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint retrieves a list of condition statuses. It supports pagination and filtering by providing optional parameters.
         * @summary Get condition statuses
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConditionStatuses(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageConditionStatusResponse> {
            return localVarFp.getConditionStatuses(filter, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing condition status with the provided details. Requires ADMIN or USER role.
         * @summary Update Condition Status
         * @param {number} id 
         * @param {ConditionStatusRequest} conditionStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConditionStatus(id: number, conditionStatusRequest: ConditionStatusRequest, options?: any): AxiosPromise<number> {
            return localVarFp.updateConditionStatus(id, conditionStatusRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConditionStatusRestControllerApi - object-oriented interface
 * @export
 * @class ConditionStatusRestControllerApi
 * @extends {BaseAPI}
 */
export class ConditionStatusRestControllerApi extends BaseAPI {
    /**
     * Create a new condition status with the provided details. Requires ADMIN or USER role.
     * @summary Create Condition Status
     * @param {ConditionStatusRequest} conditionStatusRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConditionStatusRestControllerApi
     */
    public createConditionStatus(conditionStatusRequest: ConditionStatusRequest, options?: RawAxiosRequestConfig) {
        return ConditionStatusRestControllerApiFp(this.configuration).createConditionStatus(conditionStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an existing condition status by its ID. Requires ADMIN or USER role.
     * @summary Delete Condition Status
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConditionStatusRestControllerApi
     */
    public deleteConditionStatus(id: number, options?: RawAxiosRequestConfig) {
        return ConditionStatusRestControllerApiFp(this.configuration).deleteConditionStatus(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve the details of a condition status based on its unique identifier.
     * @summary Get Condition Status by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConditionStatusRestControllerApi
     */
    public getConditionStatusById(id: number, options?: RawAxiosRequestConfig) {
        return ConditionStatusRestControllerApiFp(this.configuration).getConditionStatusById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint retrieves a list of condition statuses. It supports pagination and filtering by providing optional parameters.
     * @summary Get condition statuses
     * @param {string} [filter] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConditionStatusRestControllerApi
     */
    public getConditionStatuses(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return ConditionStatusRestControllerApiFp(this.configuration).getConditionStatuses(filter, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing condition status with the provided details. Requires ADMIN or USER role.
     * @summary Update Condition Status
     * @param {number} id 
     * @param {ConditionStatusRequest} conditionStatusRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConditionStatusRestControllerApi
     */
    public updateConditionStatus(id: number, conditionStatusRequest: ConditionStatusRequest, options?: RawAxiosRequestConfig) {
        return ConditionStatusRestControllerApiFp(this.configuration).updateConditionStatus(id, conditionStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DashboardRestControllerApi - axios parameter creator
 * @export
 */
export const DashboardRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Fetch data for the dashboard, including recent MNAs and notes.
         * @summary Retrieve Dashboard Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboard: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardRestControllerApi - functional programming interface
 * @export
 */
export const DashboardRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DashboardRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Fetch data for the dashboard, including recent MNAs and notes.
         * @summary Retrieve Dashboard Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDashboard(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardResp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDashboard(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DashboardRestControllerApi.getDashboard']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DashboardRestControllerApi - factory interface
 * @export
 */
export const DashboardRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DashboardRestControllerApiFp(configuration)
    return {
        /**
         * Fetch data for the dashboard, including recent MNAs and notes.
         * @summary Retrieve Dashboard Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboard(options?: any): AxiosPromise<DashboardResp> {
            return localVarFp.getDashboard(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DashboardRestControllerApi - object-oriented interface
 * @export
 * @class DashboardRestControllerApi
 * @extends {BaseAPI}
 */
export class DashboardRestControllerApi extends BaseAPI {
    /**
     * Fetch data for the dashboard, including recent MNAs and notes.
     * @summary Retrieve Dashboard Data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardRestControllerApi
     */
    public getDashboard(options?: RawAxiosRequestConfig) {
        return DashboardRestControllerApiFp(this.configuration).getDashboard(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DateEventRestControllerApi - axios parameter creator
 * @export
 */
export const DateEventRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new Date Event.
         * @summary Create Date Event
         * @param {DateEventRequest} dateEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDateEvent: async (dateEventRequest: DateEventRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dateEventRequest' is not null or undefined
            assertParamExists('createDateEvent', 'dateEventRequest', dateEventRequest)
            const localVarPath = `/api/mna/dates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dateEventRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a Date Event identified by its ID.
         * @summary Delete Date Event
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDateEvent: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDateEvent', 'id', id)
            const localVarPath = `/api/mna/dates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a Date Event by its unique identifier.
         * @summary Get Date Event by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDateEventById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDateEventById', 'id', id)
            const localVarPath = `/api/mna/dates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve date events with optional filtering(currently not utilized) and pagination.
         * @summary Get Date Events
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDates: async (filter?: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/mna/dates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a list of upcoming Date Events.
         * @summary Get Upcoming Date Events
         * @param {number} id 
         * @param {DateEventRequest} dateEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUpcomingDateEvents: async (id: number, dateEventRequest: DateEventRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUpcomingDateEvents', 'id', id)
            // verify required parameter 'dateEventRequest' is not null or undefined
            assertParamExists('getUpcomingDateEvents', 'dateEventRequest', dateEventRequest)
            const localVarPath = `/api/mna/dates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dateEventRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUpcomingDateEvents1: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/mna/dates/upcoming`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DateEventRestControllerApi - functional programming interface
 * @export
 */
export const DateEventRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DateEventRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new Date Event.
         * @summary Create Date Event
         * @param {DateEventRequest} dateEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDateEvent(dateEventRequest: DateEventRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDateEvent(dateEventRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DateEventRestControllerApi.createDateEvent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete a Date Event identified by its ID.
         * @summary Delete Date Event
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDateEvent(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDateEvent(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DateEventRestControllerApi.deleteDateEvent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve a Date Event by its unique identifier.
         * @summary Get Date Event by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDateEventById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DateEventResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDateEventById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DateEventRestControllerApi.getDateEventById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve date events with optional filtering(currently not utilized) and pagination.
         * @summary Get Date Events
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDates(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDateEventResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDates(filter, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DateEventRestControllerApi.getDates']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve a list of upcoming Date Events.
         * @summary Get Upcoming Date Events
         * @param {number} id 
         * @param {DateEventRequest} dateEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUpcomingDateEvents(id: number, dateEventRequest: DateEventRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUpcomingDateEvents(id, dateEventRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DateEventRestControllerApi.getUpcomingDateEvents']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUpcomingDateEvents1(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDateEventResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUpcomingDateEvents1(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DateEventRestControllerApi.getUpcomingDateEvents1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DateEventRestControllerApi - factory interface
 * @export
 */
export const DateEventRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DateEventRestControllerApiFp(configuration)
    return {
        /**
         * Create a new Date Event.
         * @summary Create Date Event
         * @param {DateEventRequest} dateEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDateEvent(dateEventRequest: DateEventRequest, options?: any): AxiosPromise<number> {
            return localVarFp.createDateEvent(dateEventRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a Date Event identified by its ID.
         * @summary Delete Date Event
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDateEvent(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDateEvent(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a Date Event by its unique identifier.
         * @summary Get Date Event by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDateEventById(id: number, options?: any): AxiosPromise<DateEventResponse> {
            return localVarFp.getDateEventById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve date events with optional filtering(currently not utilized) and pagination.
         * @summary Get Date Events
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDates(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageDateEventResponse> {
            return localVarFp.getDates(filter, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a list of upcoming Date Events.
         * @summary Get Upcoming Date Events
         * @param {number} id 
         * @param {DateEventRequest} dateEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUpcomingDateEvents(id: number, dateEventRequest: DateEventRequest, options?: any): AxiosPromise<void> {
            return localVarFp.getUpcomingDateEvents(id, dateEventRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUpcomingDateEvents1(options?: any): AxiosPromise<PageDateEventResponse> {
            return localVarFp.getUpcomingDateEvents1(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DateEventRestControllerApi - object-oriented interface
 * @export
 * @class DateEventRestControllerApi
 * @extends {BaseAPI}
 */
export class DateEventRestControllerApi extends BaseAPI {
    /**
     * Create a new Date Event.
     * @summary Create Date Event
     * @param {DateEventRequest} dateEventRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DateEventRestControllerApi
     */
    public createDateEvent(dateEventRequest: DateEventRequest, options?: RawAxiosRequestConfig) {
        return DateEventRestControllerApiFp(this.configuration).createDateEvent(dateEventRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a Date Event identified by its ID.
     * @summary Delete Date Event
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DateEventRestControllerApi
     */
    public deleteDateEvent(id: number, options?: RawAxiosRequestConfig) {
        return DateEventRestControllerApiFp(this.configuration).deleteDateEvent(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a Date Event by its unique identifier.
     * @summary Get Date Event by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DateEventRestControllerApi
     */
    public getDateEventById(id: number, options?: RawAxiosRequestConfig) {
        return DateEventRestControllerApiFp(this.configuration).getDateEventById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve date events with optional filtering(currently not utilized) and pagination.
     * @summary Get Date Events
     * @param {string} [filter] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DateEventRestControllerApi
     */
    public getDates(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return DateEventRestControllerApiFp(this.configuration).getDates(filter, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a list of upcoming Date Events.
     * @summary Get Upcoming Date Events
     * @param {number} id 
     * @param {DateEventRequest} dateEventRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DateEventRestControllerApi
     */
    public getUpcomingDateEvents(id: number, dateEventRequest: DateEventRequest, options?: RawAxiosRequestConfig) {
        return DateEventRestControllerApiFp(this.configuration).getUpcomingDateEvents(id, dateEventRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DateEventRestControllerApi
     */
    public getUpcomingDateEvents1(options?: RawAxiosRequestConfig) {
        return DateEventRestControllerApiFp(this.configuration).getUpcomingDateEvents1(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DomainTypeRestControllerApi - axios parameter creator
 * @export
 */
export const DomainTypeRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a paginated list of domain types. Domain types could include mnas, fundamental, links, files, etc.
         * @summary Get Domain Types
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDomainTypes: async (filter?: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/domains/types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DomainTypeRestControllerApi - functional programming interface
 * @export
 */
export const DomainTypeRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DomainTypeRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves a paginated list of domain types. Domain types could include mnas, fundamental, links, files, etc.
         * @summary Get Domain Types
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDomainTypes(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDomainTypeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDomainTypes(filter, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DomainTypeRestControllerApi.getDomainTypes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DomainTypeRestControllerApi - factory interface
 * @export
 */
export const DomainTypeRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DomainTypeRestControllerApiFp(configuration)
    return {
        /**
         * Retrieves a paginated list of domain types. Domain types could include mnas, fundamental, links, files, etc.
         * @summary Get Domain Types
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDomainTypes(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageDomainTypeResponse> {
            return localVarFp.getDomainTypes(filter, page, size, sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DomainTypeRestControllerApi - object-oriented interface
 * @export
 * @class DomainTypeRestControllerApi
 * @extends {BaseAPI}
 */
export class DomainTypeRestControllerApi extends BaseAPI {
    /**
     * Retrieves a paginated list of domain types. Domain types could include mnas, fundamental, links, files, etc.
     * @summary Get Domain Types
     * @param {string} [filter] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DomainTypeRestControllerApi
     */
    public getDomainTypes(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return DomainTypeRestControllerApiFp(this.configuration).getDomainTypes(filter, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ErrorLogRestControllerApi - axios parameter creator
 * @export
 */
export const ErrorLogRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete an error log by its ID. Requires ADMIN or user privileges.
         * @summary Delete Error Log by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteErrorLogById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteErrorLogById', 'id', id)
            const localVarPath = `/api/error-logs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve an error log by its ID. Requires ADMIN privileges.
         * @summary Get Error Log by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getErrorLogById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getErrorLogById', 'id', id)
            const localVarPath = `/api/error-logs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a list of error logs. Requires ADMIN privileges.
         * @summary Get Error Logs
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getErrorLogs: async (pageable: Pageable, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getErrorLogs', 'pageable', pageable)
            const localVarPath = `/api/error-logs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ErrorLogRestControllerApi - functional programming interface
 * @export
 */
export const ErrorLogRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ErrorLogRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete an error log by its ID. Requires ADMIN or user privileges.
         * @summary Delete Error Log by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteErrorLogById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteErrorLogById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ErrorLogRestControllerApi.deleteErrorLogById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve an error log by its ID. Requires ADMIN privileges.
         * @summary Get Error Log by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getErrorLogById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ErrorLogResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getErrorLogById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ErrorLogRestControllerApi.getErrorLogById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve a list of error logs. Requires ADMIN privileges.
         * @summary Get Error Logs
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getErrorLogs(pageable: Pageable, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimplePageErrorLogResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getErrorLogs(pageable, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ErrorLogRestControllerApi.getErrorLogs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ErrorLogRestControllerApi - factory interface
 * @export
 */
export const ErrorLogRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ErrorLogRestControllerApiFp(configuration)
    return {
        /**
         * Delete an error log by its ID. Requires ADMIN or user privileges.
         * @summary Delete Error Log by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteErrorLogById(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteErrorLogById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve an error log by its ID. Requires ADMIN privileges.
         * @summary Get Error Log by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getErrorLogById(id: number, options?: any): AxiosPromise<ErrorLogResponse> {
            return localVarFp.getErrorLogById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a list of error logs. Requires ADMIN privileges.
         * @summary Get Error Logs
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getErrorLogs(pageable: Pageable, options?: any): AxiosPromise<SimplePageErrorLogResponse> {
            return localVarFp.getErrorLogs(pageable, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ErrorLogRestControllerApi - object-oriented interface
 * @export
 * @class ErrorLogRestControllerApi
 * @extends {BaseAPI}
 */
export class ErrorLogRestControllerApi extends BaseAPI {
    /**
     * Delete an error log by its ID. Requires ADMIN or user privileges.
     * @summary Delete Error Log by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ErrorLogRestControllerApi
     */
    public deleteErrorLogById(id: number, options?: RawAxiosRequestConfig) {
        return ErrorLogRestControllerApiFp(this.configuration).deleteErrorLogById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve an error log by its ID. Requires ADMIN privileges.
     * @summary Get Error Log by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ErrorLogRestControllerApi
     */
    public getErrorLogById(id: number, options?: RawAxiosRequestConfig) {
        return ErrorLogRestControllerApiFp(this.configuration).getErrorLogById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a list of error logs. Requires ADMIN privileges.
     * @summary Get Error Logs
     * @param {Pageable} pageable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ErrorLogRestControllerApi
     */
    public getErrorLogs(pageable: Pageable, options?: RawAxiosRequestConfig) {
        return ErrorLogRestControllerApiFp(this.configuration).getErrorLogs(pageable, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ExchangeRestControllerApi - axios parameter creator
 * @export
 */
export const ExchangeRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new exchange. Requires ADMIN or USER role.
         * @summary Create Exchange
         * @param {ExchangeRequest} exchangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExchange: async (exchangeRequest: ExchangeRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exchangeRequest' is not null or undefined
            assertParamExists('createExchange', 'exchangeRequest', exchangeRequest)
            const localVarPath = `/api/exchanges`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exchangeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an exchange by its ID. Requires ADMIN or USER role.
         * @summary Delete Exchange
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExchange: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteExchange', 'id', id)
            const localVarPath = `/api/exchanges/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve an exchange by its unique identifier.
         * @summary Get exchange by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExchangeById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getExchangeById', 'id', id)
            const localVarPath = `/api/exchanges/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a paginated list of all exchanges.
         * @summary Get all exchanges
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExchanges: async (page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/exchanges`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing exchange. Requires ADMIN or USER role.
         * @summary Update Exchange
         * @param {number} id 
         * @param {ExchangeRequest} exchangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExchange: async (id: number, exchangeRequest: ExchangeRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateExchange', 'id', id)
            // verify required parameter 'exchangeRequest' is not null or undefined
            assertParamExists('updateExchange', 'exchangeRequest', exchangeRequest)
            const localVarPath = `/api/exchanges/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exchangeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExchangeRestControllerApi - functional programming interface
 * @export
 */
export const ExchangeRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExchangeRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new exchange. Requires ADMIN or USER role.
         * @summary Create Exchange
         * @param {ExchangeRequest} exchangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createExchange(exchangeRequest: ExchangeRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createExchange(exchangeRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExchangeRestControllerApi.createExchange']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete an exchange by its ID. Requires ADMIN or USER role.
         * @summary Delete Exchange
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteExchange(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteExchange(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExchangeRestControllerApi.deleteExchange']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve an exchange by its unique identifier.
         * @summary Get exchange by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExchangeById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExchangeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExchangeById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExchangeRestControllerApi.getExchangeById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve a paginated list of all exchanges.
         * @summary Get all exchanges
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExchanges(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageExchangeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExchanges(page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExchangeRestControllerApi.getExchanges']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an existing exchange. Requires ADMIN or USER role.
         * @summary Update Exchange
         * @param {number} id 
         * @param {ExchangeRequest} exchangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateExchange(id: number, exchangeRequest: ExchangeRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateExchange(id, exchangeRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExchangeRestControllerApi.updateExchange']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ExchangeRestControllerApi - factory interface
 * @export
 */
export const ExchangeRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExchangeRestControllerApiFp(configuration)
    return {
        /**
         * Create a new exchange. Requires ADMIN or USER role.
         * @summary Create Exchange
         * @param {ExchangeRequest} exchangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExchange(exchangeRequest: ExchangeRequest, options?: any): AxiosPromise<number> {
            return localVarFp.createExchange(exchangeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an exchange by its ID. Requires ADMIN or USER role.
         * @summary Delete Exchange
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExchange(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteExchange(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve an exchange by its unique identifier.
         * @summary Get exchange by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExchangeById(id: number, options?: any): AxiosPromise<ExchangeResponse> {
            return localVarFp.getExchangeById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a paginated list of all exchanges.
         * @summary Get all exchanges
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExchanges(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageExchangeResponse> {
            return localVarFp.getExchanges(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing exchange. Requires ADMIN or USER role.
         * @summary Update Exchange
         * @param {number} id 
         * @param {ExchangeRequest} exchangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExchange(id: number, exchangeRequest: ExchangeRequest, options?: any): AxiosPromise<number> {
            return localVarFp.updateExchange(id, exchangeRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExchangeRestControllerApi - object-oriented interface
 * @export
 * @class ExchangeRestControllerApi
 * @extends {BaseAPI}
 */
export class ExchangeRestControllerApi extends BaseAPI {
    /**
     * Create a new exchange. Requires ADMIN or USER role.
     * @summary Create Exchange
     * @param {ExchangeRequest} exchangeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeRestControllerApi
     */
    public createExchange(exchangeRequest: ExchangeRequest, options?: RawAxiosRequestConfig) {
        return ExchangeRestControllerApiFp(this.configuration).createExchange(exchangeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an exchange by its ID. Requires ADMIN or USER role.
     * @summary Delete Exchange
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeRestControllerApi
     */
    public deleteExchange(id: number, options?: RawAxiosRequestConfig) {
        return ExchangeRestControllerApiFp(this.configuration).deleteExchange(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve an exchange by its unique identifier.
     * @summary Get exchange by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeRestControllerApi
     */
    public getExchangeById(id: number, options?: RawAxiosRequestConfig) {
        return ExchangeRestControllerApiFp(this.configuration).getExchangeById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a paginated list of all exchanges.
     * @summary Get all exchanges
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeRestControllerApi
     */
    public getExchanges(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return ExchangeRestControllerApiFp(this.configuration).getExchanges(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing exchange. Requires ADMIN or USER role.
     * @summary Update Exchange
     * @param {number} id 
     * @param {ExchangeRequest} exchangeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangeRestControllerApi
     */
    public updateExchange(id: number, exchangeRequest: ExchangeRequest, options?: RawAxiosRequestConfig) {
        return ExchangeRestControllerApiFp(this.configuration).updateExchange(id, exchangeRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FeatureAssociationControllerApi - axios parameter creator
 * @export
 */
export const FeatureAssociationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {FeatureAssociationRequest} featureAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFeatureAssociation: async (featureAssociationRequest: FeatureAssociationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'featureAssociationRequest' is not null or undefined
            assertParamExists('createFeatureAssociation', 'featureAssociationRequest', featureAssociationRequest)
            const localVarPath = `/api/features/associations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(featureAssociationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeatureAssociation: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteFeatureAssociation', 'id', id)
            const localVarPath = `/api/features/associations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureAssociationById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFeatureAssociationById', 'id', id)
            const localVarPath = `/api/features/associations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureAssociations: async (page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/features/associations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureAssociationsByMnaId: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFeatureAssociationsByMnaId', 'id', id)
            const localVarPath = `/api/features/associations/mnas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {FeatureAssociationRequest} featureAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFeatureAssociation: async (id: number, featureAssociationRequest: FeatureAssociationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateFeatureAssociation', 'id', id)
            // verify required parameter 'featureAssociationRequest' is not null or undefined
            assertParamExists('updateFeatureAssociation', 'featureAssociationRequest', featureAssociationRequest)
            const localVarPath = `/api/features/associations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(featureAssociationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeatureAssociationControllerApi - functional programming interface
 * @export
 */
export const FeatureAssociationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeatureAssociationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {FeatureAssociationRequest} featureAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFeatureAssociation(featureAssociationRequest: FeatureAssociationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFeatureAssociation(featureAssociationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureAssociationControllerApi.createFeatureAssociation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFeatureAssociation(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFeatureAssociation(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureAssociationControllerApi.deleteFeatureAssociation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeatureAssociationById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeatureAssociationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeatureAssociationById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureAssociationControllerApi.getFeatureAssociationById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeatureAssociations(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageFeatureAssociationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeatureAssociations(page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureAssociationControllerApi.getFeatureAssociations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeatureAssociationsByMnaId(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FeatureAssociationResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeatureAssociationsByMnaId(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureAssociationControllerApi.getFeatureAssociationsByMnaId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {FeatureAssociationRequest} featureAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFeatureAssociation(id: number, featureAssociationRequest: FeatureAssociationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFeatureAssociation(id, featureAssociationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureAssociationControllerApi.updateFeatureAssociation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FeatureAssociationControllerApi - factory interface
 * @export
 */
export const FeatureAssociationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeatureAssociationControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {FeatureAssociationRequest} featureAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFeatureAssociation(featureAssociationRequest: FeatureAssociationRequest, options?: any): AxiosPromise<number> {
            return localVarFp.createFeatureAssociation(featureAssociationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeatureAssociation(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteFeatureAssociation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureAssociationById(id: number, options?: any): AxiosPromise<FeatureAssociationResponse> {
            return localVarFp.getFeatureAssociationById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureAssociations(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageFeatureAssociationResponse> {
            return localVarFp.getFeatureAssociations(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureAssociationsByMnaId(id: number, options?: any): AxiosPromise<Array<FeatureAssociationResponse>> {
            return localVarFp.getFeatureAssociationsByMnaId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {FeatureAssociationRequest} featureAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFeatureAssociation(id: number, featureAssociationRequest: FeatureAssociationRequest, options?: any): AxiosPromise<number> {
            return localVarFp.updateFeatureAssociation(id, featureAssociationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeatureAssociationControllerApi - object-oriented interface
 * @export
 * @class FeatureAssociationControllerApi
 * @extends {BaseAPI}
 */
export class FeatureAssociationControllerApi extends BaseAPI {
    /**
     * 
     * @param {FeatureAssociationRequest} featureAssociationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureAssociationControllerApi
     */
    public createFeatureAssociation(featureAssociationRequest: FeatureAssociationRequest, options?: RawAxiosRequestConfig) {
        return FeatureAssociationControllerApiFp(this.configuration).createFeatureAssociation(featureAssociationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureAssociationControllerApi
     */
    public deleteFeatureAssociation(id: number, options?: RawAxiosRequestConfig) {
        return FeatureAssociationControllerApiFp(this.configuration).deleteFeatureAssociation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureAssociationControllerApi
     */
    public getFeatureAssociationById(id: number, options?: RawAxiosRequestConfig) {
        return FeatureAssociationControllerApiFp(this.configuration).getFeatureAssociationById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureAssociationControllerApi
     */
    public getFeatureAssociations(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return FeatureAssociationControllerApiFp(this.configuration).getFeatureAssociations(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureAssociationControllerApi
     */
    public getFeatureAssociationsByMnaId(id: number, options?: RawAxiosRequestConfig) {
        return FeatureAssociationControllerApiFp(this.configuration).getFeatureAssociationsByMnaId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {FeatureAssociationRequest} featureAssociationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureAssociationControllerApi
     */
    public updateFeatureAssociation(id: number, featureAssociationRequest: FeatureAssociationRequest, options?: RawAxiosRequestConfig) {
        return FeatureAssociationControllerApiFp(this.configuration).updateFeatureAssociation(id, featureAssociationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FeatureControllerApi - axios parameter creator
 * @export
 */
export const FeatureControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {FeatureRequest} featureRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFeature: async (featureRequest: FeatureRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'featureRequest' is not null or undefined
            assertParamExists('createFeature', 'featureRequest', featureRequest)
            const localVarPath = `/api/features`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(featureRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeature: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteFeature', 'id', id)
            const localVarPath = `/api/features/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFeatureById', 'id', id)
            const localVarPath = `/api/features/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatures: async (page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/features`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {FeatureRequest} featureRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFeature: async (id: number, featureRequest: FeatureRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateFeature', 'id', id)
            // verify required parameter 'featureRequest' is not null or undefined
            assertParamExists('updateFeature', 'featureRequest', featureRequest)
            const localVarPath = `/api/features/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(featureRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeatureControllerApi - functional programming interface
 * @export
 */
export const FeatureControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeatureControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {FeatureRequest} featureRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFeature(featureRequest: FeatureRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFeature(featureRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureControllerApi.createFeature']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFeature(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFeature(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureControllerApi.deleteFeature']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeatureById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeatureResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeatureById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureControllerApi.getFeatureById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeatures(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageFeatureResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeatures(page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureControllerApi.getFeatures']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {FeatureRequest} featureRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFeature(id: number, featureRequest: FeatureRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeatureResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFeature(id, featureRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureControllerApi.updateFeature']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FeatureControllerApi - factory interface
 * @export
 */
export const FeatureControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeatureControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {FeatureRequest} featureRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFeature(featureRequest: FeatureRequest, options?: any): AxiosPromise<number> {
            return localVarFp.createFeature(featureRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeature(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteFeature(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureById(id: number, options?: any): AxiosPromise<FeatureResponse> {
            return localVarFp.getFeatureById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatures(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageFeatureResponse> {
            return localVarFp.getFeatures(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {FeatureRequest} featureRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFeature(id: number, featureRequest: FeatureRequest, options?: any): AxiosPromise<FeatureResponse> {
            return localVarFp.updateFeature(id, featureRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeatureControllerApi - object-oriented interface
 * @export
 * @class FeatureControllerApi
 * @extends {BaseAPI}
 */
export class FeatureControllerApi extends BaseAPI {
    /**
     * 
     * @param {FeatureRequest} featureRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureControllerApi
     */
    public createFeature(featureRequest: FeatureRequest, options?: RawAxiosRequestConfig) {
        return FeatureControllerApiFp(this.configuration).createFeature(featureRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureControllerApi
     */
    public deleteFeature(id: number, options?: RawAxiosRequestConfig) {
        return FeatureControllerApiFp(this.configuration).deleteFeature(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureControllerApi
     */
    public getFeatureById(id: number, options?: RawAxiosRequestConfig) {
        return FeatureControllerApiFp(this.configuration).getFeatureById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureControllerApi
     */
    public getFeatures(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return FeatureControllerApiFp(this.configuration).getFeatures(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {FeatureRequest} featureRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureControllerApi
     */
    public updateFeature(id: number, featureRequest: FeatureRequest, options?: RawAxiosRequestConfig) {
        return FeatureControllerApiFp(this.configuration).updateFeature(id, featureRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FileAssociationRestControllerApi - axios parameter creator
 * @export
 */
export const FileAssociationRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a single file association. Only users with ADMIN or USER roles are authorized to perform this operation.
         * @summary Create file association
         * @param {FileAssociationRequest} fileAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFileAssociation: async (fileAssociationRequest: FileAssociationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileAssociationRequest' is not null or undefined
            assertParamExists('createFileAssociation', 'fileAssociationRequest', fileAssociationRequest)
            const localVarPath = `/api/files/assoc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fileAssociationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates multiple file associations. Only users with ADMIN or USER roles are authorized to perform this operation.
         * @summary Create multiple file associations
         * @param {Array<FileAssociationRequest>} fileAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMultiFileAssociation: async (fileAssociationRequest: Array<FileAssociationRequest>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileAssociationRequest' is not null or undefined
            assertParamExists('createMultiFileAssociation', 'fileAssociationRequest', fileAssociationRequest)
            const localVarPath = `/api/files/assoc/multi`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fileAssociationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes multiple file associations by their IDs. Only users with ADMIN or USER roles are authorized to perform this operation.
         * @summary Delete all file associations
         * @param {Array<number>} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllFileAssociation: async (id: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAllFileAssociation', 'id', id)
            const localVarPath = `/api/files/assoc/multi`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes a single file association by its ID. Only users with ADMIN or USER roles are authorized to perform this operation.
         * @summary Delete file association
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFileAssociation: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteFileAssociation', 'id', id)
            const localVarPath = `/api/files/assoc/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a file association by its ID.
         * @summary Retrieve file association by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileAssociationById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFileAssociationById', 'id', id)
            const localVarPath = `/api/files/assoc/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves file associations with other domains in the system.
         * @summary Retrieve file associations
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileAssociations: async (filter?: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/files/assoc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates a file association. Only users with ADMIN or USER roles are authorized to perform this operation.
         * @summary Update file association
         * @param {number} id 
         * @param {FileAssociationRequest} fileAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFileAssociation: async (id: number, fileAssociationRequest: FileAssociationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateFileAssociation', 'id', id)
            // verify required parameter 'fileAssociationRequest' is not null or undefined
            assertParamExists('updateFileAssociation', 'fileAssociationRequest', fileAssociationRequest)
            const localVarPath = `/api/files/assoc/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fileAssociationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileAssociationRestControllerApi - functional programming interface
 * @export
 */
export const FileAssociationRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileAssociationRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a single file association. Only users with ADMIN or USER roles are authorized to perform this operation.
         * @summary Create file association
         * @param {FileAssociationRequest} fileAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFileAssociation(fileAssociationRequest: FileAssociationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFileAssociation(fileAssociationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FileAssociationRestControllerApi.createFileAssociation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates multiple file associations. Only users with ADMIN or USER roles are authorized to perform this operation.
         * @summary Create multiple file associations
         * @param {Array<FileAssociationRequest>} fileAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMultiFileAssociation(fileAssociationRequest: Array<FileAssociationRequest>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMultiFileAssociation(fileAssociationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FileAssociationRestControllerApi.createMultiFileAssociation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Deletes multiple file associations by their IDs. Only users with ADMIN or USER roles are authorized to perform this operation.
         * @summary Delete all file associations
         * @param {Array<number>} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAllFileAssociation(id: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAllFileAssociation(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FileAssociationRestControllerApi.deleteAllFileAssociation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Deletes a single file association by its ID. Only users with ADMIN or USER roles are authorized to perform this operation.
         * @summary Delete file association
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFileAssociation(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFileAssociation(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FileAssociationRestControllerApi.deleteFileAssociation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a file association by its ID.
         * @summary Retrieve file association by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFileAssociationById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileAssociationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFileAssociationById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FileAssociationRestControllerApi.getFileAssociationById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves file associations with other domains in the system.
         * @summary Retrieve file associations
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFileAssociations(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageFileAssociationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFileAssociations(filter, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FileAssociationRestControllerApi.getFileAssociations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates a file association. Only users with ADMIN or USER roles are authorized to perform this operation.
         * @summary Update file association
         * @param {number} id 
         * @param {FileAssociationRequest} fileAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFileAssociation(id: number, fileAssociationRequest: FileAssociationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFileAssociation(id, fileAssociationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FileAssociationRestControllerApi.updateFileAssociation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FileAssociationRestControllerApi - factory interface
 * @export
 */
export const FileAssociationRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileAssociationRestControllerApiFp(configuration)
    return {
        /**
         * Creates a single file association. Only users with ADMIN or USER roles are authorized to perform this operation.
         * @summary Create file association
         * @param {FileAssociationRequest} fileAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFileAssociation(fileAssociationRequest: FileAssociationRequest, options?: any): AxiosPromise<number> {
            return localVarFp.createFileAssociation(fileAssociationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates multiple file associations. Only users with ADMIN or USER roles are authorized to perform this operation.
         * @summary Create multiple file associations
         * @param {Array<FileAssociationRequest>} fileAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMultiFileAssociation(fileAssociationRequest: Array<FileAssociationRequest>, options?: any): AxiosPromise<Array<number>> {
            return localVarFp.createMultiFileAssociation(fileAssociationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes multiple file associations by their IDs. Only users with ADMIN or USER roles are authorized to perform this operation.
         * @summary Delete all file associations
         * @param {Array<number>} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllFileAssociation(id: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAllFileAssociation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes a single file association by its ID. Only users with ADMIN or USER roles are authorized to perform this operation.
         * @summary Delete file association
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFileAssociation(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteFileAssociation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a file association by its ID.
         * @summary Retrieve file association by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileAssociationById(id: number, options?: any): AxiosPromise<FileAssociationResponse> {
            return localVarFp.getFileAssociationById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves file associations with other domains in the system.
         * @summary Retrieve file associations
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileAssociations(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageFileAssociationResponse> {
            return localVarFp.getFileAssociations(filter, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates a file association. Only users with ADMIN or USER roles are authorized to perform this operation.
         * @summary Update file association
         * @param {number} id 
         * @param {FileAssociationRequest} fileAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFileAssociation(id: number, fileAssociationRequest: FileAssociationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateFileAssociation(id, fileAssociationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileAssociationRestControllerApi - object-oriented interface
 * @export
 * @class FileAssociationRestControllerApi
 * @extends {BaseAPI}
 */
export class FileAssociationRestControllerApi extends BaseAPI {
    /**
     * Creates a single file association. Only users with ADMIN or USER roles are authorized to perform this operation.
     * @summary Create file association
     * @param {FileAssociationRequest} fileAssociationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileAssociationRestControllerApi
     */
    public createFileAssociation(fileAssociationRequest: FileAssociationRequest, options?: RawAxiosRequestConfig) {
        return FileAssociationRestControllerApiFp(this.configuration).createFileAssociation(fileAssociationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates multiple file associations. Only users with ADMIN or USER roles are authorized to perform this operation.
     * @summary Create multiple file associations
     * @param {Array<FileAssociationRequest>} fileAssociationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileAssociationRestControllerApi
     */
    public createMultiFileAssociation(fileAssociationRequest: Array<FileAssociationRequest>, options?: RawAxiosRequestConfig) {
        return FileAssociationRestControllerApiFp(this.configuration).createMultiFileAssociation(fileAssociationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes multiple file associations by their IDs. Only users with ADMIN or USER roles are authorized to perform this operation.
     * @summary Delete all file associations
     * @param {Array<number>} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileAssociationRestControllerApi
     */
    public deleteAllFileAssociation(id: Array<number>, options?: RawAxiosRequestConfig) {
        return FileAssociationRestControllerApiFp(this.configuration).deleteAllFileAssociation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes a single file association by its ID. Only users with ADMIN or USER roles are authorized to perform this operation.
     * @summary Delete file association
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileAssociationRestControllerApi
     */
    public deleteFileAssociation(id: number, options?: RawAxiosRequestConfig) {
        return FileAssociationRestControllerApiFp(this.configuration).deleteFileAssociation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a file association by its ID.
     * @summary Retrieve file association by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileAssociationRestControllerApi
     */
    public getFileAssociationById(id: number, options?: RawAxiosRequestConfig) {
        return FileAssociationRestControllerApiFp(this.configuration).getFileAssociationById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves file associations with other domains in the system.
     * @summary Retrieve file associations
     * @param {string} [filter] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileAssociationRestControllerApi
     */
    public getFileAssociations(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return FileAssociationRestControllerApiFp(this.configuration).getFileAssociations(filter, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates a file association. Only users with ADMIN or USER roles are authorized to perform this operation.
     * @summary Update file association
     * @param {number} id 
     * @param {FileAssociationRequest} fileAssociationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileAssociationRestControllerApi
     */
    public updateFileAssociation(id: number, fileAssociationRequest: FileAssociationRequest, options?: RawAxiosRequestConfig) {
        return FileAssociationRestControllerApiFp(this.configuration).updateFileAssociation(id, fileAssociationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FileRestControllerApi - axios parameter creator
 * @export
 */
export const FileRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Deletes a file by its ID.
         * @summary Delete File
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFile: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteFile', 'id', id)
            const localVarPath = `/api/files/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Downloads a file by its ID.
         * @summary Download File
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFile: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadFile', 'id', id)
            const localVarPath = `/api/files/{id}/download`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] Optional search parameter. If \&#39;filename\&#39; is specified as the key, it searches by file name. If the key is not \&#39;name\&#39;, the value will be neglected, and the endpoint will fall back to the default behavior of retrieving all mnas. 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findFiles: async (search?: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Uploads a file to the system.
         * @summary Upload File
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile: async (file: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadFile', 'file', file)
            const localVarPath = `/api/files/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileRestControllerApi - functional programming interface
 * @export
 */
export const FileRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Deletes a file by its ID.
         * @summary Delete File
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFile(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFile(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FileRestControllerApi.deleteFile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Downloads a file by its ID.
         * @summary Download File
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadFile(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadFile(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FileRestControllerApi.downloadFile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] Optional search parameter. If \&#39;filename\&#39; is specified as the key, it searches by file name. If the key is not \&#39;name\&#39;, the value will be neglected, and the endpoint will fall back to the default behavior of retrieving all mnas. 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findFiles(search?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageFileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findFiles(search, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FileRestControllerApi.findFiles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Uploads a file to the system.
         * @summary Upload File
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFile(file: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFile(file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FileRestControllerApi.uploadFile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FileRestControllerApi - factory interface
 * @export
 */
export const FileRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileRestControllerApiFp(configuration)
    return {
        /**
         * Deletes a file by its ID.
         * @summary Delete File
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFile(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteFile(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Downloads a file by its ID.
         * @summary Download File
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFile(id: number, options?: any): AxiosPromise<object> {
            return localVarFp.downloadFile(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] Optional search parameter. If \&#39;filename\&#39; is specified as the key, it searches by file name. If the key is not \&#39;name\&#39;, the value will be neglected, and the endpoint will fall back to the default behavior of retrieving all mnas. 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findFiles(search?: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageFileResponse> {
            return localVarFp.findFiles(search, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Uploads a file to the system.
         * @summary Upload File
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile(file: File, options?: any): AxiosPromise<void> {
            return localVarFp.uploadFile(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileRestControllerApi - object-oriented interface
 * @export
 * @class FileRestControllerApi
 * @extends {BaseAPI}
 */
export class FileRestControllerApi extends BaseAPI {
    /**
     * Deletes a file by its ID.
     * @summary Delete File
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileRestControllerApi
     */
    public deleteFile(id: number, options?: RawAxiosRequestConfig) {
        return FileRestControllerApiFp(this.configuration).deleteFile(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Downloads a file by its ID.
     * @summary Download File
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileRestControllerApi
     */
    public downloadFile(id: number, options?: RawAxiosRequestConfig) {
        return FileRestControllerApiFp(this.configuration).downloadFile(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] Optional search parameter. If \&#39;filename\&#39; is specified as the key, it searches by file name. If the key is not \&#39;name\&#39;, the value will be neglected, and the endpoint will fall back to the default behavior of retrieving all mnas. 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileRestControllerApi
     */
    public findFiles(search?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return FileRestControllerApiFp(this.configuration).findFiles(search, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Uploads a file to the system.
     * @summary Upload File
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileRestControllerApi
     */
    public uploadFile(file: File, options?: RawAxiosRequestConfig) {
        return FileRestControllerApiFp(this.configuration).uploadFile(file, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FundamentalRestControllerApi - axios parameter creator
 * @export
 */
export const FundamentalRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new fundamental data. This operation is accessible to both ADMIN and USER roles.
         * @summary Create Fundamental
         * @param {FundamentalRequest} fundamentalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFundamental: async (fundamentalRequest: FundamentalRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fundamentalRequest' is not null or undefined
            assertParamExists('createFundamental', 'fundamentalRequest', fundamentalRequest)
            const localVarPath = `/api/fundamentals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fundamentalRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a fundamental data. This operation is accessible to both ADMIN and USER roles.
         * @summary Delete Fundamental
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFundamental: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteFundamental', 'id', id)
            const localVarPath = `/api/fundamentals/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the fundamental data for a company by its unique identifier.
         * @summary Get fundamental by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFundamentalById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFundamentalById', 'id', id)
            const localVarPath = `/api/fundamentals/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get notes associated with a fundamental data.
         * @summary Get Fundamental Notes
         * @param {number} id 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFundamentalNotes: async (id: number, pageable: Pageable, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFundamentalNotes', 'id', id)
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getFundamentalNotes', 'pageable', pageable)
            const localVarPath = `/api/fundamentals/{id}/notes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a paginated list of all fundamental data for companies.
         * @summary Get all fundamentals
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFundamentals: async (page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/fundamentals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Searches for companies based on their name or ticker symbol.
         * @summary Search fundamentals by name or ticker
         * @param {string} query 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchFundamentalByNameOrTicker: async (query: string, pageable: Pageable, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'query' is not null or undefined
            assertParamExists('searchFundamentalByNameOrTicker', 'query', query)
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('searchFundamentalByNameOrTicker', 'pageable', pageable)
            const localVarPath = `/api/fundamentals/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing fundamental data. This operation is accessible to both ADMIN and USER roles.
         * @summary Update Fundamental
         * @param {number} id 
         * @param {FundamentalRequest} fundamentalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFundamental: async (id: number, fundamentalRequest: FundamentalRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateFundamental', 'id', id)
            // verify required parameter 'fundamentalRequest' is not null or undefined
            assertParamExists('updateFundamental', 'fundamentalRequest', fundamentalRequest)
            const localVarPath = `/api/fundamentals/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fundamentalRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FundamentalRestControllerApi - functional programming interface
 * @export
 */
export const FundamentalRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FundamentalRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new fundamental data. This operation is accessible to both ADMIN and USER roles.
         * @summary Create Fundamental
         * @param {FundamentalRequest} fundamentalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFundamental(fundamentalRequest: FundamentalRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFundamental(fundamentalRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FundamentalRestControllerApi.createFundamental']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete a fundamental data. This operation is accessible to both ADMIN and USER roles.
         * @summary Delete Fundamental
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFundamental(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFundamental(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FundamentalRestControllerApi.deleteFundamental']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves the fundamental data for a company by its unique identifier.
         * @summary Get fundamental by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFundamentalById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FundamentalResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFundamentalById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FundamentalRestControllerApi.getFundamentalById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get notes associated with a fundamental data.
         * @summary Get Fundamental Notes
         * @param {number} id 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFundamentalNotes(id: number, pageable: Pageable, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageNoteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFundamentalNotes(id, pageable, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FundamentalRestControllerApi.getFundamentalNotes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a paginated list of all fundamental data for companies.
         * @summary Get all fundamentals
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFundamentals(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageFundamentalResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFundamentals(page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FundamentalRestControllerApi.getFundamentals']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Searches for companies based on their name or ticker symbol.
         * @summary Search fundamentals by name or ticker
         * @param {string} query 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchFundamentalByNameOrTicker(query: string, pageable: Pageable, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageCompanyData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchFundamentalByNameOrTicker(query, pageable, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FundamentalRestControllerApi.searchFundamentalByNameOrTicker']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an existing fundamental data. This operation is accessible to both ADMIN and USER roles.
         * @summary Update Fundamental
         * @param {number} id 
         * @param {FundamentalRequest} fundamentalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFundamental(id: number, fundamentalRequest: FundamentalRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFundamental(id, fundamentalRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FundamentalRestControllerApi.updateFundamental']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FundamentalRestControllerApi - factory interface
 * @export
 */
export const FundamentalRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FundamentalRestControllerApiFp(configuration)
    return {
        /**
         * Create a new fundamental data. This operation is accessible to both ADMIN and USER roles.
         * @summary Create Fundamental
         * @param {FundamentalRequest} fundamentalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFundamental(fundamentalRequest: FundamentalRequest, options?: any): AxiosPromise<number> {
            return localVarFp.createFundamental(fundamentalRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a fundamental data. This operation is accessible to both ADMIN and USER roles.
         * @summary Delete Fundamental
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFundamental(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteFundamental(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the fundamental data for a company by its unique identifier.
         * @summary Get fundamental by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFundamentalById(id: number, options?: any): AxiosPromise<FundamentalResponse> {
            return localVarFp.getFundamentalById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get notes associated with a fundamental data.
         * @summary Get Fundamental Notes
         * @param {number} id 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFundamentalNotes(id: number, pageable: Pageable, options?: any): AxiosPromise<PageNoteResponse> {
            return localVarFp.getFundamentalNotes(id, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a paginated list of all fundamental data for companies.
         * @summary Get all fundamentals
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFundamentals(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageFundamentalResponse> {
            return localVarFp.getFundamentals(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Searches for companies based on their name or ticker symbol.
         * @summary Search fundamentals by name or ticker
         * @param {string} query 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchFundamentalByNameOrTicker(query: string, pageable: Pageable, options?: any): AxiosPromise<PageCompanyData> {
            return localVarFp.searchFundamentalByNameOrTicker(query, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing fundamental data. This operation is accessible to both ADMIN and USER roles.
         * @summary Update Fundamental
         * @param {number} id 
         * @param {FundamentalRequest} fundamentalRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFundamental(id: number, fundamentalRequest: FundamentalRequest, options?: any): AxiosPromise<number> {
            return localVarFp.updateFundamental(id, fundamentalRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FundamentalRestControllerApi - object-oriented interface
 * @export
 * @class FundamentalRestControllerApi
 * @extends {BaseAPI}
 */
export class FundamentalRestControllerApi extends BaseAPI {
    /**
     * Create a new fundamental data. This operation is accessible to both ADMIN and USER roles.
     * @summary Create Fundamental
     * @param {FundamentalRequest} fundamentalRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundamentalRestControllerApi
     */
    public createFundamental(fundamentalRequest: FundamentalRequest, options?: RawAxiosRequestConfig) {
        return FundamentalRestControllerApiFp(this.configuration).createFundamental(fundamentalRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a fundamental data. This operation is accessible to both ADMIN and USER roles.
     * @summary Delete Fundamental
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundamentalRestControllerApi
     */
    public deleteFundamental(id: number, options?: RawAxiosRequestConfig) {
        return FundamentalRestControllerApiFp(this.configuration).deleteFundamental(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the fundamental data for a company by its unique identifier.
     * @summary Get fundamental by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundamentalRestControllerApi
     */
    public getFundamentalById(id: number, options?: RawAxiosRequestConfig) {
        return FundamentalRestControllerApiFp(this.configuration).getFundamentalById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get notes associated with a fundamental data.
     * @summary Get Fundamental Notes
     * @param {number} id 
     * @param {Pageable} pageable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundamentalRestControllerApi
     */
    public getFundamentalNotes(id: number, pageable: Pageable, options?: RawAxiosRequestConfig) {
        return FundamentalRestControllerApiFp(this.configuration).getFundamentalNotes(id, pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a paginated list of all fundamental data for companies.
     * @summary Get all fundamentals
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundamentalRestControllerApi
     */
    public getFundamentals(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return FundamentalRestControllerApiFp(this.configuration).getFundamentals(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Searches for companies based on their name or ticker symbol.
     * @summary Search fundamentals by name or ticker
     * @param {string} query 
     * @param {Pageable} pageable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundamentalRestControllerApi
     */
    public searchFundamentalByNameOrTicker(query: string, pageable: Pageable, options?: RawAxiosRequestConfig) {
        return FundamentalRestControllerApiFp(this.configuration).searchFundamentalByNameOrTicker(query, pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing fundamental data. This operation is accessible to both ADMIN and USER roles.
     * @summary Update Fundamental
     * @param {number} id 
     * @param {FundamentalRequest} fundamentalRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundamentalRestControllerApi
     */
    public updateFundamental(id: number, fundamentalRequest: FundamentalRequest, options?: RawAxiosRequestConfig) {
        return FundamentalRestControllerApiFp(this.configuration).updateFundamental(id, fundamentalRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ImpliedProbabilityRestControllerApi - axios parameter creator
 * @export
 */
export const ImpliedProbabilityRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ImpliedProbabilityRequest} impliedProbabilityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createImpliedProbability: async (impliedProbabilityRequest: ImpliedProbabilityRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'impliedProbabilityRequest' is not null or undefined
            assertParamExists('createImpliedProbability', 'impliedProbabilityRequest', impliedProbabilityRequest)
            const localVarPath = `/api/implied-probabilities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(impliedProbabilityRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImpliedProbability: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteImpliedProbability', 'id', id)
            const localVarPath = `/api/implied-probabilities/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllImpliedProbByDateModified: async (startDate: string, endDate: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getAllImpliedProbByDateModified', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getAllImpliedProbByDateModified', 'endDate', endDate)
            const localVarPath = `/api/implied-probabilities/betweenDates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImpliedProbCountByDateModified: async (startDate: string, endDate: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getImpliedProbCountByDateModified', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getImpliedProbCountByDateModified', 'endDate', endDate)
            const localVarPath = `/api/implied-probabilities/countBetweenDates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImpliedProbabilities: async (filter?: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/implied-probabilities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImpliedProbabilityById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getImpliedProbabilityById', 'id', id)
            const localVarPath = `/api/implied-probabilities/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ImpliedProbabilityRequest} impliedProbabilityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateImpliedProbability: async (id: number, impliedProbabilityRequest: ImpliedProbabilityRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateImpliedProbability', 'id', id)
            // verify required parameter 'impliedProbabilityRequest' is not null or undefined
            assertParamExists('updateImpliedProbability', 'impliedProbabilityRequest', impliedProbabilityRequest)
            const localVarPath = `/api/implied-probabilities/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(impliedProbabilityRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImpliedProbabilityRestControllerApi - functional programming interface
 * @export
 */
export const ImpliedProbabilityRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ImpliedProbabilityRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ImpliedProbabilityRequest} impliedProbabilityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createImpliedProbability(impliedProbabilityRequest: ImpliedProbabilityRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createImpliedProbability(impliedProbabilityRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImpliedProbabilityRestControllerApi.createImpliedProbability']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteImpliedProbability(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteImpliedProbability(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImpliedProbabilityRestControllerApi.deleteImpliedProbability']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllImpliedProbByDateModified(startDate: string, endDate: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageImpliedProbabilityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllImpliedProbByDateModified(startDate, endDate, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImpliedProbabilityRestControllerApi.getAllImpliedProbByDateModified']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getImpliedProbCountByDateModified(startDate: string, endDate: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getImpliedProbCountByDateModified(startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImpliedProbabilityRestControllerApi.getImpliedProbCountByDateModified']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getImpliedProbabilities(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageImpliedProbabilityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getImpliedProbabilities(filter, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImpliedProbabilityRestControllerApi.getImpliedProbabilities']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getImpliedProbabilityById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImpliedProbabilityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getImpliedProbabilityById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImpliedProbabilityRestControllerApi.getImpliedProbabilityById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {ImpliedProbabilityRequest} impliedProbabilityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateImpliedProbability(id: number, impliedProbabilityRequest: ImpliedProbabilityRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateImpliedProbability(id, impliedProbabilityRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImpliedProbabilityRestControllerApi.updateImpliedProbability']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ImpliedProbabilityRestControllerApi - factory interface
 * @export
 */
export const ImpliedProbabilityRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ImpliedProbabilityRestControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {ImpliedProbabilityRequest} impliedProbabilityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createImpliedProbability(impliedProbabilityRequest: ImpliedProbabilityRequest, options?: any): AxiosPromise<number> {
            return localVarFp.createImpliedProbability(impliedProbabilityRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImpliedProbability(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteImpliedProbability(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllImpliedProbByDateModified(startDate: string, endDate: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageImpliedProbabilityResponse> {
            return localVarFp.getAllImpliedProbByDateModified(startDate, endDate, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImpliedProbCountByDateModified(startDate: string, endDate: string, options?: any): AxiosPromise<number> {
            return localVarFp.getImpliedProbCountByDateModified(startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImpliedProbabilities(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageImpliedProbabilityResponse> {
            return localVarFp.getImpliedProbabilities(filter, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImpliedProbabilityById(id: number, options?: any): AxiosPromise<ImpliedProbabilityResponse> {
            return localVarFp.getImpliedProbabilityById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ImpliedProbabilityRequest} impliedProbabilityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateImpliedProbability(id: number, impliedProbabilityRequest: ImpliedProbabilityRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateImpliedProbability(id, impliedProbabilityRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ImpliedProbabilityRestControllerApi - object-oriented interface
 * @export
 * @class ImpliedProbabilityRestControllerApi
 * @extends {BaseAPI}
 */
export class ImpliedProbabilityRestControllerApi extends BaseAPI {
    /**
     * 
     * @param {ImpliedProbabilityRequest} impliedProbabilityRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImpliedProbabilityRestControllerApi
     */
    public createImpliedProbability(impliedProbabilityRequest: ImpliedProbabilityRequest, options?: RawAxiosRequestConfig) {
        return ImpliedProbabilityRestControllerApiFp(this.configuration).createImpliedProbability(impliedProbabilityRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImpliedProbabilityRestControllerApi
     */
    public deleteImpliedProbability(id: number, options?: RawAxiosRequestConfig) {
        return ImpliedProbabilityRestControllerApiFp(this.configuration).deleteImpliedProbability(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImpliedProbabilityRestControllerApi
     */
    public getAllImpliedProbByDateModified(startDate: string, endDate: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return ImpliedProbabilityRestControllerApiFp(this.configuration).getAllImpliedProbByDateModified(startDate, endDate, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImpliedProbabilityRestControllerApi
     */
    public getImpliedProbCountByDateModified(startDate: string, endDate: string, options?: RawAxiosRequestConfig) {
        return ImpliedProbabilityRestControllerApiFp(this.configuration).getImpliedProbCountByDateModified(startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [filter] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImpliedProbabilityRestControllerApi
     */
    public getImpliedProbabilities(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return ImpliedProbabilityRestControllerApiFp(this.configuration).getImpliedProbabilities(filter, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImpliedProbabilityRestControllerApi
     */
    public getImpliedProbabilityById(id: number, options?: RawAxiosRequestConfig) {
        return ImpliedProbabilityRestControllerApiFp(this.configuration).getImpliedProbabilityById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ImpliedProbabilityRequest} impliedProbabilityRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImpliedProbabilityRestControllerApi
     */
    public updateImpliedProbability(id: number, impliedProbabilityRequest: ImpliedProbabilityRequest, options?: RawAxiosRequestConfig) {
        return ImpliedProbabilityRestControllerApiFp(this.configuration).updateImpliedProbability(id, impliedProbabilityRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LinkAssociationRestControllerApi - axios parameter creator
 * @export
 */
export const LinkAssociationRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new association between a link and other domains. Requires ADMIN or USER role.
         * @summary Create a new link association
         * @param {LinkAssociationRequest} linkAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLinkAssociation: async (linkAssociationRequest: LinkAssociationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'linkAssociationRequest' is not null or undefined
            assertParamExists('createLinkAssociation', 'linkAssociationRequest', linkAssociationRequest)
            const localVarPath = `/api/link/assoc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(linkAssociationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the association between a link and other domains. Requires ADMIN or USER role.
         * @summary Delete a link association
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLinkAssociation: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteLinkAssociation', 'id', id)
            const localVarPath = `/api/link/assoc/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve associations between a link and a specific domain type by entity ID and domain type ID.
         * @summary Find associations by entity ID and domain type ID
         * @param {number} entityId 
         * @param {number} domainTypeId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByEntityIdAndDomainTypeId2: async (entityId: number, domainTypeId: number, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('findByEntityIdAndDomainTypeId2', 'entityId', entityId)
            // verify required parameter 'domainTypeId' is not null or undefined
            assertParamExists('findByEntityIdAndDomainTypeId2', 'domainTypeId', domainTypeId)
            const localVarPath = `/api/link/assoc/{entityId}/domain/{domainTypeId}`
                .replace(`{${"entityId"}}`, encodeURIComponent(String(entityId)))
                .replace(`{${"domainTypeId"}}`, encodeURIComponent(String(domainTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a link association by its unique identifier.
         * @summary Get link association by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinkAssociationById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLinkAssociationById', 'id', id)
            const localVarPath = `/api/link/assoc/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve all associations between links and other domains.
         * @summary Get all link associations
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinkAssociations: async (page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/link/assoc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an existing association between a link and other domains. Requires ADMIN or USER role.
         * @summary Update an existing link association
         * @param {number} id 
         * @param {LinkAssociationRequest} linkAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLinkAssociation: async (id: number, linkAssociationRequest: LinkAssociationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateLinkAssociation', 'id', id)
            // verify required parameter 'linkAssociationRequest' is not null or undefined
            assertParamExists('updateLinkAssociation', 'linkAssociationRequest', linkAssociationRequest)
            const localVarPath = `/api/link/assoc/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(linkAssociationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LinkAssociationRestControllerApi - functional programming interface
 * @export
 */
export const LinkAssociationRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LinkAssociationRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a new association between a link and other domains. Requires ADMIN or USER role.
         * @summary Create a new link association
         * @param {LinkAssociationRequest} linkAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLinkAssociation(linkAssociationRequest: LinkAssociationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLinkAssociation(linkAssociationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LinkAssociationRestControllerApi.createLinkAssociation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Deletes the association between a link and other domains. Requires ADMIN or USER role.
         * @summary Delete a link association
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLinkAssociation(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLinkAssociation(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LinkAssociationRestControllerApi.deleteLinkAssociation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve associations between a link and a specific domain type by entity ID and domain type ID.
         * @summary Find associations by entity ID and domain type ID
         * @param {number} entityId 
         * @param {number} domainTypeId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByEntityIdAndDomainTypeId2(entityId: number, domainTypeId: number, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimplePageLinkAssociationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findByEntityIdAndDomainTypeId2(entityId, domainTypeId, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LinkAssociationRestControllerApi.findByEntityIdAndDomainTypeId2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve a link association by its unique identifier.
         * @summary Get link association by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLinkAssociationById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinkAssociationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLinkAssociationById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LinkAssociationRestControllerApi.getLinkAssociationById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve all associations between links and other domains.
         * @summary Get all link associations
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLinkAssociations(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimplePageLinkAssociationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLinkAssociations(page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LinkAssociationRestControllerApi.getLinkAssociations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates an existing association between a link and other domains. Requires ADMIN or USER role.
         * @summary Update an existing link association
         * @param {number} id 
         * @param {LinkAssociationRequest} linkAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLinkAssociation(id: number, linkAssociationRequest: LinkAssociationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLinkAssociation(id, linkAssociationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LinkAssociationRestControllerApi.updateLinkAssociation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * LinkAssociationRestControllerApi - factory interface
 * @export
 */
export const LinkAssociationRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LinkAssociationRestControllerApiFp(configuration)
    return {
        /**
         * Creates a new association between a link and other domains. Requires ADMIN or USER role.
         * @summary Create a new link association
         * @param {LinkAssociationRequest} linkAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLinkAssociation(linkAssociationRequest: LinkAssociationRequest, options?: any): AxiosPromise<number> {
            return localVarFp.createLinkAssociation(linkAssociationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes the association between a link and other domains. Requires ADMIN or USER role.
         * @summary Delete a link association
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLinkAssociation(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteLinkAssociation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve associations between a link and a specific domain type by entity ID and domain type ID.
         * @summary Find associations by entity ID and domain type ID
         * @param {number} entityId 
         * @param {number} domainTypeId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByEntityIdAndDomainTypeId2(entityId: number, domainTypeId: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<SimplePageLinkAssociationResponse> {
            return localVarFp.findByEntityIdAndDomainTypeId2(entityId, domainTypeId, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a link association by its unique identifier.
         * @summary Get link association by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinkAssociationById(id: number, options?: any): AxiosPromise<LinkAssociationResponse> {
            return localVarFp.getLinkAssociationById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve all associations between links and other domains.
         * @summary Get all link associations
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinkAssociations(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<SimplePageLinkAssociationResponse> {
            return localVarFp.getLinkAssociations(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates an existing association between a link and other domains. Requires ADMIN or USER role.
         * @summary Update an existing link association
         * @param {number} id 
         * @param {LinkAssociationRequest} linkAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLinkAssociation(id: number, linkAssociationRequest: LinkAssociationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateLinkAssociation(id, linkAssociationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LinkAssociationRestControllerApi - object-oriented interface
 * @export
 * @class LinkAssociationRestControllerApi
 * @extends {BaseAPI}
 */
export class LinkAssociationRestControllerApi extends BaseAPI {
    /**
     * Creates a new association between a link and other domains. Requires ADMIN or USER role.
     * @summary Create a new link association
     * @param {LinkAssociationRequest} linkAssociationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkAssociationRestControllerApi
     */
    public createLinkAssociation(linkAssociationRequest: LinkAssociationRequest, options?: RawAxiosRequestConfig) {
        return LinkAssociationRestControllerApiFp(this.configuration).createLinkAssociation(linkAssociationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes the association between a link and other domains. Requires ADMIN or USER role.
     * @summary Delete a link association
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkAssociationRestControllerApi
     */
    public deleteLinkAssociation(id: number, options?: RawAxiosRequestConfig) {
        return LinkAssociationRestControllerApiFp(this.configuration).deleteLinkAssociation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve associations between a link and a specific domain type by entity ID and domain type ID.
     * @summary Find associations by entity ID and domain type ID
     * @param {number} entityId 
     * @param {number} domainTypeId 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkAssociationRestControllerApi
     */
    public findByEntityIdAndDomainTypeId2(entityId: number, domainTypeId: number, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return LinkAssociationRestControllerApiFp(this.configuration).findByEntityIdAndDomainTypeId2(entityId, domainTypeId, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a link association by its unique identifier.
     * @summary Get link association by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkAssociationRestControllerApi
     */
    public getLinkAssociationById(id: number, options?: RawAxiosRequestConfig) {
        return LinkAssociationRestControllerApiFp(this.configuration).getLinkAssociationById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve all associations between links and other domains.
     * @summary Get all link associations
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkAssociationRestControllerApi
     */
    public getLinkAssociations(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return LinkAssociationRestControllerApiFp(this.configuration).getLinkAssociations(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates an existing association between a link and other domains. Requires ADMIN or USER role.
     * @summary Update an existing link association
     * @param {number} id 
     * @param {LinkAssociationRequest} linkAssociationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkAssociationRestControllerApi
     */
    public updateLinkAssociation(id: number, linkAssociationRequest: LinkAssociationRequest, options?: RawAxiosRequestConfig) {
        return LinkAssociationRestControllerApiFp(this.configuration).updateLinkAssociation(id, linkAssociationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LinkCategoryRestControllerApi - axios parameter creator
 * @export
 */
export const LinkCategoryRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new link category and returns its ID. Accessible by users with the role of ADMIN or USER.
         * @summary Create a new link category
         * @param {LinkCategoryRequest} linkCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLinkCategory: async (linkCategoryRequest: LinkCategoryRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'linkCategoryRequest' is not null or undefined
            assertParamExists('createLinkCategory', 'linkCategoryRequest', linkCategoryRequest)
            const localVarPath = `/api/links/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(linkCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes an existing link category by its ID. Accessible by users with the role of ADMIN or USER.
         * @summary Delete a link category
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLinkCategory: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteLinkCategory', 'id', id)
            const localVarPath = `/api/links/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetches a paginated list of all link categories.
         * @summary Retrieve all link categories
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinkCategories: async (filter?: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/links/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetches the details of a specific link category by its ID.
         * @summary Retrieve a link category by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinkCategoryById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLinkCategoryById', 'id', id)
            const localVarPath = `/api/links/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the details of an existing link category by its ID. Accessible by users with the role of ADMIN or USER.
         * @summary Update a link category
         * @param {number} id 
         * @param {LinkCategoryRequest} linkCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLinkCategory: async (id: number, linkCategoryRequest: LinkCategoryRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateLinkCategory', 'id', id)
            // verify required parameter 'linkCategoryRequest' is not null or undefined
            assertParamExists('updateLinkCategory', 'linkCategoryRequest', linkCategoryRequest)
            const localVarPath = `/api/links/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(linkCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LinkCategoryRestControllerApi - functional programming interface
 * @export
 */
export const LinkCategoryRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LinkCategoryRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a new link category and returns its ID. Accessible by users with the role of ADMIN or USER.
         * @summary Create a new link category
         * @param {LinkCategoryRequest} linkCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLinkCategory(linkCategoryRequest: LinkCategoryRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLinkCategory(linkCategoryRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LinkCategoryRestControllerApi.createLinkCategory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Deletes an existing link category by its ID. Accessible by users with the role of ADMIN or USER.
         * @summary Delete a link category
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLinkCategory(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLinkCategory(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LinkCategoryRestControllerApi.deleteLinkCategory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Fetches a paginated list of all link categories.
         * @summary Retrieve all link categories
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLinkCategories(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLinkCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLinkCategories(filter, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LinkCategoryRestControllerApi.getLinkCategories']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Fetches the details of a specific link category by its ID.
         * @summary Retrieve a link category by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLinkCategoryById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinkCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLinkCategoryById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LinkCategoryRestControllerApi.getLinkCategoryById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the details of an existing link category by its ID. Accessible by users with the role of ADMIN or USER.
         * @summary Update a link category
         * @param {number} id 
         * @param {LinkCategoryRequest} linkCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLinkCategory(id: number, linkCategoryRequest: LinkCategoryRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLinkCategory(id, linkCategoryRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LinkCategoryRestControllerApi.updateLinkCategory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * LinkCategoryRestControllerApi - factory interface
 * @export
 */
export const LinkCategoryRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LinkCategoryRestControllerApiFp(configuration)
    return {
        /**
         * Creates a new link category and returns its ID. Accessible by users with the role of ADMIN or USER.
         * @summary Create a new link category
         * @param {LinkCategoryRequest} linkCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLinkCategory(linkCategoryRequest: LinkCategoryRequest, options?: any): AxiosPromise<number> {
            return localVarFp.createLinkCategory(linkCategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes an existing link category by its ID. Accessible by users with the role of ADMIN or USER.
         * @summary Delete a link category
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLinkCategory(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteLinkCategory(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetches a paginated list of all link categories.
         * @summary Retrieve all link categories
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinkCategories(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageLinkCategoryResponse> {
            return localVarFp.getLinkCategories(filter, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetches the details of a specific link category by its ID.
         * @summary Retrieve a link category by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinkCategoryById(id: number, options?: any): AxiosPromise<LinkCategoryResponse> {
            return localVarFp.getLinkCategoryById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the details of an existing link category by its ID. Accessible by users with the role of ADMIN or USER.
         * @summary Update a link category
         * @param {number} id 
         * @param {LinkCategoryRequest} linkCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLinkCategory(id: number, linkCategoryRequest: LinkCategoryRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateLinkCategory(id, linkCategoryRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LinkCategoryRestControllerApi - object-oriented interface
 * @export
 * @class LinkCategoryRestControllerApi
 * @extends {BaseAPI}
 */
export class LinkCategoryRestControllerApi extends BaseAPI {
    /**
     * Creates a new link category and returns its ID. Accessible by users with the role of ADMIN or USER.
     * @summary Create a new link category
     * @param {LinkCategoryRequest} linkCategoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkCategoryRestControllerApi
     */
    public createLinkCategory(linkCategoryRequest: LinkCategoryRequest, options?: RawAxiosRequestConfig) {
        return LinkCategoryRestControllerApiFp(this.configuration).createLinkCategory(linkCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes an existing link category by its ID. Accessible by users with the role of ADMIN or USER.
     * @summary Delete a link category
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkCategoryRestControllerApi
     */
    public deleteLinkCategory(id: number, options?: RawAxiosRequestConfig) {
        return LinkCategoryRestControllerApiFp(this.configuration).deleteLinkCategory(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetches a paginated list of all link categories.
     * @summary Retrieve all link categories
     * @param {string} [filter] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkCategoryRestControllerApi
     */
    public getLinkCategories(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return LinkCategoryRestControllerApiFp(this.configuration).getLinkCategories(filter, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetches the details of a specific link category by its ID.
     * @summary Retrieve a link category by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkCategoryRestControllerApi
     */
    public getLinkCategoryById(id: number, options?: RawAxiosRequestConfig) {
        return LinkCategoryRestControllerApiFp(this.configuration).getLinkCategoryById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the details of an existing link category by its ID. Accessible by users with the role of ADMIN or USER.
     * @summary Update a link category
     * @param {number} id 
     * @param {LinkCategoryRequest} linkCategoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkCategoryRestControllerApi
     */
    public updateLinkCategory(id: number, linkCategoryRequest: LinkCategoryRequest, options?: RawAxiosRequestConfig) {
        return LinkCategoryRestControllerApiFp(this.configuration).updateLinkCategory(id, linkCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LinkRestControllerApi - axios parameter creator
 * @export
 */
export const LinkRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new link with the provided details. The link must include an associated M&A ID and link category ID. Accessible by users with the role of ADMIN or USER. 
         * @summary Create a new link
         * @param {LinkRequest} linkRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLink: async (linkRequest: LinkRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'linkRequest' is not null or undefined
            assertParamExists('createLink', 'linkRequest', linkRequest)
            const localVarPath = `/api/links`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(linkRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the link identified by its ID. Accessible by users with the role of ADMIN or USER. 
         * @summary Delete a link
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLink: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteLink', 'id', id)
            const localVarPath = `/api/links/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetches a link by its unique identifier. 
         * @summary Retrieve link by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinkById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLinkById', 'id', id)
            const localVarPath = `/api/links/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetches a paginated list of links. Optionally filters the links based on the provided filter(not currently used).The list is sorted by the date it was  last modified in descending order by default. 
         * @summary Retrieve paginated list of links
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinks: async (filter?: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/links`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the details of an existing link identified by its ID. Accessible by users with the role of ADMIN or USER. 
         * @summary Update an existing link
         * @param {number} id 
         * @param {LinkRequest} linkRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLink: async (id: number, linkRequest: LinkRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateLink', 'id', id)
            // verify required parameter 'linkRequest' is not null or undefined
            assertParamExists('updateLink', 'linkRequest', linkRequest)
            const localVarPath = `/api/links/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(linkRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LinkRestControllerApi - functional programming interface
 * @export
 */
export const LinkRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LinkRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a new link with the provided details. The link must include an associated M&A ID and link category ID. Accessible by users with the role of ADMIN or USER. 
         * @summary Create a new link
         * @param {LinkRequest} linkRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLink(linkRequest: LinkRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLink(linkRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LinkRestControllerApi.createLink']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Deletes the link identified by its ID. Accessible by users with the role of ADMIN or USER. 
         * @summary Delete a link
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLink(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLink(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LinkRestControllerApi.deleteLink']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Fetches a link by its unique identifier. 
         * @summary Retrieve link by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLinkById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LinkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLinkById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LinkRestControllerApi.getLinkById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Fetches a paginated list of links. Optionally filters the links based on the provided filter(not currently used).The list is sorted by the date it was  last modified in descending order by default. 
         * @summary Retrieve paginated list of links
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLinks(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLinkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLinks(filter, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LinkRestControllerApi.getLinks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates the details of an existing link identified by its ID. Accessible by users with the role of ADMIN or USER. 
         * @summary Update an existing link
         * @param {number} id 
         * @param {LinkRequest} linkRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLink(id: number, linkRequest: LinkRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLink(id, linkRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LinkRestControllerApi.updateLink']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * LinkRestControllerApi - factory interface
 * @export
 */
export const LinkRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LinkRestControllerApiFp(configuration)
    return {
        /**
         * Creates a new link with the provided details. The link must include an associated M&A ID and link category ID. Accessible by users with the role of ADMIN or USER. 
         * @summary Create a new link
         * @param {LinkRequest} linkRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLink(linkRequest: LinkRequest, options?: any): AxiosPromise<number> {
            return localVarFp.createLink(linkRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes the link identified by its ID. Accessible by users with the role of ADMIN or USER. 
         * @summary Delete a link
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLink(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteLink(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetches a link by its unique identifier. 
         * @summary Retrieve link by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinkById(id: number, options?: any): AxiosPromise<LinkResponse> {
            return localVarFp.getLinkById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetches a paginated list of links. Optionally filters the links based on the provided filter(not currently used).The list is sorted by the date it was  last modified in descending order by default. 
         * @summary Retrieve paginated list of links
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinks(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageLinkResponse> {
            return localVarFp.getLinks(filter, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the details of an existing link identified by its ID. Accessible by users with the role of ADMIN or USER. 
         * @summary Update an existing link
         * @param {number} id 
         * @param {LinkRequest} linkRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLink(id: number, linkRequest: LinkRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateLink(id, linkRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LinkRestControllerApi - object-oriented interface
 * @export
 * @class LinkRestControllerApi
 * @extends {BaseAPI}
 */
export class LinkRestControllerApi extends BaseAPI {
    /**
     * Creates a new link with the provided details. The link must include an associated M&A ID and link category ID. Accessible by users with the role of ADMIN or USER. 
     * @summary Create a new link
     * @param {LinkRequest} linkRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkRestControllerApi
     */
    public createLink(linkRequest: LinkRequest, options?: RawAxiosRequestConfig) {
        return LinkRestControllerApiFp(this.configuration).createLink(linkRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes the link identified by its ID. Accessible by users with the role of ADMIN or USER. 
     * @summary Delete a link
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkRestControllerApi
     */
    public deleteLink(id: number, options?: RawAxiosRequestConfig) {
        return LinkRestControllerApiFp(this.configuration).deleteLink(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetches a link by its unique identifier. 
     * @summary Retrieve link by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkRestControllerApi
     */
    public getLinkById(id: number, options?: RawAxiosRequestConfig) {
        return LinkRestControllerApiFp(this.configuration).getLinkById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetches a paginated list of links. Optionally filters the links based on the provided filter(not currently used).The list is sorted by the date it was  last modified in descending order by default. 
     * @summary Retrieve paginated list of links
     * @param {string} [filter] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkRestControllerApi
     */
    public getLinks(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return LinkRestControllerApiFp(this.configuration).getLinks(filter, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the details of an existing link identified by its ID. Accessible by users with the role of ADMIN or USER. 
     * @summary Update an existing link
     * @param {number} id 
     * @param {LinkRequest} linkRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinkRestControllerApi
     */
    public updateLink(id: number, linkRequest: LinkRequest, options?: RawAxiosRequestConfig) {
        return LinkRestControllerApiFp(this.configuration).updateLink(id, linkRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MnaCategoryRestControllerApi - axios parameter creator
 * @export
 */
export const MnaCategoryRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Endpoint to create a new MNA category. Only users with ADMIN or USER roles can access.
         * @summary Create MNA category
         * @param {MnaCategoryRequest} mnaCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMnaCategory: async (mnaCategoryRequest: MnaCategoryRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mnaCategoryRequest' is not null or undefined
            assertParamExists('createMnaCategory', 'mnaCategoryRequest', mnaCategoryRequest)
            const localVarPath = `/api/mnas/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mnaCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint to delete an existing MNA category. Only users with ADMIN or USER roles can access.
         * @summary Delete MNA category
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMnaCategory: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteMnaCategory', 'id', id)
            const localVarPath = `/api/mnas/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint to retrieve all MNA categories.
         * @summary Retrieve all MNA categories
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMnaCategories: async (filter?: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/mnas/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint to retrieve an MNA category by its ID.
         * @summary Retrieve MNA category by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMnaCategoryById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMnaCategoryById', 'id', id)
            const localVarPath = `/api/mnas/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint to update an existing MNA category. Only users with ADMIN or USER roles can access.
         * @summary Update MNA category
         * @param {number} id 
         * @param {MnaCategoryRequest} mnaCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMnaCategory: async (id: number, mnaCategoryRequest: MnaCategoryRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateMnaCategory', 'id', id)
            // verify required parameter 'mnaCategoryRequest' is not null or undefined
            assertParamExists('updateMnaCategory', 'mnaCategoryRequest', mnaCategoryRequest)
            const localVarPath = `/api/mnas/categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mnaCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MnaCategoryRestControllerApi - functional programming interface
 * @export
 */
export const MnaCategoryRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MnaCategoryRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Endpoint to create a new MNA category. Only users with ADMIN or USER roles can access.
         * @summary Create MNA category
         * @param {MnaCategoryRequest} mnaCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMnaCategory(mnaCategoryRequest: MnaCategoryRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMnaCategory(mnaCategoryRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MnaCategoryRestControllerApi.createMnaCategory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Endpoint to delete an existing MNA category. Only users with ADMIN or USER roles can access.
         * @summary Delete MNA category
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMnaCategory(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMnaCategory(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MnaCategoryRestControllerApi.deleteMnaCategory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Endpoint to retrieve all MNA categories.
         * @summary Retrieve all MNA categories
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMnaCategories(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageMnaCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMnaCategories(filter, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MnaCategoryRestControllerApi.getMnaCategories']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Endpoint to retrieve an MNA category by its ID.
         * @summary Retrieve MNA category by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMnaCategoryById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MnaCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMnaCategoryById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MnaCategoryRestControllerApi.getMnaCategoryById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Endpoint to update an existing MNA category. Only users with ADMIN or USER roles can access.
         * @summary Update MNA category
         * @param {number} id 
         * @param {MnaCategoryRequest} mnaCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMnaCategory(id: number, mnaCategoryRequest: MnaCategoryRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMnaCategory(id, mnaCategoryRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MnaCategoryRestControllerApi.updateMnaCategory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MnaCategoryRestControllerApi - factory interface
 * @export
 */
export const MnaCategoryRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MnaCategoryRestControllerApiFp(configuration)
    return {
        /**
         * Endpoint to create a new MNA category. Only users with ADMIN or USER roles can access.
         * @summary Create MNA category
         * @param {MnaCategoryRequest} mnaCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMnaCategory(mnaCategoryRequest: MnaCategoryRequest, options?: any): AxiosPromise<number> {
            return localVarFp.createMnaCategory(mnaCategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint to delete an existing MNA category. Only users with ADMIN or USER roles can access.
         * @summary Delete MNA category
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMnaCategory(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteMnaCategory(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint to retrieve all MNA categories.
         * @summary Retrieve all MNA categories
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMnaCategories(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageMnaCategoryResponse> {
            return localVarFp.getMnaCategories(filter, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint to retrieve an MNA category by its ID.
         * @summary Retrieve MNA category by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMnaCategoryById(id: number, options?: any): AxiosPromise<MnaCategoryResponse> {
            return localVarFp.getMnaCategoryById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint to update an existing MNA category. Only users with ADMIN or USER roles can access.
         * @summary Update MNA category
         * @param {number} id 
         * @param {MnaCategoryRequest} mnaCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMnaCategory(id: number, mnaCategoryRequest: MnaCategoryRequest, options?: any): AxiosPromise<number> {
            return localVarFp.updateMnaCategory(id, mnaCategoryRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MnaCategoryRestControllerApi - object-oriented interface
 * @export
 * @class MnaCategoryRestControllerApi
 * @extends {BaseAPI}
 */
export class MnaCategoryRestControllerApi extends BaseAPI {
    /**
     * Endpoint to create a new MNA category. Only users with ADMIN or USER roles can access.
     * @summary Create MNA category
     * @param {MnaCategoryRequest} mnaCategoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MnaCategoryRestControllerApi
     */
    public createMnaCategory(mnaCategoryRequest: MnaCategoryRequest, options?: RawAxiosRequestConfig) {
        return MnaCategoryRestControllerApiFp(this.configuration).createMnaCategory(mnaCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint to delete an existing MNA category. Only users with ADMIN or USER roles can access.
     * @summary Delete MNA category
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MnaCategoryRestControllerApi
     */
    public deleteMnaCategory(id: number, options?: RawAxiosRequestConfig) {
        return MnaCategoryRestControllerApiFp(this.configuration).deleteMnaCategory(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint to retrieve all MNA categories.
     * @summary Retrieve all MNA categories
     * @param {string} [filter] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MnaCategoryRestControllerApi
     */
    public getMnaCategories(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return MnaCategoryRestControllerApiFp(this.configuration).getMnaCategories(filter, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint to retrieve an MNA category by its ID.
     * @summary Retrieve MNA category by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MnaCategoryRestControllerApi
     */
    public getMnaCategoryById(id: number, options?: RawAxiosRequestConfig) {
        return MnaCategoryRestControllerApiFp(this.configuration).getMnaCategoryById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint to update an existing MNA category. Only users with ADMIN or USER roles can access.
     * @summary Update MNA category
     * @param {number} id 
     * @param {MnaCategoryRequest} mnaCategoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MnaCategoryRestControllerApi
     */
    public updateMnaCategory(id: number, mnaCategoryRequest: MnaCategoryRequest, options?: RawAxiosRequestConfig) {
        return MnaCategoryRestControllerApiFp(this.configuration).updateMnaCategory(id, mnaCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MnaHistoryRestControllerApi - axios parameter creator
 * @export
 */
export const MnaHistoryRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the history of changes for a specific MNA. Accessible by all authenticated users.
         * @summary Get MNA History
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMnaHistory: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMnaHistory', 'id', id)
            const localVarPath = `/api/mnas/history/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the history of changes for a specific MNA between specified dates. Accessible by all authenticated users.
         * @summary Get MNA History Between Dates
         * @param {number} id 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMnaHistoryBetweenDates: async (id: number, startDate: string, endDate: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMnaHistoryBetweenDates', 'id', id)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getMnaHistoryBetweenDates', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getMnaHistoryBetweenDates', 'endDate', endDate)
            const localVarPath = `/api/mnas/history/{id}/between-dates`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MnaHistoryRestControllerApi - functional programming interface
 * @export
 */
export const MnaHistoryRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MnaHistoryRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves the history of changes for a specific MNA. Accessible by all authenticated users.
         * @summary Get MNA History
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMnaHistory(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DomainChangeByProperty>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMnaHistory(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MnaHistoryRestControllerApi.getMnaHistory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves the history of changes for a specific MNA between specified dates. Accessible by all authenticated users.
         * @summary Get MNA History Between Dates
         * @param {number} id 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMnaHistoryBetweenDates(id: number, startDate: string, endDate: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DomainPropertyChange>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMnaHistoryBetweenDates(id, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MnaHistoryRestControllerApi.getMnaHistoryBetweenDates']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MnaHistoryRestControllerApi - factory interface
 * @export
 */
export const MnaHistoryRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MnaHistoryRestControllerApiFp(configuration)
    return {
        /**
         * Retrieves the history of changes for a specific MNA. Accessible by all authenticated users.
         * @summary Get MNA History
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMnaHistory(id: number, options?: any): AxiosPromise<DomainChangeByProperty> {
            return localVarFp.getMnaHistory(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the history of changes for a specific MNA between specified dates. Accessible by all authenticated users.
         * @summary Get MNA History Between Dates
         * @param {number} id 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMnaHistoryBetweenDates(id: number, startDate: string, endDate: string, options?: any): AxiosPromise<Array<DomainPropertyChange>> {
            return localVarFp.getMnaHistoryBetweenDates(id, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MnaHistoryRestControllerApi - object-oriented interface
 * @export
 * @class MnaHistoryRestControllerApi
 * @extends {BaseAPI}
 */
export class MnaHistoryRestControllerApi extends BaseAPI {
    /**
     * Retrieves the history of changes for a specific MNA. Accessible by all authenticated users.
     * @summary Get MNA History
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MnaHistoryRestControllerApi
     */
    public getMnaHistory(id: number, options?: RawAxiosRequestConfig) {
        return MnaHistoryRestControllerApiFp(this.configuration).getMnaHistory(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the history of changes for a specific MNA between specified dates. Accessible by all authenticated users.
     * @summary Get MNA History Between Dates
     * @param {number} id 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MnaHistoryRestControllerApi
     */
    public getMnaHistoryBetweenDates(id: number, startDate: string, endDate: string, options?: RawAxiosRequestConfig) {
        return MnaHistoryRestControllerApiFp(this.configuration).getMnaHistoryBetweenDates(id, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MnaRestControllerApi - axios parameter creator
 * @export
 */
export const MnaRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new MNA entity. Only users with ADMIN or USER roles can access this endpoint.
         * @summary Create MNA
         * @param {MnaRequest} mnaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMna: async (mnaRequest: MnaRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mnaRequest' is not null or undefined
            assertParamExists('createMna', 'mnaRequest', mnaRequest)
            const localVarPath = `/api/mnas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mnaRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} startDate The start date for the period to retrieve MNA records from.
         * @param {string} endDate The end date for the period to retrieve MNA records up to.
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMnasByDateModified: async (startDate: string, endDate: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getAllMnasByDateModified', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getAllMnasByDateModified', 'endDate', endDate)
            const localVarPath = `/api/mnas/betweenDates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve MNA data by its unique identifier.
         * @summary Get MNA by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMnaById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMnaById', 'id', id)
            const localVarPath = `/api/mnas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get notes associated with a mna data.
         * @summary Get Mna Notes
         * @param {number} id 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMnaNotes: async (id: number, pageable: Pageable, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMnaNotes', 'id', id)
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getMnaNotes', 'pageable', pageable)
            const localVarPath = `/api/mnas/{id}/notes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all MNAs with optional search
         * @param {string} [search] Optional search parameter. If \&#39;name\&#39; is specified as the key, it searches by MNA name. If the key is not \&#39;name\&#39;, the value will be neglected, and the endpoint will fall back to the default behavior of retrieving all MNAs. 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMnas: async (search?: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/mnas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} startDate The start date for the period to count MNA records from.
         * @param {string} endDate The end date for the period to count MNA records up to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMnasCountByDateModified: async (startDate: string, endDate: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getMnasCountByDateModified', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getMnasCountByDateModified', 'endDate', endDate)
            const localVarPath = `/api/mnas/countBetweenDates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing MNA entity by its unique identifier. Only users with ADMIN or USER roles can access this endpoint.
         * @summary Update MNA
         * @param {number} id 
         * @param {MnaRequest} mnaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMna: async (id: number, mnaRequest: MnaRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateMna', 'id', id)
            // verify required parameter 'mnaRequest' is not null or undefined
            assertParamExists('updateMna', 'mnaRequest', mnaRequest)
            const localVarPath = `/api/mnas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mnaRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MnaRestControllerApi - functional programming interface
 * @export
 */
export const MnaRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MnaRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new MNA entity. Only users with ADMIN or USER roles can access this endpoint.
         * @summary Create MNA
         * @param {MnaRequest} mnaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMna(mnaRequest: MnaRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMna(mnaRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MnaRestControllerApi.createMna']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} startDate The start date for the period to retrieve MNA records from.
         * @param {string} endDate The end date for the period to retrieve MNA records up to.
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllMnasByDateModified(startDate: string, endDate: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageMnaResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllMnasByDateModified(startDate, endDate, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MnaRestControllerApi.getAllMnasByDateModified']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve MNA data by its unique identifier.
         * @summary Get MNA by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMnaById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MnaResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMnaById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MnaRestControllerApi.getMnaById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get notes associated with a mna data.
         * @summary Get Mna Notes
         * @param {number} id 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMnaNotes(id: number, pageable: Pageable, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageNoteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMnaNotes(id, pageable, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MnaRestControllerApi.getMnaNotes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all MNAs with optional search
         * @param {string} [search] Optional search parameter. If \&#39;name\&#39; is specified as the key, it searches by MNA name. If the key is not \&#39;name\&#39;, the value will be neglected, and the endpoint will fall back to the default behavior of retrieving all MNAs. 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMnas(search?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageMnaResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMnas(search, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MnaRestControllerApi.getMnas']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} startDate The start date for the period to count MNA records from.
         * @param {string} endDate The end date for the period to count MNA records up to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMnasCountByDateModified(startDate: string, endDate: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMnasCountByDateModified(startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MnaRestControllerApi.getMnasCountByDateModified']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an existing MNA entity by its unique identifier. Only users with ADMIN or USER roles can access this endpoint.
         * @summary Update MNA
         * @param {number} id 
         * @param {MnaRequest} mnaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMna(id: number, mnaRequest: MnaRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMna(id, mnaRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MnaRestControllerApi.updateMna']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MnaRestControllerApi - factory interface
 * @export
 */
export const MnaRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MnaRestControllerApiFp(configuration)
    return {
        /**
         * Create a new MNA entity. Only users with ADMIN or USER roles can access this endpoint.
         * @summary Create MNA
         * @param {MnaRequest} mnaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMna(mnaRequest: MnaRequest, options?: any): AxiosPromise<number> {
            return localVarFp.createMna(mnaRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} startDate The start date for the period to retrieve MNA records from.
         * @param {string} endDate The end date for the period to retrieve MNA records up to.
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMnasByDateModified(startDate: string, endDate: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageMnaResponse> {
            return localVarFp.getAllMnasByDateModified(startDate, endDate, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve MNA data by its unique identifier.
         * @summary Get MNA by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMnaById(id: number, options?: any): AxiosPromise<MnaResponse> {
            return localVarFp.getMnaById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get notes associated with a mna data.
         * @summary Get Mna Notes
         * @param {number} id 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMnaNotes(id: number, pageable: Pageable, options?: any): AxiosPromise<PageNoteResponse> {
            return localVarFp.getMnaNotes(id, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all MNAs with optional search
         * @param {string} [search] Optional search parameter. If \&#39;name\&#39; is specified as the key, it searches by MNA name. If the key is not \&#39;name\&#39;, the value will be neglected, and the endpoint will fall back to the default behavior of retrieving all MNAs. 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMnas(search?: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageMnaResponse> {
            return localVarFp.getMnas(search, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} startDate The start date for the period to count MNA records from.
         * @param {string} endDate The end date for the period to count MNA records up to.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMnasCountByDateModified(startDate: string, endDate: string, options?: any): AxiosPromise<number> {
            return localVarFp.getMnasCountByDateModified(startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing MNA entity by its unique identifier. Only users with ADMIN or USER roles can access this endpoint.
         * @summary Update MNA
         * @param {number} id 
         * @param {MnaRequest} mnaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMna(id: number, mnaRequest: MnaRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateMna(id, mnaRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MnaRestControllerApi - object-oriented interface
 * @export
 * @class MnaRestControllerApi
 * @extends {BaseAPI}
 */
export class MnaRestControllerApi extends BaseAPI {
    /**
     * Create a new MNA entity. Only users with ADMIN or USER roles can access this endpoint.
     * @summary Create MNA
     * @param {MnaRequest} mnaRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MnaRestControllerApi
     */
    public createMna(mnaRequest: MnaRequest, options?: RawAxiosRequestConfig) {
        return MnaRestControllerApiFp(this.configuration).createMna(mnaRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} startDate The start date for the period to retrieve MNA records from.
     * @param {string} endDate The end date for the period to retrieve MNA records up to.
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MnaRestControllerApi
     */
    public getAllMnasByDateModified(startDate: string, endDate: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return MnaRestControllerApiFp(this.configuration).getAllMnasByDateModified(startDate, endDate, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve MNA data by its unique identifier.
     * @summary Get MNA by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MnaRestControllerApi
     */
    public getMnaById(id: number, options?: RawAxiosRequestConfig) {
        return MnaRestControllerApiFp(this.configuration).getMnaById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get notes associated with a mna data.
     * @summary Get Mna Notes
     * @param {number} id 
     * @param {Pageable} pageable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MnaRestControllerApi
     */
    public getMnaNotes(id: number, pageable: Pageable, options?: RawAxiosRequestConfig) {
        return MnaRestControllerApiFp(this.configuration).getMnaNotes(id, pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all MNAs with optional search
     * @param {string} [search] Optional search parameter. If \&#39;name\&#39; is specified as the key, it searches by MNA name. If the key is not \&#39;name\&#39;, the value will be neglected, and the endpoint will fall back to the default behavior of retrieving all MNAs. 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MnaRestControllerApi
     */
    public getMnas(search?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return MnaRestControllerApiFp(this.configuration).getMnas(search, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} startDate The start date for the period to count MNA records from.
     * @param {string} endDate The end date for the period to count MNA records up to.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MnaRestControllerApi
     */
    public getMnasCountByDateModified(startDate: string, endDate: string, options?: RawAxiosRequestConfig) {
        return MnaRestControllerApiFp(this.configuration).getMnasCountByDateModified(startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing MNA entity by its unique identifier. Only users with ADMIN or USER roles can access this endpoint.
     * @summary Update MNA
     * @param {number} id 
     * @param {MnaRequest} mnaRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MnaRestControllerApi
     */
    public updateMna(id: number, mnaRequest: MnaRequest, options?: RawAxiosRequestConfig) {
        return MnaRestControllerApiFp(this.configuration).updateMna(id, mnaRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MnaSubCategoryRestControllerApi - axios parameter creator
 * @export
 */
export const MnaSubCategoryRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new MNA sub-category. Only users with ADMIN or USER roles are allowed to perform this operation.
         * @summary Create MNA Sub-Category
         * @param {MnaSubCategoryRequest} mnaSubCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMnaSubCategory: async (mnaSubCategoryRequest: MnaSubCategoryRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mnaSubCategoryRequest' is not null or undefined
            assertParamExists('createMnaSubCategory', 'mnaSubCategoryRequest', mnaSubCategoryRequest)
            const localVarPath = `/api/mnas/sub-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mnaSubCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes an existing MNA sub-category. Only users with ADMIN or USER roles are allowed to perform this operation.
         * @summary Delete MNA Sub-Category
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMnaSubCategory: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteMnaSubCategory', 'id', id)
            const localVarPath = `/api/mnas/sub-categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint to retrieve all MNA sub-categories. No authentication required.
         * @summary Get MNA sub-categories
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMnaSubCategories: async (filter?: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/mnas/sub-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint to retrieve a specific MNA sub-category by its ID. No authentication required.
         * @summary Get MNA sub-category by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMnaSubCategoryById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMnaSubCategoryById', 'id', id)
            const localVarPath = `/api/mnas/sub-categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an existing MNA sub-category. Only users with ADMIN or USER roles are allowed to perform this operation.
         * @summary Update MNA Sub-Category
         * @param {number} id 
         * @param {MnaSubCategoryRequest} mnaSubCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMnaSubCategory: async (id: number, mnaSubCategoryRequest: MnaSubCategoryRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateMnaSubCategory', 'id', id)
            // verify required parameter 'mnaSubCategoryRequest' is not null or undefined
            assertParamExists('updateMnaSubCategory', 'mnaSubCategoryRequest', mnaSubCategoryRequest)
            const localVarPath = `/api/mnas/sub-categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mnaSubCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MnaSubCategoryRestControllerApi - functional programming interface
 * @export
 */
export const MnaSubCategoryRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MnaSubCategoryRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a new MNA sub-category. Only users with ADMIN or USER roles are allowed to perform this operation.
         * @summary Create MNA Sub-Category
         * @param {MnaSubCategoryRequest} mnaSubCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMnaSubCategory(mnaSubCategoryRequest: MnaSubCategoryRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMnaSubCategory(mnaSubCategoryRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MnaSubCategoryRestControllerApi.createMnaSubCategory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Deletes an existing MNA sub-category. Only users with ADMIN or USER roles are allowed to perform this operation.
         * @summary Delete MNA Sub-Category
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMnaSubCategory(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMnaSubCategory(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MnaSubCategoryRestControllerApi.deleteMnaSubCategory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Endpoint to retrieve all MNA sub-categories. No authentication required.
         * @summary Get MNA sub-categories
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMnaSubCategories(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageMnaSubCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMnaSubCategories(filter, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MnaSubCategoryRestControllerApi.getMnaSubCategories']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Endpoint to retrieve a specific MNA sub-category by its ID. No authentication required.
         * @summary Get MNA sub-category by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMnaSubCategoryById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MnaSubCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMnaSubCategoryById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MnaSubCategoryRestControllerApi.getMnaSubCategoryById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates an existing MNA sub-category. Only users with ADMIN or USER roles are allowed to perform this operation.
         * @summary Update MNA Sub-Category
         * @param {number} id 
         * @param {MnaSubCategoryRequest} mnaSubCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMnaSubCategory(id: number, mnaSubCategoryRequest: MnaSubCategoryRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMnaSubCategory(id, mnaSubCategoryRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MnaSubCategoryRestControllerApi.updateMnaSubCategory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MnaSubCategoryRestControllerApi - factory interface
 * @export
 */
export const MnaSubCategoryRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MnaSubCategoryRestControllerApiFp(configuration)
    return {
        /**
         * Creates a new MNA sub-category. Only users with ADMIN or USER roles are allowed to perform this operation.
         * @summary Create MNA Sub-Category
         * @param {MnaSubCategoryRequest} mnaSubCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMnaSubCategory(mnaSubCategoryRequest: MnaSubCategoryRequest, options?: any): AxiosPromise<number> {
            return localVarFp.createMnaSubCategory(mnaSubCategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes an existing MNA sub-category. Only users with ADMIN or USER roles are allowed to perform this operation.
         * @summary Delete MNA Sub-Category
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMnaSubCategory(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteMnaSubCategory(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint to retrieve all MNA sub-categories. No authentication required.
         * @summary Get MNA sub-categories
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMnaSubCategories(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageMnaSubCategoryResponse> {
            return localVarFp.getMnaSubCategories(filter, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint to retrieve a specific MNA sub-category by its ID. No authentication required.
         * @summary Get MNA sub-category by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMnaSubCategoryById(id: number, options?: any): AxiosPromise<MnaSubCategoryResponse> {
            return localVarFp.getMnaSubCategoryById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates an existing MNA sub-category. Only users with ADMIN or USER roles are allowed to perform this operation.
         * @summary Update MNA Sub-Category
         * @param {number} id 
         * @param {MnaSubCategoryRequest} mnaSubCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMnaSubCategory(id: number, mnaSubCategoryRequest: MnaSubCategoryRequest, options?: any): AxiosPromise<number> {
            return localVarFp.updateMnaSubCategory(id, mnaSubCategoryRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MnaSubCategoryRestControllerApi - object-oriented interface
 * @export
 * @class MnaSubCategoryRestControllerApi
 * @extends {BaseAPI}
 */
export class MnaSubCategoryRestControllerApi extends BaseAPI {
    /**
     * Creates a new MNA sub-category. Only users with ADMIN or USER roles are allowed to perform this operation.
     * @summary Create MNA Sub-Category
     * @param {MnaSubCategoryRequest} mnaSubCategoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MnaSubCategoryRestControllerApi
     */
    public createMnaSubCategory(mnaSubCategoryRequest: MnaSubCategoryRequest, options?: RawAxiosRequestConfig) {
        return MnaSubCategoryRestControllerApiFp(this.configuration).createMnaSubCategory(mnaSubCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes an existing MNA sub-category. Only users with ADMIN or USER roles are allowed to perform this operation.
     * @summary Delete MNA Sub-Category
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MnaSubCategoryRestControllerApi
     */
    public deleteMnaSubCategory(id: number, options?: RawAxiosRequestConfig) {
        return MnaSubCategoryRestControllerApiFp(this.configuration).deleteMnaSubCategory(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint to retrieve all MNA sub-categories. No authentication required.
     * @summary Get MNA sub-categories
     * @param {string} [filter] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MnaSubCategoryRestControllerApi
     */
    public getMnaSubCategories(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return MnaSubCategoryRestControllerApiFp(this.configuration).getMnaSubCategories(filter, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint to retrieve a specific MNA sub-category by its ID. No authentication required.
     * @summary Get MNA sub-category by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MnaSubCategoryRestControllerApi
     */
    public getMnaSubCategoryById(id: number, options?: RawAxiosRequestConfig) {
        return MnaSubCategoryRestControllerApiFp(this.configuration).getMnaSubCategoryById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates an existing MNA sub-category. Only users with ADMIN or USER roles are allowed to perform this operation.
     * @summary Update MNA Sub-Category
     * @param {number} id 
     * @param {MnaSubCategoryRequest} mnaSubCategoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MnaSubCategoryRestControllerApi
     */
    public updateMnaSubCategory(id: number, mnaSubCategoryRequest: MnaSubCategoryRequest, options?: RawAxiosRequestConfig) {
        return MnaSubCategoryRestControllerApiFp(this.configuration).updateMnaSubCategory(id, mnaSubCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MultiStepRestControllerApi - axios parameter creator
 * @export
 */
export const MultiStepRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Handles the create/submission of a multi-step form for the specified domain(MNA). Requires ADMIN or USER role.
         * @summary Update Multi-Step Form
         * @param {string} domainUri 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMultiStepFormGetId: async (domainUri: string, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'domainUri' is not null or undefined
            assertParamExists('createMultiStepFormGetId', 'domainUri', domainUri)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createMultiStepFormGetId', 'body', body)
            const localVarPath = `/api/{domainUri}/multistep`
                .replace(`{${"domainUri"}}`, encodeURIComponent(String(domainUri)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles the creation of a page within a multi-step form for mna. Requires ADMIN or USER role.
         * @summary Create Multi-Step Form Page
         * @param {string} domainUri 
         * @param {number} page 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMultiStepFormGetObject: async (domainUri: string, page: number, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'domainUri' is not null or undefined
            assertParamExists('createMultiStepFormGetObject', 'domainUri', domainUri)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('createMultiStepFormGetObject', 'page', page)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createMultiStepFormGetObject', 'body', body)
            const localVarPath = `/api/{domainUri}/multistep/page/{page}`
                .replace(`{${"domainUri"}}`, encodeURIComponent(String(domainUri)))
                .replace(`{${"page"}}`, encodeURIComponent(String(page)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles the update/submission of a multi-step form for the specified domain(MNA). Requires ADMIN or USER role.
         * @summary Update Multi-Step Form
         * @param {string} domainUri 
         * @param {number} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMultiStepFormGetId: async (domainUri: string, id: number, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'domainUri' is not null or undefined
            assertParamExists('updateMultiStepFormGetId', 'domainUri', domainUri)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateMultiStepFormGetId', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateMultiStepFormGetId', 'body', body)
            const localVarPath = `/api/{domainUri}/{id}/multistep`
                .replace(`{${"domainUri"}}`, encodeURIComponent(String(domainUri)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles the update of a page within a multi-step form for the specified domain(MNA).  If the formId is not found in the bodyNode, a new form is created. Requires ADMIN or USER role. 
         * @summary Update Multi-Step Form Page
         * @param {string} domainUri 
         * @param {number} id 
         * @param {number} page 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMultiStepFormGetObject: async (domainUri: string, id: number, page: number, body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'domainUri' is not null or undefined
            assertParamExists('updateMultiStepFormGetObject', 'domainUri', domainUri)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateMultiStepFormGetObject', 'id', id)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('updateMultiStepFormGetObject', 'page', page)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateMultiStepFormGetObject', 'body', body)
            const localVarPath = `/api/{domainUri}/{id}/multistep/page/{page}`
                .replace(`{${"domainUri"}}`, encodeURIComponent(String(domainUri)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"page"}}`, encodeURIComponent(String(page)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MultiStepRestControllerApi - functional programming interface
 * @export
 */
export const MultiStepRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MultiStepRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Handles the create/submission of a multi-step form for the specified domain(MNA). Requires ADMIN or USER role.
         * @summary Update Multi-Step Form
         * @param {string} domainUri 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMultiStepFormGetId(domainUri: string, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMultiStepFormGetId(domainUri, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MultiStepRestControllerApi.createMultiStepFormGetId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Handles the creation of a page within a multi-step form for mna. Requires ADMIN or USER role.
         * @summary Create Multi-Step Form Page
         * @param {string} domainUri 
         * @param {number} page 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMultiStepFormGetObject(domainUri: string, page: number, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MultiStepResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMultiStepFormGetObject(domainUri, page, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MultiStepRestControllerApi.createMultiStepFormGetObject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Handles the update/submission of a multi-step form for the specified domain(MNA). Requires ADMIN or USER role.
         * @summary Update Multi-Step Form
         * @param {string} domainUri 
         * @param {number} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMultiStepFormGetId(domainUri: string, id: number, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMultiStepFormGetId(domainUri, id, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MultiStepRestControllerApi.updateMultiStepFormGetId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Handles the update of a page within a multi-step form for the specified domain(MNA).  If the formId is not found in the bodyNode, a new form is created. Requires ADMIN or USER role. 
         * @summary Update Multi-Step Form Page
         * @param {string} domainUri 
         * @param {number} id 
         * @param {number} page 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMultiStepFormGetObject(domainUri: string, id: number, page: number, body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MultiStepResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMultiStepFormGetObject(domainUri, id, page, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MultiStepRestControllerApi.updateMultiStepFormGetObject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MultiStepRestControllerApi - factory interface
 * @export
 */
export const MultiStepRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MultiStepRestControllerApiFp(configuration)
    return {
        /**
         * Handles the create/submission of a multi-step form for the specified domain(MNA). Requires ADMIN or USER role.
         * @summary Update Multi-Step Form
         * @param {string} domainUri 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMultiStepFormGetId(domainUri: string, body: object, options?: any): AxiosPromise<number> {
            return localVarFp.createMultiStepFormGetId(domainUri, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Handles the creation of a page within a multi-step form for mna. Requires ADMIN or USER role.
         * @summary Create Multi-Step Form Page
         * @param {string} domainUri 
         * @param {number} page 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMultiStepFormGetObject(domainUri: string, page: number, body: object, options?: any): AxiosPromise<MultiStepResponse> {
            return localVarFp.createMultiStepFormGetObject(domainUri, page, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Handles the update/submission of a multi-step form for the specified domain(MNA). Requires ADMIN or USER role.
         * @summary Update Multi-Step Form
         * @param {string} domainUri 
         * @param {number} id 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMultiStepFormGetId(domainUri: string, id: number, body: object, options?: any): AxiosPromise<number> {
            return localVarFp.updateMultiStepFormGetId(domainUri, id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Handles the update of a page within a multi-step form for the specified domain(MNA).  If the formId is not found in the bodyNode, a new form is created. Requires ADMIN or USER role. 
         * @summary Update Multi-Step Form Page
         * @param {string} domainUri 
         * @param {number} id 
         * @param {number} page 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMultiStepFormGetObject(domainUri: string, id: number, page: number, body: object, options?: any): AxiosPromise<MultiStepResponse> {
            return localVarFp.updateMultiStepFormGetObject(domainUri, id, page, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MultiStepRestControllerApi - object-oriented interface
 * @export
 * @class MultiStepRestControllerApi
 * @extends {BaseAPI}
 */
export class MultiStepRestControllerApi extends BaseAPI {
    /**
     * Handles the create/submission of a multi-step form for the specified domain(MNA). Requires ADMIN or USER role.
     * @summary Update Multi-Step Form
     * @param {string} domainUri 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MultiStepRestControllerApi
     */
    public createMultiStepFormGetId(domainUri: string, body: object, options?: RawAxiosRequestConfig) {
        return MultiStepRestControllerApiFp(this.configuration).createMultiStepFormGetId(domainUri, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles the creation of a page within a multi-step form for mna. Requires ADMIN or USER role.
     * @summary Create Multi-Step Form Page
     * @param {string} domainUri 
     * @param {number} page 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MultiStepRestControllerApi
     */
    public createMultiStepFormGetObject(domainUri: string, page: number, body: object, options?: RawAxiosRequestConfig) {
        return MultiStepRestControllerApiFp(this.configuration).createMultiStepFormGetObject(domainUri, page, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles the update/submission of a multi-step form for the specified domain(MNA). Requires ADMIN or USER role.
     * @summary Update Multi-Step Form
     * @param {string} domainUri 
     * @param {number} id 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MultiStepRestControllerApi
     */
    public updateMultiStepFormGetId(domainUri: string, id: number, body: object, options?: RawAxiosRequestConfig) {
        return MultiStepRestControllerApiFp(this.configuration).updateMultiStepFormGetId(domainUri, id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles the update of a page within a multi-step form for the specified domain(MNA).  If the formId is not found in the bodyNode, a new form is created. Requires ADMIN or USER role. 
     * @summary Update Multi-Step Form Page
     * @param {string} domainUri 
     * @param {number} id 
     * @param {number} page 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MultiStepRestControllerApi
     */
    public updateMultiStepFormGetObject(domainUri: string, id: number, page: number, body: object, options?: RawAxiosRequestConfig) {
        return MultiStepRestControllerApiFp(this.configuration).updateMultiStepFormGetObject(domainUri, id, page, body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * NoteAssociationRestControllerApi - axios parameter creator
 * @export
 */
export const NoteAssociationRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new association between a note and other domains. Requires ADMIN or USER role.
         * @summary Create a new note association
         * @param {NoteAssociationRequest} noteAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNoteAssociation: async (noteAssociationRequest: NoteAssociationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'noteAssociationRequest' is not null or undefined
            assertParamExists('createNoteAssociation', 'noteAssociationRequest', noteAssociationRequest)
            const localVarPath = `/api/notes/assoc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(noteAssociationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the association between a note and other domains. Requires ADMIN or USER role.
         * @summary Delete a note association
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNoteAssociation: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteNoteAssociation', 'id', id)
            const localVarPath = `/api/notes/assoc/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve associations between a note and a specific domain type by entity ID and domain type ID.
         * @summary Find associations by entity ID and domain type ID
         * @param {number} entityId 
         * @param {number} domainTypeId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByEntityIdAndDomainTypeId1: async (entityId: number, domainTypeId: number, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('findByEntityIdAndDomainTypeId1', 'entityId', entityId)
            // verify required parameter 'domainTypeId' is not null or undefined
            assertParamExists('findByEntityIdAndDomainTypeId1', 'domainTypeId', domainTypeId)
            const localVarPath = `/api/notes/assoc/{entityId}/domain/{domainTypeId}`
                .replace(`{${"entityId"}}`, encodeURIComponent(String(entityId)))
                .replace(`{${"domainTypeId"}}`, encodeURIComponent(String(domainTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a note association by its unique identifier.
         * @summary Get note association by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoteAssociationById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getNoteAssociationById', 'id', id)
            const localVarPath = `/api/notes/assoc/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve all associations between notes and other domains.
         * @summary Get all note associations
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoteAssociations: async (page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/notes/assoc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Searches for company data based on the provided query. The query is used to search both Fundamentals and MNA by name or ticker.
         * @summary Search domain
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchDomain: async (query: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'query' is not null or undefined
            assertParamExists('searchDomain', 'query', query)
            const localVarPath = `/api/notes/assoc/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an existing association between a note and other domains. Requires ADMIN or USER role.
         * @summary Update an existing note association
         * @param {number} id 
         * @param {NoteAssociationRequest} noteAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNoteAssociation: async (id: number, noteAssociationRequest: NoteAssociationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateNoteAssociation', 'id', id)
            // verify required parameter 'noteAssociationRequest' is not null or undefined
            assertParamExists('updateNoteAssociation', 'noteAssociationRequest', noteAssociationRequest)
            const localVarPath = `/api/notes/assoc/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(noteAssociationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NoteAssociationRestControllerApi - functional programming interface
 * @export
 */
export const NoteAssociationRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NoteAssociationRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a new association between a note and other domains. Requires ADMIN or USER role.
         * @summary Create a new note association
         * @param {NoteAssociationRequest} noteAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNoteAssociation(noteAssociationRequest: NoteAssociationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNoteAssociation(noteAssociationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteAssociationRestControllerApi.createNoteAssociation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Deletes the association between a note and other domains. Requires ADMIN or USER role.
         * @summary Delete a note association
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNoteAssociation(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNoteAssociation(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteAssociationRestControllerApi.deleteNoteAssociation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve associations between a note and a specific domain type by entity ID and domain type ID.
         * @summary Find associations by entity ID and domain type ID
         * @param {number} entityId 
         * @param {number} domainTypeId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByEntityIdAndDomainTypeId1(entityId: number, domainTypeId: number, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimplePageNoteAssociationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findByEntityIdAndDomainTypeId1(entityId, domainTypeId, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteAssociationRestControllerApi.findByEntityIdAndDomainTypeId1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve a note association by its unique identifier.
         * @summary Get note association by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNoteAssociationById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoteAssociationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNoteAssociationById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteAssociationRestControllerApi.getNoteAssociationById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve all associations between notes and other domains.
         * @summary Get all note associations
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNoteAssociations(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimplePageNoteAssociationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNoteAssociations(page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteAssociationRestControllerApi.getNoteAssociations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Searches for company data based on the provided query. The query is used to search both Fundamentals and MNA by name or ticker.
         * @summary Search domain
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchDomain(query: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchDomain(query, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteAssociationRestControllerApi.searchDomain']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates an existing association between a note and other domains. Requires ADMIN or USER role.
         * @summary Update an existing note association
         * @param {number} id 
         * @param {NoteAssociationRequest} noteAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNoteAssociation(id: number, noteAssociationRequest: NoteAssociationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNoteAssociation(id, noteAssociationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteAssociationRestControllerApi.updateNoteAssociation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * NoteAssociationRestControllerApi - factory interface
 * @export
 */
export const NoteAssociationRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NoteAssociationRestControllerApiFp(configuration)
    return {
        /**
         * Creates a new association between a note and other domains. Requires ADMIN or USER role.
         * @summary Create a new note association
         * @param {NoteAssociationRequest} noteAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNoteAssociation(noteAssociationRequest: NoteAssociationRequest, options?: any): AxiosPromise<number> {
            return localVarFp.createNoteAssociation(noteAssociationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes the association between a note and other domains. Requires ADMIN or USER role.
         * @summary Delete a note association
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNoteAssociation(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteNoteAssociation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve associations between a note and a specific domain type by entity ID and domain type ID.
         * @summary Find associations by entity ID and domain type ID
         * @param {number} entityId 
         * @param {number} domainTypeId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByEntityIdAndDomainTypeId1(entityId: number, domainTypeId: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<SimplePageNoteAssociationResponse> {
            return localVarFp.findByEntityIdAndDomainTypeId1(entityId, domainTypeId, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a note association by its unique identifier.
         * @summary Get note association by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoteAssociationById(id: number, options?: any): AxiosPromise<NoteAssociationResponse> {
            return localVarFp.getNoteAssociationById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve all associations between notes and other domains.
         * @summary Get all note associations
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoteAssociations(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<SimplePageNoteAssociationResponse> {
            return localVarFp.getNoteAssociations(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Searches for company data based on the provided query. The query is used to search both Fundamentals and MNA by name or ticker.
         * @summary Search domain
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchDomain(query: string, options?: any): AxiosPromise<Array<CompanyData>> {
            return localVarFp.searchDomain(query, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates an existing association between a note and other domains. Requires ADMIN or USER role.
         * @summary Update an existing note association
         * @param {number} id 
         * @param {NoteAssociationRequest} noteAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNoteAssociation(id: number, noteAssociationRequest: NoteAssociationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateNoteAssociation(id, noteAssociationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NoteAssociationRestControllerApi - object-oriented interface
 * @export
 * @class NoteAssociationRestControllerApi
 * @extends {BaseAPI}
 */
export class NoteAssociationRestControllerApi extends BaseAPI {
    /**
     * Creates a new association between a note and other domains. Requires ADMIN or USER role.
     * @summary Create a new note association
     * @param {NoteAssociationRequest} noteAssociationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteAssociationRestControllerApi
     */
    public createNoteAssociation(noteAssociationRequest: NoteAssociationRequest, options?: RawAxiosRequestConfig) {
        return NoteAssociationRestControllerApiFp(this.configuration).createNoteAssociation(noteAssociationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes the association between a note and other domains. Requires ADMIN or USER role.
     * @summary Delete a note association
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteAssociationRestControllerApi
     */
    public deleteNoteAssociation(id: number, options?: RawAxiosRequestConfig) {
        return NoteAssociationRestControllerApiFp(this.configuration).deleteNoteAssociation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve associations between a note and a specific domain type by entity ID and domain type ID.
     * @summary Find associations by entity ID and domain type ID
     * @param {number} entityId 
     * @param {number} domainTypeId 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteAssociationRestControllerApi
     */
    public findByEntityIdAndDomainTypeId1(entityId: number, domainTypeId: number, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return NoteAssociationRestControllerApiFp(this.configuration).findByEntityIdAndDomainTypeId1(entityId, domainTypeId, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a note association by its unique identifier.
     * @summary Get note association by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteAssociationRestControllerApi
     */
    public getNoteAssociationById(id: number, options?: RawAxiosRequestConfig) {
        return NoteAssociationRestControllerApiFp(this.configuration).getNoteAssociationById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve all associations between notes and other domains.
     * @summary Get all note associations
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteAssociationRestControllerApi
     */
    public getNoteAssociations(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return NoteAssociationRestControllerApiFp(this.configuration).getNoteAssociations(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Searches for company data based on the provided query. The query is used to search both Fundamentals and MNA by name or ticker.
     * @summary Search domain
     * @param {string} query 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteAssociationRestControllerApi
     */
    public searchDomain(query: string, options?: RawAxiosRequestConfig) {
        return NoteAssociationRestControllerApiFp(this.configuration).searchDomain(query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates an existing association between a note and other domains. Requires ADMIN or USER role.
     * @summary Update an existing note association
     * @param {number} id 
     * @param {NoteAssociationRequest} noteAssociationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteAssociationRestControllerApi
     */
    public updateNoteAssociation(id: number, noteAssociationRequest: NoteAssociationRequest, options?: RawAxiosRequestConfig) {
        return NoteAssociationRestControllerApiFp(this.configuration).updateNoteAssociation(id, noteAssociationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * NoteHistoryRestControllerApi - axios parameter creator
 * @export
 */
export const NoteHistoryRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieve the history of changes for a note by its ID.
         * @summary Get Note History
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoteHistory: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getNoteHistory', 'id', id)
            const localVarPath = `/api/notes/history/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve the history of changes for a note between specified dates.
         * @summary Get Note History Between Dates
         * @param {number} id 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoteHistoryBetweenDates: async (id: number, startDate: string, endDate: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getNoteHistoryBetweenDates', 'id', id)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getNoteHistoryBetweenDates', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getNoteHistoryBetweenDates', 'endDate', endDate)
            const localVarPath = `/api/notes/history/{id}/between-dates`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NoteHistoryRestControllerApi - functional programming interface
 * @export
 */
export const NoteHistoryRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NoteHistoryRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieve the history of changes for a note by its ID.
         * @summary Get Note History
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNoteHistory(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNoteHistory(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteHistoryRestControllerApi.getNoteHistory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve the history of changes for a note between specified dates.
         * @summary Get Note History Between Dates
         * @param {number} id 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNoteHistoryBetweenDates(id: number, startDate: string, endDate: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DomainPropertyChange>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNoteHistoryBetweenDates(id, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteHistoryRestControllerApi.getNoteHistoryBetweenDates']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * NoteHistoryRestControllerApi - factory interface
 * @export
 */
export const NoteHistoryRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NoteHistoryRestControllerApiFp(configuration)
    return {
        /**
         * Retrieve the history of changes for a note by its ID.
         * @summary Get Note History
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoteHistory(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.getNoteHistory(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve the history of changes for a note between specified dates.
         * @summary Get Note History Between Dates
         * @param {number} id 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoteHistoryBetweenDates(id: number, startDate: string, endDate: string, options?: any): AxiosPromise<Array<DomainPropertyChange>> {
            return localVarFp.getNoteHistoryBetweenDates(id, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NoteHistoryRestControllerApi - object-oriented interface
 * @export
 * @class NoteHistoryRestControllerApi
 * @extends {BaseAPI}
 */
export class NoteHistoryRestControllerApi extends BaseAPI {
    /**
     * Retrieve the history of changes for a note by its ID.
     * @summary Get Note History
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteHistoryRestControllerApi
     */
    public getNoteHistory(id: number, options?: RawAxiosRequestConfig) {
        return NoteHistoryRestControllerApiFp(this.configuration).getNoteHistory(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve the history of changes for a note between specified dates.
     * @summary Get Note History Between Dates
     * @param {number} id 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteHistoryRestControllerApi
     */
    public getNoteHistoryBetweenDates(id: number, startDate: string, endDate: string, options?: RawAxiosRequestConfig) {
        return NoteHistoryRestControllerApiFp(this.configuration).getNoteHistoryBetweenDates(id, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * NoteRestControllerApi - axios parameter creator
 * @export
 */
export const NoteRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new note with the provided details. Accessible by users with the \'ADMIN\' or \'USER\' roles.
         * @summary Create a new note
         * @param {NoteRequest} noteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNote: async (noteRequest: NoteRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'noteRequest' is not null or undefined
            assertParamExists('createNote', 'noteRequest', noteRequest)
            const localVarPath = `/api/notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(noteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the note specified by its ID. Accessible by users with the \'ADMIN\' or \'USER\' roles.
         * @summary Delete a note
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNote: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteNote', 'id', id)
            const localVarPath = `/api/notes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a paginated list of notes that have been modified between the specified start and end dates.
         * @summary Get notes modified between specified dates
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllNotesByDateModified: async (startDate: string, endDate: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getAllNotesByDateModified', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getAllNotesByDateModified', 'endDate', endDate)
            const localVarPath = `/api/notes/betweenDates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the details of a note specified by its ID.
         * @summary Get note by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoteById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getNoteById', 'id', id)
            const localVarPath = `/api/notes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] Optional search parameters. If \&#39;domainId\&#39; and \&#39;entityId\&#39; are specified, notes are returned that are associated with that domain type. Otherwise, the endpoint will search for notes containing the given search phrase. 
         * @param {string} [format]     Format of the notes. Use \&#39;verbose\&#39; for detailed notes.     Any other value or absence of this parameter returns a standard summary of notes. 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotes: async (search?: string, format?: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the count of notes that have been modified between the specified start and end dates.
         * @summary Get count of notes modified between specified dates
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotesCountByDateModified: async (startDate: string, endDate: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getNotesCountByDateModified', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getNotesCountByDateModified', 'endDate', endDate)
            const localVarPath = `/api/notes/countBetweenDates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an existing note with the provided details. Accessible by users with the \'ADMIN\' or \'USER\' roles.
         * @summary Update an existing note
         * @param {number} id 
         * @param {NoteRequest} noteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNote: async (id: number, noteRequest: NoteRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateNote', 'id', id)
            // verify required parameter 'noteRequest' is not null or undefined
            assertParamExists('updateNote', 'noteRequest', noteRequest)
            const localVarPath = `/api/notes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(noteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NoteRestControllerApi - functional programming interface
 * @export
 */
export const NoteRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NoteRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a new note with the provided details. Accessible by users with the \'ADMIN\' or \'USER\' roles.
         * @summary Create a new note
         * @param {NoteRequest} noteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNote(noteRequest: NoteRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNote(noteRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteRestControllerApi.createNote']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Deletes the note specified by its ID. Accessible by users with the \'ADMIN\' or \'USER\' roles.
         * @summary Delete a note
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNote(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNote(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteRestControllerApi.deleteNote']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns a paginated list of notes that have been modified between the specified start and end dates.
         * @summary Get notes modified between specified dates
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllNotesByDateModified(startDate: string, endDate: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageNoteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllNotesByDateModified(startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteRestControllerApi.getAllNotesByDateModified']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns the details of a note specified by its ID.
         * @summary Get note by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNoteById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNoteById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteRestControllerApi.getNoteById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] Optional search parameters. If \&#39;domainId\&#39; and \&#39;entityId\&#39; are specified, notes are returned that are associated with that domain type. Otherwise, the endpoint will search for notes containing the given search phrase. 
         * @param {string} [format]     Format of the notes. Use \&#39;verbose\&#39; for detailed notes.     Any other value or absence of this parameter returns a standard summary of notes. 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotes(search?: string, format?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageNoteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotes(search, format, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteRestControllerApi.getNotes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns the count of notes that have been modified between the specified start and end dates.
         * @summary Get count of notes modified between specified dates
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotesCountByDateModified(startDate: string, endDate: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotesCountByDateModified(startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteRestControllerApi.getNotesCountByDateModified']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates an existing note with the provided details. Accessible by users with the \'ADMIN\' or \'USER\' roles.
         * @summary Update an existing note
         * @param {number} id 
         * @param {NoteRequest} noteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNote(id: number, noteRequest: NoteRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNote(id, noteRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteRestControllerApi.updateNote']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * NoteRestControllerApi - factory interface
 * @export
 */
export const NoteRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NoteRestControllerApiFp(configuration)
    return {
        /**
         * Creates a new note with the provided details. Accessible by users with the \'ADMIN\' or \'USER\' roles.
         * @summary Create a new note
         * @param {NoteRequest} noteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNote(noteRequest: NoteRequest, options?: any): AxiosPromise<number> {
            return localVarFp.createNote(noteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes the note specified by its ID. Accessible by users with the \'ADMIN\' or \'USER\' roles.
         * @summary Delete a note
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNote(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteNote(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a paginated list of notes that have been modified between the specified start and end dates.
         * @summary Get notes modified between specified dates
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllNotesByDateModified(startDate: string, endDate: string, options?: any): AxiosPromise<PageNoteResponse> {
            return localVarFp.getAllNotesByDateModified(startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the details of a note specified by its ID.
         * @summary Get note by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoteById(id: number, options?: any): AxiosPromise<NoteResponse> {
            return localVarFp.getNoteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [search] Optional search parameters. If \&#39;domainId\&#39; and \&#39;entityId\&#39; are specified, notes are returned that are associated with that domain type. Otherwise, the endpoint will search for notes containing the given search phrase. 
         * @param {string} [format]     Format of the notes. Use \&#39;verbose\&#39; for detailed notes.     Any other value or absence of this parameter returns a standard summary of notes. 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotes(search?: string, format?: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageNoteResponse> {
            return localVarFp.getNotes(search, format, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the count of notes that have been modified between the specified start and end dates.
         * @summary Get count of notes modified between specified dates
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotesCountByDateModified(startDate: string, endDate: string, options?: any): AxiosPromise<number> {
            return localVarFp.getNotesCountByDateModified(startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates an existing note with the provided details. Accessible by users with the \'ADMIN\' or \'USER\' roles.
         * @summary Update an existing note
         * @param {number} id 
         * @param {NoteRequest} noteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNote(id: number, noteRequest: NoteRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateNote(id, noteRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NoteRestControllerApi - object-oriented interface
 * @export
 * @class NoteRestControllerApi
 * @extends {BaseAPI}
 */
export class NoteRestControllerApi extends BaseAPI {
    /**
     * Creates a new note with the provided details. Accessible by users with the \'ADMIN\' or \'USER\' roles.
     * @summary Create a new note
     * @param {NoteRequest} noteRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteRestControllerApi
     */
    public createNote(noteRequest: NoteRequest, options?: RawAxiosRequestConfig) {
        return NoteRestControllerApiFp(this.configuration).createNote(noteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes the note specified by its ID. Accessible by users with the \'ADMIN\' or \'USER\' roles.
     * @summary Delete a note
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteRestControllerApi
     */
    public deleteNote(id: number, options?: RawAxiosRequestConfig) {
        return NoteRestControllerApiFp(this.configuration).deleteNote(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a paginated list of notes that have been modified between the specified start and end dates.
     * @summary Get notes modified between specified dates
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteRestControllerApi
     */
    public getAllNotesByDateModified(startDate: string, endDate: string, options?: RawAxiosRequestConfig) {
        return NoteRestControllerApiFp(this.configuration).getAllNotesByDateModified(startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the details of a note specified by its ID.
     * @summary Get note by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteRestControllerApi
     */
    public getNoteById(id: number, options?: RawAxiosRequestConfig) {
        return NoteRestControllerApiFp(this.configuration).getNoteById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [search] Optional search parameters. If \&#39;domainId\&#39; and \&#39;entityId\&#39; are specified, notes are returned that are associated with that domain type. Otherwise, the endpoint will search for notes containing the given search phrase. 
     * @param {string} [format]     Format of the notes. Use \&#39;verbose\&#39; for detailed notes.     Any other value or absence of this parameter returns a standard summary of notes. 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteRestControllerApi
     */
    public getNotes(search?: string, format?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return NoteRestControllerApiFp(this.configuration).getNotes(search, format, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the count of notes that have been modified between the specified start and end dates.
     * @summary Get count of notes modified between specified dates
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteRestControllerApi
     */
    public getNotesCountByDateModified(startDate: string, endDate: string, options?: RawAxiosRequestConfig) {
        return NoteRestControllerApiFp(this.configuration).getNotesCountByDateModified(startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates an existing note with the provided details. Accessible by users with the \'ADMIN\' or \'USER\' roles.
     * @summary Update an existing note
     * @param {number} id 
     * @param {NoteRequest} noteRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteRestControllerApi
     */
    public updateNote(id: number, noteRequest: NoteRequest, options?: RawAxiosRequestConfig) {
        return NoteRestControllerApiFp(this.configuration).updateNote(id, noteRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * NoteSourceTypeRestControllerApi - axios parameter creator
 * @export
 */
export const NoteSourceTypeRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new note source type with the provided details. Requires ADMIN or USER role.
         * @summary Create a new note source type
         * @param {NoteSourceTypeCreateRequest} noteSourceTypeCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNoteSourceType: async (noteSourceTypeCreateRequest: NoteSourceTypeCreateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'noteSourceTypeCreateRequest' is not null or undefined
            assertParamExists('createNoteSourceType', 'noteSourceTypeCreateRequest', noteSourceTypeCreateRequest)
            const localVarPath = `/api/notes/sources/types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(noteSourceTypeCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a note source type by its unique ID. Requires ADMIN or USER role.
         * @summary Delete a note source type
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNoteSourceType: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteNoteSourceType', 'id', id)
            const localVarPath = `/api/notes/sources/types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a paginated list of all note source types.
         * @summary Get all note source types
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoteSourceType: async (filter?: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/notes/sources/types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a note source type by its unique ID.
         * @summary Get a note source type by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoteSourceTypeById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getNoteSourceTypeById', 'id', id)
            const localVarPath = `/api/notes/sources/types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing note source type with the provided details. Requires ADMIN or USER role.
         * @summary Update an existing note source type
         * @param {number} id 
         * @param {NoteSourceTypeCreateRequest} noteSourceTypeCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNoteSourceType: async (id: number, noteSourceTypeCreateRequest: NoteSourceTypeCreateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateNoteSourceType', 'id', id)
            // verify required parameter 'noteSourceTypeCreateRequest' is not null or undefined
            assertParamExists('updateNoteSourceType', 'noteSourceTypeCreateRequest', noteSourceTypeCreateRequest)
            const localVarPath = `/api/notes/sources/types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(noteSourceTypeCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NoteSourceTypeRestControllerApi - functional programming interface
 * @export
 */
export const NoteSourceTypeRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NoteSourceTypeRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new note source type with the provided details. Requires ADMIN or USER role.
         * @summary Create a new note source type
         * @param {NoteSourceTypeCreateRequest} noteSourceTypeCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNoteSourceType(noteSourceTypeCreateRequest: NoteSourceTypeCreateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNoteSourceType(noteSourceTypeCreateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteSourceTypeRestControllerApi.createNoteSourceType']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete a note source type by its unique ID. Requires ADMIN or USER role.
         * @summary Delete a note source type
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNoteSourceType(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNoteSourceType(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteSourceTypeRestControllerApi.deleteNoteSourceType']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve a paginated list of all note source types.
         * @summary Get all note source types
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNoteSourceType(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageNoteSourceTypeCreateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNoteSourceType(filter, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteSourceTypeRestControllerApi.getNoteSourceType']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve a note source type by its unique ID.
         * @summary Get a note source type by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNoteSourceTypeById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoteSourceTypeCreateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNoteSourceTypeById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteSourceTypeRestControllerApi.getNoteSourceTypeById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an existing note source type with the provided details. Requires ADMIN or USER role.
         * @summary Update an existing note source type
         * @param {number} id 
         * @param {NoteSourceTypeCreateRequest} noteSourceTypeCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNoteSourceType(id: number, noteSourceTypeCreateRequest: NoteSourceTypeCreateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNoteSourceType(id, noteSourceTypeCreateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NoteSourceTypeRestControllerApi.updateNoteSourceType']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * NoteSourceTypeRestControllerApi - factory interface
 * @export
 */
export const NoteSourceTypeRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NoteSourceTypeRestControllerApiFp(configuration)
    return {
        /**
         * Create a new note source type with the provided details. Requires ADMIN or USER role.
         * @summary Create a new note source type
         * @param {NoteSourceTypeCreateRequest} noteSourceTypeCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNoteSourceType(noteSourceTypeCreateRequest: NoteSourceTypeCreateRequest, options?: any): AxiosPromise<number> {
            return localVarFp.createNoteSourceType(noteSourceTypeCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a note source type by its unique ID. Requires ADMIN or USER role.
         * @summary Delete a note source type
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNoteSourceType(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteNoteSourceType(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a paginated list of all note source types.
         * @summary Get all note source types
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoteSourceType(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageNoteSourceTypeCreateResponse> {
            return localVarFp.getNoteSourceType(filter, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a note source type by its unique ID.
         * @summary Get a note source type by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoteSourceTypeById(id: number, options?: any): AxiosPromise<NoteSourceTypeCreateResponse> {
            return localVarFp.getNoteSourceTypeById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing note source type with the provided details. Requires ADMIN or USER role.
         * @summary Update an existing note source type
         * @param {number} id 
         * @param {NoteSourceTypeCreateRequest} noteSourceTypeCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNoteSourceType(id: number, noteSourceTypeCreateRequest: NoteSourceTypeCreateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateNoteSourceType(id, noteSourceTypeCreateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NoteSourceTypeRestControllerApi - object-oriented interface
 * @export
 * @class NoteSourceTypeRestControllerApi
 * @extends {BaseAPI}
 */
export class NoteSourceTypeRestControllerApi extends BaseAPI {
    /**
     * Create a new note source type with the provided details. Requires ADMIN or USER role.
     * @summary Create a new note source type
     * @param {NoteSourceTypeCreateRequest} noteSourceTypeCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteSourceTypeRestControllerApi
     */
    public createNoteSourceType(noteSourceTypeCreateRequest: NoteSourceTypeCreateRequest, options?: RawAxiosRequestConfig) {
        return NoteSourceTypeRestControllerApiFp(this.configuration).createNoteSourceType(noteSourceTypeCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a note source type by its unique ID. Requires ADMIN or USER role.
     * @summary Delete a note source type
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteSourceTypeRestControllerApi
     */
    public deleteNoteSourceType(id: number, options?: RawAxiosRequestConfig) {
        return NoteSourceTypeRestControllerApiFp(this.configuration).deleteNoteSourceType(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a paginated list of all note source types.
     * @summary Get all note source types
     * @param {string} [filter] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteSourceTypeRestControllerApi
     */
    public getNoteSourceType(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return NoteSourceTypeRestControllerApiFp(this.configuration).getNoteSourceType(filter, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a note source type by its unique ID.
     * @summary Get a note source type by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteSourceTypeRestControllerApi
     */
    public getNoteSourceTypeById(id: number, options?: RawAxiosRequestConfig) {
        return NoteSourceTypeRestControllerApiFp(this.configuration).getNoteSourceTypeById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing note source type with the provided details. Requires ADMIN or USER role.
     * @summary Update an existing note source type
     * @param {number} id 
     * @param {NoteSourceTypeCreateRequest} noteSourceTypeCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteSourceTypeRestControllerApi
     */
    public updateNoteSourceType(id: number, noteSourceTypeCreateRequest: NoteSourceTypeCreateRequest, options?: RawAxiosRequestConfig) {
        return NoteSourceTypeRestControllerApiFp(this.configuration).updateNoteSourceType(id, noteSourceTypeCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PasswordResetControllerApi - axios parameter creator
 * @export
 */
export const PasswordResetControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (email: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('resetPassword', 'email', email)
            const localVarPath = `/api/users/password-reset/request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {PasswordResetRequest} passwordResetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword: async (token: string, passwordResetRequest: PasswordResetRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('updatePassword', 'token', token)
            // verify required parameter 'passwordResetRequest' is not null or undefined
            assertParamExists('updatePassword', 'passwordResetRequest', passwordResetRequest)
            const localVarPath = `/api/users/password-reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordResetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateToken: async (token: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('validateToken', 'token', token)
            const localVarPath = `/api/users/password-reset/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PasswordResetControllerApi - functional programming interface
 * @export
 */
export const PasswordResetControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PasswordResetControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(email: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(email, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PasswordResetControllerApi.resetPassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} token 
         * @param {PasswordResetRequest} passwordResetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePassword(token: string, passwordResetRequest: PasswordResetRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PasswordResetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePassword(token, passwordResetRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PasswordResetControllerApi.updatePassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateToken(token: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateToken(token, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PasswordResetControllerApi.validateToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PasswordResetControllerApi - factory interface
 * @export
 */
export const PasswordResetControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PasswordResetControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(email: string, options?: any): AxiosPromise<string> {
            return localVarFp.resetPassword(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {PasswordResetRequest} passwordResetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword(token: string, passwordResetRequest: PasswordResetRequest, options?: any): AxiosPromise<PasswordResetResponse> {
            return localVarFp.updatePassword(token, passwordResetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateToken(token: string, options?: any): AxiosPromise<string> {
            return localVarFp.validateToken(token, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PasswordResetControllerApi - object-oriented interface
 * @export
 * @class PasswordResetControllerApi
 * @extends {BaseAPI}
 */
export class PasswordResetControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordResetControllerApi
     */
    public resetPassword(email: string, options?: RawAxiosRequestConfig) {
        return PasswordResetControllerApiFp(this.configuration).resetPassword(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} token 
     * @param {PasswordResetRequest} passwordResetRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordResetControllerApi
     */
    public updatePassword(token: string, passwordResetRequest: PasswordResetRequest, options?: RawAxiosRequestConfig) {
        return PasswordResetControllerApiFp(this.configuration).updatePassword(token, passwordResetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordResetControllerApi
     */
    public validateToken(token: string, options?: RawAxiosRequestConfig) {
        return PasswordResetControllerApiFp(this.configuration).validateToken(token, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RegulatoryRestControllerApi - axios parameter creator
 * @export
 */
export const RegulatoryRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new Regulatory entity. Only users with ADMIN or USER roles can access this endpoint.
         * @summary Create Regulatory
         * @param {RegulatoryRequest} regulatoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRegulatory: async (regulatoryRequest: RegulatoryRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'regulatoryRequest' is not null or undefined
            assertParamExists('createRegulatory', 'regulatoryRequest', regulatoryRequest)
            const localVarPath = `/api/regulatory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(regulatoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetches a paginated list of regulatory items. The list is sorted by the date it was last modified in descending order by default. 
         * @summary Retrieve paginated list of regulatory items
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegulatory: async (page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/regulatory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve Regulatory data by its unique identifier.
         * @summary Get Regulatory by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegulatoryById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRegulatoryById', 'id', id)
            const localVarPath = `/api/regulatory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get notes associated with a regulatory data.
         * @summary Get regulatory Notes
         * @param {number} id 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegulatoryNotes: async (id: number, pageable: Pageable, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRegulatoryNotes', 'id', id)
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getRegulatoryNotes', 'pageable', pageable)
            const localVarPath = `/api/regulatory/{id}/notes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing Regulatory entity by its unique identifier. Only users with ADMIN or USER roles can access this endpoint.
         * @summary Update Regulatory
         * @param {number} id 
         * @param {RegulatoryRequest} regulatoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRegulatory: async (id: number, regulatoryRequest: RegulatoryRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateRegulatory', 'id', id)
            // verify required parameter 'regulatoryRequest' is not null or undefined
            assertParamExists('updateRegulatory', 'regulatoryRequest', regulatoryRequest)
            const localVarPath = `/api/regulatory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(regulatoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RegulatoryRestControllerApi - functional programming interface
 * @export
 */
export const RegulatoryRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RegulatoryRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new Regulatory entity. Only users with ADMIN or USER roles can access this endpoint.
         * @summary Create Regulatory
         * @param {RegulatoryRequest} regulatoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRegulatory(regulatoryRequest: RegulatoryRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRegulatory(regulatoryRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RegulatoryRestControllerApi.createRegulatory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Fetches a paginated list of regulatory items. The list is sorted by the date it was last modified in descending order by default. 
         * @summary Retrieve paginated list of regulatory items
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegulatory(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageRegulatoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegulatory(page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RegulatoryRestControllerApi.getRegulatory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve Regulatory data by its unique identifier.
         * @summary Get Regulatory by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegulatoryById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegulatoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegulatoryById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RegulatoryRestControllerApi.getRegulatoryById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get notes associated with a regulatory data.
         * @summary Get regulatory Notes
         * @param {number} id 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegulatoryNotes(id: number, pageable: Pageable, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageNoteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegulatoryNotes(id, pageable, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RegulatoryRestControllerApi.getRegulatoryNotes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an existing Regulatory entity by its unique identifier. Only users with ADMIN or USER roles can access this endpoint.
         * @summary Update Regulatory
         * @param {number} id 
         * @param {RegulatoryRequest} regulatoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRegulatory(id: number, regulatoryRequest: RegulatoryRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRegulatory(id, regulatoryRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RegulatoryRestControllerApi.updateRegulatory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RegulatoryRestControllerApi - factory interface
 * @export
 */
export const RegulatoryRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RegulatoryRestControllerApiFp(configuration)
    return {
        /**
         * Create a new Regulatory entity. Only users with ADMIN or USER roles can access this endpoint.
         * @summary Create Regulatory
         * @param {RegulatoryRequest} regulatoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRegulatory(regulatoryRequest: RegulatoryRequest, options?: any): AxiosPromise<number> {
            return localVarFp.createRegulatory(regulatoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetches a paginated list of regulatory items. The list is sorted by the date it was last modified in descending order by default. 
         * @summary Retrieve paginated list of regulatory items
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegulatory(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageRegulatoryResponse> {
            return localVarFp.getRegulatory(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve Regulatory data by its unique identifier.
         * @summary Get Regulatory by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegulatoryById(id: number, options?: any): AxiosPromise<RegulatoryResponse> {
            return localVarFp.getRegulatoryById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get notes associated with a regulatory data.
         * @summary Get regulatory Notes
         * @param {number} id 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegulatoryNotes(id: number, pageable: Pageable, options?: any): AxiosPromise<PageNoteResponse> {
            return localVarFp.getRegulatoryNotes(id, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing Regulatory entity by its unique identifier. Only users with ADMIN or USER roles can access this endpoint.
         * @summary Update Regulatory
         * @param {number} id 
         * @param {RegulatoryRequest} regulatoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRegulatory(id: number, regulatoryRequest: RegulatoryRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateRegulatory(id, regulatoryRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RegulatoryRestControllerApi - object-oriented interface
 * @export
 * @class RegulatoryRestControllerApi
 * @extends {BaseAPI}
 */
export class RegulatoryRestControllerApi extends BaseAPI {
    /**
     * Create a new Regulatory entity. Only users with ADMIN or USER roles can access this endpoint.
     * @summary Create Regulatory
     * @param {RegulatoryRequest} regulatoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegulatoryRestControllerApi
     */
    public createRegulatory(regulatoryRequest: RegulatoryRequest, options?: RawAxiosRequestConfig) {
        return RegulatoryRestControllerApiFp(this.configuration).createRegulatory(regulatoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetches a paginated list of regulatory items. The list is sorted by the date it was last modified in descending order by default. 
     * @summary Retrieve paginated list of regulatory items
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegulatoryRestControllerApi
     */
    public getRegulatory(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return RegulatoryRestControllerApiFp(this.configuration).getRegulatory(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve Regulatory data by its unique identifier.
     * @summary Get Regulatory by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegulatoryRestControllerApi
     */
    public getRegulatoryById(id: number, options?: RawAxiosRequestConfig) {
        return RegulatoryRestControllerApiFp(this.configuration).getRegulatoryById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get notes associated with a regulatory data.
     * @summary Get regulatory Notes
     * @param {number} id 
     * @param {Pageable} pageable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegulatoryRestControllerApi
     */
    public getRegulatoryNotes(id: number, pageable: Pageable, options?: RawAxiosRequestConfig) {
        return RegulatoryRestControllerApiFp(this.configuration).getRegulatoryNotes(id, pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing Regulatory entity by its unique identifier. Only users with ADMIN or USER roles can access this endpoint.
     * @summary Update Regulatory
     * @param {number} id 
     * @param {RegulatoryRequest} regulatoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegulatoryRestControllerApi
     */
    public updateRegulatory(id: number, regulatoryRequest: RegulatoryRequest, options?: RawAxiosRequestConfig) {
        return RegulatoryRestControllerApiFp(this.configuration).updateRegulatory(id, regulatoryRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SearchRestControllerApi - axios parameter creator
 * @export
 */
export const SearchRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Searches for MNAs and Notes based on the provided query string, including tags.
         * @summary Search for MNAs and Notes
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search: async (query: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'query' is not null or undefined
            assertParamExists('search', 'query', query)
            const localVarPath = `/api/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchRestControllerApi - functional programming interface
 * @export
 */
export const SearchRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SearchRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Searches for MNAs and Notes based on the provided query string, including tags.
         * @summary Search for MNAs and Notes
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async search(query: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.search(query, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SearchRestControllerApi.search']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SearchRestControllerApi - factory interface
 * @export
 */
export const SearchRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SearchRestControllerApiFp(configuration)
    return {
        /**
         * Searches for MNAs and Notes based on the provided query string, including tags.
         * @summary Search for MNAs and Notes
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search(query: string, options?: any): AxiosPromise<SearchResponse> {
            return localVarFp.search(query, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SearchRestControllerApi - object-oriented interface
 * @export
 * @class SearchRestControllerApi
 * @extends {BaseAPI}
 */
export class SearchRestControllerApi extends BaseAPI {
    /**
     * Searches for MNAs and Notes based on the provided query string, including tags.
     * @summary Search for MNAs and Notes
     * @param {string} query 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchRestControllerApi
     */
    public search(query: string, options?: RawAxiosRequestConfig) {
        return SearchRestControllerApiFp(this.configuration).search(query, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SectorRestControllerApi - axios parameter creator
 * @export
 */
export const SectorRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new sector with the provided details. Accessible by ADMIN and USER roles.
         * @summary Create a new sector
         * @param {SectorRequest} sectorRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSector: async (sectorRequest: SectorRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sectorRequest' is not null or undefined
            assertParamExists('createSector', 'sectorRequest', sectorRequest)
            const localVarPath = `/api/sectors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sectorRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a sector by its unique identifier. Accessible by ADMIN and USER roles.
         * @summary Delete a sector
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSector: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSector', 'id', id)
            const localVarPath = `/api/sectors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a sector by its unique identifier.
         * @summary Get sector by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSectorById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSectorById', 'id', id)
            const localVarPath = `/api/sectors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a paginated list of all sectors.
         * @summary Get all sectors
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSectors: async (filter?: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/sectors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing sector with the provided details. Accessible by ADMIN and USER roles.
         * @summary Update a sector
         * @param {number} id 
         * @param {SectorRequest} sectorRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSector: async (id: number, sectorRequest: SectorRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSector', 'id', id)
            // verify required parameter 'sectorRequest' is not null or undefined
            assertParamExists('updateSector', 'sectorRequest', sectorRequest)
            const localVarPath = `/api/sectors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sectorRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SectorRestControllerApi - functional programming interface
 * @export
 */
export const SectorRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SectorRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new sector with the provided details. Accessible by ADMIN and USER roles.
         * @summary Create a new sector
         * @param {SectorRequest} sectorRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSector(sectorRequest: SectorRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSector(sectorRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SectorRestControllerApi.createSector']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete a sector by its unique identifier. Accessible by ADMIN and USER roles.
         * @summary Delete a sector
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSector(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSector(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SectorRestControllerApi.deleteSector']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve a sector by its unique identifier.
         * @summary Get sector by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSectorById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSectorById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SectorRestControllerApi.getSectorById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve a paginated list of all sectors.
         * @summary Get all sectors
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSectors(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageSectorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSectors(filter, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SectorRestControllerApi.getSectors']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an existing sector with the provided details. Accessible by ADMIN and USER roles.
         * @summary Update a sector
         * @param {number} id 
         * @param {SectorRequest} sectorRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSector(id: number, sectorRequest: SectorRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSector(id, sectorRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SectorRestControllerApi.updateSector']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SectorRestControllerApi - factory interface
 * @export
 */
export const SectorRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SectorRestControllerApiFp(configuration)
    return {
        /**
         * Create a new sector with the provided details. Accessible by ADMIN and USER roles.
         * @summary Create a new sector
         * @param {SectorRequest} sectorRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSector(sectorRequest: SectorRequest, options?: any): AxiosPromise<number> {
            return localVarFp.createSector(sectorRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a sector by its unique identifier. Accessible by ADMIN and USER roles.
         * @summary Delete a sector
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSector(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSector(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a sector by its unique identifier.
         * @summary Get sector by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSectorById(id: number, options?: any): AxiosPromise<SectorResponse> {
            return localVarFp.getSectorById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a paginated list of all sectors.
         * @summary Get all sectors
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSectors(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageSectorResponse> {
            return localVarFp.getSectors(filter, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing sector with the provided details. Accessible by ADMIN and USER roles.
         * @summary Update a sector
         * @param {number} id 
         * @param {SectorRequest} sectorRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSector(id: number, sectorRequest: SectorRequest, options?: any): AxiosPromise<number> {
            return localVarFp.updateSector(id, sectorRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SectorRestControllerApi - object-oriented interface
 * @export
 * @class SectorRestControllerApi
 * @extends {BaseAPI}
 */
export class SectorRestControllerApi extends BaseAPI {
    /**
     * Create a new sector with the provided details. Accessible by ADMIN and USER roles.
     * @summary Create a new sector
     * @param {SectorRequest} sectorRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectorRestControllerApi
     */
    public createSector(sectorRequest: SectorRequest, options?: RawAxiosRequestConfig) {
        return SectorRestControllerApiFp(this.configuration).createSector(sectorRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a sector by its unique identifier. Accessible by ADMIN and USER roles.
     * @summary Delete a sector
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectorRestControllerApi
     */
    public deleteSector(id: number, options?: RawAxiosRequestConfig) {
        return SectorRestControllerApiFp(this.configuration).deleteSector(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a sector by its unique identifier.
     * @summary Get sector by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectorRestControllerApi
     */
    public getSectorById(id: number, options?: RawAxiosRequestConfig) {
        return SectorRestControllerApiFp(this.configuration).getSectorById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a paginated list of all sectors.
     * @summary Get all sectors
     * @param {string} [filter] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectorRestControllerApi
     */
    public getSectors(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return SectorRestControllerApiFp(this.configuration).getSectors(filter, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing sector with the provided details. Accessible by ADMIN and USER roles.
     * @summary Update a sector
     * @param {number} id 
     * @param {SectorRequest} sectorRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectorRestControllerApi
     */
    public updateSector(id: number, sectorRequest: SectorRequest, options?: RawAxiosRequestConfig) {
        return SectorRestControllerApiFp(this.configuration).updateSector(id, sectorRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TagAssociationRestControllerApi - axios parameter creator
 * @export
 */
export const TagAssociationRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create multiple tag associations at once. Only users with ADMIN or USER roles can access this API.
         * @summary Create multiple tag associations
         * @param {Array<TagAssociationRequest>} tagAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMultiTagAssociation: async (tagAssociationRequest: Array<TagAssociationRequest>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagAssociationRequest' is not null or undefined
            assertParamExists('createMultiTagAssociation', 'tagAssociationRequest', tagAssociationRequest)
            const localVarPath = `/api/tags/assoc/multi`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tagAssociationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new tag association. Only users with ADMIN or USER roles can access this API.
         * @summary Create tag association
         * @param {TagAssociationRequest} tagAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTagAssociation: async (tagAssociationRequest: TagAssociationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagAssociationRequest' is not null or undefined
            assertParamExists('createTagAssociation', 'tagAssociationRequest', tagAssociationRequest)
            const localVarPath = `/api/tags/assoc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tagAssociationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete multiple tag associations by their IDs. Only users with ADMIN or USER roles can access this API.
         * @summary Delete multiple tag associations
         * @param {Array<number>} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllTagAssociation: async (id: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAllTagAssociation', 'id', id)
            const localVarPath = `/api/tags/assoc/multi`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a tag association by its ID. Only users with ADMIN or USER roles can access this API.
         * @summary Delete tag association
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTagAssociation: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTagAssociation', 'id', id)
            const localVarPath = `/api/tags/assoc/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a tag association by its unique identifier.
         * @summary Get tag association by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagAssociationById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTagAssociationById', 'id', id)
            const localVarPath = `/api/tags/assoc/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a paginated list of tag associations.
         * @summary Get all tag associations
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagAssociations: async (filter?: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tags/assoc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing tag association. Only users with ADMIN or USER roles can access this API.
         * @summary Update tag association
         * @param {number} id 
         * @param {TagAssociationRequest} tagAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTagAssociation: async (id: number, tagAssociationRequest: TagAssociationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTagAssociation', 'id', id)
            // verify required parameter 'tagAssociationRequest' is not null or undefined
            assertParamExists('updateTagAssociation', 'tagAssociationRequest', tagAssociationRequest)
            const localVarPath = `/api/tags/assoc/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tagAssociationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TagAssociationRestControllerApi - functional programming interface
 * @export
 */
export const TagAssociationRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TagAssociationRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Create multiple tag associations at once. Only users with ADMIN or USER roles can access this API.
         * @summary Create multiple tag associations
         * @param {Array<TagAssociationRequest>} tagAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMultiTagAssociation(tagAssociationRequest: Array<TagAssociationRequest>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMultiTagAssociation(tagAssociationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TagAssociationRestControllerApi.createMultiTagAssociation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create a new tag association. Only users with ADMIN or USER roles can access this API.
         * @summary Create tag association
         * @param {TagAssociationRequest} tagAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTagAssociation(tagAssociationRequest: TagAssociationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTagAssociation(tagAssociationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TagAssociationRestControllerApi.createTagAssociation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete multiple tag associations by their IDs. Only users with ADMIN or USER roles can access this API.
         * @summary Delete multiple tag associations
         * @param {Array<number>} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAllTagAssociation(id: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAllTagAssociation(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TagAssociationRestControllerApi.deleteAllTagAssociation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete a tag association by its ID. Only users with ADMIN or USER roles can access this API.
         * @summary Delete tag association
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTagAssociation(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTagAssociation(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TagAssociationRestControllerApi.deleteTagAssociation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve a tag association by its unique identifier.
         * @summary Get tag association by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTagAssociationById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagAssociationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTagAssociationById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TagAssociationRestControllerApi.getTagAssociationById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve a paginated list of tag associations.
         * @summary Get all tag associations
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTagAssociations(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTagAssociationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTagAssociations(filter, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TagAssociationRestControllerApi.getTagAssociations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an existing tag association. Only users with ADMIN or USER roles can access this API.
         * @summary Update tag association
         * @param {number} id 
         * @param {TagAssociationRequest} tagAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTagAssociation(id: number, tagAssociationRequest: TagAssociationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTagAssociation(id, tagAssociationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TagAssociationRestControllerApi.updateTagAssociation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TagAssociationRestControllerApi - factory interface
 * @export
 */
export const TagAssociationRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TagAssociationRestControllerApiFp(configuration)
    return {
        /**
         * Create multiple tag associations at once. Only users with ADMIN or USER roles can access this API.
         * @summary Create multiple tag associations
         * @param {Array<TagAssociationRequest>} tagAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMultiTagAssociation(tagAssociationRequest: Array<TagAssociationRequest>, options?: any): AxiosPromise<Array<number>> {
            return localVarFp.createMultiTagAssociation(tagAssociationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new tag association. Only users with ADMIN or USER roles can access this API.
         * @summary Create tag association
         * @param {TagAssociationRequest} tagAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTagAssociation(tagAssociationRequest: TagAssociationRequest, options?: any): AxiosPromise<number> {
            return localVarFp.createTagAssociation(tagAssociationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete multiple tag associations by their IDs. Only users with ADMIN or USER roles can access this API.
         * @summary Delete multiple tag associations
         * @param {Array<number>} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllTagAssociation(id: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAllTagAssociation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a tag association by its ID. Only users with ADMIN or USER roles can access this API.
         * @summary Delete tag association
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTagAssociation(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTagAssociation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a tag association by its unique identifier.
         * @summary Get tag association by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagAssociationById(id: number, options?: any): AxiosPromise<TagAssociationResponse> {
            return localVarFp.getTagAssociationById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a paginated list of tag associations.
         * @summary Get all tag associations
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagAssociations(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageTagAssociationResponse> {
            return localVarFp.getTagAssociations(filter, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing tag association. Only users with ADMIN or USER roles can access this API.
         * @summary Update tag association
         * @param {number} id 
         * @param {TagAssociationRequest} tagAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTagAssociation(id: number, tagAssociationRequest: TagAssociationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateTagAssociation(id, tagAssociationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TagAssociationRestControllerApi - object-oriented interface
 * @export
 * @class TagAssociationRestControllerApi
 * @extends {BaseAPI}
 */
export class TagAssociationRestControllerApi extends BaseAPI {
    /**
     * Create multiple tag associations at once. Only users with ADMIN or USER roles can access this API.
     * @summary Create multiple tag associations
     * @param {Array<TagAssociationRequest>} tagAssociationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagAssociationRestControllerApi
     */
    public createMultiTagAssociation(tagAssociationRequest: Array<TagAssociationRequest>, options?: RawAxiosRequestConfig) {
        return TagAssociationRestControllerApiFp(this.configuration).createMultiTagAssociation(tagAssociationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new tag association. Only users with ADMIN or USER roles can access this API.
     * @summary Create tag association
     * @param {TagAssociationRequest} tagAssociationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagAssociationRestControllerApi
     */
    public createTagAssociation(tagAssociationRequest: TagAssociationRequest, options?: RawAxiosRequestConfig) {
        return TagAssociationRestControllerApiFp(this.configuration).createTagAssociation(tagAssociationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete multiple tag associations by their IDs. Only users with ADMIN or USER roles can access this API.
     * @summary Delete multiple tag associations
     * @param {Array<number>} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagAssociationRestControllerApi
     */
    public deleteAllTagAssociation(id: Array<number>, options?: RawAxiosRequestConfig) {
        return TagAssociationRestControllerApiFp(this.configuration).deleteAllTagAssociation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a tag association by its ID. Only users with ADMIN or USER roles can access this API.
     * @summary Delete tag association
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagAssociationRestControllerApi
     */
    public deleteTagAssociation(id: number, options?: RawAxiosRequestConfig) {
        return TagAssociationRestControllerApiFp(this.configuration).deleteTagAssociation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a tag association by its unique identifier.
     * @summary Get tag association by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagAssociationRestControllerApi
     */
    public getTagAssociationById(id: number, options?: RawAxiosRequestConfig) {
        return TagAssociationRestControllerApiFp(this.configuration).getTagAssociationById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a paginated list of tag associations.
     * @summary Get all tag associations
     * @param {string} [filter] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagAssociationRestControllerApi
     */
    public getTagAssociations(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return TagAssociationRestControllerApiFp(this.configuration).getTagAssociations(filter, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing tag association. Only users with ADMIN or USER roles can access this API.
     * @summary Update tag association
     * @param {number} id 
     * @param {TagAssociationRequest} tagAssociationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagAssociationRestControllerApi
     */
    public updateTagAssociation(id: number, tagAssociationRequest: TagAssociationRequest, options?: RawAxiosRequestConfig) {
        return TagAssociationRestControllerApiFp(this.configuration).updateTagAssociation(id, tagAssociationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TagRestContollerApi - axios parameter creator
 * @export
 */
export const TagRestContollerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new tag. Requires ADMIN or USER role.
         * @summary Create tag
         * @param {TagRequest} tagRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTag: async (tagRequest: TagRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagRequest' is not null or undefined
            assertParamExists('createTag', 'tagRequest', tagRequest)
            const localVarPath = `/api/tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tagRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a tag by its ID. Requires ADMIN or USER role.
         * @summary Delete tag
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTag: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTag', 'id', id)
            const localVarPath = `/api/tags/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a tag by its ID.
         * @summary Get tag by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTagById', 'id', id)
            const localVarPath = `/api/tags/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a paginated list of tags.
         * @summary Get tags
         * @param {string} [filter] Filter tags by name. If provided, only tags containing the filter string will be returned.
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTags: async (filter?: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an existing tag. Requires ADMIN or USER role.
         * @summary Update tag
         * @param {number} id 
         * @param {TagRequest} tagRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTag: async (id: number, tagRequest: TagRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTag', 'id', id)
            // verify required parameter 'tagRequest' is not null or undefined
            assertParamExists('updateTag', 'tagRequest', tagRequest)
            const localVarPath = `/api/tags/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tagRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TagRestContollerApi - functional programming interface
 * @export
 */
export const TagRestContollerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TagRestContollerApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new tag. Requires ADMIN or USER role.
         * @summary Create tag
         * @param {TagRequest} tagRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTag(tagRequest: TagRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTag(tagRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TagRestContollerApi.createTag']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete a tag by its ID. Requires ADMIN or USER role.
         * @summary Delete tag
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTag(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTag(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TagRestContollerApi.deleteTag']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve a tag by its ID.
         * @summary Get tag by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTagById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTagById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TagRestContollerApi.getTagById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve a paginated list of tags.
         * @summary Get tags
         * @param {string} [filter] Filter tags by name. If provided, only tags containing the filter string will be returned.
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTags(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTagResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTags(filter, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TagRestContollerApi.getTags']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update an existing tag. Requires ADMIN or USER role.
         * @summary Update tag
         * @param {number} id 
         * @param {TagRequest} tagRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTag(id: number, tagRequest: TagRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTag(id, tagRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TagRestContollerApi.updateTag']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TagRestContollerApi - factory interface
 * @export
 */
export const TagRestContollerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TagRestContollerApiFp(configuration)
    return {
        /**
         * Create a new tag. Requires ADMIN or USER role.
         * @summary Create tag
         * @param {TagRequest} tagRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTag(tagRequest: TagRequest, options?: any): AxiosPromise<number> {
            return localVarFp.createTag(tagRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a tag by its ID. Requires ADMIN or USER role.
         * @summary Delete tag
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTag(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTag(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a tag by its ID.
         * @summary Get tag by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagById(id: number, options?: any): AxiosPromise<TagResponse> {
            return localVarFp.getTagById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a paginated list of tags.
         * @summary Get tags
         * @param {string} [filter] Filter tags by name. If provided, only tags containing the filter string will be returned.
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTags(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageTagResponse> {
            return localVarFp.getTags(filter, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an existing tag. Requires ADMIN or USER role.
         * @summary Update tag
         * @param {number} id 
         * @param {TagRequest} tagRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTag(id: number, tagRequest: TagRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateTag(id, tagRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TagRestContollerApi - object-oriented interface
 * @export
 * @class TagRestContollerApi
 * @extends {BaseAPI}
 */
export class TagRestContollerApi extends BaseAPI {
    /**
     * Create a new tag. Requires ADMIN or USER role.
     * @summary Create tag
     * @param {TagRequest} tagRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagRestContollerApi
     */
    public createTag(tagRequest: TagRequest, options?: RawAxiosRequestConfig) {
        return TagRestContollerApiFp(this.configuration).createTag(tagRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a tag by its ID. Requires ADMIN or USER role.
     * @summary Delete tag
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagRestContollerApi
     */
    public deleteTag(id: number, options?: RawAxiosRequestConfig) {
        return TagRestContollerApiFp(this.configuration).deleteTag(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a tag by its ID.
     * @summary Get tag by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagRestContollerApi
     */
    public getTagById(id: number, options?: RawAxiosRequestConfig) {
        return TagRestContollerApiFp(this.configuration).getTagById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a paginated list of tags.
     * @summary Get tags
     * @param {string} [filter] Filter tags by name. If provided, only tags containing the filter string will be returned.
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagRestContollerApi
     */
    public getTags(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return TagRestContollerApiFp(this.configuration).getTags(filter, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an existing tag. Requires ADMIN or USER role.
     * @summary Update tag
     * @param {number} id 
     * @param {TagRequest} tagRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagRestContollerApi
     */
    public updateTag(id: number, tagRequest: TagRequest, options?: RawAxiosRequestConfig) {
        return TagRestContollerApiFp(this.configuration).updateTag(id, tagRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TxDocSummaryRestControllerApi - axios parameter creator
 * @export
 */
export const TxDocSummaryRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {TxDocSummaryRequest} txDocSummaryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTxDocSummary: async (txDocSummaryRequest: TxDocSummaryRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'txDocSummaryRequest' is not null or undefined
            assertParamExists('createTxDocSummary', 'txDocSummaryRequest', txDocSummaryRequest)
            const localVarPath = `/api/mnas/tx-doc-summaries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(txDocSummaryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTxDocSummary: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTxDocSummary', 'id', id)
            const localVarPath = `/api/mnas/tx-doc-summaries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTxDocSummaries: async (page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/mnas/tx-doc-summaries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTxDocSummaryById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTxDocSummaryById', 'id', id)
            const localVarPath = `/api/mnas/tx-doc-summaries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {TxDocSummaryRequest} txDocSummaryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTxDocSummary: async (id: number, txDocSummaryRequest: TxDocSummaryRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTxDocSummary', 'id', id)
            // verify required parameter 'txDocSummaryRequest' is not null or undefined
            assertParamExists('updateTxDocSummary', 'txDocSummaryRequest', txDocSummaryRequest)
            const localVarPath = `/api/mnas/tx-doc-summaries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(txDocSummaryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TxDocSummaryRestControllerApi - functional programming interface
 * @export
 */
export const TxDocSummaryRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TxDocSummaryRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {TxDocSummaryRequest} txDocSummaryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTxDocSummary(txDocSummaryRequest: TxDocSummaryRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTxDocSummary(txDocSummaryRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TxDocSummaryRestControllerApi.createTxDocSummary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTxDocSummary(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTxDocSummary(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TxDocSummaryRestControllerApi.deleteTxDocSummary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllTxDocSummaries(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTxDocSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllTxDocSummaries(page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TxDocSummaryRestControllerApi.getAllTxDocSummaries']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTxDocSummaryById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TxDocSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTxDocSummaryById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TxDocSummaryRestControllerApi.getTxDocSummaryById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {TxDocSummaryRequest} txDocSummaryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTxDocSummary(id: number, txDocSummaryRequest: TxDocSummaryRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTxDocSummary(id, txDocSummaryRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TxDocSummaryRestControllerApi.updateTxDocSummary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TxDocSummaryRestControllerApi - factory interface
 * @export
 */
export const TxDocSummaryRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TxDocSummaryRestControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {TxDocSummaryRequest} txDocSummaryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTxDocSummary(txDocSummaryRequest: TxDocSummaryRequest, options?: any): AxiosPromise<number> {
            return localVarFp.createTxDocSummary(txDocSummaryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTxDocSummary(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTxDocSummary(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTxDocSummaries(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageTxDocSummaryResponse> {
            return localVarFp.getAllTxDocSummaries(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTxDocSummaryById(id: number, options?: any): AxiosPromise<TxDocSummaryResponse> {
            return localVarFp.getTxDocSummaryById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {TxDocSummaryRequest} txDocSummaryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTxDocSummary(id: number, txDocSummaryRequest: TxDocSummaryRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateTxDocSummary(id, txDocSummaryRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TxDocSummaryRestControllerApi - object-oriented interface
 * @export
 * @class TxDocSummaryRestControllerApi
 * @extends {BaseAPI}
 */
export class TxDocSummaryRestControllerApi extends BaseAPI {
    /**
     * 
     * @param {TxDocSummaryRequest} txDocSummaryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TxDocSummaryRestControllerApi
     */
    public createTxDocSummary(txDocSummaryRequest: TxDocSummaryRequest, options?: RawAxiosRequestConfig) {
        return TxDocSummaryRestControllerApiFp(this.configuration).createTxDocSummary(txDocSummaryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TxDocSummaryRestControllerApi
     */
    public deleteTxDocSummary(id: number, options?: RawAxiosRequestConfig) {
        return TxDocSummaryRestControllerApiFp(this.configuration).deleteTxDocSummary(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TxDocSummaryRestControllerApi
     */
    public getAllTxDocSummaries(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return TxDocSummaryRestControllerApiFp(this.configuration).getAllTxDocSummaries(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TxDocSummaryRestControllerApi
     */
    public getTxDocSummaryById(id: number, options?: RawAxiosRequestConfig) {
        return TxDocSummaryRestControllerApiFp(this.configuration).getTxDocSummaryById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {TxDocSummaryRequest} txDocSummaryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TxDocSummaryRestControllerApi
     */
    public updateTxDocSummary(id: number, txDocSummaryRequest: TxDocSummaryRequest, options?: RawAxiosRequestConfig) {
        return TxDocSummaryRestControllerApiFp(this.configuration).updateTxDocSummary(id, txDocSummaryRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TxDocSummaryTypeRestControllerApi - axios parameter creator
 * @export
 */
export const TxDocSummaryTypeRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTxDocSummaryTypes: async (page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/mnas/tx-doc-summaries/types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTxDocSummaryTypeById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTxDocSummaryTypeById', 'id', id)
            const localVarPath = `/api/mnas/tx-doc-summaries/types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TxDocSummaryTypeRestControllerApi - functional programming interface
 * @export
 */
export const TxDocSummaryTypeRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TxDocSummaryTypeRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllTxDocSummaryTypes(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimplePageTxDocSummaryTypeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllTxDocSummaryTypes(page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TxDocSummaryTypeRestControllerApi.getAllTxDocSummaryTypes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTxDocSummaryTypeById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TxDocSummaryTypeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTxDocSummaryTypeById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TxDocSummaryTypeRestControllerApi.getTxDocSummaryTypeById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TxDocSummaryTypeRestControllerApi - factory interface
 * @export
 */
export const TxDocSummaryTypeRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TxDocSummaryTypeRestControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTxDocSummaryTypes(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<SimplePageTxDocSummaryTypeResponse> {
            return localVarFp.getAllTxDocSummaryTypes(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTxDocSummaryTypeById(id: number, options?: any): AxiosPromise<TxDocSummaryTypeResponse> {
            return localVarFp.getTxDocSummaryTypeById(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TxDocSummaryTypeRestControllerApi - object-oriented interface
 * @export
 * @class TxDocSummaryTypeRestControllerApi
 * @extends {BaseAPI}
 */
export class TxDocSummaryTypeRestControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TxDocSummaryTypeRestControllerApi
     */
    public getAllTxDocSummaryTypes(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return TxDocSummaryTypeRestControllerApiFp(this.configuration).getAllTxDocSummaryTypes(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TxDocSummaryTypeRestControllerApi
     */
    public getTxDocSummaryTypeById(id: number, options?: RawAxiosRequestConfig) {
        return TxDocSummaryTypeRestControllerApiFp(this.configuration).getTxDocSummaryTypeById(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserPreferenceRestControllerApi - axios parameter creator
 * @export
 */
export const UserPreferenceRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieve user preferences based on the authenticated user\'s account.
         * @summary Get user preferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPreferences: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users/preferences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update user preferences for the authenticated user\'s account.
         * @summary Update user preferences
         * @param {UserPreferenceRequest} userPreferenceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPreferences: async (userPreferenceRequest: UserPreferenceRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userPreferenceRequest' is not null or undefined
            assertParamExists('updateUserPreferences', 'userPreferenceRequest', userPreferenceRequest)
            const localVarPath = `/api/users/preferences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userPreferenceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserPreferenceRestControllerApi - functional programming interface
 * @export
 */
export const UserPreferenceRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserPreferenceRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieve user preferences based on the authenticated user\'s account.
         * @summary Get user preferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserPreferences(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserPreferenceResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserPreferences(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserPreferenceRestControllerApi.getUserPreferences']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update user preferences for the authenticated user\'s account.
         * @summary Update user preferences
         * @param {UserPreferenceRequest} userPreferenceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserPreferences(userPreferenceRequest: UserPreferenceRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserPreferences(userPreferenceRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserPreferenceRestControllerApi.updateUserPreferences']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserPreferenceRestControllerApi - factory interface
 * @export
 */
export const UserPreferenceRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserPreferenceRestControllerApiFp(configuration)
    return {
        /**
         * Retrieve user preferences based on the authenticated user\'s account.
         * @summary Get user preferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPreferences(options?: any): AxiosPromise<Array<UserPreferenceResponse>> {
            return localVarFp.getUserPreferences(options).then((request) => request(axios, basePath));
        },
        /**
         * Update user preferences for the authenticated user\'s account.
         * @summary Update user preferences
         * @param {UserPreferenceRequest} userPreferenceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPreferences(userPreferenceRequest: UserPreferenceRequest, options?: any): AxiosPromise<number> {
            return localVarFp.updateUserPreferences(userPreferenceRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserPreferenceRestControllerApi - object-oriented interface
 * @export
 * @class UserPreferenceRestControllerApi
 * @extends {BaseAPI}
 */
export class UserPreferenceRestControllerApi extends BaseAPI {
    /**
     * Retrieve user preferences based on the authenticated user\'s account.
     * @summary Get user preferences
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPreferenceRestControllerApi
     */
    public getUserPreferences(options?: RawAxiosRequestConfig) {
        return UserPreferenceRestControllerApiFp(this.configuration).getUserPreferences(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update user preferences for the authenticated user\'s account.
     * @summary Update user preferences
     * @param {UserPreferenceRequest} userPreferenceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPreferenceRestControllerApi
     */
    public updateUserPreferences(userPreferenceRequest: UserPreferenceRequest, options?: RawAxiosRequestConfig) {
        return UserPreferenceRestControllerApiFp(this.configuration).updateUserPreferences(userPreferenceRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserPreferenceTypeRestControllerApi - axios parameter creator
 * @export
 */
export const UserPreferenceTypeRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new user preference type based on the provided request.
         * @summary Create User Preference Type
         * @param {UserPreferenceTypeRequest} userPreferenceTypeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserPreferenceType: async (userPreferenceTypeRequest: UserPreferenceTypeRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userPreferenceTypeRequest' is not null or undefined
            assertParamExists('createUserPreferenceType', 'userPreferenceTypeRequest', userPreferenceTypeRequest)
            const localVarPath = `/api/users/preferenceType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userPreferenceTypeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes a user preference type with the specified ID.
         * @summary Delete User Preference Type
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserPreferenceType: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteUserPreferenceType', 'id', id)
            const localVarPath = `/api/users/preferenceType/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a user preference type by its unique identifier.
         * @summary Get User Preference Type by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPreferenceTypeById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserPreferenceTypeById', 'id', id)
            const localVarPath = `/api/users/preferenceType/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves all options available.
         * @summary Get Options for User Preference Type
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPreferenceTypeOptionsById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserPreferenceTypeOptionsById', 'id', id)
            const localVarPath = `/api/users/preferenceType/options/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a paginated list of all user preference types.
         * @summary Get All User Preference Types
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPreferenceTypes: async (page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users/preferenceType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an existing user preference type with the provided request.
         * @summary Update User Preference Type
         * @param {number} id 
         * @param {UserPreferenceTypeRequest} userPreferenceTypeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPreferenceType: async (id: number, userPreferenceTypeRequest: UserPreferenceTypeRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUserPreferenceType', 'id', id)
            // verify required parameter 'userPreferenceTypeRequest' is not null or undefined
            assertParamExists('updateUserPreferenceType', 'userPreferenceTypeRequest', userPreferenceTypeRequest)
            const localVarPath = `/api/users/preferenceType/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userPreferenceTypeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserPreferenceTypeRestControllerApi - functional programming interface
 * @export
 */
export const UserPreferenceTypeRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserPreferenceTypeRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a new user preference type based on the provided request.
         * @summary Create User Preference Type
         * @param {UserPreferenceTypeRequest} userPreferenceTypeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserPreferenceType(userPreferenceTypeRequest: UserPreferenceTypeRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserPreferenceType(userPreferenceTypeRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserPreferenceTypeRestControllerApi.createUserPreferenceType']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Deletes a user preference type with the specified ID.
         * @summary Delete User Preference Type
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserPreferenceType(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserPreferenceType(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserPreferenceTypeRestControllerApi.deleteUserPreferenceType']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a user preference type by its unique identifier.
         * @summary Get User Preference Type by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserPreferenceTypeById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPreferenceTypeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserPreferenceTypeById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserPreferenceTypeRestControllerApi.getUserPreferenceTypeById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves all options available.
         * @summary Get Options for User Preference Type
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserPreferenceTypeOptionsById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageUserPreferenceTypeOptionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserPreferenceTypeOptionsById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserPreferenceTypeRestControllerApi.getUserPreferenceTypeOptionsById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves a paginated list of all user preference types.
         * @summary Get All User Preference Types
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserPreferenceTypes(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageUserPreferenceTypeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserPreferenceTypes(page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserPreferenceTypeRestControllerApi.getUserPreferenceTypes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates an existing user preference type with the provided request.
         * @summary Update User Preference Type
         * @param {number} id 
         * @param {UserPreferenceTypeRequest} userPreferenceTypeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserPreferenceType(id: number, userPreferenceTypeRequest: UserPreferenceTypeRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserPreferenceType(id, userPreferenceTypeRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserPreferenceTypeRestControllerApi.updateUserPreferenceType']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserPreferenceTypeRestControllerApi - factory interface
 * @export
 */
export const UserPreferenceTypeRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserPreferenceTypeRestControllerApiFp(configuration)
    return {
        /**
         * Creates a new user preference type based on the provided request.
         * @summary Create User Preference Type
         * @param {UserPreferenceTypeRequest} userPreferenceTypeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserPreferenceType(userPreferenceTypeRequest: UserPreferenceTypeRequest, options?: any): AxiosPromise<number> {
            return localVarFp.createUserPreferenceType(userPreferenceTypeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes a user preference type with the specified ID.
         * @summary Delete User Preference Type
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserPreferenceType(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUserPreferenceType(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a user preference type by its unique identifier.
         * @summary Get User Preference Type by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPreferenceTypeById(id: number, options?: any): AxiosPromise<UserPreferenceTypeResponse> {
            return localVarFp.getUserPreferenceTypeById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves all options available.
         * @summary Get Options for User Preference Type
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPreferenceTypeOptionsById(id: number, options?: any): AxiosPromise<PageUserPreferenceTypeOptionResponse> {
            return localVarFp.getUserPreferenceTypeOptionsById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a paginated list of all user preference types.
         * @summary Get All User Preference Types
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPreferenceTypes(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageUserPreferenceTypeResponse> {
            return localVarFp.getUserPreferenceTypes(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates an existing user preference type with the provided request.
         * @summary Update User Preference Type
         * @param {number} id 
         * @param {UserPreferenceTypeRequest} userPreferenceTypeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPreferenceType(id: number, userPreferenceTypeRequest: UserPreferenceTypeRequest, options?: any): AxiosPromise<number> {
            return localVarFp.updateUserPreferenceType(id, userPreferenceTypeRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserPreferenceTypeRestControllerApi - object-oriented interface
 * @export
 * @class UserPreferenceTypeRestControllerApi
 * @extends {BaseAPI}
 */
export class UserPreferenceTypeRestControllerApi extends BaseAPI {
    /**
     * Creates a new user preference type based on the provided request.
     * @summary Create User Preference Type
     * @param {UserPreferenceTypeRequest} userPreferenceTypeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPreferenceTypeRestControllerApi
     */
    public createUserPreferenceType(userPreferenceTypeRequest: UserPreferenceTypeRequest, options?: RawAxiosRequestConfig) {
        return UserPreferenceTypeRestControllerApiFp(this.configuration).createUserPreferenceType(userPreferenceTypeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes a user preference type with the specified ID.
     * @summary Delete User Preference Type
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPreferenceTypeRestControllerApi
     */
    public deleteUserPreferenceType(id: number, options?: RawAxiosRequestConfig) {
        return UserPreferenceTypeRestControllerApiFp(this.configuration).deleteUserPreferenceType(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a user preference type by its unique identifier.
     * @summary Get User Preference Type by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPreferenceTypeRestControllerApi
     */
    public getUserPreferenceTypeById(id: number, options?: RawAxiosRequestConfig) {
        return UserPreferenceTypeRestControllerApiFp(this.configuration).getUserPreferenceTypeById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves all options available.
     * @summary Get Options for User Preference Type
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPreferenceTypeRestControllerApi
     */
    public getUserPreferenceTypeOptionsById(id: number, options?: RawAxiosRequestConfig) {
        return UserPreferenceTypeRestControllerApiFp(this.configuration).getUserPreferenceTypeOptionsById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a paginated list of all user preference types.
     * @summary Get All User Preference Types
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPreferenceTypeRestControllerApi
     */
    public getUserPreferenceTypes(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return UserPreferenceTypeRestControllerApiFp(this.configuration).getUserPreferenceTypes(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates an existing user preference type with the provided request.
     * @summary Update User Preference Type
     * @param {number} id 
     * @param {UserPreferenceTypeRequest} userPreferenceTypeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPreferenceTypeRestControllerApi
     */
    public updateUserPreferenceType(id: number, userPreferenceTypeRequest: UserPreferenceTypeRequest, options?: RawAxiosRequestConfig) {
        return UserPreferenceTypeRestControllerApiFp(this.configuration).updateUserPreferenceType(id, userPreferenceTypeRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserProfileRestControllerApi - axios parameter creator
 * @export
 */
export const UserProfileRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Updates the profile of a user with the specified ID
         * @summary Update user profile
         * @param {number} id 
         * @param {UserProfileRequest} userProfileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfile: async (id: number, userProfileRequest: UserProfileRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUserProfile', 'id', id)
            // verify required parameter 'userProfileRequest' is not null or undefined
            assertParamExists('updateUserProfile', 'userProfileRequest', userProfileRequest)
            const localVarPath = `/api/users/profiles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userProfileRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves information about the currently authenticated user
         * @summary Get current user information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        whoAmI: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users/profiles/whoami`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserProfileRestControllerApi - functional programming interface
 * @export
 */
export const UserProfileRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserProfileRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Updates the profile of a user with the specified ID
         * @summary Update user profile
         * @param {number} id 
         * @param {UserProfileRequest} userProfileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserProfile(id: number, userProfileRequest: UserProfileRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserProfile(id, userProfileRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileRestControllerApi.updateUserProfile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves information about the currently authenticated user
         * @summary Get current user information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async whoAmI(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WhoAmIResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.whoAmI(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserProfileRestControllerApi.whoAmI']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserProfileRestControllerApi - factory interface
 * @export
 */
export const UserProfileRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserProfileRestControllerApiFp(configuration)
    return {
        /**
         * Updates the profile of a user with the specified ID
         * @summary Update user profile
         * @param {number} id 
         * @param {UserProfileRequest} userProfileRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserProfile(id: number, userProfileRequest: UserProfileRequest, options?: any): AxiosPromise<number> {
            return localVarFp.updateUserProfile(id, userProfileRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves information about the currently authenticated user
         * @summary Get current user information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        whoAmI(options?: any): AxiosPromise<WhoAmIResponse> {
            return localVarFp.whoAmI(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserProfileRestControllerApi - object-oriented interface
 * @export
 * @class UserProfileRestControllerApi
 * @extends {BaseAPI}
 */
export class UserProfileRestControllerApi extends BaseAPI {
    /**
     * Updates the profile of a user with the specified ID
     * @summary Update user profile
     * @param {number} id 
     * @param {UserProfileRequest} userProfileRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileRestControllerApi
     */
    public updateUserProfile(id: number, userProfileRequest: UserProfileRequest, options?: RawAxiosRequestConfig) {
        return UserProfileRestControllerApiFp(this.configuration).updateUserProfile(id, userProfileRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves information about the currently authenticated user
     * @summary Get current user information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileRestControllerApi
     */
    public whoAmI(options?: RawAxiosRequestConfig) {
        return UserProfileRestControllerApiFp(this.configuration).whoAmI(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserRestControllerApi - axios parameter creator
 * @export
 */
export const UserRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Reset the password for the currently authenticated user.
         * @summary Reset User Password
         * @param {PasswordResetRequest} passwordResetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userResetPassword: async (passwordResetRequest: PasswordResetRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'passwordResetRequest' is not null or undefined
            assertParamExists('userResetPassword', 'passwordResetRequest', passwordResetRequest)
            const localVarPath = `/api/users/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordResetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserRestControllerApi - functional programming interface
 * @export
 */
export const UserRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Reset the password for the currently authenticated user.
         * @summary Reset User Password
         * @param {PasswordResetRequest} passwordResetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userResetPassword(passwordResetRequest: PasswordResetRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PasswordResetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userResetPassword(passwordResetRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserRestControllerApi.userResetPassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserRestControllerApi - factory interface
 * @export
 */
export const UserRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserRestControllerApiFp(configuration)
    return {
        /**
         * Reset the password for the currently authenticated user.
         * @summary Reset User Password
         * @param {PasswordResetRequest} passwordResetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userResetPassword(passwordResetRequest: PasswordResetRequest, options?: any): AxiosPromise<PasswordResetResponse> {
            return localVarFp.userResetPassword(passwordResetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserRestControllerApi - object-oriented interface
 * @export
 * @class UserRestControllerApi
 * @extends {BaseAPI}
 */
export class UserRestControllerApi extends BaseAPI {
    /**
     * Reset the password for the currently authenticated user.
     * @summary Reset User Password
     * @param {PasswordResetRequest} passwordResetRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRestControllerApi
     */
    public userResetPassword(passwordResetRequest: PasswordResetRequest, options?: RawAxiosRequestConfig) {
        return UserRestControllerApiFp(this.configuration).userResetPassword(passwordResetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WhatMattersAssociationRestControllerApi - axios parameter creator
 * @export
 */
export const WhatMattersAssociationRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new association between a WhatMatters and other domains. Requires ADMIN or USER role.
         * @summary Create a new WhatMatters association
         * @param {WhatMattersAssociationRequest} whatMattersAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWhatMattersAssociation: async (whatMattersAssociationRequest: WhatMattersAssociationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'whatMattersAssociationRequest' is not null or undefined
            assertParamExists('createWhatMattersAssociation', 'whatMattersAssociationRequest', whatMattersAssociationRequest)
            const localVarPath = `/api/what-matters/assoc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(whatMattersAssociationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the association between a WhatMatters and other domains. Requires ADMIN or USER role.
         * @summary Delete a WhatMatters association
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWhatMattersAssociation: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteWhatMattersAssociation', 'id', id)
            const localVarPath = `/api/what-matters/assoc/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve associations between a WhatMatters and a specific domain type by entity ID and domain type ID.
         * @summary Find associations by entity ID and domain type ID
         * @param {number} entityId 
         * @param {number} domainTypeId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByEntityIdAndDomainTypeId: async (entityId: number, domainTypeId: number, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('findByEntityIdAndDomainTypeId', 'entityId', entityId)
            // verify required parameter 'domainTypeId' is not null or undefined
            assertParamExists('findByEntityIdAndDomainTypeId', 'domainTypeId', domainTypeId)
            const localVarPath = `/api/what-matters/assoc/{entityId}/domain/{domainTypeId}`
                .replace(`{${"entityId"}}`, encodeURIComponent(String(entityId)))
                .replace(`{${"domainTypeId"}}`, encodeURIComponent(String(domainTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a WhatMatters association by its unique identifier.
         * @summary Get WhatMatters association by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWhatMattersAssociationById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getWhatMattersAssociationById', 'id', id)
            const localVarPath = `/api/what-matters/assoc/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve all associations between WhatMatters and other domains.
         * @summary Get all WhatMatters associations
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWhatMattersAssociations: async (page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/what-matters/assoc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an existing association between a WhatMatters and other domains. Requires ADMIN or USER role.
         * @summary Update an existing WhatMatters association
         * @param {number} id 
         * @param {WhatMattersAssociationRequest} whatMattersAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWhatMattersAssociation: async (id: number, whatMattersAssociationRequest: WhatMattersAssociationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWhatMattersAssociation', 'id', id)
            // verify required parameter 'whatMattersAssociationRequest' is not null or undefined
            assertParamExists('updateWhatMattersAssociation', 'whatMattersAssociationRequest', whatMattersAssociationRequest)
            const localVarPath = `/api/what-matters/assoc/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(whatMattersAssociationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WhatMattersAssociationRestControllerApi - functional programming interface
 * @export
 */
export const WhatMattersAssociationRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WhatMattersAssociationRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a new association between a WhatMatters and other domains. Requires ADMIN or USER role.
         * @summary Create a new WhatMatters association
         * @param {WhatMattersAssociationRequest} whatMattersAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWhatMattersAssociation(whatMattersAssociationRequest: WhatMattersAssociationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWhatMattersAssociation(whatMattersAssociationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WhatMattersAssociationRestControllerApi.createWhatMattersAssociation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Deletes the association between a WhatMatters and other domains. Requires ADMIN or USER role.
         * @summary Delete a WhatMatters association
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWhatMattersAssociation(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWhatMattersAssociation(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WhatMattersAssociationRestControllerApi.deleteWhatMattersAssociation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve associations between a WhatMatters and a specific domain type by entity ID and domain type ID.
         * @summary Find associations by entity ID and domain type ID
         * @param {number} entityId 
         * @param {number} domainTypeId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByEntityIdAndDomainTypeId(entityId: number, domainTypeId: number, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimplePageWhatMattersAssociationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findByEntityIdAndDomainTypeId(entityId, domainTypeId, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WhatMattersAssociationRestControllerApi.findByEntityIdAndDomainTypeId']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve a WhatMatters association by its unique identifier.
         * @summary Get WhatMatters association by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWhatMattersAssociationById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WhatMattersAssociationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWhatMattersAssociationById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WhatMattersAssociationRestControllerApi.getWhatMattersAssociationById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve all associations between WhatMatters and other domains.
         * @summary Get all WhatMatters associations
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWhatMattersAssociations(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimplePageWhatMattersAssociationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWhatMattersAssociations(page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WhatMattersAssociationRestControllerApi.getWhatMattersAssociations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates an existing association between a WhatMatters and other domains. Requires ADMIN or USER role.
         * @summary Update an existing WhatMatters association
         * @param {number} id 
         * @param {WhatMattersAssociationRequest} whatMattersAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWhatMattersAssociation(id: number, whatMattersAssociationRequest: WhatMattersAssociationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWhatMattersAssociation(id, whatMattersAssociationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WhatMattersAssociationRestControllerApi.updateWhatMattersAssociation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * WhatMattersAssociationRestControllerApi - factory interface
 * @export
 */
export const WhatMattersAssociationRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WhatMattersAssociationRestControllerApiFp(configuration)
    return {
        /**
         * Creates a new association between a WhatMatters and other domains. Requires ADMIN or USER role.
         * @summary Create a new WhatMatters association
         * @param {WhatMattersAssociationRequest} whatMattersAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWhatMattersAssociation(whatMattersAssociationRequest: WhatMattersAssociationRequest, options?: any): AxiosPromise<number> {
            return localVarFp.createWhatMattersAssociation(whatMattersAssociationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes the association between a WhatMatters and other domains. Requires ADMIN or USER role.
         * @summary Delete a WhatMatters association
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWhatMattersAssociation(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteWhatMattersAssociation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve associations between a WhatMatters and a specific domain type by entity ID and domain type ID.
         * @summary Find associations by entity ID and domain type ID
         * @param {number} entityId 
         * @param {number} domainTypeId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByEntityIdAndDomainTypeId(entityId: number, domainTypeId: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<SimplePageWhatMattersAssociationResponse> {
            return localVarFp.findByEntityIdAndDomainTypeId(entityId, domainTypeId, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a WhatMatters association by its unique identifier.
         * @summary Get WhatMatters association by ID
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWhatMattersAssociationById(id: number, options?: any): AxiosPromise<WhatMattersAssociationResponse> {
            return localVarFp.getWhatMattersAssociationById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve all associations between WhatMatters and other domains.
         * @summary Get all WhatMatters associations
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWhatMattersAssociations(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<SimplePageWhatMattersAssociationResponse> {
            return localVarFp.getWhatMattersAssociations(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates an existing association between a WhatMatters and other domains. Requires ADMIN or USER role.
         * @summary Update an existing WhatMatters association
         * @param {number} id 
         * @param {WhatMattersAssociationRequest} whatMattersAssociationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWhatMattersAssociation(id: number, whatMattersAssociationRequest: WhatMattersAssociationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateWhatMattersAssociation(id, whatMattersAssociationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WhatMattersAssociationRestControllerApi - object-oriented interface
 * @export
 * @class WhatMattersAssociationRestControllerApi
 * @extends {BaseAPI}
 */
export class WhatMattersAssociationRestControllerApi extends BaseAPI {
    /**
     * Creates a new association between a WhatMatters and other domains. Requires ADMIN or USER role.
     * @summary Create a new WhatMatters association
     * @param {WhatMattersAssociationRequest} whatMattersAssociationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhatMattersAssociationRestControllerApi
     */
    public createWhatMattersAssociation(whatMattersAssociationRequest: WhatMattersAssociationRequest, options?: RawAxiosRequestConfig) {
        return WhatMattersAssociationRestControllerApiFp(this.configuration).createWhatMattersAssociation(whatMattersAssociationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes the association between a WhatMatters and other domains. Requires ADMIN or USER role.
     * @summary Delete a WhatMatters association
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhatMattersAssociationRestControllerApi
     */
    public deleteWhatMattersAssociation(id: number, options?: RawAxiosRequestConfig) {
        return WhatMattersAssociationRestControllerApiFp(this.configuration).deleteWhatMattersAssociation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve associations between a WhatMatters and a specific domain type by entity ID and domain type ID.
     * @summary Find associations by entity ID and domain type ID
     * @param {number} entityId 
     * @param {number} domainTypeId 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhatMattersAssociationRestControllerApi
     */
    public findByEntityIdAndDomainTypeId(entityId: number, domainTypeId: number, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return WhatMattersAssociationRestControllerApiFp(this.configuration).findByEntityIdAndDomainTypeId(entityId, domainTypeId, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a WhatMatters association by its unique identifier.
     * @summary Get WhatMatters association by ID
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhatMattersAssociationRestControllerApi
     */
    public getWhatMattersAssociationById(id: number, options?: RawAxiosRequestConfig) {
        return WhatMattersAssociationRestControllerApiFp(this.configuration).getWhatMattersAssociationById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve all associations between WhatMatters and other domains.
     * @summary Get all WhatMatters associations
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhatMattersAssociationRestControllerApi
     */
    public getWhatMattersAssociations(page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return WhatMattersAssociationRestControllerApiFp(this.configuration).getWhatMattersAssociations(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates an existing association between a WhatMatters and other domains. Requires ADMIN or USER role.
     * @summary Update an existing WhatMatters association
     * @param {number} id 
     * @param {WhatMattersAssociationRequest} whatMattersAssociationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhatMattersAssociationRestControllerApi
     */
    public updateWhatMattersAssociation(id: number, whatMattersAssociationRequest: WhatMattersAssociationRequest, options?: RawAxiosRequestConfig) {
        return WhatMattersAssociationRestControllerApiFp(this.configuration).updateWhatMattersAssociation(id, whatMattersAssociationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WhatMattersRestControllerApi - axios parameter creator
 * @export
 */
export const WhatMattersRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {WhatMattersRequest} whatMattersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWhatMatters: async (whatMattersRequest: WhatMattersRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'whatMattersRequest' is not null or undefined
            assertParamExists('createWhatMatters', 'whatMattersRequest', whatMattersRequest)
            const localVarPath = `/api/what-matters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(whatMattersRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWhatMatters: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteWhatMatters', 'id', id)
            const localVarPath = `/api/what-matters/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllWhatMattersByDateModified: async (startDate: string, endDate: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getAllWhatMattersByDateModified', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getAllWhatMattersByDateModified', 'endDate', endDate)
            const localVarPath = `/api/what-matters/betweenDates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWhatMatters: async (filter?: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/what-matters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWhatMattersById: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getWhatMattersById', 'id', id)
            const localVarPath = `/api/what-matters/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWhatMattersCountByDateModified: async (startDate: string, endDate: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getWhatMattersCountByDateModified', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getWhatMattersCountByDateModified', 'endDate', endDate)
            const localVarPath = `/api/what-matters/countBetweenDates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {WhatMattersRequest} whatMattersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWhatMatters: async (id: number, whatMattersRequest: WhatMattersRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWhatMatters', 'id', id)
            // verify required parameter 'whatMattersRequest' is not null or undefined
            assertParamExists('updateWhatMatters', 'whatMattersRequest', whatMattersRequest)
            const localVarPath = `/api/what-matters/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(whatMattersRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WhatMattersRestControllerApi - functional programming interface
 * @export
 */
export const WhatMattersRestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WhatMattersRestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {WhatMattersRequest} whatMattersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWhatMatters(whatMattersRequest: WhatMattersRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWhatMatters(whatMattersRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WhatMattersRestControllerApi.createWhatMatters']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWhatMatters(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWhatMatters(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WhatMattersRestControllerApi.deleteWhatMatters']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllWhatMattersByDateModified(startDate: string, endDate: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageWhatMattersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllWhatMattersByDateModified(startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WhatMattersRestControllerApi.getAllWhatMattersByDateModified']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWhatMatters(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageWhatMattersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWhatMatters(filter, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WhatMattersRestControllerApi.getWhatMatters']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWhatMattersById(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WhatMattersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWhatMattersById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WhatMattersRestControllerApi.getWhatMattersById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWhatMattersCountByDateModified(startDate: string, endDate: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWhatMattersCountByDateModified(startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WhatMattersRestControllerApi.getWhatMattersCountByDateModified']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {WhatMattersRequest} whatMattersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWhatMatters(id: number, whatMattersRequest: WhatMattersRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWhatMatters(id, whatMattersRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WhatMattersRestControllerApi.updateWhatMatters']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * WhatMattersRestControllerApi - factory interface
 * @export
 */
export const WhatMattersRestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WhatMattersRestControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {WhatMattersRequest} whatMattersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWhatMatters(whatMattersRequest: WhatMattersRequest, options?: any): AxiosPromise<number> {
            return localVarFp.createWhatMatters(whatMattersRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWhatMatters(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteWhatMatters(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllWhatMattersByDateModified(startDate: string, endDate: string, options?: any): AxiosPromise<PageWhatMattersResponse> {
            return localVarFp.getAllWhatMattersByDateModified(startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWhatMatters(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageWhatMattersResponse> {
            return localVarFp.getWhatMatters(filter, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWhatMattersById(id: number, options?: any): AxiosPromise<WhatMattersResponse> {
            return localVarFp.getWhatMattersById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWhatMattersCountByDateModified(startDate: string, endDate: string, options?: any): AxiosPromise<number> {
            return localVarFp.getWhatMattersCountByDateModified(startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {WhatMattersRequest} whatMattersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWhatMatters(id: number, whatMattersRequest: WhatMattersRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateWhatMatters(id, whatMattersRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WhatMattersRestControllerApi - object-oriented interface
 * @export
 * @class WhatMattersRestControllerApi
 * @extends {BaseAPI}
 */
export class WhatMattersRestControllerApi extends BaseAPI {
    /**
     * 
     * @param {WhatMattersRequest} whatMattersRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhatMattersRestControllerApi
     */
    public createWhatMatters(whatMattersRequest: WhatMattersRequest, options?: RawAxiosRequestConfig) {
        return WhatMattersRestControllerApiFp(this.configuration).createWhatMatters(whatMattersRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhatMattersRestControllerApi
     */
    public deleteWhatMatters(id: number, options?: RawAxiosRequestConfig) {
        return WhatMattersRestControllerApiFp(this.configuration).deleteWhatMatters(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhatMattersRestControllerApi
     */
    public getAllWhatMattersByDateModified(startDate: string, endDate: string, options?: RawAxiosRequestConfig) {
        return WhatMattersRestControllerApiFp(this.configuration).getAllWhatMattersByDateModified(startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [filter] 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhatMattersRestControllerApi
     */
    public getWhatMatters(filter?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return WhatMattersRestControllerApiFp(this.configuration).getWhatMatters(filter, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhatMattersRestControllerApi
     */
    public getWhatMattersById(id: number, options?: RawAxiosRequestConfig) {
        return WhatMattersRestControllerApiFp(this.configuration).getWhatMattersById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhatMattersRestControllerApi
     */
    public getWhatMattersCountByDateModified(startDate: string, endDate: string, options?: RawAxiosRequestConfig) {
        return WhatMattersRestControllerApiFp(this.configuration).getWhatMattersCountByDateModified(startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {WhatMattersRequest} whatMattersRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhatMattersRestControllerApi
     */
    public updateWhatMatters(id: number, whatMattersRequest: WhatMattersRequest, options?: RawAxiosRequestConfig) {
        return WhatMattersRestControllerApiFp(this.configuration).updateWhatMatters(id, whatMattersRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



