// React
import React, {useEffect, useRef, useState} from "react";
import { useNavigate, useParams } from "react-router-dom";
// Third party
import { useFormik } from "formik";
import moment from "moment";
import ReactQuill from "react-quill";
import toast from "react-hot-toast";
// Components
import AsyncSelect from "../Components/Async-Select";
import Breadcrumbs from "../Components/Breadcrumbs/Breadcrumbs";
import { CustomErrorModal } from "../Components/Modal/customModal";
import FileUpload from "../Components/File-Upload";
import InputField from "../Components/Common-inputs";
import Loader from "../Components/Loader";
import TextEditor from "../Components/TextEditor";
// OpenAPI
import { apiConfig } from "../Components/ConfigurationApi/Configuration";
import { FundamentalRestControllerApi, TagRestContollerApi } from "../openapi";
import { expiredTokenValidation } from "../api/expiredTokenValidation";
import { getApiData } from "../Controller/getApiDataConfiguration";
// Types
import { ErrorFileData } from "../interfaces/pages/Errors";
import { SearchResultTag } from "../interfaces/pages/variedInterfaces";

// Define initial form values
const initialValues = {
  name: "",
  sectorId: "",
  ticker: "",
  incorp: "",
  hq: "",
  geoExposure: "",
  tags: [],
  whatMatters: "",
  businessDesc: "",
  companyHistory: "",
  companyBasics: "",
  driversValuation: "",
  valuationHistory: "",
  companyId: "",
  files: { add: [], delete: [] },
  dateIpo: null,
  description: "",
  address: ""
};

const AddEditFundamental = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [serverError, setServerError] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [sectorList, setSectorList] = useState<any>([]);
  const [tagLoading, setTagLoading] = useState(false);
  const [changedValues, setChangedValues] = useState<any>({});
  const $businessDescription = useRef<ReactQuill>(null);
  const $whatMatters = useRef<ReactQuill>(null);
  const $companyHistory = useRef<ReactQuill>(null);
  const $companyBasics = useRef<ReactQuill>(null);
  const $driversValuation = useRef<ReactQuill>(null);
  const $valuationHistory = useRef<ReactQuill>(null);
  const $tags = useRef<any>(null);
  const submitButtonRef = useRef<HTMLButtonElement | null>(null);
  const [editorData, setEditorData] = useState({ ref: null, keyName: '' });

  // Fetch Sector List options on component mount
  useEffect(() => {
    fetchSectorList();
  }, []);

  // Function to fetch Sector List options
  const fetchSectorList = async () => {
    try {
      const api = new getApiData();
      const response = await api.getSectorRestControllerApi(id);
      const filteredOptions = response?.data?.content!.map((item: any) => ({
        value: item.id,
        label: item.name,
      }));
      setSectorList(filteredOptions);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!id) return;
    fetchFundamentalData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const fetchFundamentalData = async () => {
    setLoading(true);
    try {
      const api = new FundamentalRestControllerApi(apiConfig());

      const response = await api.getFundamentalById(Number(id));
      if (response.data) {
        const data = response.data as any;

        data.dateIpo = moment(data.dateIpo).isValid() ? new Date(data.dateIpo) : null

        data.tags =
          data.tags?.map((tag: any) => {
            return { value: tag.id, label: tag.tag };
          }) || null;
        data.old = data.files
        formik.setValues(data);
        setChangedValues(data);
      }
    } catch (error) {
      expiredTokenValidation(error);
    }
    setLoading(false);
  };

  // Formik hook for form management
  const formik: any = useFormik({
    initialValues,
    onSubmit: async (values: any, { setSubmitting, setErrors }) => {
      values[editorData.keyName] = editorData.ref
      values.dateIpo = formik.values.dateIpo !== null ? new Date(values.dateIpo).toISOString() : null
      setLoading(true);
      const data: any = {
        ...formik.values,
        version: 0,
        mnaId: values.mnaId?.value,
        companyId: values.companyId?.value,
        dateIpo: values.dateIpo,
      };

      if (data.tags?.length > 0) {
        const tags = data.tags.reduce(
          (result: any, obj: any) => {
            if (!changedValues.tags?.includes(obj)) {
              result.add = result.add || [];
              result.add = [...result.add, { tag: obj.label }];
            }
            return result;
          },
          { add: null, delete: null }
        );

        changedValues.tags?.forEach((item: any) => {
          const deletedObj = data.tags?.map((obj: any) => {
            if (obj?.value === item.value) {
              return obj.value;
            } else {
              return null
            }
          });
          if (!deletedObj.includes(item.value)) {
            tags.delete = tags.delete || [];
            tags.delete = [...tags.delete, item.value];
          }
        });
        if (id) {
          data.tags = tags;
        } else {
          delete tags.delete;
          data.tags = tags?.add?.length > 0 ? tags : null;
        }
      } else {
        const tags: any = { add: null, delete: [] };
        tags.delete = changedValues.tags?.map((item: any) => item.value);
        data.tags = id ? tags : null;
      }


      if (Object.keys(data.files).includes("add") || Object.keys(data.files).includes("delete")) {
      } else {
        data.files = { add: null, delete: null }
      }
      delete data.old
      if (submitButtonRef.current && !submitButtonRef.current.disabled) {
        submitButtonRef.current.disabled = true;
      try {
        // API call
        const api = new FundamentalRestControllerApi(apiConfig());
        id ? await api.updateFundamental(Number(id), data) : await api.createFundamental(data);
        navigate("/fundamental");
      } catch (error: any) {
        if (error.response) {
          values.dateIpo = formik.values['dateIpo'] !== null ? new Date(values.dateIpo) : null
          if (error.response.data?.exception && !error.response.data?.fieldErrors?.length) {
            toast.custom(
              (t) => <CustomErrorModal visible={t.visible} error={error} />,
              {
                duration: 500,
              }
            );
          }

          if (error.response.data?.fieldErrors?.length) {
            const errorsObject = {} as any;
            error.response.data?.fieldErrors.forEach((error: ErrorFileData) => {
              errorsObject[error.field] = error.defaultMsg;
            });
            setErrors(errorsObject);
            setServerError(errorsObject);
          }
        } else {
          // Handle other errors
          console.error("Error submitting form:", error.message);
        }
      } finally {
        setSubmitting(false);
        setLoading(false);
        submitButtonRef.current.disabled = false
      }
    }
    },
  });

  const fetchTags = async (inputValue: string): Promise<any[]> => {
    // Simulate an asynchronous call with a delay
    setTagLoading(true);
    try {
      const api = new TagRestContollerApi(apiConfig());

      const response = await api.getTags(`${inputValue}`, undefined, undefined, undefined);

      const filteredOptions = (response.data.content as SearchResultTag[]).map((item) => ({
        value: item.id,
        label: item.tag,
      }));

      setTagLoading(false);
      return filteredOptions;
    } catch (error) {
      console.error("Error fetching options:", error);
      setTagLoading(false);
      return [];
    }
  };

  const focusTextEditor =  (e:  React.KeyboardEvent<HTMLDivElement>, reference: React.RefObject<ReactQuill>) => {
    if (e.key === 'Tab' ) {
      e.preventDefault()
      e.stopPropagation()
      reference.current && reference.current.focus()
    }
  }

  const handleEditorRef = (ref:any) => {
    setEditorData(ref);
  };

  return (
    <React.Fragment>
      <div className="p-[30px]">
        {/* Breadcrumbs component */}
        <Breadcrumbs breadcrumbs={[id ? "Update Fundamental" : "Add New Fundamental"]} />

        <div>
          {/* Form */}
          <form onChange={() => setServerError(null)}>
            <div className="py-6">
              <div className="mb-8 flex flex-col gap-[30px] xl:flex-row">
                {/* Name */}

                <div className="w-full xl:w-1/3">
                  <InputField
                    label="Name"
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Name"
                    serverError={serverError || ""}
                    formik={formik}
                    showIcon
                    bgColor={"#fff"}
                  />
                </div>

                {/* Ticker */}

                <div className="w-full xl:w-1/3">
                  <InputField
                    label="Ticker"
                    id="ticker"
                    name="ticker"
                    type="text"
                    placeholder="Ticker"
                    serverError={serverError || ""}
                    formik={formik}
                    showIcon
                    bgColor={"#fff"}
                  />
                </div>

                {/* Sector */}
                <div className="w-full xl:w-1/3">
                  <InputField
                    label="Sector"
                    id="sectorId"
                    name="sectorId"
                    type="select"
                    optionList={sectorList}
                    placeholder="Sector"
                    serverError={serverError || ""}
                    formik={formik}
                    labelKey="label"
                    valueKey="value"
                    showIcon
                    bgColor={"#fff"}
                    last
                  />
                </div>
              </div>


              <div className="w-full mb-8">
                {/* Description */}

                <div className="w-full xl:w-full">
                  <InputField
                    label="Description"
                    id="description"
                    name="description"
                    type="text"
                    placeholder="Description"
                    serverError={serverError || ""}
                    formik={formik}
                    showIcon
                    last
                    bgColor={"#fff"}
                  />
                </div>
              </div>

              <div className="mb-8 flex flex-col gap-[30px] xl:flex-row">
                {/* Incorporation  */}
                <div className="w-full  xl:w-1/5">
                  <InputField
                    label="Incorporation"
                    id="incorp"
                    name="incorp"
                    type="text"
                    placeholder="Incorporation"
                    serverError={serverError || ""}
                    formik={formik}
                    showIcon
                    bgColor={"#fff"}
                  />
                </div>

                {/* Headquarters  */}

                <div className="w-full xl:w-1/5">
                  <InputField
                    label="Headquarters"
                    id="hq"
                    name="hq"
                    type="text"
                    placeholder="Headquarters"
                    serverError={serverError || ""}
                    formik={formik}
                    showIcon
                    bgColor={"#fff"}
                  />
                </div>

                {/* Geo Exposure  */}

                <div className="w-full xl:w-1/5">
                  <InputField
                    label="Geo Exposure"
                    id="geoExposure"
                    name="geoExposure"
                    type="text"
                    placeholder="Geo Exposure"
                    serverError={serverError || ""}
                    formik={formik}
                    showIcon
                    bgColor={"#fff"}
                    last
                  />
                </div>
                {/* Date IPO */}

                <div className="w-full xl:w-1/5">
                  <InputField
                    label="Date IPO"
                    id="dateIpo"
                    name="dateIpo"
                    type="date"
                    placeholder="Date IPO"
                    serverError={serverError || ""}
                    formik={formik}
                    showIcon
                    bgColor={"#fff"}
                    last={true}
                  />
                </div>
                {/* Address */}

                <div className="w-full xl:w-1/5">
                  <InputField
                    label="Address"
                    id="address"
                    name="address"
                    type="text"
                    placeholder="Address"
                    serverError={serverError || ""}
                    formik={formik}
                    showIcon
                    bgColor={"#fff"}
                    last
                    onKeyDownCapture={(e) => focusTextEditor(e, $whatMatters)}
                  />
                </div>
              </div>
              {/* What matters */}
              <div className="mb-8">
                <div className="relative" onKeyDownCapture={(e) => focusTextEditor(e, $businessDescription)}>
                  <TextEditor
                    formik={formik}
                    error={serverError?.content}
                    id="whatMatters"
                    quillRef={$whatMatters}
                    name="whatMatters"
                    label="What Matters"
                    onEditorRef={handleEditorRef}
                  />
                </div>
              </div>
              {/* Business description */}
              <div className="mb-8">
                <div className="relative" onKeyDownCapture={(e) => focusTextEditor(e, $companyHistory)}>
                  <TextEditor
                      formik={formik}
                      quillRef={$businessDescription}
                      error={serverError?.content}
                      id="businessDesc"
                      name="businessDesc"
                      label="Business Description"
                      onEditorRef={handleEditorRef}
                  />
                </div>
              </div>

              <div className="mb-8">
                <div className="relative" onKeyDownCapture={(e) => focusTextEditor(e, $companyBasics)}>
                  <TextEditor
                    formik={formik}
                    error={serverError?.content}
                    id="companyHistory"
                    quillRef={$companyHistory}
                    name="companyHistory"
                    label="Company History"
                    onEditorRef={handleEditorRef}
                  />
                </div>
              </div>

              <div className="mb-8">
                <div className="relative" onKeyDownCapture={(e) => focusTextEditor(e, $driversValuation)}>
                  <TextEditor
                    formik={formik}
                    error={serverError?.content}
                    quillRef={$companyBasics}
                    id="companyBasics"
                    name="companyBasics"
                    label="Company Basics"
                    onEditorRef={handleEditorRef}
                  />
                </div>
              </div>

              <div className="mb-8">
                <div className="relative" onKeyDownCapture={(e) => focusTextEditor(e, $valuationHistory)}>
                  <TextEditor
                    formik={formik}
                    quillRef={$driversValuation}
                    error={serverError?.content}
                    id="driversValuation"
                    name="driversValuation"
                    label="Drivers / Valuation"
                    onEditorRef={handleEditorRef}
                  />
                </div>
              </div>

              <div className="mb-8">
                <div className="relative" onKeyDownCapture={(e) => focusTextEditor(e, $tags)}>
                  <TextEditor
                    formik={formik}
                    error={serverError?.content}
                    quillRef={$valuationHistory}
                    id="valuationHistory"
                    name="valuationHistory"
                    label="Valuation History"
                    onEditorRef={handleEditorRef}
                  />
                </div>
              </div>

              {/* Tags */}

              <div className="w-full mb-8">
                <AsyncSelect
                  label="Tags"
                  name="tags"
                  id="tags"
                  placeholder="Tags"
                  fetchOptions={fetchTags}
                  formik={formik}
                  loading={tagLoading}
                  error={""}
                  isMulti
                  bgColor="#FFF"
                  tagRef={$tags}
                  last
                  noOptionsMessage="No tag found"
                  tags
                />
              </div>

              {/* Company */}


              <div className="border border-[#E4E3ED] rounded-lg  mt-[30px]">
                <div className="px-[23px] py-[30px] border-b border-b-[#E5E4FB]">
                  <h2 className="text-[#000] text-lg font-bold">File</h2>
                </div>
                <div className="px-[23px] py-[30px]">
                  <FileUpload formik={formik} />
                </div>
              </div>

              <div className="flex justify-end gap-[30px] mt-[80px]">
                <div>
                  <button
                    type="button"
                    className="py-[18px] border border-secondary text-secondary text-base font-medium rounded-full px-[116px]"
                    onClick={() => navigate("/fundamental")}
                  >
                    Cancel
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    name="Save"
                    ref={submitButtonRef}
                    onClick={formik.handleSubmit}
                    className="py-[18px] border border-secondary text-[#fff] bg-primary-default text-base font-medium rounded-full px-[103px]"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div >
      {loading && <Loader loading={loading} />}
    </React.Fragment >
  );
};

export default AddEditFundamental;
