// React
import {useRef} from "react";
// Third party
import ReactQuill from "react-quill";
// Components
import DeleteTooltip from "../../Delete-Tooltip";
import TextEditor from "../../TextEditor";

// Types
import { StepBase } from "..";

interface StepThreeProps extends StepBase {
  Minus: string;
  Plus: string;
}

export default function StepThree(props: StepThreeProps) {
  const {
    association,
    deleteID,
    deleteModal,
    formik,
    handleRemoveAssociation,
    Minus,
    Plus,
    setAssociation,
    setDeleteID,
    setDeleteModal,
    onEditorRef
  } = props;
  const $whatMatters = useRef<ReactQuill>(null);
  // Add entry
  const handleAddEntry = () => {
    formik.setValues({
      ...formik.values,
      whatMatters: [...formik.values.whatMatters, { note: "", mnaId: -1 }],
    });
  };

  return (
    <>
      {/* <div className="border border-[#E4E3ED] rounded-lg  mt-[30px]">
        <div className="px-[23px] py-[30px]">
          <div className="flex flex-col gap-[30px] xl:flex-row"> */}
      {/* Description */}
      {/* <div className="w-full">
              <InputField
                label="Description"
                id="reviewed"
                name="reviewed"
                type="textarea"
                rows={4}
                placeholder="Enter here..."
                formik={formik}
                serverError={serverError || ""}
                showIcon
                last={true}
              />
            </div> */}
      {/* </div>
        </div>
      </div> */}

      <div className="border border-[#E4E3ED] rounded-lg dark:bg-table-dark bg-table-light" onKeyDownCapture={(e) => {
        if (e.key === 'Tab' ) {
          e.preventDefault()
          e.stopPropagation()
          $whatMatters.current && $whatMatters.current.focus()
        }
      }}>
        <div className="px-[23px] py-[30px]">
          <div>
            {/* What Matters */}
            {formik.values.whatMatters?.map((entry: any, index: number) => (
              <div key={index} className="w-full mb-6">
                <div>
                   <TextEditor
                    formik={formik}
                    id={`whatMatters[${index}].note`}
                    quillRef={$whatMatters}
                    name={`whatMatters[${index}].note`}
                    isDynamic
                    value={entry.note}
                    onEditorRef={onEditorRef}
                  />
                  <div className="relative mt-1">
                        <button
                          onClick={() => {
                            setDeleteID(index);
                            setAssociation("whatMatters");
                            setDeleteModal(true);
                            // handleRemoveEntry(index)
                          }}
                          type="button"
                          className="text-secondary text-base font-medium flex items-center gap-[6px]"
                        >
                          <img src={Minus} alt="minus" /> Remove
                        </button>
                        {deleteID === index &&
                          association === "whatMatters" && (
                            <DeleteTooltip
                              tooltip={deleteModal}
                              setTooltip={setDeleteModal}
                              onDeleteItem={handleRemoveAssociation}
                            />
                          )}                     
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-end items-center">
              <button
                type="button"
                data-testid="Addnew"
                onClick={() => {
                  handleAddEntry();
                }}
                className="text-secondary text-lg py-4 rounded-lg font-bold flex items-center justify-center  mt-4 border border-[#C3C1DF] w-1/6 bg-[#FAFAFE] dark:bg-button-dark dark:text-white"
              >
                <img src={Plus} alt="plus" className="dark:brightness-200" /> Add new
              </button>
            </div>
        </div>
      </div>
    </>
  );
}
