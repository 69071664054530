// React
import React, {useRef, useState} from "react";
// Third party
import AsyncCreatableSelect from "react-select/async-creatable";
import ReactSelectAsync from "react-select/async";
// Theme
import { useTheme } from "../../App";
// Images
import Search from "../../Assets/svg/search.svg";

interface AsyncSelectProps {
  formik: any;
  error: any;
  required?: boolean;
  fetchOptions: (value: any) => Promise<{ value: number; label: string }[]>;
  onkeydown?: (event: any) => void;
  loading: boolean;
  id: string;
  name: string;
  placeholder: string;
  label?: string;
  isMulti?: boolean;
  last?: boolean;
  noOptionsMessage: string;
  defaultOptions?: boolean;
  value?: any;
  bgColor?: string;
  tags?: boolean;
  isCustom?: boolean;
  tagRef?: any
}

const AsyncSelect: React.FC<AsyncSelectProps> = ({
  formik,
  error,
  required,
  fetchOptions,
  loading,
  id,
  name,
  placeholder,
  label,
  isMulti,
  last,
  noOptionsMessage,
  defaultOptions,
  onkeydown,
  value,
  bgColor,
  tags,
  isCustom,
                                                   tagRef
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const theme = useTheme();
  const $tagRef = useRef<any>();
  const CustomDropdownIndicator = (props: any) => {
    return (
      <div style={{ cursor: "pointer" }} onClick={() => props.selectProps.onChange(props.selectProps.value)}>
        {" "}
        <img src={Search} alt="dropdown" />
      </div>
    );
  };

  interface State {
    isSelected: boolean;
  }

  const customStyles = {
    menu: (provided: {}) => ({
      ...provided,
      left: "0px", // Set your desired height here
      backgroundColor: theme.theme === "light" ? "#fff" : "#202020",
    }),
    option: (provided: {}, state: State) => ({
      ...provided,
      // backgroundColor: state.isSelected ? "#f0f0f0" : "white",
      backgroundColor: theme.theme === "light" ? "" : "#202020",
      color: state.isSelected ? "#333" : "#666",
      borderRight: "none",
    }),
    control: (provided: {}) => ({
      ...provided,
      padding: "0", // Remove input padding
      border: "0px", // Remove input border
      backgroundColor: "transparent",
      boxShadow: "0px",
      "&:hover": {
        // Add the hover styles to maintain the default color
        border: "0px",
      },
      "&:focus": {
        borderColor: "transparent", // Remove blue border on focus
      },
    }),
    valueContainer: (provided: {}) => ({
      ...provided,
      padding: "0px", // Remove padding for the value container
    }),
    indicatorsContainer: (provided: {}) => ({
      ...provided,
      padding: "0px",
      backgroundColor: "transparent",
    }),
    dropdownIndicator: (provided: {}) => ({
      ...provided,
      display: "none", // Hide the default dropdown indicator
    }),
    placeholder: (provided: {}) => ({
      ...provided,
      color: "#999", // Adjust the color of the placeholder
    }),
    singleValue: (provided: {}) => ({
      ...provided,
      color: theme.theme === "light" ? "#333" : "#FFF",
      backgroundColor: isCustom ? "#E5E5E5" : "",
      margin: isCustom ? "2px" : "",
      padding: isCustom ? "3px" : "",
      borderRadius: isCustom ? "2px" : "",
      width: isCustom ? "fit-content" : ""
    }),
    input: (provided: {}) => ({
      ...provided,
      margin: "0px", // Remove the input margin
      color: theme.theme === "light" ? "black" : "white",
    }),
    focus: (provided: {}) => ({
      ...provided,
      borderColor: "transparent", // Set focus border color to blue
    }),
  };

  return (
    <React.Fragment>
      {" "}
      <div className="flex justify-between items-center">
        <label htmlFor="Content" className="text-base  text-[#393C51]  dark:text-white  font-medium mb-2 flex gap-1">
          {label} {required && <span className="text-[#DB1A21]">*</span>}
        </label>

        <div className={` ${last ? "tooltip2" : "tooltip"}`}>
          <div
            className={`bg-[#fff]  bottom-9   text-[#393C51]  dark:text-white dark:bg-primary-dark  transition-all  text-sm  z-50 tooltiptext w-[328px] px-[14px] pt-[12px] pb-[17px] rounded-[10px] border dark:before:border-t-primary-dark ${error[name]
              ? "border-[#DB1A21] after:border-transparent after:border-t-[#DB1A21] before:top-[97%]"
              : "border-[#4185EE] after:border-transparent after:border-t-[#4185EE]  before:top-[99%]"
              } ${last ? " tooltiptext2" : " tooltiptext"}`}
          >
            {error[name]
              ? error[name]
              : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."}
          </div>
          <svg
            className={"dark:brightness-[4]"}
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
          >
            <path
              d="M9 0.5C4.02571 0.5 0 4.52525 0 9.5C0 14.4742 4.02525 18.5 9 18.5C13.9743 18.5 18 14.4747 18 9.5C18 4.52571 13.9747 0.5 9 0.5ZM9 17.2442C4.72985 17.2442 1.25582 13.7702 1.25582 9.5C1.25582 5.22982 4.72985 1.75582 9 1.75582C13.2702 1.75582 16.7442 5.22982 16.7442 9.5C16.7442 13.7702 13.2702 17.2442 9 17.2442Z"
              fill={` ${error[name] ? "#DB1A21" : "#1E87F0"}`}
            />
            <path
              d="M8.73354 11.8881C8.23597 11.8881 7.83319 12.3027 7.83319 12.8002C7.83319 13.286 8.22413 13.7125 8.73354 13.7125C9.24295 13.7125 9.6457 13.286 9.6457 12.8002C9.6457 12.3027 9.23107 11.8881 8.73354 11.8881ZM8.88752 4.98145C7.28819 4.98145 6.55371 5.92922 6.55371 6.56893C6.55371 7.03095 6.94465 7.24421 7.2645 7.24421C7.90424 7.24421 7.64363 6.33197 8.85198 6.33197C9.44429 6.33197 9.9182 6.59262 9.9182 7.13758C9.9182 7.77728 9.25477 8.14452 8.86383 8.47622C8.52025 8.77234 8.07011 9.2581 8.07011 10.2769C8.07011 10.8929 8.23597 11.0706 8.72166 11.0706C9.30212 11.0706 9.4206 10.81 9.4206 10.5849C9.4206 9.96889 9.43245 9.61349 10.084 9.10408C10.4039 8.85531 11.4108 8.04971 11.4108 6.93613C11.4108 5.82256 10.4039 4.98145 8.88752 4.98145Z"
              fill={` ${error[name] ? "#DB1A21" : "#1E87F0"}`}
            />
          </svg>{" "}
        </div>
      </div>
      <div>
        <div className="relative">
          {tags ? (
            <AsyncCreatableSelect
              isMulti={isMulti}
              defaultOptions={defaultOptions}
              name={name}
              noOptionsMessage={() => <p>{noOptionsMessage}</p>}
              placeholder={placeholder}
              id={id}
              ref={tagRef || $tagRef}
              loadOptions={async (inputValue) => await fetchOptions(inputValue)}
              value={formik.values[name] || value || ""}
              onBlur={formik.handleBlur}
              onChange={(option) => formik.setFieldValue(name, option)}
              onKeyDown={onkeydown}
              isClearable
              isSearchable
              components={{
                DropdownIndicator: (props) => <CustomDropdownIndicator {...props} selectProps={props.selectProps} />,
              }}
              className={` dark:bg-primary-dark dark:border-white py-[11px] px-[30px] border ${error[name] ? "border-danger" : "border-[#C3C1DF]"
                }  rounded-full text-base text-[#7B7B7B] font-light w-full  outline-none  bg-[${bgColor ? bgColor : "#FAFAFE"
                }]`}
              styles={customStyles}
              onInputChange={(input) => setSearchTerm(input)}
              inputValue={searchTerm || ""}
              isLoading={loading}
            />
          ) : (
            <ReactSelectAsync
              isMulti={isMulti}
              defaultOptions={defaultOptions}
              name={name}
              noOptionsMessage={() => <p>{noOptionsMessage}</p>}
              placeholder={placeholder}
              id={id}
              loadOptions={(inputValue) => fetchOptions(inputValue)}
              value={formik.values[name] || value}
              onBlur={(e) => {
                const value = e.target.value 
                  if ((name === "target" || name === "acquirer") && !formik.values[name]?.label) {
                    formik.setValues({ ...formik.values, [name]: value ? value : null})
                  }
              }}
              onChange={(option) => {
                formik.setFieldValue(name, option)
              }}
              onKeyDown={onkeydown}
              isClearable
              isSearchable
              components={{
                DropdownIndicator: (props) => <CustomDropdownIndicator {...props} selectProps={props.selectProps} />,
              }}
              className={`dark:text-white dark:placeholder:text-white dark:border-white dark:bg-primary-dark py-[11px] px-[30px] border ${error[name] ? "border-danger" : "border-[#C3C1DF]"
                }  rounded-full text-base text-[#7B7B7B] font-light w-full  outline-none  bg-[${bgColor ? bgColor : "#FAFAFE"
                }]`}
              styles={customStyles}
              onInputChange={(input) => setSearchTerm(input)}
              inputValue={searchTerm || ""}
              isLoading={loading}
            />
          )}
          {/*
            <ReactSelectAsync
              isMulti={isMulti}
              defaultOptions={defaultOptions}
              name={name}
              noOptionsMessage={() => <p>{noOptionsMessage}</p>}
              placeholder={placeholder}
              id={id}
              loadOptions={async (inputValue) => fetchOptions(inputValue)}
              value={formik.values[name] || value || ""}
              onBlur={(event) => {
                if (!selectedOption && searchTerm.trim() !== "") {
                  const newValue = { value: searchTerm, label: searchTerm };
                  updateFieldValue(newValue);
                }
                formik.handleBlur(event);
              }}
              onChange={(option, actionMeta) => {
                if (actionMeta.action === 'select-option') {
                  updateFieldValue(option);
                } else if (actionMeta.action === 'clear') {
                  updateFieldValue(null);
                }
              }}
              onKeyDown={onkeydown}
              isClearable
              isSearchable
              components={{
                DropdownIndicator: (props) => <CustomDropdownIndicator {...props} selectProps={props.selectProps} />,
              }}
              className={`py-[11px] px-[30px] border ${error[name] ? "border-danger" : "border-[#C3C1DF]"
                }  rounded-full text-base text-[#7B7B7B] font-light w-full  outline-none  bg-[${bgColor ? bgColor : "#FAFAFE"
                }]`}
              styles={customStyles}
              onInputChange={handleInputChange}
              inputValue={searchTerm}
              isLoading={loading}
            />

              */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default AsyncSelect;
