// React
import React, { useCallback, useState } from "react";
// Third party
import { useNavigate } from "react-router-dom";
// Components
import Breadcrumbs from "../Components/Breadcrumbs/Breadcrumbs";
// Images
import RegulatoryIcon from "../Assets/svg/Regulatory.svg";
import { expiredTokenValidation } from "../api/expiredTokenValidation";
import Table from "../Components/Tables/Mna-Table";
import { Columns } from "../Components/Columns/Columns";
import { RegulatoryRestControllerApi } from "../openapi";
import { apiConfig } from "../Components/ConfigurationApi/Configuration";
import { Sort } from "../interfaces/pages/variedInterfaces";

const RegulatoryList = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const fetchFundamentalList = useCallback(
    async (pageIndex: number, pageSize: number, sortBy: Sort[]) => {
      setLoading(true);
      try {
        const api = new RegulatoryRestControllerApi(apiConfig());
        const sort:any = sortBy.length
          ? [`${sortBy[0].id},${sortBy[0].desc ? "DESC" : "ASC"}`]
          : "";
        const response = await api.getRegulatory(pageIndex, pageSize, sort);
        return response.data;
      } catch (error) {
        expiredTokenValidation(error);
      } finally {
        setLoading(false);
      }
    },
    [search]
  );

  const columnsInstance = new Columns();

  return (
    <React.Fragment>
      <div className="p-[30px]">
        <Breadcrumbs
          breadcrumbs={["Regulatory"]}
          icon={RegulatoryIcon}
          addItem={() => navigate("/regulatory/add")}
        />
        <div className="mt-[31px]">
          <Table
            columns={columnsInstance.RegulatoryList(navigate)}
            fetchData={fetchFundamentalList}
            loading={loading}
          />
        </div>{" "}
      </div>
    </React.Fragment>
  );
};

export default RegulatoryList;
