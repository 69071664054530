// React
import { useEffect, useState } from "react";
// Third party
import { useParams } from "react-router-dom";
// OpenAPI
import { ErrorLogRestControllerApi } from "../openapi";
import { apiConfig } from "../Components/ConfigurationApi/Configuration";
import { expiredTokenValidation } from "../api/expiredTokenValidation";

export default function ErrorDetails() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>();

  const fetchNoteData = async () => {
    setLoading(true);
    try {
      const api = new ErrorLogRestControllerApi(apiConfig());

      const response = await api.getErrorLogById(Number(id));
      setData(response.data);
    } catch (error) {
      expiredTokenValidation(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchNoteData();
  }, []);

  const keyValuePairs = data?.requestHeaders.split("\n");

  return (
    <div>
      <div className="w-full bg-gray-200 dark:bg-gray-900">
        <div className="container-fluid mx-auto flex items-start justify-center">
          <div className="w-full p-6">
            <ul className="divide-y divide-[#DDDBEE] rounded-md border border-[#DDDBEE]">
              {data &&
                Object.keys(data)?.map((key: any, index: any) => {
                  return (
                    !["requestHeaders", "stackTrace"].includes(key) && (
                      <li className="w-full flex">
                        <div className="w-1/4 px-4 py-2 border-r border-[#DDDBEE] font-courier">
                          {key}
                        </div>
                        <div className="w-3/4 px-4 py-2 font-courier">
                          {data[key]}
                        </div>
                      </li>
                    )
                  );
                })}
              {keyValuePairs &&
                keyValuePairs?.map((pair: any, index: any) => {
                  const [key, value] = pair.split(":");
                  return (
                    pair !== "" && (
                      <li className="w-full flex">
                        <div className="w-1/4 px-4 py-2 border-r border-[#DDDBEE] font-courier">
                          {key?.trim()}
                        </div>
                        <div className="w-3/4 px-4 py-2 font-courier break-words">
                          {value?.trim()}
                        </div>
                      </li>
                    )
                  );
                })}
              <li className="w-full flex">
                <div className="w-1/4 px-4 py-2 border-r border-[#DDDBEE] font-courier">
                  stackTrace
                </div>
                <div className="w-3/4 px-4 py-2 font-courier break-words">
                  {data?.stackTrace}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
