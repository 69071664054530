// API
import { apiConfig } from "../Components/ConfigurationApi/Configuration";
import {
  ConditionStatusRestControllerApi,
  ErrorLogRestControllerApi,
  FeatureControllerApi,
  FundamentalRestControllerApi,
  MnaRestControllerApi,
  NoteRestControllerApi,
  RegulatoryRestControllerApi,
  SectorRestControllerApi,
  TagRestContollerApi,
} from "../openapi";
// Helpers
import { getSortArray } from "../helpers/getSortArray";
// Types
import { Sort } from "../interfaces/pages/variedInterfaces";

export class getApiData {
  async getMnaRestControllerApi(search: string, pageIndex: number, pageSize: number, sortBy: Sort[]) {
    const api = new MnaRestControllerApi(apiConfig());
    const response = search
      ? await api.getMnas(`name:${search}`, pageIndex, pageSize, getSortArray(sortBy))
      : await api.getMnas("", pageIndex, pageSize, getSortArray(sortBy));

    return response.data;
  }
  async getFeatureControllerApi() {
    const api = new FeatureControllerApi(apiConfig());
    const response = await api.getFeatures(undefined, undefined, undefined);

    const filteredOptions = response.data.content!.map((item: any) => ({
      value: item.id,
      label: item.name,
    }));

    return filteredOptions;
  }
  async getSectorRestControllerApi(response: any) {
    const api2 = new SectorRestControllerApi(apiConfig());
    const response2 = await api2.getSectors(undefined, 0, 20, undefined);
    if (response?.data?.content) {
      const content =
        response.data.content?.map((item: any) => {
          response2.data.content?.forEach((sector: any) => {
            if (sector.id === item.sectorId) {
              item.sectorId = sector.name;
            }
          });
          return item;
        }) || [];

      return content;
    } else {
      return response2;
    }
  }
  async getCompanyRestControllerApi(id: number) { }
  async getTagRestContollerApi(inputValue: string) {
    const api = new TagRestContollerApi(apiConfig());
    const response = await api.getTags(`${inputValue}`, undefined, undefined, undefined);
    return response.data.content!.map((item: any) => ({
      value: item.id,
      label: item.tag,
    }));
  }
  async getNoteRestControllerApi(id: string | undefined, pageIndex: number, pageSize: number, sortBy: Sort[]) {
    const api = new NoteRestControllerApi(apiConfig());
    const response = await api.getNotes("", id, pageIndex, pageSize, getSortArray(sortBy));
    return response.data;
  }
  async getErrorLogRestControllerApi(pageIndex: number, pageSize: number, sortBy: Sort[]) {
    const sort = sortBy.length
      ? [`${sortBy[0].id},${sortBy[0].desc ? "DESC" : "ASC"}`]
      : "";
    const pageable = { page: pageSize, size: pageIndex, sort } as any
    const api = new ErrorLogRestControllerApi(apiConfig());
    const response = await api.getErrorLogs(pageable)
    return response.data;
  }
  async getMnaRestControllerApi2(id: string | undefined, fetchFeatures: any) {
    const api = new MnaRestControllerApi(apiConfig());
    const response = await api.getMnaById(Number(id));

    const api2 = new ConditionStatusRestControllerApi(apiConfig());
    const response2 = await api2.getConditionStatuses(undefined, 0, 20, undefined);

    response.data.conditions?.map((item: any) => {
      const statusMatch = response2.data.content?.find((status: any) => status.id === item.statusId);

      if (statusMatch) {
        item.statusId = statusMatch.status;
      }
      return item;
    });

    const featureOptions = await fetchFeatures();
    const features =
      response.data?.features?.map((item: any) => {
        return {
          ...item,
          name: featureOptions?.find((value: any) => value.value === item.featureId)?.label,
        };
      }) || [];

    return { ...response.data, features };
  }
  async getNoteRestControllerApi2(id: number, pageIndex: number, pageSize: number, sortBy: Sort[], module: string) {
    const sort = sortBy.length
      ? [`${sortBy[0].id},${sortBy[0].desc ? "DESC" : "ASC"}`]
      : "";
    const pageable = { page: pageIndex, size: pageSize, sort } as any;

    const api = new MnaRestControllerApi(apiConfig());
    const funApi = new FundamentalRestControllerApi(apiConfig());
    const regApi = new RegulatoryRestControllerApi(apiConfig());

    const response = module === "Mna"
      ? await api.getMnaNotes(id, pageable) : module === "Fundamental" ? await funApi.getFundamentalNotes(id, pageable)
        : await regApi.getRegulatoryNotes(id, pageable)

    return response.data;
  }
}
