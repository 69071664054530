// React
import React, { useEffect, useRef, useState } from "react";
// Thrid party
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
// Components
import Alerts from "../Components/Alerts";
import { RootState } from "../store/store";
// There
import { useTheme } from "../App";
// Data
import { navbarData } from "../data/navbarData";
// Images
import arrow from "../Assets/images/arrow.png";
import BurguerMenu from "../Assets/svg/burguer-menu.svg";
import DarkLogo from "../Assets/images/logo_dark.png";
import LightLogo from "../Assets/images/logo_light.png";
import Search from "../Assets/svg/search.svg";
// Icons
import { faToggleOff, faToggleOn } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserMenuDropdown from "../Components/Profile-Menu";
import { apiConfig } from "../Components/ConfigurationApi/Configuration";
import { SearchRestControllerApi } from "../openapi";

interface Data {
  path: string;
  icon: string;
  active: string;
  name: string;
}

interface Mna {
  id: number;
  name: string;
  categoryName: string;
}

interface Note {
  id: number;
  title: string;
  content: string;
}

const MenuItem: React.FC<Data> = ({ path, icon, active, name }) => {
  const { pathname } = useLocation();
  const { id } = useParams<{ id: string }>();

  const isActive =
    pathname === path ||
    ((pathname === "/notes/add" || pathname === `/update-note/${id}`) &&
      path === "/notes") ||
    ((pathname === "/add-mna" ||
      pathname === `/add-mna/${id}` ||
      pathname === `/update-mna/${id}` ||
      pathname === `/mna/${id}`) &&
      path === "/mnas") ||
    ((pathname === "/add-fundamental" ||
      pathname === `/update-fundamental/${id}` ||
      pathname === `/fundamental/${id}`) &&
      path === "/fundamental");

  const getBorderColor = () => {
    return isActive ? "border-[#F3797E]" : "border-transparent";
  };

  const getTextClass = () => {
    return isActive ? "text-secondary" : "";
  };

  return (
    <li className={`border-b-[3px] py-[24px] ${getBorderColor()}`}>
      <Link to={path}>
        <div className="flex gap-[6px] items-center p-[10px]">
          <div>
            <img src={isActive ? active : icon} alt="active" />
          </div>
          <div>
            <p
              className={`text-[#393C51}] text-base font-normal ${getTextClass()}`}
            >
              {name}
            </p>
          </div>
        </div>
      </Link>
    </li>
  );
};


const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { pathname } = useLocation();
  const role = localStorage.getItem("role");
  const username = useSelector((state: RootState) => state.user.username);
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const navigateDashboard = () => {
    navigate("/dashboard");
  };

  const closeMenu = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", closeMenu);
    return () => {
      document.removeEventListener("click", closeMenu);
    };
  }, []);

  const filteredNavbarData =
    role === "ROLE_ADMIN"
      ? navbarData
      : navbarData.filter((item) => item.pathname !== "error");

  const fetchSearchResults = async (searchQuery: string) => {
    setLoading(true);
    try {
      const api = new SearchRestControllerApi(apiConfig());
      const response: any = await api.search(searchQuery);
      return response.data;
    } catch (error) {
      console.error("Error fetching search results", error);
    } finally {
      setLoading(false);
    }
  };

  const handleNavigate = (id: number, path: string) => {
    navigate(`/${path}/${id}`);
    setSearchResults(false);
  };


  const useDebounce = (value: any, delay: any) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);

    return debouncedValue;
  };

   const [query, setQuery] = useState("");
  const [showHistory, setShowHistory] = useState(false);
  const [searchHistory, setSearchHistory] = useState<string[]>([]);
  const [searchResults, setSearchResults] = useState<any>(null);
  const [autocompleteSuggestions, setAutocompleteSuggestions] = useState<any>([]);
  const [currentOperator, setCurrentOperator] = useState("");
  const searchContainerRef = useRef<any>(null);

  const debouncedQuery = useDebounce(query, 300);
  
  useEffect(() => {
    if (debouncedQuery) {
      handleSearch();
    } else {
      setSearchResults(null);
      setShowHistory(false);
    }
  }, [debouncedQuery]);

  const handleSearch = async () => {
    setLoading(true);
    try {
      const results = await fetchSearchResults(debouncedQuery);
      setSearchResults(results);
      setShowHistory(false);
      setSearchHistory((prevHistory: any) => [
        debouncedQuery,
        ...prevHistory.filter((item: any) => item !== debouncedQuery),
      ]);
    } catch (error) {
      console.error("Error fetching search results:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleAutocomplete = (suggestion: any) => {
    setQuery(`${currentOperator}:${suggestion}`);
    setAutocompleteSuggestions([]);
  };

  const handleHistoryClick = (item: any) => {
    setQuery(item);
    handleSearch();
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target)
      ) {
        setShowHistory(false);
        setAutocompleteSuggestions([]);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleRemoveHistoryItem = (itemToRemove: any) => {
    setSearchHistory((prevHistory: any) =>
      prevHistory.filter((item: any) => item !== itemToRemove)
    );
    setShowHistory(true);
  };

  return (
    <header className="sticky top-0 z-10 bg-[#FFFFFF] dark:bg-black">
      <div
        className="flex flex-wrap items-center justify-between mx-auto px-[30px]"
        style={{ boxShadow: "0px 8px 28px 0px rgba(72, 89, 102, 0.10)" }}
      >
        <div className="flex items-center gap-8">
          {/* App Logo */}
          <div className="my-2 pr-[52px] border-e	dark:border-white">
            <img
              src={theme.theme === "light" ? LightLogo : DarkLogo}
              alt="Logo"
              className="cursor-pointer"
              onClick={navigateDashboard}
            />
          </div>
          {/* Sidebar */}
          <div ref={menuRef}>
            <button type="button" className="" onClick={toggleMenu}>
              <img
                src={BurguerMenu}
                alt="butguer menu"
                className="h-5 dark:invert"
              />
            </button>
            <div
              className={`${
                isOpen ? "translate-x-0" : "-translate-x-full"
              } fixed top-[79px] py-4 flex flex-col justify-between left-0 w-[284px] h-full transition-all duration-500 transform bg-[rgb(14,111,166)] shadow-lg peer-checked:translate-x-0 
              dark:bg-[#02324D]`}
            >
              <div>
                <div className="space-y-1 pb-3 pt-2">
                  {filteredNavbarData.map((item, index) => (
                    <Link
                      key={`navbar-${item.title}-${index}`}
                      to={`/${item.pathname}`}
                      onClick={() => setIsOpen(false)}
                      className={`${
                        pathname === `/${item.pathname}`
                          ? "text-[#F2F1FD] bg-[rgb(9,89,142)] dark:text-[#F2F1FD] dark:bg-[rgb(9,89,142)]"
                          : "text-[#F2F1FD]"
                      }   block px-8 py-2 2xl:py-4 text-base font-medium hover:bg-[#09598E] transition-all duration-500 ease-out`}
                    >
                      <div className="flex gap-[6px] items-center">
                        <div>
                          <img src={item.lightIcon} alt="active" className="" />
                        </div>
                        <div>
                          <p
                            className={`text-[#393C51}] text-base font-normal`}
                          >
                            {item.title}
                          </p>
                        </div>
                        <div>
                          <img
                            className="w-4 rotate-90 absolute right-2 mt-[-8px]"
                            src={arrow}
                            alt=""
                          />
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="flex">
            <div
              ref={searchContainerRef}
              className={`${
                showHistory || searchResults
                  ? "rounded-tl-2xl rounded-tr-2xl"
                  : "rounded-2xl"
              } relative hidden lg:block   bg-b-secondary-light dark:bg-b-secondary-dark lg:w-[36vw] lg:max-w-7xl md:max-w-full`}
            >
              <div className={`relative flex justify-between items-center`}>
                <input
                  type="text"
                  placeholder="Search here......"
                  value={query}
                  onChange={(e) => {
                    const value = e.target.value;
                    setQuery(value);
                    const match = value.match(/(ticker|company):/);
                    setCurrentOperator(match ? match[1] : "");
                  }}
                  onFocus={() => setShowHistory(true)}
                  onBlur={() =>
                    setTimeout(() => {
                      // Hide history and autocomplete suggestions only if click is outside
                      if (
                        !searchContainerRef.current.contains(
                          document.activeElement
                        )
                      ) {
                        setShowHistory(false);
                        setAutocompleteSuggestions([]);
                      }
                    }, 200)
                  } // Delay to allow click
                  onKeyDown={(e) => {
                    if (e.key === "Tab" && autocompleteSuggestions.length > 0) {
                      e.preventDefault();
                      handleAutocomplete(autocompleteSuggestions[0]);
                    }
                  }}
                  className={`text-sm font-normal w-full bg-transparent p-4 pr-12 outline-none placeholder:text-black dark:placeholder:text-white text-black dark:text-white`}
                />
                <img
                  src={Search}
                  alt="search"
                  className="absolute top-4 right-6 brightness-0 dark:brightness-[100]"
                />
              </div>
              <div
                className={`relative ${
                  loading &&
                  " bg-b-secondary-light dark:bg-b-secondary-dark rounded-br-2xl rounded-bl-2xl "
                }`}
              >
                {showHistory && !query && (
                  <div
                    className={` max-h-96 overflow-y-auto absolute w-full  z-10 ${
                      showHistory || searchResults
                        ? "bg-b-secondary-light dark:bg-b-secondary-dark rounded-br-2xl rounded-bl-2xl border-t border-[#E6E6F9]"
                        : ""
                    }`}
                  >
                    {searchHistory.length === 0 ? (
                      <div className="p-4 text-gray-500 text-sm  text-center">
                        No recent searches
                      </div>
                    ) : (
                      searchHistory.filter((item: string) => item.trim() !== "").map((item: string, index: number) => (
                        <div
                        key={index}
                        className="flex items-center justify-between px-4 py-2 cursor-pointer hover:bg-[#E7E8EC] dark:hover:bg-primary-dark"
                      >
                        <div
                          onClick={() => {
                            setQuery(item);
                            handleSearch();
                          }}
                          className="flex items-center w-full overflow-hidden"
                        >
                          <svg
                            className="block w-5 h-5 text-gray-500 mr-2"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M12 8v4m0 4h.01m6.938-4A8 8 0 1112 4a8 8 0 016.938 12z"
                            ></path>
                          </svg>
                          <p className="truncate text-ellipsis overflow-hidden w-full">{item}</p>
                        </div>
                        <button
                          className="text-gray-500 hover:text-gray-700"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevents triggering the onClick for the entire item
                            handleRemoveHistoryItem(item);
                          }}
                        >
                          <svg
                            className="w-4 h-4"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M6 18L18 6M6 6l12 12"
                            ></path>
                          </svg>
                        </button>
                      </div>
                      
                      ))
                    )}
                  </div>
                )}

                {autocompleteSuggestions.length > 0 && (
                  <div
                    className={` max-h-96 overflow-y-scroll absolute w-full  z-10 ${
                      showHistory || searchResults
                        ? "bg-b-secondary-light dark:bg-b-secondary-dark rounded-br-2xl rounded-bl-2xl border-t"
                        : ""
                    }`}
                  >
                    {autocompleteSuggestions.map(
                      (suggestion: any, index: any) => (
                        <div
                          key={index}
                          className="p-2 cursor-pointer hover:bg-gray-200"
                          onClick={() => handleAutocomplete(suggestion)}
                        >
                          {suggestion}
                        </div>
                      )
                    )}
                  </div>
                )}

                {searchResults && debouncedQuery && (
                  <div
                    className={` max-h-96 overflow-y-scroll absolute w-full  z-10 ${
                      showHistory || searchResults
                        ? "bg-b-secondary-light dark:bg-b-secondary-dark rounded-br-2xl rounded-bl-2xl border-t"
                        : ""
                    }`}
                  >
                    {searchResults &&
                      (searchResults.mnas.length === 0 &&
                      searchResults.notes.length === 0 ? (
                        <div className="p-4 text-gray-500 text-sm  text-center">
                          No data found
                        </div>
                      ) : (
                        <div>
                          {searchResults.notes.length > 0 && (
                            <div className="px-4 py-2">
                              <div className="text-lg font-semibold text-dark dark:text-table-light">
                                Notes:
                              </div>
                              {searchResults.notes
                                .slice(0, 3)
                                .map((note: any) => (
                                  <div
                                    key={note.id}
                                    className="flex items-start mt-2 p-2 hover:bg-[#b8bbbf9e] dark:hover:bg-button-dark rounded"
                                  >
                                    <div className="mr-2">
                                      <span className="inline-block bg-primary-dark p-1 rounded-full dark:bg-primary-light"></span>
                                    </div>
                                    <div>
                                      <div
                                        className="text-b-secondary-dark  dark:text-b-secondary-light cursor-pointer"
                                        onClick={() =>
                                          handleNavigate(note.id, "notes")
                                        }
                                      >
                                        {note.title || "Untitled Note"}
                                      </div>
                                      <p className="text-gray-500 text-sm text-wrap text-ellipsis">
                                        {note.content}
                                      </p>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          )}
                          {searchResults.mnas.length > 0 && (
                            <div className="px-4 py-2">
                              <div className="text-lg font-semibold text-dark dark:text-table-light">
                                Mergers and Acquisitions:
                              </div>
                              {searchResults.mnas
                                .slice(0, 3)
                                .map((mna: any) => (
                                  <div
                                    key={mna.id}
                                    className="flex items-start mt-2 p-2 hover:bg-[#b8bbbf9e] dark:hover:bg-button-dark rounded"
                                  >
                                    <div className="mr-2">
                                      <span className="inline-block bg-primary-dark p-1 rounded-full dark:bg-primary-light"></span>
                                    </div>
                                    <div>
                                      <div
                                        className="text-blue-600 text-ellipsis cursor-pointer"
                                        onClick={() =>
                                          handleNavigate(mna.id, "mna")
                                        }
                                      >
                                        {mna.name}
                                      </div>
                                      <p className="text-gray-500 text-sm">
                                        {mna.categoryName}
                                      </p>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center md:order-2  w-full justify-end sm:w-auto">
          <div className="mr-3 cursor-pointer" onClick={theme.toggleTheme}>
            {theme.theme === "dark" ? (
              <FontAwesomeIcon
                icon={faToggleOn}
                className="text-[#808080] text-[20px]"
              />
            ) : (
              <FontAwesomeIcon icon={faToggleOff} className="text-[20px]" />
            )}
          </div>
          <div className="mr-6">
            <Alerts className="w-[30px] h-[30px]" isIcon />
          </div>
          <div className="2xl:block">
            <UserMenuDropdown />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
