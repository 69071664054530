// React
import React, { createContext, useContext, useState } from "react";
// Third party
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { Provider } from 'react-redux';
// Routes
import { PrivateAuthenticated, PublicAuthenticated } from "./Routes/Protected";
import { protectedRoutes, publicRoutes } from "./Routes/Route";
// Styles
import "./App.css";
import TabContainer from "./Components/Tabs/Container";
// store
import { store } from './store/store'; 

const ThemeContext = createContext({ theme: "light", toggleTheme: () => { } });

function App() {
  const themeMode = localStorage.getItem("theme") as "light" | "dark" | null;
  const [theme, setTheme] = useState<"light" | "dark">(themeMode || "light");  // Theme state

  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme); // Save selected theme in local storage
  };
  return (
    <Provider store={store}>
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <Router>
        <Toaster position="bottom-center" reverseOrder={false} />
        <Routes>
          {protectedRoutes.map(({ path, element: Component }) => (
            <Route
              key={path}
              path={path}
              element={
                <PrivateAuthenticated>
                  <Component />
                </PrivateAuthenticated>
              }
            />
          ))}
          {publicRoutes.map(({ path, element: Component }) => (
            <Route
              key={path}
              path={path}
              element={
                <PublicAuthenticated>
                  <Component />
                </PublicAuthenticated>
              }
            />
          ))}
        <Route path="/settings/*" element={<PrivateAuthenticated><SettingsRoutes /></PrivateAuthenticated>} />
        <Route path="/settings" element={<Navigate to="profile" />} />
        </Routes>
      </Router>
    </ThemeContext.Provider>
    </Provider>
  );
}
export const useTheme = () => useContext(ThemeContext);

const SettingsRoutes = () => {
  return (
    <TabContainer  />
  );
};

export default App;
