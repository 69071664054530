import AddEditFundamental from "../Pages/Add-Edit-fundamental";
import AddEditMna from "../Pages/Add-Edit-mna";
import AddEditNote from "../Pages/Add-Edit-note";
import AddEditRegulatory from "../Pages/Add-Edit-regulatory";
import AlertList from "../Pages/Alerts-list";
import Dashboard from "../Pages/Dashboard";
import ErrorDetails from "../Pages/Error-Details";
import ErrorList from "../Pages/Error-list";
import FileList from "../Pages/File-list";
import ForgotPassword from "../Pages/Auth/ForgotPassword";
import FundamentalDetails from "../Pages/Fundamental-Details";
import FundamentalList from "../Pages/Fundamental-list";
import MnaDetails from "../Pages/Mna-Details";
import MnaList from "../Pages/Mna-list";
import NoteDetails from "../Pages/Note-Details";
import NoteList from "../Pages/Note-list";
import RegulatoryDetails from "../Pages/Regulatory-Details";
import RegulatoryList from "../Pages/Regulatory-list";
import Login from "../Pages/Auth/Login";
import ResetPassword from "../Pages/Auth/ResetPassword";
import ValidateToken from "../Pages/Auth/ValidateToken";

interface Route {
  path: string;
  element: React.ComponentType<any>;
}

export const protectedRoutes: Route[] = [
  { path: "/dashboard", element: Dashboard },
  { path: "/real-time-entries", element: Dashboard },
  { path: "/notes", element: NoteList },
  { path: "/notes/add", element: AddEditNote },
  { path: "/notes/:id", element: NoteDetails },
  { path: "/notes/:id/edit", element: AddEditNote },
  { path: "/add-mna", element: AddEditMna },
  { path: "/update-mna/:id", element: AddEditMna },
  { path: "/add-mna/:id", element: AddEditMna },
  { path: "/mnas", element: MnaList },
  { path: "/mna/:id", element: MnaDetails },
  { path: "/regulatory-library", element: Dashboard },
  { path: "/files", element: FileList },
  { path: "/error", element: ErrorList },
  { path: "/error/:id", element: ErrorDetails },
  { path: "/alerts", element: AlertList },
  { path: "/fundamental", element: FundamentalList },
  { path: "/add-fundamental", element: AddEditFundamental },
  { path: "/update-fundamental/:id", element: AddEditFundamental },
  { path: "/fundamental/:id", element: FundamentalDetails },
  { path: "/regulatory", element: RegulatoryList },
  { path: "/regulatory/add", element: AddEditRegulatory },
  { path: "/regulatory/:id", element: RegulatoryDetails },
  { path: "/update-regulatory/:id", element: AddEditRegulatory },
];

export const publicRoutes: Route[] = [
  { path: "/", element: Login },
  { path: "/forgot-password", element: ForgotPassword },
  { path: "/reset/", element: ValidateToken },
  { path: "/reset-password", element: ResetPassword },
];
