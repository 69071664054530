export const processArrayData = (data: any, changedValues: any, keyName: string, id?: string) => {
  const results = data[keyName].reduce(
    (result: any, obj: any, index: number) => {
      if (obj.id) {
        Object.assign(obj, { mnaId: -1 });
        const changedObj: any = changedValues[keyName].find((item: any) => item.id === obj.id);
        delete changedObj.category;
        const allValuesAreNullOrEmpty = Object.keys(changedObj)?.every(
          (key) => key === "mnaId" || obj[key] === changedObj[key]
        );
        if (!allValuesAreNullOrEmpty) {
          result.edit = result.edit || {};
          result.edit = {
            ...result.edit,
            [obj.id]: {
              version: 0,
              featureId: obj.name?.value,
              mnaId: id,
              value: obj.value,
            },
          };
        }
      } else {
        const allValuesAreNullOrEmpty = Object.keys(obj).every(
          (key) => key === "mnaId" || obj[key] === null || obj[key] === ""
        );
        if (!allValuesAreNullOrEmpty) {
          result.add = result.add || [];
          result.add = [
            ...result.add,
            {
              version: 0,
              featureId: obj.name?.value,
              mnaId: obj.mnaId,
              value: obj.value,
            },
          ];
        }
      }
      return result;
    },
    { add: null, edit: null, delete: null }
  );
  changedValues[keyName]?.forEach((item: any) => {
    const deletedObj = data[keyName]?.map((obj: any) => {
      if (obj?.id === item.id) {
        return obj.id;
      } else {
        return null
      }
    });
    if (!deletedObj.includes(item.id)) {
      results.delete = results.delete || [];
      results.delete = [...results.delete, item.id];
    }
  });

  return results;
};

export function processArrayDataGeneral(data: any, changedValues: any, keyName: string, id?: string) {
  const results = data[keyName].reduce(
    (result: any, obj: any, index: number) => {
      if (obj.id) {
        Object.assign(obj);
        const changedObj: any = changedValues[keyName].find((item: any) => item.id === obj.id);
        const allValuesAreNullOrEmpty = Object.keys(changedObj)?.every(
          (key) => key === "mnaId" || obj[key] === changedObj[key]
        );
        if (!allValuesAreNullOrEmpty) {
          result.edit = result.edit || {};
          result.edit = {
            ...result.edit,
            [obj.id]: obj,
          };
        }
      } else {
        const allValuesAreNullOrEmpty = Object.keys(obj).every(
          (key) => key === "mnaId" || obj[key] === null || obj[key] === ""
        );
        if (!allValuesAreNullOrEmpty) {
          result.add = result.add || [];
          result.add = [...result.add, obj];
        }
      }

      return result;
    },
    { add: null, edit: null, delete: null }
  );

  changedValues[keyName]?.forEach((item: any) => {
    const deletedObj = data[keyName]?.map((obj: any) => {
      if (obj?.id === item.id) {
        return obj.id;
      } else {
        return null
      }
    });
    if (!deletedObj.includes(item.id)) {
      results.delete = results.delete || [];
      results.delete = [...results.delete, item.id];
    }
  });

  return results;
}
