// React
import React, { useCallback, useEffect, useState } from "react";
// Third party
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
// Componenets
import { Columns } from "../Components/Columns/Columns";
import Breadcrumbs from "../Components/Breadcrumbs/Breadcrumbs";
import Loader from "../Components/Loader";
import Table from "../Components/Tables/Recent-event";
// OpenAPI
import { expiredTokenValidation } from "../api/expiredTokenValidation";
import { getApiData } from "../Controller/getApiDataConfiguration";
// Utils
import { formatAndValidateDate, getFileSize } from "../utils";
// Types
import { Sort, Item, ItemMetaData, ResponseFile } from "../interfaces/pages/variedInterfaces";
// Images
import Close from "../Assets/svg/clsoe.svg";
import mnaIcon from "../Assets/svg/blue-mna.svg";
import { FileRestControllerApi, SectorRestControllerApi } from "../openapi";
import { apiConfig } from "../Components/ConfigurationApi/Configuration";

const MnaDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [content, setContent] = useState<string | undefined>();


  useEffect(() => {
    fetchMnaData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchFeatures = async (): Promise<any[]> => {
    try {
      const api = new getApiData();
      return api.getFeatureControllerApi();
    } catch (error) {
      console.error("Error fetching options:", error);
      expiredTokenValidation(error);
      return [];
    }
  };


  const fetchSectorNameById = async (sectorId: number) => {
    try {
      const api = new SectorRestControllerApi(apiConfig());
      const response = await api.getSectorById(sectorId);
      return response.data?.name || "";
    } catch (error) {
      expiredTokenValidation(error);
    }
  };

  const fetchMnaData = async () => {
    setLoading(true);
    try {
      const api = new getApiData();
      const response = await api.getMnaRestControllerApi2(id, fetchFeatures);
       // Check if sectorAcquirer is a number and not null or empty string
    if (!isNaN(Number(response.sectorAcquirer)) && response.sectorAcquirer !== null && response.sectorAcquirer !== '') {
      response.sectorAcquirer = await fetchSectorNameById(Number(response.sectorAcquirer));
    }

    // Check if sectorTarget is a number and not null or empty string
    if (!isNaN(Number(response.sectorTarget)) && response.sectorTarget !== null && response.sectorTarget !== '') {
      response.sectorTarget = await fetchSectorNameById(Number(response.sectorTarget));
    }
      setData(response);
    } catch (error) {
      expiredTokenValidation(error);
    }
    setLoading(false);
  };

  const columnsInstance = new Columns();

  const fetchMnaNoteList = useCallback(async (pageIndex: number, pageSize: number, sortBy: Sort[]) => {
    const getFetchMnaNoteList = new getApiData();
    return getFetchMnaNoteList.getNoteRestControllerApi2(Number(id), pageIndex, pageSize, sortBy, "Mna");
  }, [id]);

  const openModal = (content: string) => {
    setContent(content);

    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };


  const handleFileDownload = async (id: number, type: string) => {
    try {
      // Set Authorization header
      const headers: {} = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      const api = new FileRestControllerApi(apiConfig());
      const response = await api.downloadFile(id);
      const imageUrl = (response as ResponseFile).config.url;

      // Getting the image url
      const imageResponse = await fetch(imageUrl, { headers });

      // If the request image response it's ok create Blob with the imageResponse
      if (imageResponse.ok) {
        const imageBlob = await imageResponse.blob();

        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(imageBlob);

        // Config file name
        const parts = type.split(".");
        const fileName = parts[0];
        downloadLink.download = `${fileName}.${parts[1] || "png"}`;

        // Add url at document body and simulate click to start the donwload
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        return;
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };  

  return (
    <React.Fragment>
      <div className="p-[30px]">
        <Breadcrumbs
          breadcrumbs={["MNAs", `${data?.name || ""}`]}
          icon={mnaIcon}
          updateItem={() => navigate(`/update-mna/${id}`)}
        />
        <div className="p-[30px] border border-[#D4D2E6] bg-table-light dark:bg-table-dark rounded-md mt-[30px]">
          <div className="flex flex-col md:flex-row gap-[30px]">
            <div className="md:w-1/2 flex flex-col">
              <div className=" overflow-x-auto">
                <div className=" min-w-full inline-block align-middle">
                  <div className="border border-[#DCDBEE] rounded-lg overflow-hidden bg-table-light">
                    <table className="min-w-full dark:bg-table-dark bg-table-light">
                      <caption className="text-left text-[18px] fo nt-semibold bg-primary-default dark:bg-black text-[#FFF] py-[19px] px-[29px]">
                        Company Information
                      </caption>
                      <thead className="bg-table-light dark:bg-table-dark dark:text-white">
                        <tr>
                          <th
                            scope="col"
                            className="px-[30px] py-[20px] text-sub-table-lightdark:bg-table-dark dark:text-white text-base font-semibold"
                          ></th>
                          <th
                            scope="col"
                            className="px-[30px] py-[20px] text-sub-table-lighttext-base font-semibold text-left"
                          >
                            Target
                          </th>
                          <th
                            scope="col"
                            className="px-[30px] py-[20px] text-sub-table-lighttext-base font-semibold text-left"
                          >
                            Acquirer
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-[#DCDBEE] dark:bg-table-dark dark:text-white">
                        <tr>
                          <td className="px-[30px] py-[20px]  text-[#696868] dark:text-white   text-base font-normal">
                            Ticker:
                          </td>
                          <td className="px-[30px] py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.tickerTarget}
                          </td>
                          <td className="px-[30px] py-[20px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.tickerAcquirer}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[30px] py-[20px]  text-[#696868] dark:text-white   text-base font-normal">
                            Name:
                          </td>
                          <td className="px-[30px] py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.target}
                          </td>
                          <td className="px-[30px] py-[20px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.acquirer}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[30px] py-[20px]  text-[#696868] dark:text-white   text-base font-normal">
                            Sector:
                          </td>
                          <td className="px-[30px] py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.sectorTarget}
                          </td>
                          <td className="px-[30px] py-[20px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.sectorAcquirer}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[30px] py-[20px]  text-[#696868] dark:text-white   text-base font-normal">
                            Incorp:
                          </td>
                          <td className="px-[30px] py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.incorpTarget}
                          </td>
                          <td className="px-[30px] py-[20px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.incorpAcquirer}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[30px] py-[20px]  text-[#696868] dark:text-white   text-base font-normal">
                            HQ:
                          </td>
                          <td className="px-[30px] py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.hqTarget}
                          </td>
                          <td className="px-[30px] py-[20px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.hqAcquirer}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[30px] py-[20px]  text-[#696868] dark:text-white   text-base font-normal">
                            Geographic Exposure:
                          </td>
                          <td className="px-[30px] py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.geoExposureTarget}
                          </td>
                          <td className="px-[30px] py-[20px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.geoExposureAcquirer}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[30px] py-[20px]  text-[#696868] dark:text-white   text-base font-normal">
                            Financial Advisor:
                          </td>
                          <td className="px-[30px] py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.financialAdvisorForTarget}
                          </td>
                          <td className="px-[30px] py-[20px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.financialAdvisorForAcquirer}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[30px] py-[20px]  text-[#696868] dark:text-white   text-base font-normal">
                            Legal Counsel:
                          </td>
                          <td className="px-[30px] py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.legalCounselForTarget}
                          </td>
                          <td className="px-[30px] py-[20px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.legalCounselForAcquirer}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:w-1/2">
              <div className="border border-[#E4E3ED] rounded-lg h-full dark:bg-table-dark">
                <div className=" px-[30px] py-[19px] border-b border-[#E5E4FB] bg-primary-default dark:bg-black text-[#fff] text-lg font-semibold flex items-center justify-between rounded-tl-md rounded-tr-md">
                  What Matters
                </div>
                <div>
                  <ul className="list-disc px-[53px] py-[36px]">
                    {data?.whatMatters?.map(
                      (item: Item, index: number) =>
                        item?.note && (
                           <div
                           key={`what-matters-${index}`}

                           className="ql-container ql-snow"
                           style={{ position: "relative" }}
                         >
                           <div
                             className="ql-editor"
                             dangerouslySetInnerHTML={{
                               __html: item?.note || "",
                             }}
                           />
                         </div>
                        )
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-[30px] mt-[29px]">
              <div className="w-full overflow-x-auto">
                <div className=" min-w-full inline-block align-middle">
                  <div className="border border-[#DCDBEE] rounded-lg overflow-hidden">
                    <table className="min-w-full dark:bg-table-dark bg-table-light">
                      <caption className="text-left text-[18px] font-semibold bg-primary-default dark:bg-black  text-[#FFF] py-[19px] px-[29px]">
                      Company Descriptions
                      </caption>
                      <thead>
                        <tr>
                          <th scope="col" className="w-1/3"></th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-[#DCDBEE]">
                        <tr>
                          <td className="px-[30px] py-[21px]  text-[#696868] dark:text-white   text-base font-normal">
                          Descriptions:
                          </td>
                          <td className="px-[30px] py-[10 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                          {data?.description}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[30px] py-[21px]  text-[#696868] dark:text-white   text-base font-normal">
                          Competitors:
                          </td>
                          <td className="px-[30px] py-[10 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.competitors}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[30px] py-[21px]  text-[#696868] dark:text-white   text-base font-normal">
                          Customers:
                          </td>
                          <td className="px-[30px] py-[10 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.customers}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-[30px] mt-[29px]">
            <div className="md:w-1/2 flex flex-col">
              <div className=" overflow-x-auto">
                <div className=" min-w-full inline-block align-middle">
                  <div className="border border-[#DCDBEE] rounded-lg overflow-hidden">
                    <table className="min-w-full dark:bg-table-dark bg-table-light">
                      <caption className="text-left text-[18px] font-semibold bg-primary-default dark:bg-black  text-[#FFF] py-[19px] px-[29px]">
                        MNA Basics
                      </caption>
                      <thead>
                        <tr>
                          <th scope="col" className="w-1/3"></th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-[#DCDBEE]">
                        <tr>
                          <td className="px-[30px] py-[21px]  text-[#696868] dark:text-white   text-base font-normal">
                          Date Announced:
                          </td>
                          <td className="px-[30px] py-[10 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                          {moment(data?.dateAnnounced).format("MM/DD/YYYY")}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[30px] py-[21px]  text-[#696868] dark:text-white   text-base font-normal">
                            Terms:
                          </td>
                          <td className="px-[30px] py-[10 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.terms}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[30px] py-[21px]  text-[#696868] dark:text-white   text-base font-normal">
                            Break Fee:
                          </td>
                          <td className="px-[30px] py-[10 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.terminationFeeAcquirer}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[30px] py-[21px]  text-[#696868] dark:text-white   text-base font-normal">
                            MNA value:
                          </td>
                          <td className="px-[30px] py-[10 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.size}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[30px] py-[21px]  text-[#696868] dark:text-white   text-base font-normal">
                            Premium:
                          </td>
                          <td className="px-[30px] py-[10 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.premium}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[30px] py-[21px]  text-[#696868] dark:text-white   text-base font-normal">
                            Closing Language:
                          </td>
                          <td className="px-[30px] py-[21px]  text-[#000817] dark:text-white   text-base font-medium text-left truncate max-w-[calc(100vw-80rem)]">
                            {data?.closingLanguage}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[30px] py-[21px]  text-[#696868] dark:text-white   text-base font-normal">
                          Termination Fee:
                          </td>
                          <td className="px-[30px] py-[21px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.terminationFee}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[30px] py-[21px]  text-[#696868] dark:text-white   text-base font-normal">
                          Reverse Termination Fee:
                          </td>
                          <td className="px-[30px] py-[21px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.terminationFeeReverse}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[30px] py-[21px]  text-[#696868] dark:text-white   text-base font-normal flex items-start">
                            Out Dates:
                          </td>
                          <td
                            colSpan={2}
                            className="px-[30px] py-[21px]  text-[#000817] dark:text-white   text-base font-medium text-left"
                          >
                            {data.outDates?.map(
                              (item: Item, index: number) => (
                                <div
                                  key={`out-dates${index}`}
                                  className={`flex justify-between items-start ${data.outDates.length - 1 !== index && "mb-4"}`}
                                >
                                  <div className="w-1/2">
                                    {moment(item?.dateEvent).format("MM/DD/YYYY")}
                                  </div>
                                  <div className="w-1/2">{item?.comment}</div>
                                </div>
                              )
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[30px] py-[21px]  text-[#696868] dark:text-white   text-base font-normal flex items-start">
                          Broker Closing Estimate Date:
                          </td>
                          <td
                            colSpan={2}
                            className="px-[30px] py-[21px]  text-[#000817] dark:text-white   text-base font-medium text-left"
                          >
                            {data.brokerClosingDates?.map(
                              (item: Item, index: number) => (
                                <div
                                  key={`out-dates${index}`}
                                  className={`flex justify-between items-start ${data.brokerClosingDates.length - 1 !== index && "mb-4"}`}
                                >
                                  <div className="w-1/2">
                                    {moment(item?.dateEvent).format("MM/DD/YYYY")}
                                  </div>
                                  <div className="w-1/2">{item?.comment}</div>
                                </div>
                              )
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[30px] py-[21px]  text-[#696868] dark:text-white   text-base font-normal flex items-start">
                          Financing Date:
                          </td>
                          <td
                            colSpan={2}
                            className="px-[30px] py-[21px]  text-[#000817] dark:text-white   text-base font-medium text-left"
                          >
                            {data.financingDates?.map(
                              (item: Item, index: number) => (
                                <div
                                  key={`out-dates${index}`}
                                  className={`flex justify-between items-start ${data.financingDates.length - 1 !== index && "mb-4"}`}
                                >
                                  <div className="w-1/2">
                                    {moment(item?.dateEvent).format("MM/DD/YYYY")}
                                  </div>
                                  <div className="w-1/2">{item?.comment}</div>
                                </div>
                              )
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[30px] py-[21px]  text-[#696868] dark:text-white   text-base font-normal">
                            Required to Litigate Comment:
                          </td>
                          <td className="px-[30px] py-[10 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.requiredToLitigateComment}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[30px] py-[21px]  text-[#696868] dark:text-white   text-base font-normal">
                            Required to Litigate:
                          </td>
                          <td className="px-[30px] py-[10 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.requiredToLitigate ? "True" : "False"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:w-1/2 flex flex-col">
              <div className="overflow-x-auto">
                <div className="min-w-full inline-block">
                  <div className="border border-[#DCDBEE] rounded-lg overflow-hidden">
                    <table className="min-w-full dark:bg-table-dark bg-table-light">
                      <caption className="text-left text-[18px] font-semibold bg-primary-default dark:bg-black  text-[#FFF] py-[19px] px-[29px]">
                        Holders Top 5 Holders
                      </caption>
                      <tbody className="divide-y divide-[#DCDBEE]">
                        <tr className="flex  justify-between items-center w-full">
                          <td className="px-[27px] wh py-[10px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            8.30%
                          </td>
                          <td className="px-[27px] wh py-[10px] text-[#007B1B] text-base font-medium text-left">
                            ATVI
                          </td>
                          <td className="px-[27px] wh py-[10px] text-[#D70000] text-base font-medium text-left">
                            Vanguard
                          </td>
                          <td className="px-[27px] wh py-[10px]  text-[#696868] dark:text-white   text-xs font-normal leading-5">
                            <div> Changed by: James</div>
                            <div>Date: November 11, 2023</div>
                          </td>
                        </tr>
                        <tr className="flex  justify-between items-center w-full">
                          <td className="px-[27px] wh py-[10px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            8.30%
                          </td>
                          <td className="px-[27px] wh py-[10px] text-[#007B1B] text-base font-medium text-left">
                            ATVI
                          </td>
                          <td className="px-[27px] wh py-[10px] text-[#D70000] text-base font-medium text-left">
                            Vanguard
                          </td>
                          <td className="px-[27px] wh py-[10px]  text-[#696868] dark:text-white   text-xs font-normal leading-5">
                            <div> Changed by: James</div>
                            <div>Date: November 11, 2023</div>
                          </td>
                        </tr>
                        <tr className="flex  justify-between items-center w-full">
                          <td className="px-[27px] wh py-[10px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            8.30%
                          </td>
                          <td className="px-[27px] wh py-[10px] text-[#007B1B] text-base font-medium text-left">
                            ATVI
                          </td>
                          <td className="px-[27px] wh py-[10px] text-[#D70000] text-base font-medium text-left">
                            Vanguard
                          </td>
                          <td className="px-[27px] wh py-[10px]  text-[#696868] dark:text-white   text-xs font-normal leading-5">
                            <div> Changed by: James</div>
                            <div>Date: November 11, 2023</div>
                          </td>
                        </tr>
                        <tr className="flex  justify-between items-center w-full">
                          <td className="px-[27px] wh py-[10px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            8.30%
                          </td>
                          <td className="px-[27px] wh py-[10px] text-[#007B1B] text-base font-medium text-left">
                            ATVI
                          </td>
                          <td className="px-[27px] wh py-[10px] text-[#D70000] text-base font-medium text-left">
                            Vanguard
                          </td>
                          <td className="px-[27px] wh py-[10px]  text-[#696868] dark:text-white   text-xs font-normal leading-5">
                            <div> Changed by: James</div>
                            <div>Date: November 11, 2023</div>
                          </td>
                        </tr>
                        <tr className="flex  justify-between items-center w-full">
                          <td className="px-[27px] wh py-[10px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            8.30%
                          </td>
                          <td className="px-[27px] wh py-[10px] text-[#007B1B] text-base font-medium text-left">
                            ATVI
                          </td>
                          <td className="px-[27px] wh py-[10px] text-[#D70000] text-base font-medium text-left">
                            Vanguard
                          </td>
                          <td className="px-[27px] wh py-[10px]  text-[#696868] dark:text-white   text-xs font-normal leading-5">
                            <div> Changed by: James</div>
                            <div>Date: November 11, 2023</div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="overflow-x-auto mt-[30px]">
                <div className=" min-w-full inline-block align-middle">
                  <div className="border border-[#DCDBEE] rounded-lg overflow-hidden">
                    <table className="w-full rounded-bl-lg rounded-br-lg">
                      <caption className="text-left text-[18px] font-semibold bg-primary-default  dark:bg-table-dark text-[#FFF] py-[19px] px-[29px]">
                        Contacts
                      </caption>
                      <tbody className="divide-y divide-[#DCDBEE]">
                        <tr>
                          <td className="px-[27px] py-[21px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            [ ]:
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[27px] py-[21px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            [ ]:
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[27px] py-[21px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            Info agent:
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[27px] py-[21px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            Transfer agent: Broadridge
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {data?.links?.length > 0 && (
                <div className="overflow-x-auto mt-[30px]">
                  <div className="min-w-full inline-block align-middle">
                    <div className="border border-[#DCDBEE] rounded-lg overflow-hidden">
                      <table className="w-full rounded-bl-lg rounded-br-lg">
                        <caption className="text-left text-[18px] font-semibold bg-primary-default  dark:bg-black text-[#FFF] py-[19px] px-[29px]">
                          Links for Merger Documents
                        </caption>
                        <thead>
                          <tr>
                            <th scope="col" className="w-1/3"></th>
                            <th scope="col" className="w-2/3"></th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-[#DCDBEE]">
                          {data?.links?.map(
                            (item: ItemMetaData, index: number) => (
                              <tr key={`links-${index}`}>
                                <td className="px-[27px] py-[20px]  text-[#696868] dark:text-white   text-base font-medium text-left">
                                  {item?.category}
                                </td>
                                <td className="px-[30px] py-[20px]  text-[#000817] dark:text-white   text-base font-medium truncate max-w-[calc(100vw-72rem)]">
                                  <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={item.link}
                                  >
                                    {item?.link}
                                  </a>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="mt-[29px] overflow-x-auto">
            <div className=" min-w-full inline-block align-middle">
              <div className="border border-[#DCDBEE] rounded-lg overflow-hidden">
                <table className="min-w-full  dark:bg-table-dark">
                  <caption className="text-left text-[18px] font-semibold bg-primary-default dark:bg-black text-[#FFF] py-[19px] px-[29px]">
                    Features
                  </caption>
                  {data?.features?.length > 0 && (
                    <thead className="border-b-2 border-[#DCDBEE]">
                      <tr>
                        <th
                          scope="col"
                          className="text-left py-[10px] px-[29px]"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="text-left py-[10px] px-[29px]"
                        >
                          Value
                        </th>
                      </tr>
                    </thead>
                  )}
                  <tbody className="divide-y divide-[#DCDBEE]">
                    {data?.features?.length > 0 ? (
                      data.features?.map((value: any, index: number) => (
                        <tr key={`features-${index}`}>
                          <td className="px-[30px] py-[10px]  text-[#696868] dark:text-white   text-base font-normal">
                            {value.name}
                          </td>
                          <td className="px-[30px] py-[10px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {value?.value}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <div className="text-primary font-bold text-base p-4 text-center">
                        No data found
                      </div>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="overflow-x-auto mt-[30px]">
            <div className="min-w-full inline-block align-middle">
              <div className="border border-[#DCDBEE] rounded-lg overflow-hidden">
                <div className="min-w-full rounded-bl-lg rounded-br-lg">
                  <div className="text-left font-semibold bg-primary-default dark:bg-black text-[#FFF] py-[19px] px-[29px]">
                    <div className="flex items-center justify-between">
                      <div className="text-xs xs:text-xs sm:text-sm md:text-base lg:text-lg">
                         Notes
                      </div>
                    </div>
                  </div>
                  <div className="p-[30px]">
                    <Table
                      fetchData={fetchMnaNoteList}
                      columns={columnsInstance.NotesListDetail()}
                      additionalDataKey="notes"
                      openModal={openModal}
                    />
                    {}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="overflow-x-auto mt-[30px]">
            <div className="min-w-full inline-block align-middle">
              <div className="border border-[#DCDBEE] rounded-lg overflow-hidden">
                <table className="min-w-full dark:bg-table-dark bg-table-light rounded-bl-lg rounded-br-lg">
                  <thead className="bg-primary-default dark:bg-black">
                    <tr>
                      <th
                        scope="col"
                        className="px-[30px] py-[20px] text-[#fff] text-base font-semibold text-left
                    "
                      >
                        Condition
                      </th>
                      <th
                        scope="col"
                        className="px-[30px] py-[20 px] text-[#fff] text-base font-semibold text-left"
                      >
                        Filed
                      </th>
                      <th
                        scope="col"
                        className="px-[30px] py-[20px] text-[#fff] text-base font-semibold text-left"
                      >
                        Expected / Confirmed
                      </th>
                      <th
                        scope="col"
                        className="px-[30px] py-[20px] text-[#fff] text-base font-semibold text-left"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="w-[300px] px-[30px] py-[20px] text-[#fff] text-base font-semibold text-left"
                      >
                        Commentary
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-[#DCDBEE]">
                    {data?.conditions?.length > 0 ? (
                      data?.conditions?.map((item: any, index: number) => (
                        <tr key={`conditions-${index}`}>
                          <td className="px-[30px] py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {`${item?.category?.name}  ${
                              item?.category?.name === "Other"
                                ? `- ${item?.categoryOtherComment}`
                                : ""
                            }`}
                          </td>
                          <td className="px-[30px] py-[20px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {item?.filed !== null &&
                              moment(formatAndValidateDate(item?.filed)).format(
                                "MM/DD/YYYY"
                              )}
                          </td>
                          <td className="px-[30px] py-[20px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {item?.expectedConfirmed !== null &&
                              moment(item?.expectedConfirmed).format(
                                "MM/DD/YYYY"
                              )}
                          </td>
                          <td className="px-[30px] py-[20px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {item?.statusId}
                          </td>
                          <td className="px-[30px] py-[20px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {item?.commentary}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="text-center">
                        <td
                          colSpan={columnsInstance.MnaDetails()?.length}
                          className="text-primary font-bold text-base p-4"
                        >
                          None
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="overflow-x-auto mt-[30px]">
            <div className="min-w-full inline-block align-middle">
              <div className="border border-[#DCDBEE] rounded-lg overflow-hidden">
                <table className="min-w-full dark:bg-table-dark bg-table-light rounded-bl-lg rounded-br-lg">
                  <thead className="bg-primary-default dark:bg-black">
                    <tr>
                      <th
                        scope="col"
                        className="px-[30px] py-[20px] text-[#fff] text-base font-semibold text-left
                    "
                      >
                        Filename
                      </th>
                      <th
                        scope="col"
                        className="px-[30px] py-[20 px] text-[#fff] text-base font-semibold text-left"
                      >
                        Size
                      </th>
                      <th
                        scope="col"
                        className="px-[30px] py-[20px] text-[#fff] text-base font-semibold text-left"
                      >
                        Date modified
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-[#DCDBEE]">
                    {data?.files?.length > 0 ? (
                      data?.files?.map((item: any, index: number) => (
                        <tr key={`files-${index}`}>
                          <td className="px-[30px] py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            <button
                              onClick={() =>
                                handleFileDownload(item.filename, item.fileId)
                              }
                            >
                              {item.filename}
                            </button>
                          </td>
                          <td className="px-[30px] py-[20px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {getFileSize(item.size)}
                          </td>
                          <td className="px-[30px] py-[20px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {moment(item?.dateModified).format("MM/DD/YYYY")}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="text-center">
                        <td
                          colSpan={3}
                          className="text-primary font-bold text-base p-4"
                        >
                          None
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && <Loader loading={loading} />}
      {isModalOpen && content && (
        <div
          className={`fixed top-0 left-0 z-30 flex h-full min-h-screen w-full items-center justify-center px-4 py-5  ${
            isModalOpen ? "translate-x-0" : "translate-x-full"
          }`}
        >
          <div
            className="absolute inset-0 transition-opacity bg-[#222222]"
            onClick={closeModal}
          ></div>
          <div className="pointer-events-auto relative flex h-[680px] w-[1440px] flex-col overflow-hidden rounded-md border-none bg-[#EAE5F7] bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
            <div className="flex justify-end p-5">
              <img
                src={Close}
                alt="clsoe"
                onClick={() => closeModal()}
                className="rounded-full p-2 border border-[#E6E6F9] bg-b-seconday-light cursor-pointer"
              />
            </div>
            <h2 className="px-[50px] pt-[18px] pb-5 text-[#fff] bg-primary-default dark:bg-black font-semibold text-lg">
              Description
            </h2>
            {/*Modal body*/}
            <div className="relative overflow-y-auto py-4 px-[50px]">
              <ul className="list-disc">
                <li
                  className=" text-[#393C51]  dark:text-white  text-sm font-normal leading-5 capitalize mb-3"
                  dangerouslySetInnerHTML={{
                    __html: content || "",
                  }}
                />
              </ul>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default MnaDetails;
