// React
import React, { useCallback, useEffect, useState } from "react";
// Third party
import { useNavigate, useParams } from "react-router-dom";
// Components
import Breadcrumbs from "../Components/Breadcrumbs/Breadcrumbs";
import Loader from "../Components/Loader";
import Table from "../Components/Tables/Recent-event";
import { Columns } from "../Components/Columns/Columns";
// OpenAPI
import { apiConfig } from "../Components/ConfigurationApi/Configuration";
import { expiredTokenValidation } from "../api/expiredTokenValidation";
import { RegulatoryRestControllerApi } from "../openapi";
// Images
import RegulatoryIcon from "../Assets/svg/Regulatory.svg";
import Close from "../Assets/svg/clsoe.svg";
import { getApiData } from "../Controller/getApiDataConfiguration";
// Types
import { Sort } from "../interfaces/pages/variedInterfaces";

const RegulatoryDetails = () => {
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [content, setContent] = useState<string | undefined>();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    fetchFundamentalData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchFundamentalData = async () => {
    setLoading(true);
    try {
      const api = new RegulatoryRestControllerApi(apiConfig());
      const response = await api.getRegulatoryById(Number(id));
      setData({ ...response?.data } || {});
    } catch (error) {
      expiredTokenValidation(error);
    }
    setLoading(false);
  };

  const fetchRegulatoryNoteList = useCallback(async (pageIndex: number, pageSize: number, sortBy: Sort[]) => {
    const getFetchRegulatoryNoteList = new getApiData();
    return getFetchRegulatoryNoteList.getNoteRestControllerApi2(Number(id), pageIndex, pageSize, sortBy, "Regulatory");
  }, [id]);

  const columnsInstance = new Columns();

  const openModal = (content: string) => {
    setContent(content);

    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };


  return (
    <React.Fragment>
      {loading && <Loader loading={loading} />}
      <div className="p-[30px]">
        <Breadcrumbs
          breadcrumbs={["Regulatory", `${data?.name || ""}`]}
          icon={RegulatoryIcon}
          addItem={() => navigate("/regulatory/add")}
          updateItem={() => navigate(`/update-regulatory/${id}`)}
        />
        <div className="px-[30px] pt-5 pb-[30px] border border-[#D4D2E6] rounded-md mt-[30px]">
          <div className="flex flex-col md:flex-row gap-[30px] mt-[29px]">
            <div className="md:w-1/2 flex flex-col">
              <div className=" overflow-x-auto">
                <div className=" min-w-full inline-block align-middle">
                  <div className="border border-[#DCDBEE] rounded-lg overflow-hidden bg-[#FAFAFE]">
                    <table className="min-w-full dark:bg-primary-dark">
                      <caption className="text-left text-[18px] font-semibold bg-primary-default dark:bg-black text-[#FFF] py-[19px] px-[29px]">
                        Regulatory Information
                      </caption>
                      <tbody className="divide-y divide-[#DCDBEE]">
                        <tr>
                          <td className="px-[30px] py-[20px]  text-[#696868]  dark:text-white   text-base font-normal">
                            Description:
                          </td>
                          <td className="px-[30px] py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.description}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[30px] py-[20px]  text-[#696868] dark:text-white   text-base font-normal">
                            Purpose:
                          </td>
                          <td className="px-[30px] py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.purpose}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[30px] py-[20px]  text-[#696868] dark:text-white   text-base font-normal">
                            Mandate:
                          </td>
                          <td className="px-[30px] py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.mandate}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[30px] py-[20px]  text-[#696868] dark:text-white   text-base font-normal">
                            Timing:
                          </td>
                          <td className="px-[30px] py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.timing}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[30px] py-[20px]  text-[#696868] dark:text-white   text-base font-normal">
                            Commentary:
                          </td>
                          <td className="px-[30px] py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.commentary}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:w-1/2 bg-[#FAFAFE] dark:bg-primary-dark">
              <div className="border border-[#E4E3ED] rounded-lg h-full">
                <div className=" px-[30px] py-[19px] border-b border-[#E5E4FB] bg-primary-default dark:bg-black text-[#fff] dark:text-white text-lg font-semibold flex items-center justify-between rounded-tl-md rounded-tr-md">
                  Process
                </div>
                <div className="bg-[#FAFAFE]]  px-[30px] py-[19px] ">
                  <div
                    className="ql-container ql-snow"
                    style={{ position: "relative" }}
                  >
                    <div
                      className="ql-editor"
                      dangerouslySetInnerHTML={{
                        __html: data?.process,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="overflow-x-auto mt-[30px]">
            <div className="min-w-full inline-block align-middle">
              <div className="border border-[#DCDBEE] rounded-lg overflow-hidden">
                <div className="min-w-full rounded-bl-lg rounded-br-lg">
                  <div className="text-left font-semibold bg-primary-default dark:bg-black text-[#FFF] py-[19px] px-[29px]">
                    <div className="flex items-center justify-between">
                      <div className="text-xs xs:text-xs sm:text-sm md:text-base lg:text-lg">
                         Notes
                      </div>
                    </div>
                  </div>
                  <div className="p-[30px]">
                    <Table
                      fetchData={fetchRegulatoryNoteList}
                      columns={columnsInstance.NotesListDetail()}
                      additionalDataKey="notes"
                      openModal={openModal}
                    />
                    {}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && content && (
        <div
          className={`fixed top-0 left-0 z-30 flex h-full min-h-screen w-full items-center justify-center px-4 py-5  ${
            isModalOpen ? "translate-x-0" : "translate-x-full"
          }`}
        >
          <div
            className="absolute inset-0 transition-opacity bg-[#222222]"
            onClick={closeModal}
          ></div>
          <div className="pointer-events-auto relative flex h-[680px] w-[1440px] flex-col overflow-hidden rounded-md border-none bg-[#EAE5F7] bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
            <div className="flex justify-end p-5">
              <img
                src={Close}
                alt="clsoe"
                onClick={() => closeModal()}
                className="rounded-full p-2 border border-[#E6E6F9] bg-b-seconday-light cursor-pointer"
              />
            </div>
            <h2 className="px-[50px] pt-[18px] pb-5 text-[#fff] bg-primary-default dark:bg-black font-semibold text-lg">
              Description
            </h2>
            {/*Modal body*/}
            <div className="relative overflow-y-auto py-4 px-[50px]">
              <ul className="list-disc">
                <li
                  className=" text-[#393C51]  dark:text-white  text-sm font-normal leading-5 capitalize mb-3"
                  dangerouslySetInnerHTML={{
                    __html: content || "",
                  }}
                />
              </ul>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default RegulatoryDetails;
