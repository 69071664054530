// Components
import DeleteTooltip from "../../Delete-Tooltip";
import InputField from "../../Common-inputs";
// Types
import { StepBase } from "..";

interface StepSixProps extends StepBase {
  Minus: string;
  linkCategoryList: any;
  Plus: string;
}

export default function StepSix(props: StepSixProps) {
  const {
    association,
    deleteID,
    deleteModal,
    formik,
    handleRemoveAssociation,
    linkCategoryList,
    Minus,
    Plus,
    serverError,
    setAssociation,
    setDeleteID,
    setDeleteModal,
  } = props;

  const handleAddLinks = () => {
    formik.setValues({
      ...formik.values,
      links: [
        ...formik.values.links,
        {
          linkCategoryId: "",
          link: "",
          mnaId: -1,
        },
      ],
    });
  };

  return (
    <div className="border border-[#E4E3ED] rounded-lg dark:bg-table-dark bg-table-light">
      <div className="px-[23px] py-[30px]">
        <div>
          {formik.values.links?.map((link: any, index: number) => (
            <div
              key={index}
              className={`flex flex-col gap-[30px] xl:flex-row items-center ${
                index === formik.values.links?.length - 1 ? " " : "mb-[30px]"
              }`}
            >
              <div className="w-full xl:w-1/3">
                <InputField
                  label="Category"
                  id={`links[${index}].linkCategoryId`}
                  name={`links[${index}].linkCategoryId`}
                  type="select"
                  placeholder="Select"
                  serverError={serverError || ""}
                  formik={formik}
                  showIcon
                  optionList={linkCategoryList}
                  labelKey="name"
                  valueKey="id"
                  isDynamic={true}
                  value={link.linkCategoryId}
                />
              </div>
              <div className="w-full xl:w-1/3">
                <InputField
                  label="URL"
                  id={`links[${index}].link`}
                  name={`links[${index}].link`}
                  type="text"
                  placeholder="Enter here..."
                  formik={formik}
                  serverError={serverError}
                  showIcon
                  isDynamic={true}
                  value={link?.link}
                />
              </div>
              <div className="w-full xl:w-1/3 relative">
                <button
                  onClick={() => {
                    setDeleteID(index);
                    setAssociation("links");
                    setDeleteModal(true);
                  }}
                  type="button"
                  className="text-secondary text-base font-medium flex items-center gap-[6px]"
                >
                  <img src={Minus} alt="minus" /> Remove
                </button>
                {deleteID === index && association === "links" && (
                  <DeleteTooltip
                    className="bottom-10"
                    tooltip={deleteModal}
                    setTooltip={setDeleteModal}
                    onDeleteItem={handleRemoveAssociation}
                    top
                  />
                )}
              </div>
            </div>
          ))}
          <div className="flex justify-center items-center mt-[30px]">
            <button
              type="button"
              data-testid="AddnewLink"
              onClick={handleAddLinks}
              className="text-secondary text-lg py-4  font-bold flex items-center justify-center  border border-[#C3C1DF] w-full bg-[#FAFAFE] dark:bg-button-dark dark:text-white"
            >
              <img src={Plus} alt="plus" className="dark:brightness-200" /> Add
              new
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
